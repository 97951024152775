import React from 'react';
import { DashboardHeader } from '../../constants/DashboardHeader';
import Iconedit from '../../images/icons/Iconedit.png';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './manageform.css';

const Manageform = () => {
  const navigate = useNavigate();
  return (
    <>
      <DashboardHeader name="Manage Form" />
      <div className="manage-form-wrapper">
        <div className="risk-ass-div">
          <div className="risk-ass-first-row">
            <div className="risk-head">Risk assessment form</div>
            <Button
              style={{
                float: 'right',
                backgroundColor: '#1334B9',
              }}
              className="edit-risk-btn"
              onClick={() => {
                navigate('/dashboard/riskassessment');
              }}>
              Edit &nbsp;&nbsp;
              <Image src={Iconedit}></Image>
            </Button>
          </div>
          <div className="row col-10 risk-ass-sec-row mt-3">
            The Risk Assessment Form is a comprehensive document used to assess
            and evaluate potential risks and hazards associated with a specific
            activity, process, or environment.
          </div>
        </div>

        <div className="risk-ass-div">
          <div className="risk-ass-first-row">
            <div className="risk-head">Daily report form</div>
            <Button
              style={{
                float: 'right',
                backgroundColor: '#1334B9',
              }}
              className="edit-risk-btn"
              onClick={() => navigate('/dashboard/dailyreport')}
            >
              Edit &nbsp;&nbsp;
              <Image src={Iconedit}></Image>
            </Button>
          </div>
          <div className="row col-10 risk-ass-sec-row mt-3">
            The Daily Report Form is a standardized document used to capture and
            summarize key information and activities that occur on a daily
            basis.
          </div>
        </div>
      </div>
    </>
  );
};

export default Manageform;
