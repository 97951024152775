import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DeleteModal = (props) => {
  function handleClose() {
    props.changeDeleteModal(false);
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered>
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">Delete Organizations</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <h3 className="personalLabels">
          Are you sure you want to delete this Organization?
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button className="backButton" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          className="modalOkButton"
          onClick={async () => {
            let url = api_urls.Organization + props.userid + '/';
            await ApiRequests(url, 'DELETE');
            handleClose();
            toast.success('Organization Deleted Successfully');
            props.clickdelete();
          }}>
          Delete Organization
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
