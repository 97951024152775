import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { api_urls } from '../../constants/constants';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { toast } from 'react-toastify';


export const DayOffReportModal = (props) => {
    console.log("MenuOptionModal",props.menuOption)
    const [year, setYear] = useState('');
    function handleClose() {
        props.handleDiscard(false);
    }

    const handleDownload = async () => {
        try {
            let params={};
            if(props.menuOption=='dayoff'){
                if(year === ''){
                   return toast.error('Please select year!');
                }
                 params = {
                    organization_id: sessionStorage.getItem('organization_id') === 'null' ? 2 : sessionStorage.getItem('organization_id'),
                    year: year
                };
            }
            else{
             params = {
                organization_id: sessionStorage.getItem('organization_id') === 'null' ? 2 : sessionStorage.getItem('organization_id'),
                // year: year
            };
        }
            const token = sessionStorage.getItem('token');
            const apiKey=props.menuOption=='dayoff' ? api_urls.dayoff_report : props.menuOption=='medical' ? api_urls.medical_report : props.menuOption=='qualification'? api_urls.qualification_report : '';
            const response = await axios.get(apiKey, {
                params: params,
                responseType: 'blob',
                headers: {
                    Authorization: token ? `token ${token}` : '',
                },
            });

            // Extract the filename from the Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let filename = 'daysOff_report.xlsx'; 
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }

            saveAs(response.data, filename);
            handleClose();
            toast.success("Excel file downloaded successfully")
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= currentYear - 20; i--) {
        years.push(i);
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header closeButton>
                {/* <Modal.Title className="modalTitle">Download Day Off Report</Modal.Title> */}
                <Modal.Title className="modalTitle">Download {props.menuOption=='dayoff' ? 'Day Off ': props.menuOption=='medical' ? 'Medical ': props.menuOption=='qualification'? 'Qualification ':''}Report</Modal.Title>
            </Modal.Header>
           {props.menuOption=='dayoff' && <Modal.Body className="modalBody">
                <Form.Group controlId="year">
                    <Form.Label>Year</Form.Label>
                    <Form.Select
                        aria-label="Select Year"
                        onChange={(e) => setYear(e.target.value)}
                        value={year}
                    >
                        <option value="">Select Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Modal.Body>}
            <Modal.Footer>
                <Button className="backButton" onClick={handleClose}>
                    Close
                </Button>

                <Button
                    className="modalOkButton"
                    handleDiscard
                    onClick={() => {
                        handleDownload();
                    }}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
