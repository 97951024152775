import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../Sidebar';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { DiscardChanges } from './DiscardChanges';
import { OrganizationGeneralDetails } from './OrganizationGeneralDetails';
import toggleOn from '../../images/icons/toggleOn.png';
import toggleOff from '../../images/icons/toggleoff.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Iconedit from '../../images/icons/Iconedit.png';
import Save from '../../images/icons/save.png';

import arrowleft from '../../images/icons/arrow-left.png';
import { api_urls, organization_id } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { ToggleOnModal } from './ToggleOnModal';
import { ToggleOffModal } from './ToggleOffModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ViewOrganizations = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const authRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [showToggleOnModal, setShowToggleOnModal] = useState(false);
  const [showToggleOffModal, setShowToggleOffModal] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [showdiscard, setdiscard] = useState(false);
  const [save, setSave] = useState(false);
  const [headerName, setHeaderName] = useState('');

  const handleDiscard = (flag) => {
    if (flag === true) {
      setdiscard(false);
      navigate('/dashboard/organizations', { replace: true });
    } else {
      setdiscard(false);
    }
  };

  let editable = location.state.edit;
  let create = location.state.create;
  let rowclick = location.state.rowclick;
  if (editable) {
    editable = false;
  } else {
    editable = true;
  }
  const [readOnly, setReadOnly] = useState(location.state.edit);
  const [editView, setEditView] = useState(JSON.parse(!editable));
  const [dataFetched, setDataFetched] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [pilotName, setPilotName] = useState('');
  const [pilotId, setPilotId] = useState(null);

  const fetchData = async () => {
    try {
      const url = new URL(window.location.href);
      const id = url.pathname.split('/').pop();
      setUserId(id);

      // let params = { organization_id: organization_id }
      let apiurl = api_urls.Organization + id + '/';
      const response = await ApiRequests(apiurl, 'GET', null);

      setprofileDetails(response.data);
      setPilotName(response.data.owner.name);
      setPilotId(response.data.id);
      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (create) {
    } else {
      fetchData();
    }
  }, [dataFetched, editView]);

  useEffect(() => {
    handleheader();
  }, []);
  const handleheader = (editClicked) => {
    if (editClicked === true) {
      setHeaderName('Edit Organization');
      return;
    }
    if (!create) {
      setHeaderName('Organization Details');
      return;
    } else {
      setHeaderName('Create Organization');
      return;
    }
  };

  useEffect(() => {
  }, [pilotId]);

  const changeToggleOnModal = (state) => {
    setShowToggleOnModal(state);
  };
  const changeToggleOffModal = (state) => {
    setShowToggleOffModal(state);
  };
  const editOrganization = () => {
    setReadOnly(false);
    setEditView(false);
    setSave(true);
    handleheader(true);
  };

  const saveChanges = () => {
    // setReadOnly(true)
    // setEditView(false)
    if (authRef && authRef.current) {
      authRef.current.handleSubmit();
    }

    // childFormRef.current.handleSubmit() // Trigger form submission in child component
  };

  const storeNewOrganizationDetails = async (object) => {
    storeOrganization(object);
  };

  const storeOrganization = async (object) => {
    try {
      const response = await ApiRequests(
        api_urls.CreateOrganization,
        'POST',
        object,
        null,
      );
      if (response.status === 200) {
        toast.success('Organization created successfully');
        navigate('/dashboard/organizations', { replace: true });
      }
    } catch (error) {
      console.error('There was an error!', error);
      if (error.response.data) {
        toast.error(error.response.data.message);
      }
      else {
        toast.error('Error in adding Organization');
      }
    }
  };

  const cancelChanges = async () => {
    setdiscard(true);
  };

  const savedSuccessfully = () => {
    setReadOnly(true);
    setEditView(false);
  };
  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name={headerName} />
          <>
            {rowclick === true ? (
              <Row>
                <Col md={1} xs={1}></Col>
                <Col
                  style={{
                    height: '80vh',
                    marginTop: '20px',
                    paddingRight: '20px',
                    overflowY: 'auto',
                  }}
                  md={10}
                  xs={10}>
                  <Row>
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: 'block',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                    </Col>
                    <Col xs={8} md={8}></Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: '10px',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      padding: '10px',
                    }}>
                    <Col md={5} xs={5}>
                      {/* <Image
                  style={{ float: "left", marginRight: "20px" }}
                  src={profileDetails.image ? profileDetails.image : imageIcon}
                  height={80}
                ></Image> */}
                      <div style={{ marginTop: '10px' }}>
                        <h5>
                          <b>{pilotName}</b>
                        </h5>
                        <p>Organization</p>
                      </div>
                    </Col>
                    {/* <Col md={3} xs={3} style={{
                  marginLeft: "20px",
                  padding: "10px"
                }}>
                
              </Col> */}
                    <Col md={4} xs={4}></Col>
                    <>
                      {location.state.create === true ? null : (
                        <Col
                          md={3}
                          xs={3}
                          style={{
                            display: rowclick === true ? 'none' : 'block',
                          }}>
                          <p style={{ float: 'right', display: 'none' }}>
                            Organization Status &nbsp;
                            <Image
                              src={
                                profileDetails.is_disabled===false
                                  ? toggleOn
                                  : toggleOff
                              }
                              onClick={() => {
                                if (editView && !create) {
                                  if (profileDetails.is_disabled===false) {
                                    setShowToggleOffModal(true);
                                  } else {
                                    setShowToggleOnModal(true);
                                  }
                                }
                              }}></Image>
                          </p>
                        </Col>
                      )}
                    </>
                  </Row>

                  <Row
                    style={{
                      marginTop: '10px',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      padding: '10px',
                    }}>
                    <Col>
                      <OrganizationGeneralDetails
                        ref={authRef}
                        readOnly={readOnly}
                        savedSuccessfully={savedSuccessfully}
                        profileDetails={profileDetails}
                        editView={editView}
                        create={create}
                        storeNewOrganizationDetails={
                          storeNewOrganizationDetails
                        }></OrganizationGeneralDetails>
                    </Col>
                  </Row>
                </Col>
                <Col md={1} xs={1}></Col>
              </Row>
            ) : (
              <Row>
                <Col md={1} xs={1}></Col>
                <Col
                  style={{
                    height: '80vh',
                    marginTop: '20px',
                    paddingRight: '20px',
                    overflowY: 'auto',
                  }}
                  md={10}
                  xs={10}>
                  <Row>
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'block' : 'none',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'none' : 'block',
                        }}
                        className="backButton"
                        onClick={cancelChanges}>
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={8} md={8}></Col>
                    <Col xs={2} md={2} style={{ paddingRight: '0px' }}>
                      <Button
                        style={{
                          float: 'right',
                          backgroundColor: '#1334B9',
                          display:
                            editView && !create && !rowclick ? 'block' : 'none',
                        }}
                        className="editPilot"
                        onClick={editOrganization}>
                        Edit &nbsp;&nbsp;
                        <Image src={Iconedit}></Image>
                      </Button>
                      <Button
                        style={{
                          float: 'right',
                          backgroundColor: '#1334B9',
                          display: save || create ? 'block' : 'none',
                        }}
                        className="editPilot"
                        onClick={saveChanges}>
                        Save &nbsp;&nbsp;
                        <Image src={Save}></Image>
                      </Button>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginTop: '10px',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      padding: '10px',
                    }}>
                    <Col md={5} xs={5}>
                      {/* <Image
                  style={{ float: "left", marginRight: "20px" }}
                  src={profileDetails.image ? profileDetails.image : imageIcon}
                  height={80}
                ></Image> */}
                      <div style={{ marginTop: '10px' }}>
                        <h5>
                          <b>{pilotName}</b>
                        </h5>
                        <p>Organization</p>
                      </div>
                    </Col>
                    {/* <Col md={3} xs={3} style={{
                  marginLeft: "20px",
                  padding: "10px"
                }}>
                
              </Col> */}
                    <Col md={4} xs={4}></Col>
                    {/* {create === true ? null : (
                      <Col
                        md={3}
                        xs={3}
                        style={{
                          display: rowclick === true ? 'none' : 'block',
                        }}>
                        <p style={{ float: 'right' }}>
                          Organization Status &nbsp;
                          <Image
                            src={
                              profileDetails.subscription_active
                                ? toggleOn
                                : toggleOff
                            }
                            onClick={() => {
                              if (editView && !create) {
                                if (profileDetails.subscription_active) {
                                  setShowToggleOffModal(true);
                                } else {
                                  setShowToggleOnModal(true);
                                }
                              }
                            }}></Image>
                        </p>
                      </Col>
                    )} */}
                  </Row>

                  <Row
                    style={{
                      marginTop: '10px',
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      padding: '10px',
                    }}>
                    <Col>
                      <OrganizationGeneralDetails
                        ref={authRef}
                        readOnly={readOnly}
                        savedSuccessfully={savedSuccessfully}
                        profileDetails={profileDetails}
                        editView={editView}
                        create={create}
                        storeNewOrganizationDetails={
                          storeNewOrganizationDetails
                        }></OrganizationGeneralDetails>
                    </Col>
                  </Row>
                </Col>
                <Col md={1} xs={1}></Col>
              </Row>
            )}
          </>
        </Sidebar>

        {showToggleOnModal && (
          <ToggleOnModal
            changeToggleOnModal={changeToggleOnModal}
            show={showToggleOnModal}
            userid={UserId}
            clickdelete={fetchData}
          />
        )}
        {showToggleOffModal && (
          <ToggleOffModal
            changeToggleOffModal={changeToggleOffModal}
            show={showToggleOffModal}
            userid={UserId}
            clickdelete={fetchData}
          />
        )}
      </Container>
      {showdiscard === true && (
        <DiscardChanges show={showdiscard} handleDiscard={handleDiscard} />
      )}
    </>
  );
};
