import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logo.png';
import logoText from '../../images/logoText.png';
import { Formik } from 'formik';
import { useNavigate } from "react-router-dom"
import CheckCricle from '../../images/icons/check-circle-blue.png';

export const PasswordChangeSuccessfull = () => {
  const navigate = useNavigate()
  return (
    <Container fluid>
      <Row style={{ height: '100vh' , overflow: 'auto'}}>
        <Col style={{ height: '100%' }} xs={12} md={6} className="zeropadding">
          <Image
            style={{ height: '100%', width: '100%'}}
            src={logo}
            fluid
            className="Logo-img"
          />
        </Col>
        <Col xs={12} md={6} className='success-cont'>
          <div>
            <Image src={logoText}></Image>
          </div>
          <div className="Auth-form-container">
            <Formik>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
                /* and other goodies */
              }) => (
                <form
                  className="Auth-form"
                  onSubmit={handleSubmit}
                  noValidate>
                  <div
                    className="Auth-form-content d-flex flex-column align-items-center justify-content-center"
                    style={{ marginTop: '-20px' }}>
                    <Image
                      className="Auth-form-title"
                      src={CheckCricle}></Image>
                    <h3>Password Updated</h3>

                    <div className="d-grid gap-2 mt-3">
                      <button
                        onClick={()=> navigate('/login')}
                        isValid={false}
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary "
                        style={{ width: '100%', backgroundColor: ' #1334B9' }}>
                        Sign In Now
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
