import React, { useEffect, useMemo, useState } from 'react';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { convertUtcDateTimeToLocal, formatDate } from '../../utils/util.js';
import {timezoneHandler} from '../../utils/timezoneHandler.js';
import defaultProfile from '../../images/icons/default-profile.png';
import { Menu, MenuItem } from '@mui/material';
import selector from '../../images/icons/selector.png';
import { DashboardHeader } from '../../constants/DashboardHeader';
import Loader from '../Loader/index';
import { Button, Image } from 'react-bootstrap';
import ErrorMsg from '../ErrorMsg';
import { UploadMemo } from './UploadMemo.js';
import viewIcon from '../../images/icons/viewIcon.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './table.css';

export const Memos = () => {
  const [loading, setLoading] = useState(true); // Add loading state
  const [noFound, setNoFound] = useState(false);
  const [products, setProducts] = useState([]);
  const [showUpload, setshowUpload] = useState('');
  const [fileList, setFileList] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  const storeMemoDocument = (data) => {
  }
  const changeModalState = (state) => {
    setshowUpload(state);
  };
  const showfileList = (temp) => {
    setFileList(temp);
  };
  const showUpdatedData = () => {
    toast.success("Memo created successfully");
    setUpdateData(true);
  };

  useEffect(() => {
    let organization_id =
      sessionStorage.getItem('organization_id') === 'null'
        ? 2
        : sessionStorage.getItem('organization_id');
    let body = { organization_id: organization_id };
    fetchData(body);
  }, [updateData]);

  const fetchData = async (body) => {
    setLoading(true);
    try {
      const response = await ApiRequests(
        api_urls.memoProfile,
        'GET',
        null,
        body,
      );

      let tempdata = response.data;
      if (tempdata.length > 0) {
        setLoading(false);
        setNoFound(false);
      } else if (tempdata.length == 0) {
        setNoFound(true);
        setLoading(false);
      }

      setProducts(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const data = useMemo(() => products, [products]);
  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        sticky: 'left',
        disableSortBy: false,
      },
      {
        Header: 'Date Posted',
        accessor: 'uploaded_at',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ value }) => {
          // return <span>{formatDate(value)}</span>;
          return <span>{convertUtcDateTimeToLocal(value)}</span>;
        },
      },
      {
        Header: 'Viewed by',
        accessor: 'viewed_by.length',
        sticky: 'left',
        disableSortBy: false,
        disableFilters: true,
        Cell: ({ value, row }) => {
          const [open, setOpen] = useState(false);
          const [menuItems, setMenuItems] = useState([]);
          const [anchorEl, setAnchorEl] = useState(null);

          const handleMenuOpen = (event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
          };

          const handleMenuClose = () => {
            setAnchorEl(null);
            setOpen(false);
          };
          useEffect(() => {
            const rowData = row.original;
            const viewedBy = rowData.viewed_by;
            // setMenuItems(viewedBy.map((item) => item.name));
            const menuItems = viewedBy.map((item) => ({
              name: item.name,
              profilePic: item.pilot_image,
            }));
            setMenuItems(menuItems);
          }, [row]);

          return (
            <>
              <span
                className=""
                style={{ color: '#1334B9', cursor: 'pointer' }}
                onClick={handleMenuOpen}>
                {value > 0 ? `${value}+` : null}
              </span>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                className="viewed_by_list">
                <MenuItem className="viewed-by-txt">Viewed By</MenuItem>
                {menuItems.map((item, index) => (
                  <MenuItem key={index} className="menu-item-viewedby p-0">
                    <div className="menu-view">
                      <img
                        src={item.profilePic}
                        alt="Profile Pic"
                        className="user-pic"
                      />
                      <span className="menu-name">{item.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </>
          );
        },
      },
      {
        Header: 'Action',
        disableSortBy: true,
        accessor: 'document',
        sticky: 'left',
        Cell: (props) => {
          return (
            <>
              <span style={{ cursor: 'pointer' }}>
                <Image
                  src={viewIcon}
                  onClick={() => {
                    window.open(props.row.original.document);
                  }}></Image>
              </span>
            </>
          );
        },
      },
    ],
    [data],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, pageSize } = state;

  return (

    <>
      <div>
        <DashboardHeader name="Memos" />
        <>
          <div
            style={{
              padding: '0px 30px',
              backgroundColor: '##F3F4F6',
              display: 'flex',
              alignItems: 'center',
              justifyContent: noFound === true ? 'flex-end' : 'space-between',
              marginTop: '20px',
              borderColor: '#1334B9',
            }}>
            <div className="search-bar w-25"></div>
            <div className="right-div d-flex">
              {' '}
              <Button
                className="addNewButton"
                onClick={() => setshowUpload(true)}>
                Add New +
              </Button>
            </div>
          </div>
          <>
            {loading === true ? (
              <Loader loadingMsg="Data is Loading... Please Wait" />
            ) : (
              <>
                <>{noFound === true ? (
                  <ErrorMsg errormsg={'No Records Exist'} />
                ) :
                  (
                    <>
                      <div className="table-container">
                        <table {...getTableProps()} className="table-cls">
                          <thead>
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps(),
                                    )}>
                                    {column.render('Header')}
                                    <span>
                                      &nbsp;
                                      {column.disableSortBy === false ? (
                                        <Image src={selector}></Image>
                                      ) : null}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);
                              return (
                                // <tr {...row.getRowProps()}>
                                //   {row.cells.map((cell, cellIndex) => {
                                //     return (
                                //       <td {...cell.getCellProps()}>
                                //         {cell.render('Cell')}
                                //       </td>
                                //     );
                                //   })}
                                // </tr>
                                <tr {...row.getRowProps()}>
                                  <td
                                    {...row.cells[0].getCellProps()}
                                    className="first-cell">
                                    {row.cells[0].render('Cell')}
                                  </td>
                                  <td
                                    {...row.cells[1].getCellProps()}
                                    className="second-cell">
                                    {row.cells[1].render('Cell')}
                                  </td>
                                  <td
                                    {...row.cells[2].getCellProps()}
                                    className="third-cell">
                                    {row.cells[2].render('Cell')}
                                  </td>
                                  <td
                                    {...row.cells[2].getCellProps()}
                                    className="fourth-cell">
                                    {row.cells[3].render('Cell')}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="paginationBox">
                        <div
                          style={{ float: 'left', gap: '5px' }}
                          className="d-flex align-items-center">
                          <label className="paginationText">
                            Number of rows&nbsp;
                          </label>
                          <select
                            style={{ float: 'right' }}
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}>
                            {[10, 25, 50].map((pageSize) => (
                              <option key={pageSize} value={pageSize}>
                                {pageSize}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div style={{ float: 'right' }}>
                          <button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                              }`}>
                            Prev
                          </button>
                          {pageOptions.map((page, index) => (
                            <button
                              key={index}
                              onClick={() => gotoPage(index)}
                              className={`paginationButton ${pageIndex === index ? 'activeButton' : ''
                                }`}>
                              {index + 1}
                            </button>
                          ))}
                          <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                              }`}>
                            Next
                          </button>
                        </div>
                      </div>

                    </>
                  )}
                </>
              </>
            )}
          </>
        </>
        {showUpload === true && (
          <UploadMemo
            setShowMoal={setshowUpload}
            show={showUpload}
            changeModalState={changeModalState}
            storePilotDocument={storeMemoDocument}
            showfileList={showfileList}
            showUpdatedData={showUpdatedData}
          />
        )}
      </div >
    </>
  );
};
