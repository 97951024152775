import React, { useEffect, useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import logo from "../../images/logo.png"
import logoText from "../../images/logoText.png"
import { Formik } from "formik"
import * as Yup from "yup"
import { Form } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { api_urls } from "../../constants/constants"
import { ApiRequests } from "../https/ApiRequests"
import {
  faEnvelope,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons"
import { GetUrlParameters } from "../../constants/GetUrlParameters"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const UpdatePassword = () => {

  const navigate = useNavigate()
  const [errorState, setErrorState] = useState(false)

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

const userCode=GetUrlParameters("code")
  
  
 
  const schema = Yup.object().shape({
    newPassword: Yup.string().required("Confirm Password is required"),
    confrimNewPassword: Yup.string().required(
      "Confirm new Password is required"
    )
    
  })

  return (
    <Container fluid>
      <Row style={{ height: "100vh" , overflow: 'auto'}}>
        <Col style={{ height: "100%" }} xs={12} md={6} className="zeropadding">
          <Image
            style={{ height: "100%", width: "100%"}}
            src={logo}
            fluid
            className="Logo-img"
          />
        </Col>
        <Col xs={12} md={6} className='success-cont'>
          <div>
            <Image src={logoText}></Image>
          </div>
          <div className="Auth-form-container">
            <Formik
              validationSchema={schema}
              initialValues={{ email: "", password: "" }}
              onSubmit={async values => {
                // sessionStorage.setItem("token","1234567adda")

                
                  if (values.newPassword === values.confrimNewPassword) {
                    let body = {
                      // code: userCode,
                      new_password: values.newPassword,
                      // re_password: values.confrimNewPassword
                    }
                    try {
                      const response = await ApiRequests(api_urls.updatePassword,"POST",body)
                      if (response.status === 200) {
                       
                        navigate("/update-success")
                      }
                    } catch (error) {
                     
                        toast.error(error.response.data.error);
                        console.error("There was an error!", error.response.data.error)
                    
                    }
                   } else {
                    setErrorState(true);
                  }
                
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty
                /* and other goodies */
              }) => (
                <form
                  className="Auth-form"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div
                    className="Auth-form-content"
                    style={{ marginTop: "-20px" }}
                  >
                    <h3 className="Auth-form-title">Update Password</h3>

                    <p className="Auth-form-subtitle">
                      This is your first time logging in. For security purposes,
                      please follow the steps below to change your password:
                    </p>
                    <Form.Group controlId="newPassword">
                      <div
                        className={`form-floating   mb-3 ${
                          touched.newPassword && errors.newPassword
                            ? "has-validation-error"
                            : ""
                        }`}
                      >
                        <Form.Control
                          type={showPassword1 ? "text" : "password"}
                          name="newPassword"
                          placeholder="New Password"
                          value={values.newPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.newPassword && errors.newPassword}
                        />
                        <label for="floatingInput">New Password*</label>
                        {touched.newPassword && errors.newPassword ? null : (
                          <div className="input-group-append ">
                            <button className="btn" type="button" onClick={() => setShowPassword1(!showPassword1)}>
                              <FontAwesomeIcon
                                icon={showPassword1 ? faEye : faEyeSlash}
                              />
                            </button>
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.newPassword}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <Form.Group controlId="confrimNewPassword">
                      <div
                        className={`form-floating   mb-3 ${
                          touched.confrimNewPassword &&
                          errors.confrimNewPassword
                            ? "has-validation-error"
                            : ""
                        }`}
                      >
                        <Form.Control
                          type={showPassword2 ? "text" : "password"}
                          name="confrimNewPassword"
                          placeholder="Confirm New Password"
                          value={values.confrimNewPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.confrimNewPassword &&
                            errors.confrimNewPassword
                          }
                        />
                        <label for="floatingInput">Confirm New Password*</label>
                        {touched.confrimNewPassword &&
                        errors.confrimNewPassword ? null : (
                          <div className="input-group-append ">
                            <button className="btn" type="button" onClick={() => setShowPassword2(!showPassword2)}>
                              <FontAwesomeIcon
                                icon={showPassword2 ? faEye : faEyeSlash}
                              />
                            </button>
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.confrimNewPassword}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="d-grid gap-2 mt-3">
                      <button
                        type="submit"
                        isValid={false}
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary "
                        style={{ width: "100%", backgroundColor: " #1334B9" }}
                      >
                        Update & Login
                      </button>
                      <div>
                        {errorState ? (
                          <p
                            className="form-floating   mb-3 .invalid-feedback"
                            style={{ color: "red" }}
                          >
                            Passwords didn't match
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
