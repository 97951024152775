import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
import closeIcon from '../../images/icons/close-event.png';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './calendar.css';
import { convertCustomLocalDateTimeToUTCForCalender, convertLocalDateTimeToUTCForCalender } from '../../utils/util';

const createTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push(<option key={time} value={time}>{time}</option>);
    }
  }
  return options;
};

const isStartTimeBeforeEndTime = (startTime, endTime) => {
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  return startHour < endHour || (startHour === endHour && startMinute < endMinute);
};


const EventForm = (props) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [showStartDate, setStartDate] = useState('');
  const [showEndDate, setEndDate] = useState('');
  const [showStartDest, setStartDest] = useState('');
  const [showEndDest, setEndDest] = useState('');
  const [start, setStart] = useState(
    props.selectedDate
      ? moment(props.selectedDate).format('YYYY-MM-DDTHH:mm')
      : '',
  );
  const [getInitialValue, setInitialValue] = useState({});
  const [eventData, setEventData] = useState({});
  const [getCustomer, setCustomer] = useState('');
  const [getpilot, setpilot] = useState(null);
  const [getaircraft, setaircraft] = useState(null);
  const [end, setEnd] = useState('');
  const [status, setStatus] = useState('1');
  const [getPilots, setPilots] = useState([]);
  const [getCustomers, setCustomers] = useState([]);
  const [getAircrafts, setAircrafts] = useState([]);
  const [customerVal, setCustomerVal] = useState([]);
  const [aircraftVal, setaircraftVal] = useState([]);
  const [pilotVal, setpilotVal] = useState([]);

  const schema = Yup.object().shape({
    title: status === '1' ? Yup.string().required('Appointment title is required') : Yup.string().required('Additional comments is required'),
    appStartDate: Yup.string().required('Start date is required'),
    appEndDate: status === '1' && Yup.string().required('End date is required'),
  });

  useEffect(() => {
    fetchAppointment(props.id);
  }, []);

  let initialFormValues = {};
  const fetchAppointment = async (id) => {
    let apiUrl = `${api_urls.getAllAppointments}${id}/`;
    let resp = await ApiRequests(apiUrl, 'GET', null);
    getSpecificPilot(resp.data.assigned_pilot_profile);
    getSpecificAircraft(resp.data.aircraft ? resp.data.aircraft.id : null);
    getCustomersData(resp.data.customer);
    setEventData(resp.data);
    setTitle(resp.data.title);
    setStartDate(resp.data.time);
    setEndDate(resp.data.end_time);
    setStartDest(resp.data.start_location);
    setEndDest(resp.data.drop_location);
    initialFormValues = {
      title: resp.data.title || '',
      appStartDate: formatDate(resp.data.time) || '',
      appStartTime: formatTime(resp.data.time) || '',
      appEndDate: formatDate(resp.data.end_time) || '',
      appEndTime: formatTime(resp.data.end_time) || '',
      startPlace: resp.data.start_location || '',
      endPlace: resp.data.drop_location || '',
      note:resp.data.note || '',
      update_group:'false'
    };
    setInitialValue(initialFormValues);
    let statustemp = resp.data.type === 'day_off' ? '2' : '1';
    setStatus(statustemp);
    // let temp = resp.data.customer;
    // let str = '';
    // for (let i = 0; i < temp.length; i++) {
    //   str = str + '' + temp[i].name + ', ';
    // }
    // setCustomer(str.slice(0, str.length - 2));
  };

  const getCustomersData = (customer) => {
    console.log('customer', customer);
    let arr = [];
    for (let i = 0; i < customer.length; i++) {
      arr.push({
        label: customer[i].company,
        value: customer[i].id,
      });
    }
    setCustomer(arr);
  }

  const getSpecificPilot = async (pilot_id) => {
    let apiUrl = `${api_urls.pilotProfile}${pilot_id}/`;
    let resp = await ApiRequests(apiUrl, 'GET', null);
    console.log('resp from specific pilot', resp.data);
    // setpilot(resp.data.owner.name);
    setpilot({
      label: resp.data.owner.name,
      value: resp.data.owner.id,
    })
  };

  const getSpecificAircraft = async (aircraft_id) => {
    let apiUrl = `${api_urls.aircraftProfile}${aircraft_id}/`;
    let resp = await ApiRequests(apiUrl, 'GET', null);
    setaircraft({
      label: resp.data.tail_number,
      value: resp.data.id,
    })
  };

  useEffect(() => {
    fetchPilots();
    fetchCustomers();
    fetchAircrafts();
  }, []);

  const fetchPilots = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.pilotProfile,
        'GET',
        null,
        params,
      );
      let arr = [];
      let tempdata = response.data;
      for (let i = 0; i < tempdata.length; i++) {
        arr.push({
          label: tempdata[i].owner.name,
          value: tempdata[i].owner.id,
        });
      }
      setPilots(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.customerProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      let arr = [];

      for (let i = 0; i < tempdata.length; i++) {
        arr.push({ label: tempdata[i].company, value: tempdata[i].id });
      }

      setCustomers(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEventDelete=()=>{
    props.onClose();
    // setShowDelete(true);
    props.handleDeleteEvent(props.appointment);
  }

  const fetchAircrafts = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      let arr = [];
      const response = await ApiRequests(
        api_urls.aircraftProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      for (let i = 0; i < tempdata.length; i++) {
        arr.push({ label: tempdata[i].tail_number, value: tempdata[i].id });
      }
      setAircrafts(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlecustomerDropdown = (event) => {
    setCustomerVal(event);
    setCustomer(event)
  };

  const handleAircratsDropdown = (event) => {
    setaircraftVal(event);
    setaircraft(event);
  };

  const handlePilotsDropdown = (event) => {
    setpilotVal(event);
    setpilot(event);
  };

  const formatDate = (inputDateTime) => {
    // Convert the input string to a Date object
    var dateTime = new Date(inputDateTime);

    // Extract the desired components
    var year = dateTime.getFullYear();
    var month = ("0" + (dateTime.getMonth() + 1)).slice(-2);  // Add leading zero if necessary
    var day = ("0" + dateTime.getDate()).slice(-2);         // Add leading zero if necessary
    var hours = ("0" + dateTime.getHours()).slice(-2);      // Add leading zero if necessary
    var minutes = ("0" + dateTime.getMinutes()).slice(-2);  // Add leading zero if necessary
    var seconds = ("0" + dateTime.getSeconds()).slice(-2);  // Add leading zero if necessary

    // Assemble the formatted string
    var formattedDateTime = year + "-" + month + "-" + day
    // + " "  + hours + ":" + minutes + ":" + seconds;

    // Output the result
    console.log(formattedDateTime);

    return formattedDateTime;

  }

  const formatTime = (inputDateTime) => {
    // Convert the input string to a Date object
    var dateTime = new Date(inputDateTime);

    // Extract the desired components
    var year = dateTime.getFullYear();
    var month = ("0" + (dateTime.getMonth() + 1)).slice(-2);  // Add leading zero if necessary
    var day = ("0" + dateTime.getDate()).slice(-2);         // Add leading zero if necessary
    var hours = ("0" + dateTime.getHours()).slice(-2);      // Add leading zero if necessary
    var minutes = ("0" + dateTime.getMinutes()).slice(-2);  // Add leading zero if necessary
    var seconds = ("0" + dateTime.getSeconds()).slice(-2);  // Add leading zero if necessary

    // Assemble the formatted string
    var formattedDateTime =
      // year + "-" + month + "-" + day 
      // + " "  + 
      hours + ":" + minutes
    // + ":" + seconds;

    // Output the result
    console.log(formattedDateTime);

    return formattedDateTime;

  }

  const {
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValue,
    validationSchema: schema,
    onSubmit: async () => {
      let temparr = [];
      for (let i = 0; i < getCustomer.length; i++) {
        temparr.push(getCustomer[i].value);
      }
      let body = {};
      if (status === '1') {
        body = {
          title: values.title,
          type: status === '1' ? 'upcoming' : 'day_off',
          time:
            status === '1' ? convertLocalDateTimeToUTCForCalender(values.appStartDate,values.appStartTime):
            //  convertLocalDateTimeToUTCForCalender(values.appStartDate,values.appStartTime) , 
            //  `${values.appStartDate} 00:00:00`,
            convertCustomLocalDateTimeToUTCForCalender(values.appStartDate,'24:00'),
          assigned_pilot: getpilot ? getpilot.value : '',
          organization:
            sessionStorage.getItem('organization_id') === 'null'
              ? 2
              : sessionStorage.getItem('organization_id'),
          customer: status == '1' ? temparr : [],
          aircraft: getaircraft ? getaircraft.value : '',
          start_location: status == '1' ? values.startPlace : '',
          drop_location: status == '1' ? values.endPlace : '',
          end_time:
            status == '1' ? convertLocalDateTimeToUTCForCalender(values.appEndDate,values.appEndTime) : 
            //  `${values.appStartDate} 00:00:00`,
            convertCustomLocalDateTimeToUTCForCalender(values.appStartDate,'24:00'),
            // convertLocalDateTimeToUTCForCalender(values.appEndDate,values.appEndTime) ,
          note : values.note,
          update_group: values.update_group === 'true'
        };
      }
      else if (status === '2') {
        body = {
          title: values.title,
          type: 'day_off',
          time:convertCustomLocalDateTimeToUTCForCalender(values.appStartDate,'24:00'),
            // `${values.appStartDate.split(' ')[0]} 00:01:00`,
            // `${values.appStartDate.split(' ')[0]} 00:00:00`,
          organization:
            sessionStorage.getItem('organization_id') === 'null'
              ? 2
              : sessionStorage.getItem('organization_id'),
          assigned_pilot: getpilot ? getpilot.value : '',
          // end_time:
          //   `${values.appStartDate} 23:59:00`,
          end_time:convertCustomLocalDateTimeToUTCForCalender(values.appStartDate,'24:00'),
          // `${values.appStartDate} 00:00:00`
          update_group: values.update_group === 'true'
        };
      }
// return console.log("KKK", body)
      let dateOk = true;
      if (values.appStartDate == values.appEndDate) {
        if (!isStartTimeBeforeEndTime(values.appStartTime, values.appEndTime)) {
          dateOk = false;
        }
        else {
          dateOk = true;
        }
      } else { dateOk = true };
      if(status === '2'){
        dateOk = true;
      }

      if (dateOk) {
        console.log('body', body);
        try {
          const response = await ApiRequests(
            `${api_urls.createAppointment}${props.id}/`,
            'PATCH',
            body,
            null,
          );
          if (response.data) {
            toast.success('Appointment updated successfully');
            navigate('/dashboard/calendar', { replace: true });
            window.location.reload();
            props.onClose();
          }
        }
        catch (error) {
          console.log('Error in appointment', error);
          if (error.response.data) {
            toast.error(error.response.data.error);
          }
          else {
            toast.error('Error in updating appointment');
          }
        }
      } else {
        toast.error("End time should not be less than or equal to Start Time");
      }
    },
  });

  const radioHandler = (radiostate) => {
    // setStatus(radiostate);
  };

  return (
    <div>
      {Object.keys(getInitialValue).length > 0 && (
        <Modal
          {...props}
          size="md"
          scrollable={true}
          aria-labelledby="contained-modal-title-vcenter"
          className="delete-acc-modal"
          centered>
          <div className="event-header">
            <Modal.Header className="heading border-bottom-0 p-0">
              {status === '1' ? 'Update Appointment' : 'Update Leave'}
            </Modal.Header>
            <img
              src={closeIcon}
              style={{ cursor: 'pointer' }}
              className="event-close cursor-pointer"
              onClick={() => props.onClose()}
            />
          </div>
          <>
            <div className="radio-container">
              <div className="appointment-btn">
                <input
                  type="radio"
                  name="release"
                  checked={status === '1'}
                  onClick={(e) => radioHandler(1)}
                />
                <label
                  for="Appointment"
                  style={{ color: status === '1' ? '#000000' : '#9CA3AF' }}>
                  Appointment
                </label>
              </div>

              <div className="appointment-btn">
                <input
                  type="radio"
                  name="release"
                  checked={status === '2'}
                  onClick={(e) => radioHandler(2)}
                />
                <label
                  for="Appointment"
                  style={{ color: status === '2' ? '#000000' : '#9CA3AF' }}>
                  Pilot Leave
                </label>
              </div>
            </div>
            {status === '1' && (
              <Modal.Body className="modal-body-body">
                <Form onSubmit={handleSubmit} className="add-event-form">

                <Form.Group
                  controlId="isRepeating"
                  className="form-floating viewPilot-Box-select w-100">
                  <div className='row'>
                    <div className="form-check ms-3 col-md-5">
                      <input className="form-check-input"
                        type="radio"
                        name="update_group"
                        id="update_single"
                        value={'false'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.update_group == 'false'}
                      />
                      {console.log("IOIOIOIOIO", values.update_group)}
                      <label style={{ fontWeight: 'normal' }} >
                        Update only this appointment
                      </label>
                    </div>
                    <div className="form-check col-md-5">
                      <input className="form-check-input"
                        type="radio"
                        name="update_group"
                        id="update_single"
                        value={'true'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.update_group == 'true'}
                      />
                      <label style={{ fontWeight: 'normal' }}>
                        Update all grouped appointments
                      </label>
                    </div>
                  </div>

                </Form.Group>

                  <Form.Group
                    controlId="title"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.title && errors.title ? 'has-validation-error' : ''
                      }`}>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="title"
                      value={values.title}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.title && errors.title}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Appointment title
                    </label>

                    {touched.title && errors.title ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="appStartDate"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.appStartDate && errors.appStartDate
                      ? 'has-validation-error startdate-validation-error'
                      : ''
                      }`}>
                    {/* <Form.Control
                      type="datetime-local"
                      name="appStartDate"
                      placeholder="appStartDate"
                      value={values.appStartDate}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={new Date().toISOString().split('T')[0]}
                      isInvalid={touched.appStartDate && errors.appStartDate}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Start Date and time
                    </label> */}
                    <div className='row'>
                      <div className='col-md-8'>
                        <Form.Control
                          type="date"
                          name="appStartDate"
                          placeholder="appStartDate"
                          value={values.appStartDate}
                          className={props.readOnly ? 'disabledColor' : ''}
                          readOnly={props.readOnly}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.appStartDate && errors.appStartDate}
                          minLength={15}
                          onTimeUpdateCapture={20}
                          style={{ height: '3.5rem', paddingTop: '1.5rem' }}
                        />
                        <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0' }}>
                          Start Date*
                        </label>
                      </div>
                      <div className='col-md-4'>
                        <Form.Control
                          as="select"
                          name="appStartTime"
                          value={values.appStartTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.appStartTime && errors.appStartTime}
                          readOnly={props.readOnly}
                          style={{ height: '3.5rem', paddingTop: '1.5rem' }} // Adjust width as needed
                        >
                          {createTimeOptions()}
                        </Form.Control>
                        <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0', marginLeft: '70%' }}>
                          Start Time*
                        </label>
                      </div>
                    </div>

                    {touched.appStartDate && errors.appStartDate ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.appStartDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="appEndDate"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.appEndDate && errors.appEndDate
                      ? 'has-validation-error startdate-validation-error'
                      : ''
                      }`}>
                    {/* <Form.Control
                      type="datetime-local"
                      name="appEndDate"
                      placeholder="appEndDate"
                      value={values.appEndDate}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.appEndDate && errors.appEndDate}
                      min={values.appStartDate ? values.appStartDate : new Date().toISOString().slice(0, 16)} // Set the min attribute to appStartDate value or current time if appStartDate is not set yet.
                    />
                    <label for="floatingInput" className="greyLabel">
                      End Date and time
                    </label> */}

                    <div className='row'>
                      <div className='col-md-8'>
                        <Form.Control
                          type="date"
                          name="appEndDate"
                          placeholder="appEndDate"
                          value={values.appEndDate}
                          className={props.readOnly ? 'disabledColor' : ''}
                          readOnly={props.readOnly}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ height: '3.5rem', paddingTop: '1.5rem' }}
                          isInvalid={touched.appEndDate && errors.appEndDate}
                          // min={new Date().toISOString().slice(0, 16)}
                          // min={values.appStartDate}
                          min={values.appStartDate ? values.appStartDate : new Date().toISOString().slice(0, 16)} // Set the min attribute to appStartDate value or current time if appStartDate is not set yet.
                        />
                        <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0' }}>
                          End Date*
                        </label>
                      </div>
                      <div className='col-md-4'>
                        <Form.Control
                          as="select"
                          name="appEndTime"
                          value={values.appEndTime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.appEndTime && errors.appEndTime}
                          readOnly={props.readOnly}
                          style={{ height: '3.5rem', paddingTop: '1.5rem' }}
                        >
                          {createTimeOptions()}

                        </Form.Control>
                        <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0', marginLeft: '70%' }}>
                          End Time*
                        </label>
                      </div>
                    </div>


                    {touched.appEndDate && errors.appEndDate ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.appEndDate}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <Form.Group
                    controlId="pilot"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.pilot && errors.pilot ? 'has-validation-error' : ''
                      }`}>
                    <span className='pilot-label'>Select pilot*</span>
                    <Select
                      options={getPilots}
                      value={getpilot}
                      placeholder={
                        <div className="select-placeholder-text">
                          Select pilot*
                        </div>
                      }
                      onChange={handlePilotsDropdown}
                      className="w-100"
                    />
                    {touched.pilot && errors.pilot ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.pilot}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group
                  controlId="aircraft"
                  className={`form-floating viewaircraft-Box w-100 ${touched.aircraft && errors.pilot
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="aircraft"
                    placeholder={
                      <div className="select-placeholder-text">
                        Select aircraft
                      </div>
                    }
                    value={eventData.aircraft ? eventData.aircraft.name : ''}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.aircraft && errors.aircraft}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Aircraft
                  </label>
                  {touched.aircraft && errors.aircraft ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.aircraft}
                  </Form.Control.Feedback>
                </Form.Group> */}

                  <Form.Group
                    controlId="aircraft"
                    className={`form-floating viewaircraft-Box w-100 ${touched.aircraft && errors.pilot
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <span className='pilot-label'>Select aircraft</span>
                    <Select
                      options={getAircrafts}
                      value={getaircraft}
                      placeholder={
                        <div className="select-placeholder-text">
                          Select aircraft
                        </div>
                      }
                      onChange={handleAircratsDropdown}
                      className="w-100"
                    />
                    {touched.aircraft && errors.aircraft ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.aircraft}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* <Form.Group
                  controlId="customer"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.customer && errors.customer
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="customer"
                    placeholder={
                      <div className="select-placeholder-text">Customer</div>
                    }
                    value={getCustomer}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.customer && errors.customer}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Customer(s)
                  </label>
                  {touched.customer && errors.customer ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.customer}
                  </Form.Control.Feedback>
                </Form.Group> */}

                  <Form.Group
                    controlId="customer"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.customer && errors.customer
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <span className='pilot-label'>Select customers</span>
                    <Select
                      options={getCustomers}
                      value={getCustomer}
                      placeholder={
                        <div className="select-placeholder-text">
                          Select customers
                        </div>
                      }
                      onChange={handlecustomerDropdown}
                      isMulti
                      className="w-100"
                    />
                    {touched.customer && errors.customer ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.customer}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="startPlace"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.startPlace && errors.startPlace
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="text"
                      name="startPlace"
                      placeholder="startPlace"
                      value={values.startPlace}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.startPlace && errors.startPlace}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Start destination
                    </label>

                    {touched.startPlace && errors.startPlace ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.startPlace}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="startPlace"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.startPlace && errors.startPlace
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="text"
                      name="endPlace"
                      placeholder="endPlace"
                      value={values.endPlace}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.endPlace && errors.endPlace}
                    />
                    <label for="floatingInput" className="greyLabel">
                      End destination
                    </label>

                    {touched.endPlace && errors.endPlace ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.endPlace}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                  controlId="note"
                  className={`viewPilot-Box m-0 w-100 ${touched.note && errors.note ? 'has-validation-error' : ''
                    }`}>
                        <Form.Label className="greyLabel">Appointment note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="note"
                    style={{
                      height:'auto',
                    }}

                    value={values.note}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.note && errors.note}
                  />
                  {/* <label for="floatingInput" className="greyLabel">
                    Appointment note
                  </label> */}

                  {touched.note && errors.note ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.note}
                  </Form.Control.Feedback>
                </Form.Group>

                  <div className="events-btns w-100 d-flex justify-content-center">
                    <Button
                      // variant="outline-primary"
                      className="cancel-btn w-50"
                      // onClick={() => props.onClose()}
                      onClick={handleEventDelete}
                      >
                      {/* Cancel */}
                      Delete
                    </Button>
                    <Button
                      className="add-app-btn w-50"
                      // variant="primary rounded-pill"
                      type="submit">
                      Update Appointment
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            )
            }
            {status === '2' && (
              <Modal.Body className="modal-body-body">
                <Form onSubmit={handleSubmit} className="add-event-form">

                <Form.Group
                  controlId="isRepeating"
                  className="form-floating viewPilot-Box-select w-100">
                  <div style={{marginLeft:10}} className='row'>
                    <div className="form-check col-auto">
                      <input className="form-check-input"
                        type="radio"
                        name="update_group"
                        id="update_single"
                        value={'false'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.update_group == 'false'}
                      />
                      {console.log("IOIOIOIOIO", values.update_group)}
                      <label style={{ fontWeight: 'normal' }} >
                        Update only this leave
                      </label>
                    </div>
                    <div className="form-check col-auto">
                      <input className="form-check-input"
                        type="radio"
                        name="update_group"
                        id="update_single"
                        value={'true'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.update_group == 'true'}
                      />
                      <label style={{ fontWeight: 'normal' }}>
                        Update all grouped leave
                      </label>
                    </div>
                  </div>

                </Form.Group>

                  <Form.Group
                    controlId="appStartDate"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.appStartDate && errors.appStartDate
                      ? 'has-validation-error startdate-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="date"
                      name="appStartDate"
                      placeholder="appStartDate"
                      value={values.appStartDate ? values.appStartDate.split(' ')[0] : values.appStartDate}
                      // value="2023-07-26 00:01:00"
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={new Date().toISOString().split('T')[0]}
                      isInvalid={touched.appStartDate && errors.appStartDate}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Day of Leave*
                    </label>

                    {touched.appStartDate && errors.appStartDate ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.appStartDate}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <Form.Group
                    controlId="pilot"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.pilot && errors.pilot ? 'has-validation-error' : ''
                      }`}>
                    <span className='pilot-label'>Select pilot</span>
                    <Select
                      options={getPilots}
                      value={getpilot}
                      placeholder={
                        <div className="select-placeholder-text">
                          Select pilot
                        </div>
                      }
                      onChange={handlePilotsDropdown}
                      className="w-100"
                    />
                    {touched.pilot && errors.pilot ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.pilot}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="title"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.title && errors.title ? 'has-validation-error' : ''
                      }`}>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="title"
                      value={values.title}
                      // disabled
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.title && errors.title}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Additional comments*
                    </label>

                    {touched.title && errors.title ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="events-btns w-100 d-flex justify-content-center">
                    <Button
                      // variant="outline-primary"
                      className="cancel-btn w-50"
                      // onClick={() => props.onClose()}
                      onClick={handleEventDelete}
                      >
                        Delete
                    </Button>
                    <Button
                      className="add-app-btn w-50"
                      // variant="primary rounded-pill"
                      type="submit">
                      Update Leave
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            )}
          </>
        </Modal>
      )}
    </div>
  );
};

export default EventForm;
