import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import reseticon from '../../images/activeicons/reseticon.svg';
import './reportpopup.css';

export const Reportfilter = (props) => {
  const [aircraft, setaircraft] = useState([]);
  const [pilot, setpilot] = useState([]);
  const [customer, setcustomer] = useState([]);
  const [pilotVal, setpilotVal] = useState(props.filterdata.pilot || '');
  const [aircraftVal, setaircraftVal] = useState(
    props.filterdata.aircraft || '',
  );
  const [customerVal, setCustomerVal] = useState(
    props.filterdata.customer || '',
  );
  const [startDate, setStartDate] = useState(props.filterdata.start_date || '');
  const [endDate, setEndDate] = useState(props.filterdata.end_date || '');

  function handleClose() {
    props.toggleFilter(false);
  }

  const handlesubmit = () => {
    const body = {
      pilot_name: pilotVal.label || '',
      customer_name: customerVal.label || '',
      aircraft_name: aircraftVal.label || '',
      start_date: startDate || '',
      end_date: endDate || '',
      organization_id:
        sessionStorage.getItem('organization_id') === 'null'
          ? 2
          : sessionStorage.getItem('organization_id'),
    };

    const filterbody = {
      pilot: {
        label: pilotVal.label,
        value: pilotVal.value,
      },
      aircraft: {
        label: aircraftVal.label,
        value: aircraftVal.value,
      },
      customer: {
        label: customerVal.label,
        value: customerVal.value,
      },
      start_date: startDate || '',
      end_date: endDate || '',
      organization_id:
        sessionStorage.getItem('organization_id') === 'null'
          ? 2
          : sessionStorage.getItem('organization_id'),
    };
    props.submitfilter(body, filterbody);
    props.toggleFilter();
  };

  const resetSubmit = () => {
    const body = {
      pilot_name: '',
      customer_name: '',
      aircraft_name: '',
      start_date: '',
      end_date: '',
      organization_id:
        sessionStorage.getItem('organization_id') === 'null'
          ? 2
          : sessionStorage.getItem('organization_id'),
    };
    const filterbody = {
      pilot: {
        label: undefined,
        value: undefined,
      },
      aircraft: {
        label: undefined,
        value: undefined,
      },
      customer: {
        label: undefined,
        value: undefined,
      },
      start_date: '',
      end_date: '',
      organization_id:
        sessionStorage.getItem('organization_id') === 'null'
          ? 2
          : sessionStorage.getItem('organization_id'),
    };
    props.resetFilter(body, filterbody);
    props.toggleFilter();
  };
  const handlestartDate = useCallback((event) => {
    setStartDate(event.target.value);
  }, []);

  const handleendDate = useCallback((event) => {
    setEndDate(event.target.value);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let response = props.data;
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];
    for (let i = 0; i < props.aircraftdata.length; i++) {
      arr1.push({
        label: props.aircraftdata[i].tail_number || '',
        value: props.aircraftdata[i].id || '',
      });
    }

    for (let k = 0; k < props.customersData.length; k++) {
      arr2.push({
        label: props.customersData[k].name || '',
        value: props.customersData[k].id || '',
      });
    }

    for (let i = 0; i < props.pilotsData.length; i++) {
      arr3.push({
        label: props.pilotsData[i].owner? props.pilotsData[i].owner.name : '',
        value: props.pilotsData[i].owner? props.pilotsData[i].owner.pilot_profile_id : '',
      });
    }
    setaircraft(arr1);
    setcustomer(arr2);
    setpilot(arr3);
  };

  const handlecustomerDropdown = (event) => {
    setCustomerVal(event);
  };

  const handleAircratsDropdown = (event) => {
    setaircraftVal(event);
  };

  const handlePilotsDropdown = (event) => {
    setpilotVal(event);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      className="report-popup p-0"
      centered>
      <Modal.Header>
        <Modal.Title className="modalTitle">Advance Filters</Modal.Title>
        <img
          src={reseticon}
          className="reset-icon"
          onClick={() => {
            resetSubmit();
          }}
        />
      </Modal.Header>
      <Modal.Body className="modalBody">
        <form onSubmit={handlesubmit}>
          <label className="report-label">Aircraft</label>
          <Select
            options={aircraft}
            value={aircraftVal}
            placeholder={
              <div className="select-placeholder-text">Select Aircraft</div>
            }
            onChange={handleAircratsDropdown}
            className="w-100"
          />

          <label className="report-label">Pilot</label>
          <Select
            options={pilot}
            value={pilotVal}
            placeholder={<div className="select-placeholder-text">Pilot</div>}
            onChange={handlePilotsDropdown}
            className="w-100"
          />

          <label className="report-label">Select Customer</label>
          <Select
            options={customer}
            value={customerVal}
            placeholder={
              <div className="select-placeholder-text">Select Customer</div>
            }
            onChange={handlecustomerDropdown}
            className="w-100"
          />
          <label className="report-label">Date</label>
          <div className="date-filter d-flex">
            <input
              className="uploaddocumentInput w-50"
              type="date"
              placeholder="Start Date"
              required
              min="1600-12-31"
              max="2099-12-31"
              onChange={handlestartDate}
              value={startDate}
            />

            <input
              className="uploaddocumentInput w-50"
              type="date"
              placeholder="End Date"
              required
              min="1600-12-31"
              max="2099-12-31"
              onChange={handleendDate}
              value={endDate}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modalOkButton w-100" onClick={handlesubmit}>
          Search
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
