import React from 'react'
import { Button, Modal } from "react-bootstrap"
import { api_urls } from '../../constants/constants'
import { ApiRequests } from '../https/ApiRequests'
export const ToggleOffModal = (props) => {
    function handleClose() {
        props.changeToggleOffModal(false)
      }
    
  return (
   
    
    <Modal
    // size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    show={props.show}
    onHide={handleClose}
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title className='modalTitle'>Activated Organization</Modal.Title>
    </Modal.Header>
    <Modal.Body  className='modalBody' >
     
     <h3 className='personalLabels'>Are you sure you want to deactivate this organization?</h3>
   

    </Modal.Body>
    <Modal.Footer>
    <Button className='backButton' onClick={handleClose}>No</Button>

    <Button className='modalOkButton' onClick={async()=>
    {
        let url=(api_urls.Organization+props.userid+"/")
        await ApiRequests(url, "PATCH",{is_disabled:true})
        props.clickdelete();
        handleClose();
    }}>Yes</Button>
    </Modal.Footer>
  </Modal>
  )
}
