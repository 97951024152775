import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Image, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import upload from '../../images/icons/uploadblack.png';
import Save from '../../images/icons/save.png';
import AlertCricle from '../../images/icons/alert-circle.png';
import CheckCricle from '../../images/icons/check-circle.png';
import PdfVector from '../../images/icons/PdfVector.png';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// toast.configure()

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // padding: '20px',
  paddingTop: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#EFF6FF',
  marginBottom: '20px',
  // color: '#bdbdbd',
  // outline: 'none',
  // transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const Drop = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState({});
  const [title, setTitle] = useState(''); // Added state variable for title
  const [showError, setError] = useState(false);
  const [date, setDate] = useState('');
  const [fileSelected, setFileSelected] = useState(false);
  let organization_id =
    sessionStorage.getItem('organization_id') === 'null'
      ? 2
      : sessionStorage.getItem('organization_id');

  useEffect(() => { }, [fileData]);
  const newData = new FormData();

  const handleTitleChange = useCallback((event) => {
    setTitle(event.target.value);
  }, []);
  const handleDateChange = useCallback((event) => {
    setDate(event.target.value);
  }, []);

  const onDrop = async (acceptedFiles) => {
    setFileSelected(true);
    const url = new URL(window.location.href);
    newData.append('document', acceptedFiles[0]);
    newData.append('title', title);
    newData.append('organization', organization_id);
    // setFileData({
    //   title: newData.get('title'),
    //   document: newData.get('document'),
    //   organization: newData.get('organization'),
    // });
    setFileData({ test: newData })
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!fileSelected) {
      setError(true);
      toast.error('Please select a PDF file to upload.');
      return;
    }
    try {
      const response = await ApiRequests(
        api_urls.memoProfile,
        'POST',
        fileData.test,
      );
      if (response.status === 201) {
        props.showUpdatedData();
      }

      props.handleClose();
    } catch (error) {
      console.error('API error:', error);
      setError(true);
      toast.error(error.response.data.error || 'Error in uploading PDF');
    }
  };
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.pdf',
    multiple: false,
    onDrop: onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.path} className="file-li">
        <Image src={PdfVector}></Image> &nbsp;
        <span className="file-path"> {file.path}</span>
        <>
          {showError === true ? (
            <Image src={AlertCricle} style={{ float: 'right' }}></Image>
          ) : (
            <Image src={CheckCricle} style={{ float: 'right' }}></Image>
          )}
        </>
      </li>
    );
  });

  return (
    <Form onSubmit={handleSubmit}>
      <section className="container">
        <Row className="document-popup-details d-flex">
          <div className="training-name w-100">
            <label className="uploadDocumentLabel">Memo title</label>
            <br></br>
            <input
              className="uploaddocumentInput"
              type="text"
              placeholder="Enter Memo Title"
              required
              onChange={handleTitleChange}></input>
          </div>
        </Row>

        <div className="file-upload-data mt-4">
          <ul className="file-input-list">{acceptedFileItems}</ul>
        </div>
        <div {...getRootProps({ style })} className="uploadDocumenUploadBox">
          <input {...getInputProps()} />
          <Image src={upload}></Image>
          <label className="file-size-label mt-1">
            Maximum File Size: 10 MB
          </label>
          <label className="file-size-label mt-1">
            Select a pdf file to upload
          </label>
        </div>
        <div style={{ float: 'right', marginTop: '20px' }}>
          <Button
            style={{
              margintop: '20px !important',
              textAlign: 'center',
              backgroundColor: '#1334B9',
            }}
            type="submit">
            <> Save Memo &nbsp;&nbsp;</>

            <Image src={Save}></Image>
          </Button>
        </div>
      </section>
    </Form>
  );
};
