import React, { useEffect, useMemo, useState } from 'react';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { useNavigate } from 'react-router-dom';
import editIcon from '../../images/icons/editIcon.png';
import deleteIcon from '../../images/icons/deleteIcon.png';
import toggleOn from '../../images/icons/toggleOn.png';
import toggleOff from '../../images/icons/toggleoff.png';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Image } from 'react-bootstrap';
import defaultProfile from '../../images/icons/default-profile.png';
import ErrorMsg from '../ErrorMsg';
import Loader from '../Loader/index';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pilots/table.css';
import { DeleteModal } from './DeleteModal';
import { ToggleOffModal } from './ToggleOffModal';
import { ToggleOnModal } from './ToggleOnModal';
import selector from '../../images/icons/selector.png';

export const SuperAdminDashboard = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Add loading state
  const [noFound, setNoFound] = useState(false);

  const [totalCount, setTotalCount] = useState(0);

  const [products, setProducts] = useState([]);

  const [search, setSearch] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const options = {
    custom: true,
    totalSize: totalCount,
    // onPageChange: onPageChange
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToggleOnModal, setShowToggleOnModal] = useState(false);
  const [showToggleOffModal, setShowToggleOffModal] = useState(false);

  const [UserId, setUserId] = useState(null);

  const changeDeleteModal = (state) => {
    setShowDeleteModal(state);
  };
  const changeToggleOnModal = (state) => {
    setShowToggleOnModal(state);
  };
  const changeToggleOffModal = (state) => {
    setShowToggleOffModal(state);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await ApiRequests(
        api_urls.Organization,
        'GET',
        null
      );

      let tempdata = response.data;
      if (tempdata.length > 0) {
        setLoading(false);
        setNoFound(false);
      } else if (tempdata.length == 0) {
        setNoFound(true);
        setLoading(false);
      }
      setProducts(tempdata);
      setFilteredResults(response.data);

      setTotalCount(response.data.count);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const data = useMemo(() => products, [products]);
  const columns = useMemo(
    () => [
      {
        Header: 'Organization name',
        accessor: 'owner.name',
        disableSortBy: false,
        disableFilters: true,
        sticky: 'left',
      },
      {
        Header: 'Email',
        accessor: 'owner.email',
        disableSortBy: false,
        sticky: 'left',
      },
      {
        Header: 'Phone number',
        disableSortBy: true,
        accessor: 'phone_number',
        sticky: 'left',
      },

      {
        Header: 'Status',
        disableSortBy: true,
        accessor: 'is_disabled',
        Cell: (props) => {
          const toggleIcon =
            props.row.values.is_disabled === false ? (
              <Image
                src={toggleOn}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowToggleOffModal(true);
                  setUserId(props.row.original.owner.organization_profile_id);
                }}></Image>
            ) : (
              <Image
                src={toggleOff}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowToggleOnModal(true);
                  setUserId(props.row.original.owner.organization_profile_id);
                }}></Image>
            );
          return (
            <>
              <span style={{ marginRight: '20px' }}>{toggleIcon}</span>
            </>
          );
        },
      },
      {
        Header: 'Action',
        disableSortBy: true,
        accessor: 'state',
        Cell: (props) => {
          return (
            <>
              <span>
                <Image
                  src={editIcon}
                  style={{ marginRight: '20px' }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    navigate(
                      `/dashboard/organizations/${props.row.original.owner.organization_profile_id}`,
                      { state: { edit: true, create: false, rowclick: false } },
                    );
                    // }
                  }}></Image>

                <Image
                  src={deleteIcon}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                    setUserId(props.row.original.owner.organization_profile_id);
                  }}></Image>
              </span>
            </>
          );
        },
      },
    ],
    [],
  );
  const createOrganization = (e) => {
    e.stopPropagation();

    navigate(`/dashboard/organizations/create`, {
      state: { create: true, edit: false },
    });
  };

  const onRowClicked = (row, event) => {
    const data = products.find((item) => item.id === row.id);
    if (data) {
      // <ViewPilot/>
      navigate(`/dashboard/organizations/${data.id}`);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, pageSize } = state;

  return (
    <div>
      <DashboardHeader name="Organization" />

      <>
        <div
          style={{
            padding: '0px 30px',
            backgroundColor: '##F3F4F6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: noFound === true ? 'flex-end' : 'space-between',
            marginTop: '20px',
            borderColor: '#1334B9',
          }}>
          <>{noFound !== true &&
            <div className="search-bar w-25">
              <span className="search-icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                    stroke="#9CA3AF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.5 17.5L13.875 13.875"
                    stroke="#9CA3AF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                value={search}
                className="SearchLabel searchpilot"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
                placeholder="Search Organization"
                style={{ '::placeholder': { color: '#D1D5DB' } }}
              />
            </div>
          }
          </>
          <Button className="addNewButton" onClick={createOrganization}>
            Add New +
          </Button>
        </div>
        <>
          {loading === true ? (
            <Loader loadingMsg="Data is Loading... Please Wait" />
          ) : (
            <>{noFound === true ? (
              <ErrorMsg errormsg={'No Records Exist'} />
            ) :
              <>
                <div className="table-container">
                  <table {...getTableProps()} className="table-cls">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}>
                              {column.render('Header')}
                              <span>
                                &nbsp;
                                {column.disableSortBy === false ? (
                                  <Image src={selector}></Image>
                                ) : null}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() =>
                              navigate(
                                `/dashboard/organizations/${row.original.owner.organization_profile_id}`,
                                {
                                  state: { rowclick: true, edit: true },
                                },
                              )
                            }>
                            <td
                              className="img-name-cont"
                              style={{ cursor: 'pointer' }}>
                              {row.original.image ? (
                                <img
                                  src={row.original.image}
                                  alt="Image"
                                  className="profile-img"
                                />
                              ) : (
                                <img
                                  src={defaultProfile}
                                  alt="Default Image"
                                  className="profile-img"
                                />
                              )}
                              {row.original.owner.name}
                            </td>
                            {row.cells.map((cell, cellIndex) => {
                              if (cellIndex === 0) {
                                return null;
                              }
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  className="paginationBox"
                // style={{ backgroundColor: "#f2f2f2", padding: "10px", height: "50px" }}
                >
                  <div
                    style={{ float: 'left', gap: '5px' }}
                    className="d-flex align-items-center">
                    <label className="paginationText">
                      Number of rows&nbsp;
                    </label>
                    <select
                      style={{ float: 'right' }}
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}>
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div style={{ float: 'right' }}>
                    <button
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                      className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                        }`}>
                      Prev
                    </button>
                    {pageOptions.map((page, index) => (
                      <button
                        key={index}
                        onClick={() => gotoPage(index)}
                        className={`paginationButton ${pageIndex === index ? 'activeButton' : ''
                          }`}>
                        {index + 1}
                      </button>
                    ))}
                    <button
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                      className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                        }`}>
                      Next
                    </button>
                  </div>
                </div>
              </>
            }
            </>
          )}
        </>
      </>

      {showDeleteModal && (
        <DeleteModal
          changeDeleteModal={changeDeleteModal}
          show={showDeleteModal}
          userid={UserId}
          clickdelete={fetchData}
        />
      )}

      {showToggleOnModal && (
        <ToggleOnModal
          changeToggleOnModal={changeToggleOnModal}
          show={showToggleOnModal}
          userid={UserId}
          clickdelete={fetchData}
        />
      )}
      {showToggleOffModal && (
        <ToggleOffModal
          changeToggleOffModal={changeToggleOffModal}
          show={showToggleOffModal}
          userid={UserId}
          clickdelete={fetchData}
        />
      )}
    </div>
  );
};
