import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
export const GeneralDetails = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    Lastservice: Yup.string().required('Lastservice is required'),
    Nextmaintenance: Yup.string().required('Next maintenance is required'),
    ServicedParts: Yup.string().required('Serviced Parts is required'),
  });

  const [updateData, setUpdateData] = useState({});

  // useEffect(() => {
  //   // Update the form values when the prop changes
  //   if (props.profileDetails) {
  //     ref.current.setValues(props.profileDetails);
  //   }
  // }, [props.profileDetails]);
  const handleReset = () => {
  };

  return (
    <>
      {/* <Button onClick={handleReset}>reset</Button> */}
      <Formik
        innerRef={ref}
        validationSchema={schema}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
                fullName: props.profileDetails.aircraft.name || '',
                TailNumber: props.profileDetails.aircraft.tail_number || '',
                Mark: props.profileDetails.aircraft.mark || '',
                Model: props.profileDetails.aircraft.model || '',
                Lastservice: props.profileDetails.last_service || '',
                Nextmaintenance:
                  props.profileDetails.next_maintenance_scheduled || '',
                Upcomingmaintenance:
                  props.profileDetails.upcoming_maintenance.option || '',
                ReportedIssues: props.profileDetails.reported_issues || '',
                ServicedParts: props.profileDetails.serviced_parts || '',
              }
            : {
                fullName: '',
                TailNumber: '',
                Mark: '',
                Model: '',
                Lastservice: '',
                Nextmaintenance: '',
                Upcomingmaintenance: '',
                ReportedIssues: '',
                ServicedParts: '',
              }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          const url = new URL(window.location.href);
          const id = url.pathname.split('/')[3];
          let aircraft_id=url.pathname.split('/')[4]
          let apiurl =
            api_urls.serviceProfile + id + '/?aircraft_id='+aircraft_id;
          if (!props.create) {
            try {
              let body = {
                last_service: values.Lastservice,
                serviced_parts: values.ServicedParts,
                next_maintenance_scheduled: values.Nextmaintenance,
                organization:
                  sessionStorage.getItem('organization_id') === 'null'
                    ? 2
                    : sessionStorage.getItem('organization_id'),
              };
              const response = await ApiRequests(apiurl, 'PATCH', body, null);
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Service updated successfully');
                navigate('/dashboard/servicelog', { replace: true });
              }
            } catch (error) {
              console.error('There was an error!', error);
              toast.error('Error in updating Service');
            }
          } else {
            let body = {
              name: values.fullName,
              tail_number: values.TailNumber,
              model: values.Model,
              mark: values.Mark,
              organization:
                sessionStorage.getItem('organization_id') === 'null'
                  ? 2
                  : sessionStorage.getItem('organization_id'),
              address: values.Address,
              city: values.City,
              additional_information: values.AdditionalInfo,
            };
            props.storeNewUserDetails(body);
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form
            className=""
            noValidate
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Aircraft Details</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="fullName"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.fullName && errors.fullName
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="fullName"
                    placeholder="Aircraft Name"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      props.readOnly ? 'disabledColor' : 'disabledColor'
                    }
                    readOnly={true}
                    isInvalid={touched.fullName && errors.fullName}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Aircraft Name
                  </label>
                </Form.Group>

                <Form.Group
                  controlId="TailNumber"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.TailNumber && errors.TailNumber
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="TailNumber"
                    placeholder="Tail number"
                    value={values.TailNumber}
                    className={
                      props.readOnly ? 'disabledColor' : 'disabledColor'
                    }
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.TailNumber && errors.TailNumber}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Tail number
                  </label>
                </Form.Group>

                <Form.Group
                  controlId="Mark"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Mark && errors.Mark ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Mark"
                    placeholder="Mark"
                    value={values.Mark}
                    className={
                      props.readOnly ? 'disabledColor' : 'disabledColor'
                    }
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Mark && errors.Mark}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Mark
                  </label>

                  {touched.Mark && errors.Mark ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Mark}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="Model"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Model && errors.Model ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Model"
                    placeholder="Model"
                    value={values.Model}
                    className={
                      props.readOnly ? 'disabledColor' : 'disabledColor'
                    }
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Model && errors.Model}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Model
                  </label>

                  {touched.Model && errors.Model ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Model}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Aircraft Services</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="Lastservice"
                  className={`form-floating viewPilot-Box32 mb-3 ${
                    touched.Lastservice && errors.Lastservice
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    min="1600-12-31"
                    max="2099-12-31"
                    name="Lastservice"
                    placeholder="Last Service"
                    value={values.Lastservice}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Lastservice && errors.Lastservice}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Last Service
                  </label>

                  {touched.Lastservice && errors.Lastservice ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Lastservice}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="Upcomingmaintenance"
                  className={`form-floating viewPilot-Box32 mb-3 ${
                    touched.Upcomingmaintenance && errors.Upcomingmaintenance
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Upcomingmaintenance"
                    placeholder="Upcoming maintenance"
                    value={values.Upcomingmaintenance}
                    className={
                      props.readOnly ? 'disabledColor' : 'disabledColor'
                    }
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.Upcomingmaintenance && errors.Upcomingmaintenance
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Upcoming maintenance
                  </label>

                  {touched.Upcomingmaintenance &&
                  errors.Upcomingmaintenance ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Upcomingmaintenance}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="Nextmaintenance"
                  className={`form-floating viewPilot-Box32 mb-3 ${
                    touched.Nextmaintenance && errors.Nextmaintenance
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    min="1600-12-31"
                    max="2099-12-31"
                    name="Nextmaintenance"
                    placeholder="Next maintenance scheduled"
                    value={values.Nextmaintenance}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.Nextmaintenance && errors.Nextmaintenance
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Next maintenance scheduled
                  </label>

                  {touched.Nextmaintenance && errors.Nextmaintenance ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Nextmaintenance}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="bottom-service d-flex">
                <Form.Group
                  controlId="ReportedIssues"
                  className={`form-floating viewPilot-Box32 mb-3 w-50 ${
                    touched.ReportedIssues && errors.ReportedIssues
                      ? 'has-validation-error'
                      : ''
                  }`}
                  // style={{ width: "100%" }}
                >
                  <Form.Control
                    type="text"
                    name="ReportedIssues"
                    placeholder="Reported Issues"
                    value={values.ReportedIssues}
                    className={
                      props.readOnly ? 'disabledColor' : 'disabledColor'
                    }
                    readOnly={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.ReportedIssues && errors.ReportedIssues}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Reported Issues
                  </label>

                  {touched.ReportedIssues && errors.ReportedIssues ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.ReportedIssues}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="ServicedParts"
                  className={`form-floating viewPilot-Box mb-3 w-50 ${
                    touched.ServicedParts && errors.ServicedParts
                      ? 'has-validation-error'
                      : ''
                  }`}
                  // style={{ width: "100%" }}
                >
                  <Form.Control
                    type="text"
                    name="ServicedParts"
                    placeholder="Serviced Parts"
                    value={values.ServicedParts}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.ServicedParts && errors.ServicedParts}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Serviced Parts
                  </label>

                  {touched.ServicedParts && errors.ServicedParts ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.ServicedParts}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
});
