import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export const MultiCustomerDeleteCheck = (props) => {
    function handleClose() {
        props.handleDiscard(false);
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle">
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: '#fcad03'}}/>&nbsp;
                    Delete Customers
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <h3 className="personalLabels">
                    Are you sure you wish to mark multiple customers as "No"?
                    This action will result in the removal of all customers, except for the most recent one after click on save button.
                </h3>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button className="backButton" onClick={handleClose}>
          Cancel
        </Button> */}

                <Button
                    className="modalOkButton"
                    handleDiscard
                    //   onClick={() => {
                    //     props.handleDiscard(true);
                    //   }}
                    onClick={handleClose}
                >
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
