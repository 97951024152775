import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import Save from '../../images/icons/save.png';
import addques from '../../images/activeicons/addques.svg';
import { Form } from 'react-bootstrap';
import Editquestion from '../../images/activeicons/Editquestion.svg';
import Deletquestion from '../../images/activeicons/Deletequestion.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
import { SketchPicker } from 'react-color';
import Loader from '../Loader';
import { DeleteQualificationPop } from './DeleteQualificationPop';

export const GeneralDetails = forwardRef((props, ref) => {
  console.log('props in general details', props);
  const [aircraftOptions,setAircraftOptions]=useState([]);
  const fetchAllAppointments = async () => {
    const params = {
      organization_id: organization_id,
    };
    let resp = await ApiRequests(
      api_urls.qualification_list,
      'GET',
      null,
      params,
    );
    console.log('resp', resp);
    setAircraftOptions(resp.data);
  };
  useEffect(() => {
    fetchAllAppointments();
    console.log('genderVal##aircraftOptions',genderVal);
    console.log('aircraftOptions',aircraftOptions);
  }, []);
  
  const [getColor, setColor] = useState(props.profileDetails.color);
  const [colorPickerEnabled, setcolorPickerEnabled] = useState(props.readOnly);
  const [readOnly, setReadOnly] = useState(props.readOnly);
  const [editView, setEditView] = useState(props.editView);
  const [data, setData] = useState([]);
  const [showAddQualifiaction, setShowAddQualifiaction] = useState(true);
  const [showAddQualifiactionApi, setShowAddQualifiactionApi] = useState(true);
  const [saveQApiData, setSaveQApiData] = useState(false);
  const [save, setSave] = useState(true);
  const [saveEdit, setSaveEdit] = useState(false);
  console.log('###', props.editView, props.readOnly);
  const [loading, setLoading] = useState(false);

  const [showDelete, setDelete] = useState(false);

  const [addQualifications, setAddQualification] = useState(
    props.profileDetails.pilot_qualifications || [],
  );
  console.log('AddQ', props.qualificationData);
  console.log('addQ#', props.profileDetails.pilot_qualifications);

  const [allQualifications, setAllQualification] = useState([]);
  const [qualification, setQualification] = useState({});
  const [editQuestion, setEditQuestion] = useState();

  const [showAddQualificationOnEdit, setAddQualificationOnEdit] =
    useState(true);

  const [editSave, setEditSave] = useState(false);
  const [aircraftData, setAirCraftData] = useState('');
  const [expDate, setExpDate] = useState('');
  const [tempEditId, setTempEditId] = useState();
  const [certification, setCertification] = useState('');
  console.log('readable', props.readOnly, 'edit', props.editView);
  const navigate = useNavigate();
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const schema = Yup.object().shape({
    Email: Yup.string()
      .matches(emailRegExp, 'Invalid email format')
      .required('Email is required'),
    fullName: Yup.string().required('Name is required'),
    licenseNumber: Yup.string().required('Number is required'),
    dateOfExam: certification.length>0 && Yup.string().required('Date of exam is required'),
    // licenseExpirationDate: Yup.string().required('Expiration Date is required'),
  });

  const [updateData, setUpdateData] = useState({});
  const [genderVal, setgenderVal] = useState(null);
  const [showQualifiaction, setShowQualification] = useState(false);
  const [placeholdername, setplaceholdername] = useState('Gender');

  const handleplaceholder = (flag) => {
    if (flag === true) {
      return 'Gender';
    } else {
      return '';
    }
  };

  useEffect(() => {
    let res = handleplaceholder(true);
    setplaceholdername(res);
  }, []);

  useEffect(() => {
    setcolorPickerEnabled(!props.readOnly);
  }, [props.readOnly]);

  useEffect(() => {
    setTimeout(() => {
      setData(props.profileDetails.pilot_qualifications);
    }, 1500);
    console.log('#Medicaldata', genderVal);
  }, []);
  console.log(data, data);

  useEffect(() => {
    setgenderVal({
      value: props.profileDetails.certificate_class,
      label: props.profileDetails.certificate_class,
    });
  }, [props.profileDetails.certificate_class]);

  const hadleAircraftData = (event) => {
    console.log('event@', event.label);
    const data = event.label;
    setAirCraftData(data);
    console.log(aircraftData);
  };

  const handleExpDate = (date) => {
    setExpDate(date);
    console.log('Expdate', date);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(true);
  //   }, 1000);
  //   setLoading(false);
  // }, []);

  const handleQualificationEditApi = async (id, aircraft, exp) => {
    setAirCraftData(aircraft);
    setExpDate(exp);
    setTempEditId(id);
  };

  const [DeleteQualificationId, setQualificationId] = useState('');
  const [DeleteQualificationAircraft, setQualificationAircraft] = useState('');
  const [DeleteQualificationExp, setQualificationExp] = useState('');
  const handleDiscard = (flag) => {
    if (flag === true) {
      setDelete(false);
      handleQualificationDeleteApi(
        DeleteQualificationId,
        DeleteQualificationAircraft,
        DeleteQualificationExp,
      );
    } else {
      setDelete(false);
    }
  };

  const delteQualification = async (id, aircraft, exp) => {
    setQualificationId(id);
    setQualificationAircraft(aircraft);
    setQualificationExp(exp);
    setDelete(true);
  };

  const handleQualificationDeleteApi = async (id, aircraft, exp) => {
    try {
      let apiurl = `${api_urls.pilot_Qualification}${id}/`;
      const response = await ApiRequests(apiurl, 'DELETE');
      if (response) {
        toast.success('Pilot Qualification deleted successfully');
        window.location.reload(false);
        // setLoading(true);
        arr = props.profileDetails.pilot_qualifications;
        saveChanges();
      }
    } catch (error) {
      toast.error('Error in deleting answer');
      // setLoading(false);
    }
  };

  const saveChanges = () => {
    if (props.authRef && props.authRef.current) {
      props.authRef.current.handleSubmit();
      //   toast.success("Aircraft created successfully")
      //   navigate("/dashboard/aircrafts", { replace: true })
    }
  };

  const handleQualificationApiSave = async (id, qId, aircraft, exp) => {
    if (!aircraft.length || !exp.length) {
      toast.error('Please provide inputes!');
    } else {
      const body = {
        pilot_user: qId,
        aircraft: aircraft,
        expiration_date: exp,
      };
      // setLoading(true);
      try {
        let apiurl = `${api_urls.pilot_Qualification}${id}/`;
        const response = await ApiRequests(apiurl, 'PATCH', body);
        if (response) {
          window.location.reload(false);
          toast.success('Pilot Qualification Changes saved successfully');
          // setLoading(false);
          arr = props.profileDetails.pilot_qualifications;
          setTempEditId('');
          setAirCraftData('');
          setExpDate('');
        }
      } catch (error) {
        console.error('There was an error!', error.response.data.non_field_errors[0]);
        const er=error.response.data.non_field_errors[0];
        if(er.includes('aircraft must make a unique set')){
          
            toast.error('Qualification already exists');
          }
          else{
            toast.error('Error in saving Pilot Qualification');
          }

      
        // setLoading(false);
      }
    }
  };

  const handleReset = () => {};
  var qArr = [];
  //Save Qualifiactions
  const handleEditOption = (id, aircraft, exp) => {
    if (!aircraft.length || !exp.length) {
      toast.error('Provide some input!');
    } else {
      let editItem = addQualifications.find((item) => {
        return item.id === id;
      });
      console.log('handleEditOptions', editItem);
      // if (editableChoicesFlight.includes(choiceId)) {
      //   setEditableChoicesFlight(
      //     editableChoicesFlight.filter((id) => id !== choiceId),
      //   );
      // }
      setEditQuestion(id);
      console.log('id', id);
      setEditSave(!editSave);
      console.log('edit', editQuestion);
      setAirCraftData(aircraft);
      setExpDate(exp);
      console.log('airC', aircraft);
      console.log('exp', exp);
    }
  };
  const handleDelete = (id) => {
    console.log('before delete', id);
    const updatedQualification = addQualifications.filter((item) => {
      return id !== item.id;
    });
    console.log('after delete', updatedQualification);
    setAirCraftData('');
    setExpDate('');

    setAddQualification(updatedQualification);
  };
  const handleSave = (id, air_craft, exp_date) => {
    // console.log('aircraft data', setAddQualification);
    if (!air_craft.length) {
      console.log('qualification data', air_craft, exp_date);
      toast.error('Please provide some input!');
    }
     else {
      setQualification({ aircraft: air_craft, expiration_date: exp_date });
      console.log('qualification', qualification);
      qArr.push({
        id: id,
        aircraft: aircraftData,
        expiration_date: expDate,
      });
      console.log('qr', qArr);
      setAddQualification([
        ...addQualifications,
        {
          id: id,
          aircraft: aircraftData,
          expiration_date: expDate,
        },
      ]);
      console.log(addQualifications);
      console.log('data', qualification);
      handleQualification(air_craft, exp_date);
      setSave(!save);
      setEditView(!editView);
      // const data = {
      //   id: id,
      //   aircraft: aircraftData,
      //   expiration_date: expDate,
      // };

      setShowAddQualifiaction(!showAddQualifiaction);
    }
  };

  const handleQualification = async (air_craft, exp_date) => {
    const data = {
      aircraft: air_craft,
      expiration_date: exp_date,
      pilot_user: 3,
    };
    console.log('d', data);
    console.log('addQ', addQualifications);
    // try {
    //   // setChoicedisabled(true);
    //   const response = await ApiRequests(
    //     api_urls.pilot_Qualification,
    //     'POST',
    //     data,
    //     null,
    //   );
    //   if (response) {
    //     toast.success('Pilot Qualifications added successfully');
    //     // setEditableChoices([]);
    //     // setEditableChoicesFlight([]);
    //     // setshowFlightTxt({});
    //     // setshowMaintenanceTxt();
    //     // fetchFirstChoices();
    //     // fetchSecChoices();
    //     // setAnswer1(null);
    //     // setChoicedisabled(false);
    //   }
    // } catch (error) {
    //   toast.error('Error in Adding Qualification');
    //   // setLoading(false);
    //   // setChoicedisabled(false);
    // }
  };

  // useEffect(() => {
  //   // setAirCraftData()
  //   // hadleAircraftData();
  //   console.log(aircraftData);
  // }, [aircraftData]);

  //Add Qualifications

  const handleAddChoice = (type) => {
    if (type === 'qualification') {
      setShowAddQualifiaction(true);
      setShowQualification(true);
    } else {
      setShowAddQualifiaction(false);
      setShowQualification(false);
    }
    // setShowAddQualifiaction(!showAddQualifiaction);
    // setEditSave(!editSave)
    setEditView(!editView);
    setSave(!save);
    setSaveEdit(!saveEdit);
    setAirCraftData('');
    setExpDate('');
  };

  const addQualificationAPIcall = async (aircraftVal, date) => {
    let apiurl = api_urls.pilot_Qualification;
    console.log(apiurl);
    if (aircraftVal == '') {
      toast.warn('Qualification Field cannot be empty');
    } else {
      let body = {
        pilot_user: props.profileDetails.owner.id,
        aircraft: aircraftVal,
        expiration_date: date,
      };
      const formData = new FormData();
      for (let key in body) {
        formData.append(key, body[key]);
      }
      console.log(body);
      console.log(formData);
      try {
        const response = await ApiRequests(apiurl, 'POST', formData, null);
        if (response.status === 201) {
          toast.success('Qualification updated successfully');
          window.location.reload(true);
        }
      } catch (error) {
        console.error('There was an error!', error.response.data.non_field_errors[0]);
        // toast.error('Error in saving Pilot Qualification');
        console.error('There was an error!', error.response.data.non_field_errors[0]);
        const er=error.response.data.non_field_errors[0];
        if(er.includes('aircraft must make a unique set')){
          
            toast.error('Qualification already exists');
          }
          else{
            toast.error('Error in saving Pilot Qualification');
          }
      }
    }
  };

  const handleAddApiQualification = () => {
    // setEditSave(!saveEdit)
    setSaveQApiData(!saveQApiData);
  };

  const handleGenderDropdown = (event) => {
    console.log('event###', event);
    setCertification(event.value);
    setgenderVal(event);
    // let res=handleplaceholder(false);
    // setplaceholdername(res);
  };

  const onColorChange = (newColor) => {
    console.log('newcolor', newColor.hex);
    setColor(newColor.hex);
    // values.color = newColor.hex;
  };
  var arr = props.profileDetails.pilot_qualifications;
  // setData(props.profileDetails.pilot_qualifications);
  console.log('dS.', arr, addQualifications);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   setLoading(true);
  //   arr = props.profileDetails.pilot_qualifications;
  // }, []);

  return (
    <>
      {/* {loading ? (
        <Loader loadingMsg="Data is Loading... Please Wait" />
      ) : ( */}
      <Formik
        innerRef={ref}
        validationSchema={schema}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
                fullName: props.profileDetails.owner.name || '',
                DateOfBirth: props.profileDetails.birth_date || '',
                Email: props.profileDetails.owner.email || '',
                Phone: props.profileDetails.phone || '',
                Bio: props.profileDetails.bio || '',
                licenseNumber: props.profileDetails.certificate_number || '',
                certificateType: props.profileDetails.certificate_type || '',
                dateOfExam: props.profileDetails.date_of_exam || '',
                certificateClass: props.profileDetails.certificate_class || '',
                qualifications: props.profileDetails.certificate_class || [],
                // licenseExpirationDate:
                //   props.profileDetails.license_expiration || '',
                // medicalRecord: props.profileDetails.medical_record || '',
                medicalVehicleRecord:
                  props.profileDetails.motor_vehicle_record || '',
                dateOfIssuance: props.profileDetails.date_of_issuance || '',
                Address: props.profileDetails.address || '',
                City: props.profileDetails.city || '',
                zipCode: props.profileDetails.zip_code || '',
                State: props.profileDetails.state || '',
                Country: props.profileDetails.country || '',
                color: props.profileDetails.color || '',
                addQualification: '',
                addQualificationDate: '',
              }
            : {
                fullName: '',
                DateOfBirth: '',
                Email: '',
                Phone: '',
                Bio: '',
                licenseNumber: '',
                certificateType: '',
                certificateClass: '',
                qualifications: {
                  aircraft: '',
                  expiration_date: '',
                },
                dateOfExam: '',
                // licenseExpirationDate: '',
                // medicalRecord: '',
                medicalVehicleRecord: '',
                dateOfIssuance: '',
                Address: '',
                City: '',
                zipCode: '',
                State: '',
                Country: '',
              }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          console.log('values inside submit', values);
          // if (getColor === undefined) {
          //   setshowColorError(true);
          //   return;
          // }
          const url = new URL(window.location.href);
          const id = url.pathname.split('/')[3];
          let apiurl = api_urls.pilotProfile + id + '/';
          if (!props.create) {
            try {
              let body = {
                owner: {
                  email: values.Email,
                  name: values.fullName,
                },
                certificate_number: values.licenseNumber,
                certificate_type: values.certificateType,
                certificate_class: genderVal.value,
                date_of_exam: values.dateOfExam,
                // license_expiration: values.licenseExpirationDate,
                // medical_record: values.medicalRecord,
                motor_vehicle_record: values.medicalVehicleRecord,
                date_of_issuance: values.dateOfIssuance,
                phone: values.Phone,
                birth_date: values.DateOfBirth ? values.DateOfBirth : null,
                bio: values.Bio,
                address: values.Address,
                city: values.City,
                zip_code: values.zipCode,
                state: values.State,
                country: values.Country,
                color: getColor,
                organization_id:
                  sessionStorage.getItem('organization_id') === 'null'
                    ? 2
                    : Number(sessionStorage.getItem('organization_id')),
                is_active: true,
              };

              const formData = new FormData();
              for (const key in body) {
                if (body[key]) {
                  if (
                    typeof body[key] === 'object' &&
                    !Array.isArray(body[key])
                  ) {
                    for (const nestedKey in body[key]) {
                      formData.append(
                        `owner.${nestedKey}`,
                        body[key][nestedKey],
                      );
                    }
                  } else {
                    formData.append(key, body[key]);
                  }
                }
              }
              if (props.selectedFile) {
                formData.append('image', props.selectedFile);
              }
              const response = await ApiRequests(
                apiurl,
                'PATCH',
                formData,
                null,
              );
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Pilot updated successfully');
                navigate('/dashboard/pilots', { replace: true });
              }
            } catch (error) {
              console.error('There was an error!', error);
              toast.error('Error in adding Pilot');
            }
          } else {
            let arrQualifications = [];
            addQualifications.map((item) =>
              arrQualifications.push({
                aircraft: item.aircraft,
                expiration_date: item.expiration_date,
              }),
            );
            console.log('allQ', JSON.stringify(arrQualifications));
            let body = {
              email: values.Email,
              name: values.fullName,
              certificate_number: values.licenseNumber,
              certificate_type: values.certificateType,
              certificate_class: genderVal.value,
              date_of_exam: values.dateOfExam,
              qualifications: JSON.stringify(arrQualifications),
              // license_expiration: values.licenseExpirationDate,
              // medical_record: values.medicalRecord,
              motor_vehicle_record: values.medicalVehicleRecord,
              date_of_issuance: values.dateOfIssuance,
              phone: values.Phone,
              birth_date: values.DateOfBirth,
              bio: values.Bio,
              address: values.Address,
              city: values.City,
              zip_code: values.zipCode,
              state: values.State,
              country: values.Country,
              color: getColor,
              organization_id:
                sessionStorage.getItem('organization_id') === 'null'
                  ? 2
                  : Number(sessionStorage.getItem('organization_id')),
              is_active: true,
            };
            console.log('body', body);
            props.storeNewUserDetails(body);
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form
            className=""
            noValidate
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Personal Information</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="fullName"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.fullName && errors.fullName
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={touched.fullName && errors.fullName}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Full Name*
                  </label>
                </Form.Group>
                <Form.Group
                  controlId="DateOfBirth"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.DateOfBirth && errors.DateOfBirth
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    name="DateOfBirth"
                    placeholder=""
                    value={values.DateOfBirth}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="1600-12-31"
                    max="2099-12-31"
                    pattern="\d{4}-\d{2}-\d{2}"
                    isInvalid={touched.DateOfBirth && errors.DateOfBirth}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Date Of Birth
                  </label>

                  {touched.DateOfBirth && errors.DateOfBirth ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.DateOfBirth}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Email"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.Email && errors.Email ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="email"
                    name="Email"
                    placeholder="Email"
                    value={values.Email}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    // disabled={!props.create}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Email && errors.Email}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Email*
                  </label>

                  {touched.Email && errors.Email ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="Phone"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Phone && errors.Phone ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="number"
                    maxLength="10"
                    onChange={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                      handleChange(e);
                    }}
                    name="Phone"
                    placeholder="Phone number"
                    value={values.Phone}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={touched.Phone && errors.Phone}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Phone number
                  </label>

                  {touched.Phone && errors.Phone ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Phone}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  style={{ width: '99%' }}
                  controlId="Bio"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Bio && errors.Bio ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    as="textarea"
                    name="Bio"
                    placeholder="Bio"
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    value={values.Bio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Bio && errors.Bio}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Bio
                  </label>

                  {touched.Bio && errors.Bio ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Bio}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Pilot Certificate</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="licenseNumber"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.licenseNumber && errors.licenseNumber
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="licenseNumber"
                    placeholder="Certificate number"
                    value={values.licenseNumber}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.licenseNumber && errors.licenseNumber}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Certificate number*
                  </label>

                  {/* {touched.licenseNumber && errors.licenseNumber ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.licenseNumber}
                  </Form.Control.Feedback> */}
                </Form.Group>
                {
                  //  <Form.Group
                  //   controlId="licenseExpirationDate"
                  //   className={`form-floating viewPilot-Box   mb-3 ${touched.licenseExpirationDate &&
                  //     errors.licenseExpirationDate
                  //     ? 'has-validation-error'
                  //     : ''
                  //     }`}>
                  //   <Form.Control
                  //     type="date"
                  //     name="licenseExpirationDate"
                  //     placeholder="license Expiration Date"
                  //     value={values.licenseExpirationDate}
                  //     className={props.readOnly ? 'disabledColor' : ''}
                  //     readOnly={props.readOnly}
                  //     onChange={handleChange}
                  //     onBlur={handleBlur}
                  //     min="1600-12-31"
                  //     max="2099-12-31"
                  //     isInvalid={
                  //       touched.licenseExpirationDate &&
                  //       errors.licenseExpirationDate
                  //     }
                  //   />
                  //   <label for="floatingInput" className="greyLabel">
                  //     License Expiration Date*
                  //   </label>
                  //   {/* {touched.licenseExpirationDate &&
                  //   errors.licenseExpirationDate ? null : (
                  //     <div className="input-group-append "></div>
                  //   )}
                  //   <Form.Control.Feedback type="invalid">
                  //     {errors.licenseExpirationDate}
                  //   </Form.Control.Feedback> */}
                  // </Form.Group>
                  // <Form.Group
                  //   controlId="medicalRecord"
                  //   className={`form-floating viewPilot-Box   mb-3 ${touched.medicalRecord && errors.medicalRecord
                  //     ? 'has-validation-error'
                  //     : ''
                  //     }`}>
                  //   <Form.Control
                  //     type="text"
                  //     name="medicalRecord"
                  //     placeholder="Medical Record"
                  //     value={values.medicalRecord}
                  //     className={props.readOnly ? 'disabledColor' : ''}
                  //     readOnly={props.readOnly}
                  //     onChange={handleChange}
                  //     onBlur={handleBlur}
                  //     isInvalid={touched.medicalRecord && errors.medicalRecord}
                  //   />
                  //   <label for="floatingInput" className="greyLabel">
                  //     Medical Record
                  //   </label>
                  // </Form.Group>
                }
                <Form.Group
                  controlId="medicalVehicleRecord"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.medicalVehicleRecord && errors.medicalVehicleRecord
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    name="medicalVehicleRecord"
                    placeholder="Motor Vehicle Record"
                    value={values.medicalVehicleRecord}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.medicalVehicleRecord &&
                      errors.medicalVehicleRecord
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Motor Vehicle Record
                  </label>
                </Form.Group>

                <Form.Group
                  controlId="dateOfIssuance"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.dateOfIssuance && errors.dateOfIssuance
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    name="dateOfIssuance"
                    placeholder="Date of Issuance"
                    value={values.dateOfIssuance}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.dateOfIssuance && errors.dateOfIssuance}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Date of Issuance
                  </label>
                </Form.Group>

                <Form.Group
                  controlId="certificateType"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.certificateType && errors.certificateType
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="certificateType"
                    placeholder="Certificate type"
                    value={values.certificateType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={
                      touched.certificateType && errors.certificateType
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Certificate type
                  </label>
                </Form.Group>
              </div>
            </Row>

            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Pilot Address</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="Address"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.Address && errors.Address
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Address"
                    placeholder="Address"
                    value={values.Address}
                    onChange={handleChange}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={touched.Address && errors.Address}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Address
                  </label>

                  {touched.Address && errors.Address ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Address}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="City"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.City && errors.City ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="City"
                    placeholder="City"
                    value={values.City}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.City && errors.City}
                  />
                  <label for="floatingInput" className="greyLabel">
                    City
                  </label>

                  {touched.City && errors.City ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.City}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="zipCode"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.zipCode && errors.zipCode
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="zipCode"
                    placeholder="Zip Code"
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.zipCode && errors.zipCode}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Zip Code
                  </label>

                  {touched.zipCode && errors.zipCode ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="State"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.State && errors.State ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="State"
                    placeholder="State"
                    value={values.State}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.State && errors.State}
                  />
                  <label for="floatingInput" className="greyLabel">
                    State
                  </label>

                  {touched.State && errors.State ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.State}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Country"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Country && errors.Country
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Country"
                    placeholder="Country"
                    value={values.Country}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Country && errors.Country}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Country
                  </label>

                  {touched.Country && errors.Country ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Country}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>

            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Medical</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="certificateClass"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.certificateClass && errors.certificateClass
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Select
                    options={[
                      {
                        value: 'Select Class',
                        label: 'Select Class',
                        isDisabled: true,
                      },
                      {
                        value: '1',
                        label: '1',
                      },
                      {
                        value: '2',
                        label: '2',
                      },
                      {
                        value: '3',
                        label: '3',
                      },
                    ]}
                    value={genderVal}
                    className={
                      props.readOnly
                        ? 'showdisabledColor w-100 h-100'
                        : 'w-100 h-100'
                    }
                    isDisabled={props.readOnly}
                    onChange={handleGenderDropdown}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.certificateClass && errors.certificateClass
                    }
                    placeholder={
                      <div className="select-placeholder-text">
                        Certificate class
                      </div>
                    }
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        position: 'absolute',
                      }),
                    }}
                  />
                </Form.Group>
                <Form.Group
                  controlId="dateOfExam"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.dateOfExam && errors.dateOfExam
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    name="dateOfExam"
                    placeholder="dateOfExam"
                    value={values.dateOfExam}
                    onChange={handleChange}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={certification.length>0 && errors.dateOfExam}
                  />
                  <label for="floatingInput" className="greyLabel" style={{marginTop:`${errors.dateOfExam && '-8px'}`}}>
                    Date of Exam
                  </label>

                  {touched.dateOfExam && errors.dateOfExam ? null : (
                    <div className="input-group-append "></div>
                  )}
                <Form.Control.Feedback type="invalid">
                    {errors.dateOfExam}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>

            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Qualifications</h4>
              <hr />
              {arr &&
                arr.map((item) => (
                  <div key={item.id}>
                    {/* <Form.Group
                      controlId="aircraft"
                      className={`form-floating viewPilot-Box  mb-3 ${touched.certificateClass && errors.certificateClass
                        ? 'has-validation-error'
                        : ''
                        }`}>
                      <Form.Control
                        type="text"
                        // name="qualifications.aircraft"
                        placeholder="Qualification"
                        className={props.readOnly ? 'disabledColor' : ''}
                        readOnly={props.readOnly}
                        value={
                          tempEditId === item.id ? aircraftData : item.aircraft
                          // aircraftData
                        }
                        onChange={(event) =>
                          hadleAircraftData(event.target.value)
                        }
                        onBlur={handleBlur}
                      />
               


                      {aircraftData.length < 0 && (
                        <label for="floatingInput" className="greyLabel">
                          Qualification
                        </label>
                      )}
                    </Form.Group> */}
                    <Form.Group
                      controlId="aircraft"
                      className={`form-floating viewPilot-Box  mb-3 ${
                        touched.certificateClass && errors.certificateClass
                          ? 'has-validation-error'
                          : ''
                      }`}>
                      <Select
                        options={[
                          {
                            value: 'Qualification',
                            label: 'Qualification',
                            isDisabled: true,
                          },
                          ...aircraftOptions.map((item) => {
                            console.log('item', item.value);
                            return {
                              value: item.title,
                              label: item.title,
                            };
                          }),
                        ]}
                        // value={aircraftData}
                        placeholder="Qualification"
                        value={
                          tempEditId === item.id ? {value: aircraftData, label: aircraftData} : {value: item.aircraft,label: item.aircraft}
                          // aircraftData
                        }
                        className={
                          props.readOnly
                            ? 'showdisabledColor w-100 h-100'
                            : 'w-100 h-100'
                        }
                        isDisabled={props.readOnly}
                        onChange={(selectedOption) =>
                          hadleAircraftData(selectedOption)
                        } // Pass the selected value directly
                        onBlur={handleBlur}
                        isInvalid={
                          touched.certificateClass && errors.certificateClass
                        }
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            position: 'absolute',
                          }),
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="expiration_date"
                      className={`form-floating  viewPilot-Box  mb-3 ${
                        touched.dateOfExam && errors.dateOfExam
                          ? 'has-validation-error'
                          : ''
                      }`}>
                      <Form.Control
                        type="date"
                        // name="qualifications.expiration_date"
                        placeholder="expiration_date"
                        value={
                          tempEditId === item.id
                            ? expDate
                            : item.expiration_date
                          // expDate
                        }
                        onChange={(event) => handleExpDate(event.target.value)}
                        className={props.readOnly ? 'disabledColor' : ''}
                        readOnly={props.readOnly}
                        onBlur={handleBlur}
                        // isInvalid={touched.dateOfExam && errors.dateOfExam}
                      />
                      <label for="floatingInput" className="greyLabel">
                        Expiration Date
                      </label>

                      {touched.dateOfExam && errors.dateOfExam ? null : (
                        <div className="input-group-append "></div>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {errors.dateOfExam}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <>
                      {editView && tempEditId === item.id ? (
                        <>
                          <div
                            className="choice-option-wrapper  "
                            key={item.id}>
                            {/* <input
                          type="text"
                          className="form-control question-input w-25"
                          // onChange={(e) => setAnswer1(e.target.value)}
                        /> */}

                            <Button
                              // disabled={choicedisabled}
                              style={{
                                float: 'right',
                                backgroundColor: '#1334B9',
                                height: '55px',
                              }}
                              className="editPilot"
                              onClick={() =>
                                handleQualificationApiSave(
                                  item.id,
                                  item.pilot_user,
                                  aircraftData,
                                  expDate,
                                )
                              }>
                              Save &nbsp;&nbsp;
                              <Image src={Save}></Image>
                            </Button>
                          </div>
                        </>
                      ) : null}
                      {!props.readOnly && tempEditId !== item.id ? (
                        <>
                          <img
                            src={Editquestion}
                            className="edit-ques"
                            style={{
                              marginRight: '2px',
                              width: '60px',
                              height: '60px',
                            }}
                            alt="edit icon NP"
                            onClick={() =>
                              handleQualificationEditApi(
                                item.id,
                                item.aircraft,
                                item.expiration_date,
                              )
                            }
                          />
                          <img
                            src={Deletquestion}
                            className="edit-ques"
                            alt="edit icon NP"
                            onClick={() =>
                              delteQualification(
                                item.id,
                                item.aircraft,
                                item.expiration_date,
                              )
                            }
                            style={{
                              marginRight: '2px',
                              width: '60px',
                              height: '60px',
                            }}
                          />
                        </>
                      ) : null}
                    </>
                  </div>
                ))}
              {!props.readOnly &&
                !props.create &&
                showAddQualificationOnEdit && (
                  <div
                    className="add-choice my-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setAddQualificationOnEdit(false)}>
                    <img src={addques} className="add-choice-img" />
                    <span className="add-choice-txt">Add Qualification</span>
                  </div>
                )}

              {!props.readOnly &&
                !props.create &&
                !showAddQualificationOnEdit && (
                  <>
                    <div className="sec-row">
                      <h3 className="personalLabels">Add Qualifications</h3>
                    </div>

                    {/* <div className="row">
                      <Form.Control
                        type="text"
                        name="addQualification"
                        value={values.addQualification}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-25 col-md-3 ms-2"
                      />

                      <Form.Control
                        type="date"
                        name="addQualificationDate"
                        value={values.addQualificationDate}
                        placeholder="expiration_date"
                        className="col-md-1"
                        style={{ width: '23%', marginLeft: '15px' }}
                        readOnly={props.readOnly}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <Button
                        className="col-md-2"
                        // disabled={choicedisabled}
                        style={{
                          float: 'right',
                          backgroundColor: '#1334B9',
                          height: '55px',
                          width: '100px',
                          marginLeft: '30px',
                        }}
                        onClick={() => {
                          addQualificationAPIcall(
                            values.addQualification,
                            values.addQualificationDate,
                          );
                        }}>
                        Save &nbsp;&nbsp;
                        <Image src={Save}></Image>
                      </Button>
                    </div> */}
                    <div className="row">
    <Form.Select
        name="addQualification"
        value={values.addQualification}
        onChange={handleChange}
        onBlur={handleBlur}
        className="w-25 col-md-3 ms-2"
    >
        <option value="">Select Qualification</option>
        {
        aircraftOptions.map((item) =>
            <option value={item.title}>{item.title}</option>
        )
        }
        {/* <option value="Qualification 4">Qualification 4</option>
        <option value="Qualification 5">Qualification 5</option>
        <option value="Qualification 7">Qualification 7</option> */}
    </Form.Select>

    <Form.Control
        type="date"
        name="addQualificationDate"
        value={values.addQualificationDate}
        placeholder="expiration_date"
        className="col-md-1"
        style={{ width: '23%', marginLeft: '15px' }}
        readOnly={props.readOnly}
        onBlur={handleBlur}
        onChange={handleChange}
    />

    <Button
        className="col-md-2"
        style={{
            float: 'right',
            backgroundColor: '#1334B9',
            height: '55px',
            width: '100px',
            marginLeft: '30px',
        }}
        onClick={() => {
            addQualificationAPIcall(
                values.addQualification,
                values.addQualificationDate,
            );
        }}
    >
        Save &nbsp;&nbsp;
        <Image src={Save} />
    </Button>
</div>

                  </>
                )}

              {addQualifications.map((item) => (
                <div key={item.id}>
                  <Form.Group
                    controlId="aircraft"
                    className={`form-floating viewPilot-Box  mb-3 ${touched.certificateClass && errors.certificateClass
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="text"
                      // name="qualifications.aircraft"
                      placeholder="Select Qualification"
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      value={
                        tempEditId == item.id ? aircraftData : item.aircraft
                      }
                      onChange={(event) =>
                        hadleAircraftData(event.target.value)
                      }
                      onBlur={handleBlur}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Qualification
                    </label>
                  </Form.Group>

                  <Form.Group
                    controlId="expiration_date"
                    className={`form-floating  viewPilot-Box  mb-3 ${
                      touched.dateOfExam && errors.dateOfExam
                        ? 'has-validation-error'
                        : ''
                    }`}>
                    <Form.Control
                      type="date"
                      // name="qualifications.expiration_date"
                      placeholder="expiration_date"
                      value={
                        tempEditId == item.id ? expDate : item.expiration_date
                      }
                      onChange={(event) => handleExpDate(event.target.value)}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onBlur={handleBlur}
                      // isInvalid={touched.dateOfExam && errors.dateOfExam}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Expiration Date
                    </label>

                    {touched.dateOfExam && errors.dateOfExam ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.dateOfExam}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <>
                    {editView && tempEditId === item.id ? (
                      <>
                        <div className="choice-option-wrapper  " key={item.id}>
                          {/* <input
                          type="text"
                          className="form-control question-input w-25"
                          // onChange={(e) => setAnswer1(e.target.value)}
                        /> */}

                          <Button
                            // disabled={choicedisabled}
                            style={{
                              float: 'right',
                              backgroundColor: '#1334B9',
                              height: '55px',
                            }}
                            className="editPilot"
                            onClick={() =>
                              handleSave(
                                new Date().getTime().toString(),
                                aircraftData,
                                expDate,
                              )
                            }>
                            Save &nbsp;&nbsp;
                            <Image src={Save}></Image>
                          </Button>
                        </div>
                      </>
                    ) : null}
                    {!props.readOnly && tempEditId !== item.id ? (
                      <>
                        {/* <img
                          src={Editquestion}
                          className="edit-ques"
                          style={{
                            marginRight: '2px',
                            width: '60px',
                            height: '60px',
                          }}
                          alt="edit icon NP"
                          onClick={() =>
                            handleEditOption(
                              item.id,
                              item.aircraft,
                              item.expiration_date,
                            )
                          }
                        /> */}
                        <img
                          src={Deletquestion}
                          className="edit-ques"
                          alt="edit icon NP"
                          onClick={() => handleDelete(item.id)}
                          style={{
                            marginRight: '2px',
                            width: '60px',
                            height: '60px',
                          }}
                        />
                      </>
                    ) : null}
                  </>
                </div>
              ))}
              {save && !readOnly && !editView ? (
                <div>
                  <Form.Group
                    controlId="aircraft"
                    className={`form-floating viewPilot-Box  mb-3 ${
                      touched.certificateClass && errors.certificateClass
                        ? 'has-validation-error'
                        : ''
                    }`}
                    style={{ fontWeight: '0',fontSize: '12px'}}
                    >
                    <Select
                      options={[
                        {
                          value: 'Qualification',
                          label: 'Qualification',
                          isDisabled: true,
                        },
                        ...aircraftOptions.map((item) => ({
                          value: item.title,
                          label: item.title,
                        }))
                      ]}
                      //  value={aircraftData}
                      placeholder="Select Qualification"
                      className={
                        props.readOnly
                          ? 'showdisabledColor w-100 h-100'
                          : 'w-100 h-100'
                      }
                      isDisabled={props.readOnly}
                      onChange={(selectedOption) =>
                        hadleAircraftData(selectedOption)
                      } // Pass the selected value directly
                      onBlur={handleBlur}
                      isInvalid={
                        touched.certificateClass && errors.certificateClass
                      }
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          position: 'absolute',
                        }),
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="dateOfExam"
                    className={`form-floating  viewPilot-Box  mb-3 ${
                      touched.dateOfExam && errors.dateOfExam
                        ? 'has-validation-error'
                        : ''
                    }`}>
                    <Form.Control
                      type="date"
                      // name="qualifications.expiration_date"
                      placeholder="expiration_date"
                      value={expDate}
                      onChange={(event) => handleExpDate(event.target.value)}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onBlur={handleBlur}
                      // isInvalid={touched.dateOfExam && errors.dateOfExam}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Expiration Date
                    </label>

                    {touched.dateOfExam && errors.dateOfExam ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.dateOfExam}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <>
                    {showAddQualifiaction === true ? (
                      <>
                        <div className="choice-option-wrapper  ">
                          {/* <input
                          type="text"
                          className="form-control question-input w-25"
                          // onChange={(e) => setAnswer1(e.target.value)}
                        /> */}

                          <Button
                            // disabled={choicedisabled}
                            style={{
                              float: 'right',
                              backgroundColor: '#1334B9',
                              height: '55px',
                            }}
                            className="editPilot"
                            onClick={() =>
                              handleSave(
                                new Date().getTime().toString(),
                                aircraftData,
                                expDate,
                              )
                            }>
                            Save &nbsp;&nbsp;
                            <Image src={Save}></Image>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={Editquestion}
                          className="edit-ques"
                          style={{
                            marginRight: '5px',
                          }}
                          alt="edit icon NP"
                          // onClick={() =>
                          //   handleEditOption(
                          //     choiceitem.id,
                          //     'maintenance',
                          //   )
                          // }
                          width={'90px'}
                          height={'90px'}
                        />
                        <img
                          src={Deletquestion}
                          className="edit-ques"
                          alt="edit icon NP"
                          // onClick={() =>
                          //   handleDelChoice(
                          //     choiceitem.id,
                          //     'maintenance',
                          //   )
                          // }
                          width={'90px'}
                          height={'90px'}
                        />
                      </>
                    )}
                  </>
                </div>
              ) : null}

              {saveQApiData && !readOnly && editView ? (
                <div>
                  {/* <Form.Group
                    controlId="aircraft"
                    className={`form-floating viewPilot-Box  mb-3 ${touched.certificateClass && errors.certificateClass
                      ? 'has-validation-error'
                      : ''
                      }`}>
                    <Form.Control
                      type="text"
                      // name="qualifications.aircraft"
                      placeholder="Qualification"
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      value={aircraftData}
                      onChange={(event) =>
                        hadleAircraftData(event.target.value)
                      }
                      onBlur={handleBlur}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Qualification
                    </label>
                  </Form.Group> */}

                  <Form.Group
                    controlId="aircraft"
                    className={`form-floating viewPilot-Box  mb-3 ${
                      touched.certificateClass && errors.certificateClass
                        ? 'has-validation-error'
                        : ''
                    }`}
                    style={{ fontWeight: '0',fontSize: '14px'}}
                    >
                    <Select
                      options={[
                        {
                          value: 'Qualification',
                          label: 'Qualification',
                          isDisabled: true,
                        },
                        ...aircraftOptions.map((item) => ({
                          value: item.title,
                          label: item.title,
                        }))
                      ]}
                      //  value={aircraftData}
                      placeholder="Select Qualification"
                      className={
                        props.readOnly
                          ? 'showdisabledColor w-100 h-100'
                          : 'w-100 h-100'
                      }
                      isDisabled={props.readOnly}
                      onChange={(selectedOption) =>
                        hadleAircraftData(selectedOption)
                      } // Pass the selected value directly
                      onBlur={handleBlur}
                      isInvalid={
                        touched.certificateClass && errors.certificateClass
                      }
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          position: 'absolute',
                        }),
                      }}
                    
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="dateOfExam"
                    className={`form-floating  viewPilot-Box  mb-3 ${
                      touched.dateOfExam && errors.dateOfExam
                        ? 'has-validation-error'
                        : ''
                    }`}>
                    <Form.Control
                      type="date"
                      // name="qualifications.expiration_date"
                      placeholder="expiration_date"
                      value={expDate}
                      onChange={(event) => handleExpDate(event.target.value)}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onBlur={handleBlur}
                      // isInvalid={touched.dateOfExam && errors.dateOfExam}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Expiration Date
                    </label>

                    {touched.dateOfExam && errors.dateOfExam ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.dateOfExam}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <>
                    {showAddQualifiaction === true ? (
                      <>
                        <div className="choice-option-wrapper  ">
                          {/* <input
                          type="text"
                          className="form-control question-input w-25"
                          // onChange={(e) => setAnswer1(e.target.value)}
                        /> */}

                          <Button
                            // disabled={choicedisabled}
                            style={{
                              float: 'right',
                              backgroundColor: '#1334B9',
                              height: '55px',
                            }}
                            className="editPilot"
                            onClick={() =>
                              handleSave(
                                new Date().getTime().toString(),
                                aircraftData,
                                expDate,
                              )
                            }>
                            Save &nbsp;&nbsp;
                            <Image src={Save}></Image>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={Editquestion}
                          className="edit-ques"
                          style={{
                            marginRight: '5px',
                          }}
                          alt="edit icon NP"
                          // onClick={() =>
                          //   handleEditOption(
                          //     choiceitem.id,
                          //     'maintenance',
                          //   )
                          // }
                          width={'90px'}
                          height={'90px'}
                        />
                        <img
                          src={Deletquestion}
                          className="edit-ques"
                          alt="edit icon NP"
                          // onClick={() =>
                          //   handleDelChoice(
                          //     choiceitem.id,
                          //     'maintenance',
                          //   )
                          // }
                          width={'90px'}
                          height={'90px'}
                        />
                      </>
                    )}
                  </>
                </div>
              ) : null}

              {!showAddQualifiaction ? (
                <div
                  className="add-choice mt-4"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAddChoice('qualification')}>
                  <img src={addques} className="add-choice-img" />
                  <span className="add-choice-txt">Add Qualification</span>
                </div>
              ) : null}
              {/* {!showAddQualifiactionApi &&
              !props.editView &&
              !save &&
              !props.readOnly &&
              showAddQualifiaction &&
              !save ? (
                <div
                  className="add-choice mt-4"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAddApiQualification()}>
                  <img src={addques} className="add-choice-img" />
                  <span className="add-choice-txt">Add Qualification</span>
                </div>
              ) : null} */}
            </Row>

            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '30px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Pick a color for Pilot</h4>
              <hr />
              <div className="color-picker-wrapper">
                <div className="color-hex-cont">
                  <div className="color-hex-val">
                    <div
                      style={{
                        background: getColor || values.color,
                        width: 56,
                        height: 43,
                        color: getColor || values.color,
                      }}>
                      {getColor || values.color}
                    </div>
                    <div className="color-hex">{getColor || values.color}</div>
                  </div>
                </div>
                {colorPickerEnabled && (
                  <SketchPicker
                    color={getColor || values.color}
                    onChange={onColorChange}
                    className="p-4 color-picker"
                  />
                )}
              </div>
            </Row>

            {showDelete === true && (
              <DeleteQualificationPop
                show={showDelete}
                handleDiscard={handleDiscard}
              />
            )}
          </form>
        )}
      </Formik>
      {/* )} */}
    </>
  );
});
