import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { ApiRequests } from '../https/ApiRequests';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { api_urls, organization_id } from '../../constants/constants';

export const OrganizationGeneralDetails = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    organizationEmail: Yup.string()
      .email('Invalid email')
      .required('Email is required'),

    organizationName: Yup.string().required('Name is required'),
  });

  const [updateData, setUpdateData] = useState({});

  const handleReset = () => {
  };

  return (
    <>
      {/* <Button onClick={handleReset}>reset</Button> */}
      <Formik
        innerRef={ref}
        validationSchema={schema}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
              organizationName: props.profileDetails.owner.name || '',
              DateOfBirth: props.profileDetails.incorporation_date || '',
              organizationEmail: props.profileDetails.owner.email || '',
              phoneNumber: props.profileDetails.phone_number || '',
              additionalInformation:
                props.profileDetails.additional_information || '',
              Address: props.profileDetails.address || '',
              City: props.profileDetails.city || '',
              zipCode: props.profileDetails.zip_code || '',
              State: props.profileDetails.state || '',
              Country: props.profileDetails.country || '',
            }
            : {
              fullName: '',
              DateOfBirth: '',
              Email: '',
              Gender: '',
              Bio: '',
              licenseNumber: '',
              licenseExpirationDate: '',
              Address: '',
              City: '',
              zipCode: '',
              State: '',
              Country: '',
            }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          const url = new URL(window.location.href);
          const id = url.pathname.split('/').pop();
          // let params = { organization_id: organization_id }
          let apiurl = api_urls.Organization + id + '/';
          if (!props.create) {
            let body = {
              owner: {
                email: values.organizationEmail,
                name: values.organizationName,
              },
              country: values.Country,
              phone_number: values.phoneNumber,
              address: values.Address,
              city: values.City,
              zip_code: values.zipCode,
              state: values.State,
              additional_information: values.additionalInformation,
              incorporation_date: values.DateOfBirth
                ? values.DateOfBirth
                : null,
            };

            try {
              const response = await ApiRequests(apiurl, 'PATCH', body, null);
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Organization updated successfully');
                navigate('/dashboard/organizations', { replace: true });
              }
            } catch (error) {
              console.error('There was an error!', error);
              toast.error('Error in adding Organization');
            }
          } else {
            let body = {
              email: values.organizationEmail,
              name: values.organizationName,
              phone_number: values.phoneNumber,
              country: values.Country,
              address: values.Address,
              city: values.City,
              zip_code: values.zipCode,
              state: values.State,
              additional_information: values.additionalInformation,
              incorporation_date: values.DateOfBirth
                ? values.DateOfBirth
                : null,
            };
            props.storeNewOrganizationDetails(body);
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form className="" noValidate>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Organization Details</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="fullName"
                  className={`form-floating  viewPilot-Box  mb-3 ${touched.organizationName && errors.organizationName
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="organizationName"
                    placeholder="Organization Name*"
                    value={values.organizationName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={
                      touched.organizationName && errors.organizationName
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Organization Name*
                  </label>

                  {/* {touched.fullName && errors.fullName ? null : (
                    <div className="input-group-append " style={{marginBottom:"10px"}}></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback> */}
                </Form.Group>

                <Form.Group
                  controlId="Email"
                  className={`form-floating viewPilot-Box   mb-3 ${touched.organizationEmail && errors.organizationEmail
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    disabled={props.create==true ? false : true}
                    name="organizationEmail"
                    placeholder="Organization Email*"
                    value={values.organizationEmail}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.organizationEmail && errors.organizationEmail
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Organization Email*
                  </label>

                  {/* {touched.Email && errors.Email ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Email}
                  </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group
                  controlId="phoneNumber"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.phoneNumber && errors.phoneNumber
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={values.phoneNumber}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.phoneNumber && errors.phoneNumber}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Phone Number
                  </label>

                  {touched.phoneNumber && errors.phoneNumber ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="DateOfBirth"
                  className={`form-floating  viewPilot-Box  mb-3 ${touched.DateOfBirth && errors.DateOfBirth
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="date"
                    name="DateOfBirth"
                    min="1600-12-31"
                    max="2099-12-31"
                    placeholder="Date Started"
                    value={values.DateOfBirth}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.DateOfBirth && errors.DateOfBirth}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Date Started
                  </label>

                  {touched.DateOfBirth && errors.DateOfBirth ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.DateOfBirth}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  style={{ width: '99%' }}
                  controlId="additionalInformation"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.additionalInformation &&
                      errors.additionalInformation
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    as="textarea"
                    name="additionalInformation"
                    placeholder="Additiona lInformation"
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    value={values.additionalInformation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.additionalInformation &&
                      errors.additionalInformation
                    }
                  />
                  <label for="floatingInput" className="greyLabel">
                    Additional Information
                  </label>

                  {touched.additionalInformation &&
                    errors.additionalInformation ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.additionalInformation}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Organization Address</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="Address"
                  className={`form-floating  viewPilot-Box  mb-3 ${touched.Address && errors.Address
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="Address"
                    placeholder="Address"
                    value={values.Address}
                    onChange={handleChange}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={touched.Address && errors.Address}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Address
                  </label>

                  {touched.Address && errors.Address ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Address}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="City"
                  className={`form-floating viewPilot-Box   mb-3 ${touched.City && errors.City ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="City"
                    placeholder="City"
                    value={values.City}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.City && errors.City}
                  />
                  <label for="floatingInput" className="greyLabel">
                    City
                  </label>

                  {touched.City && errors.City ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.City}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="zipCode"
                  className={`form-floating viewPilot-Box   mb-3 ${touched.zipCode && errors.zipCode
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="zipCode"
                    placeholder="Zip Code"
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.zipCode && errors.zipCode}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Zip Code
                  </label>

                  {touched.zipCode && errors.zipCode ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="State"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.State && errors.State ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="State"
                    placeholder="State"
                    value={values.State}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.State && errors.State}
                  />
                  <label for="floatingInput" className="greyLabel">
                    State
                  </label>

                  {touched.State && errors.State ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.State}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Country"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.Country && errors.Country
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="Country"
                    placeholder="Country"
                    value={values.Country}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Country && errors.Country}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Country
                  </label>

                  {touched.Country && errors.Country ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Country}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
});
