import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../Sidebar';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import imageIcon from '../../images/icons/imageicon.png';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { PilotDocuments } from './PilotDocuments';
import { GeneralDetails } from './GeneralDetails';
import { DiscardChanges } from './DiscardChanges';
import toggleOn from '../../images/icons/toggleOn.png';
import toggleOff from '../../images/icons/toggleoff.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Iconedit from '../../images/icons/Iconedit.png';
import Save from '../../images/icons/save.png';

import arrowleft from '../../images/icons/arrow-left.png';
import { api_urls, organization_id } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { ToggleOnModal } from './ToggleOnModal';
import { ToggleOffModal } from './ToggleOffModal';
import { PilotTrainings } from './PilotTrainings';

export const ViewPilot = (props) => {
  const navigate = useNavigate();
  const authRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [showToggleOnModal, setShowToggleOnModal] = useState(false);
  const [showToggleOffModal, setShowToggleOffModal] = useState(false);
  const [UserId, setUserId] = useState(null);
  const [headerName, setHeaderName] = useState('');
  const [showdiscard, setdiscard] = useState(false);

  let editable = location.state.edit;
  let create = location.state.create;
  let rowclick = location.state.rowclick;

  if (editable) {
    editable = false;
  } else {
    editable = true;
  }
  const [readOnly, setReadOnly] = useState(location.state.edit);
  const [editView, setEditView] = useState(JSON.parse(!editable));
  const [uploadImage, setUploadImage] = useState(false);
  const [save, setSave] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [pilotName, setPilotName] = useState('');
  const [pilotId, setPilotId] = useState(null);
  const [newUserDocuments, setnewUserDocuments] = useState();
  const [newUserDetails, setnewUserDetails] = useState();
  const [selectedDocumentFile, setselectedDocumentFile] = useState(null);

  const [createUser, setCreateUser] = useState({});

  const handleDiscard = (flag) => {
    if (flag === true) {
      setdiscard(false);
      navigate('/dashboard/pilots', { replace: true });
    } else {
      setdiscard(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = new URL(window.location.href);
        // const id = url.pathname.split('/').pop();
        const id = url.pathname.split('/')[3];
        // let pilot_id = url.pathname.split('/')[4];
        setUserId(id);

        let params = {
          organization_id:
            sessionStorage.getItem('organization_id') === 'null'
              ? 2
              : sessionStorage.getItem('organization_id'),
        };
        let apiurl = api_urls.pilotProfile + id + '/';
        const response = await ApiRequests(apiurl, 'GET', null, params);

        setprofileDetails(response.data);
        setPilotName(response.data.owner.name);
        setPilotId(response.data.owner.id);
        setDataFetched(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (create) {
    } else {
      fetchData();
    }
  }, [dataFetched, showToggleOnModal, showToggleOffModal, editView]);

  useEffect(() => {
  }, [pilotId]);

  useEffect(() => {
    handleheader();
  }, []);
  const handleheader = (editClicked) => {
    if (editClicked === true) {
      setHeaderName('Edit Pilot');
      return;
    }
    if (!create) {
      setHeaderName('Pilot Details');
      return;
    } else {
      setHeaderName('Create Pilot');
      return;
    }
  };

  const storePilotDocument = (file) => {
    setselectedDocumentFile(file);
    // storePilotDocumentFile(file);
  };
  // const storePilotDocumentFile= (acceptedFiles)=>{
  //   acceptedFiles.forEach((file) => {
  //     newData.append('file', file);
  //   });
  // }

  const storeNewUserDetails = async (object) => {
    storePilot(object);
  };

  const storePilot = async (object) => {
    console.log('object', object);
    try {
      const formData = new FormData();
      for (const key in object) {
        if (object[key]) {
          formData.append(key, object[key]);
        }
      }

      if (selectedFile) {
        formData.append('image', selectedFile);
      }

      if (selectedDocumentFile && selectedDocumentFile.test) {
        console.log('selectedDocumentFile', selectedDocumentFile);
        selectedDocumentFile.test.forEach((file) => {
          formData.append('documents', file);
        });
        // formData.append('documents', selectedDocumentFile.test);
      }

      if (selectedDocumentFile && selectedDocumentFile.title) {
        formData.append('titles', selectedDocumentFile.title);
      }
      const response = await ApiRequests(
        api_urls.CreatePilot,
        'POST',
        formData,
        null,
      );
      if (response.status === 200) {
        toast.success('Pilot created successfully');
        navigate('/dashboard/pilots', { replace: true });
      }
    } catch (error) {
      console.error('There was an error!', error);
      const errorResponse = error.response.data.error;
      if (
        error.response.data.message ===
        'An account already exists with this email id.'
      ) {
        toast.error('An account already exists with this email id.');
      } else {
        if(errorResponse.includes('duplicate key value violates unique constraint \"users_pilotqualification_pilot_user_id_aircraft_b84aa5d2_uniq')) {
        toast.error('Qualification already exists');
      }
      else{
        toast.error('Error in creating Pilot! Please try again later.');
      }
    }
  };
  };
  const [selectedFile, setSelectedFile] = useState('');

  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const changeToggleOnModal = (state) => {
    setShowToggleOnModal(state);
  };
  const changeToggleOffModal = (state) => {
    setShowToggleOffModal(state);
  };
  const editPilot = () => {
    setReadOnly(false);
    setEditView(false);
    setUploadImage(true);
    setSave(true);
    handleheader(true);
  };

  const saveChanges = () => {
    if (authRef && authRef.current) {
      authRef.current.handleSubmit();
    }
  };
  const cancelChanges = async () => {
    setdiscard(true);
  };

  const savedSuccessfully = () => {
    setReadOnly(true);
    setEditView(false);
  };

  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name={headerName} />
          {/* {!create ? 'Pilot Details' : 'Create Pilot'} /> */}

          <Row className="pilot-cont">
            <Col md={1} xs={1}></Col>
            <Col
              style={{
                height: '80vh',
                marginTop: '20px',
                paddingRight: '20px',
                overflowY: 'auto',
              }}
              md={10}
              xs={10}>
              <Row>
                <>
                  {rowclick === true ? (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: 'block',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                    </Col>
                  ) : (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'block' : 'none',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'none' : 'block',
                        }}
                        className="backButton"
                        onClick={cancelChanges}>
                        Cancel
                      </Button>
                    </Col>
                  )}
                </>
                <Col xs={8} md={8}></Col>
                <Col xs={2} md={2} style={{ paddingRight: '0px' }}>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display:
                        editView && !create && !rowclick ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={editPilot}>
                    Edit Pilot &nbsp;&nbsp;
                    <Image src={Iconedit}></Image>
                  </Button>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display: save || create ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={saveChanges}>
                    Save &nbsp;&nbsp;
                    <Image src={Save}></Image>
                  </Button>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  padding: '10px',
                }}>
                <Col md={5} xs={5}>
                  {create ? (
                    <div className="file-input-container d-flex align-items-center">
                      <label htmlFor="file-input" className="custom-file-input">
                        {previewUrl ? (
                          <img
                            src={previewUrl}
                            alt="Preview"
                            style={{
                              width: '100px', // Adjust the width as per your icon size
                              height: '100px', // Adjust the height as per your icon size
                            }}
                            className='prev-img'
                          />
                        ) : (
                          <>
                            <Image src={imageIcon} style={{
                        width: '100px', // Adjust the width as per your icon size
                        height: '100px',
                        // borderRadius: '50%' // Adjust the height as per your icon size
                      }}/>
                            {selectedFile ? selectedFile.name : ''}
                          </>
                        )}
                      </label>
                      <input
                        type="file"
                        id="file-input"
                        className="hidden-input"
                        onChange={handleFileChange}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="file-input-container d-flex align-items-center">
                        <label
                          htmlFor="file-input"
                          className="custom-file-input">
                          {previewUrl ? (
                            <img
                              src={previewUrl}
                              alt="Preview"
                              style={{
                                width: '100px', // Adjust the width as per your icon size
                                height: '100px', // Adjust the height as per your icon size
                              }}
                              className='prev-img'
                            />
                          ) : (
                            <>
                              <Image
                                style={{
                                  width: '100px', // Adjust the width as per your icon size
                                  height: '100px',
                                  float: 'left', marginRight: '20px' // Adjust the height as per your icon size
                                }}
                                src={
                                  profileDetails.image
                                    ? profileDetails.image
                                    : imageIcon
                                }
                                height={80}></Image>
                            </>
                          )}
                        </label>
                        {uploadImage ? (
                          <input
                            type="file"
                            id="file-input"
                            className="hidden-input"
                            onChange={handleFileChange}
                          />
                        ) : (
                          ''
                        )}
                        <div style={{ marginTop: '10px', float: 'right' }}>
                          <h5>
                            <b>{pilotName}</b>
                          </h5>
                          <p>Pilot</p>
                        </div>
                      </div>
                    </>
                  )}
                </Col>

                <Col md={4} xs={4}></Col>
                <>
                  {!create && (
                    <Col
                      md={3}
                      xs={3}
                      style={{
                        display: rowclick === true ? 'none' : 'block',
                      }}>
                      <p style={{ float: 'right', display: 'none' }}>
                        Pilot Status &nbsp;
                        <Image
                          src={profileDetails.is_active ? toggleOn : toggleOff}
                          onClick={() => {
                            // if (rowclick == false) {
                            // if (editView && !create) {
                            if (profileDetails.is_active) {
                              setShowToggleOnModal(true);
                            } else {
                              setShowToggleOffModal(true);
                            }
                            // }
                            // }
                          }}></Image>
                      </p>
                    </Col>
                  )}
                </>
              </Row>

              <Row
                style={{
                  marginTop: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  padding: '10px',
                }}>
                <Col>
                  <Tabs
                    defaultActiveKey="generalDetails"
                    id="uncontrolled-tab-example"
                    className="gen-details-tab mb-3">
                    <Tab
                      eventKey="generalDetails"
                      title="General Details "
                      style={{ backgroundColor: 'white' }}>
                      <GeneralDetails
                        ref={authRef}
                        readOnly={readOnly}
                        savedSuccessfully={savedSuccessfully}
                        profileDetails={profileDetails}
                        editView={editView}
                        create={create}
                        storeNewUserDetails={storeNewUserDetails}
                        selectedFile={selectedFile}
                      />
                    </Tab>
                    <Tab
                      eventKey="documents"
                      title="Documents"
                      style={{ backgroundColor: 'white' }}>
                      <PilotDocuments
                        pilotId={pilotId}
                        create={create}
                        storePilotDocument={storePilotDocument}
                        selectedDocumentFile={selectedDocumentFile}
                        rowclick={rowclick}
                      />
                    </Tab>
                    {!create ? (
                      <Tab
                        eventKey="training"
                        title="Training"
                        style={{ backgroundColor: 'white' }}>
                        <PilotTrainings
                          pilotId={pilotId}
                          create={create}
                          rowclick={rowclick}
                        />
                      </Tab>
                    ) : (
                      ''
                    )}
                  </Tabs>
                </Col>
              </Row>
            </Col>
            <Col md={1} xs={1}></Col>
          </Row>
        </Sidebar>

        {showToggleOnModal && (
          <ToggleOnModal
            changeToggleOnModal={changeToggleOnModal}
            show={showToggleOnModal}
            userid={UserId}
          />
        )}
        {showToggleOffModal && (
          <ToggleOffModal
            changeToggleOffModal={changeToggleOffModal}
            show={showToggleOffModal}
            userid={UserId}
          />
        )}
      </Container>
      {showdiscard === true && (
        <DiscardChanges show={showdiscard} handleDiscard={handleDiscard} />
      )}
    </>
  );
};
