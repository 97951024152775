import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
export const GeneralDetails = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const schema = Yup.object().shape({
    // Email: Yup.string().email('Invalid email').required('Email is required'),
    Email:Yup.string().matches(emailRegExp, 'Invalid email format').required('Email is required'),
    fullName: Yup.string().required('Name is required'),
    Company: Yup.string().required('Company is required'),
  });

  const [updateData, setUpdateData] = useState({});

  // useEffect(() => {
  //   // Update the form values when the prop changes
  //   if (props.profileDetails) {
  //     ref.current.setValues(props.profileDetails);
  //   }
  // }, [props.profileDetails]);
  const handleReset = () => {
  };

  return (
    <>
      {/* <Button onClick={handleReset}>reset</Button> */}
      <Formik
        innerRef={ref}
        validationSchema={schema}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
              fullName: props.profileDetails.name || '',
              Email: props.profileDetails.email || '',
              Phone: props.profileDetails.phone_number || '',
              Company: props.profileDetails.company || '',
              Address: props.profileDetails.address || '',
              City: props.profileDetails.city || '',
              AdditionalInfo:
                props.profileDetails.additional_information || '',
            }
            : {
              fullName: '',
              Email: '',
              Phone: '',
              Company: '',
              Address: '',
              City: '',
              AdditionalInfo: '',
            }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          const url = new URL(window.location.href);
          const id = url.pathname.split('/').pop();
          let apiurl = api_urls.customerProfile + id + '/';
          if (!props.create) {
            try {
              let body = {
                name: values.fullName,
                email: values.Email,
                company: values.Company,
                phone_number: values.Phone,
                organization:
                  sessionStorage.getItem('organization_id') === 'null'
                    ? 2
                    : sessionStorage.getItem('organization_id'),
                address: values.Address,
                city: values.City,
                additional_information: values.AdditionalInfo,
                // is_active: true
              };
              const response = await ApiRequests(apiurl, 'PATCH', body, null);
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Customer updated successfully');
                navigate('/dashboard/customers', { replace: true });
              }
            } catch (error) {
              console.error('There was an error!', error);
              toast.error('Error in updating Customer');
            }
          } else {
            let body = {
              name: values.fullName,
              email: values.Email,
              company: values.Company,
              phone_number: values.Phone,
              organization:
                sessionStorage.getItem('organization_id') === 'null'
                  ? 2
                  : sessionStorage.getItem('organization_id'),
              address: values.Address,
              city: values.City,
              additional_information: values.AdditionalInfo,
            };
            props.storeNewUserDetails(body);
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form
            className=""
            noValidate
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Personal information</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="Company"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.Company && errors.Company
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="Company"
                    placeholder="Company"
                    value={values.Company}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Company && errors.Company}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Company*
                  </label>

                  {touched.Company && errors.Company ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Company}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="fullName"
                  className={`form-floating  viewPilot-Box  mb-3 ${touched.fullName && errors.fullName
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={touched.fullName && errors.fullName}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Contact person name*
                  </label>

                   {touched.fullName && errors.fullName ? null : (
                    <div className="input-group-append " style={{marginBottom:"10px"}}></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Email"
                  className={`form-floating viewPilot-Box   mb-3 ${touched.Email && errors.Email ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="email"
                    name="Email"
                    placeholder="Email"
                    value={values.Email}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Email && errors.Email}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Email*
                  </label>
                   {touched.Email && errors.Email ? null : (
                    <div className="input-group-append " style={{marginBottom:"10px"}}></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Phone"
                  className={`form-floating viewPilot-Box  mb-3 ${touched.Phone && errors.Phone ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="number"
                    name="Phone"
                    placeholder="Phone number"
                    value={values.Phone}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.Phone && errors.Phone}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Phone number
                  </label>

                  {touched.Phone && errors.Phone ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Address Details</h4>
              <hr />
              <div>
                <Form.Group
                  controlId="Address"
                  className={`form-floating  viewPilot-Box32  mb-3 ${touched.Address && errors.Address
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="Address"
                    placeholder="Address"
                    value={values.Address}
                    onChange={handleChange}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={touched.Address && errors.Address}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Address
                  </label>

                  {touched.Address && errors.Address ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Address}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="City"
                  className={`form-floating viewPilot-Box32   mb-3 ${touched.City && errors.City ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="City"
                    placeholder="City"
                    value={values.City}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.City && errors.City}
                  />
                  <label for="floatingInput" className="greyLabel">
                    City
                  </label>

                  {touched.City && errors.City ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.City}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="AdditionalInfo"
                  className={`form-floating viewPilot-Box32 mb-3 ${touched.AdditionalInfo && errors.AdditionalInfo
                      ? 'has-validation-error'
                      : ''
                    }`}
                // style={{ width: "100%" }}
                >
                  <Form.Control
                    type="text"
                    name="AdditionalInfo"
                    placeholder="AdditionalInfo"
                    value={values.AdditionalInfo}
                    className={props.readOnly ? 'disabledColor w-100' : 'w-100'}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.AdditionalInfo && errors.AdditionalInfo}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Additional Information
                  </label>

                  {touched.AdditionalInfo && errors.AdditionalInfo ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.AdditionalInfo}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
});
