import React, { useEffect, useState, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import reseticon from '../../images/activeicons/reseticon.svg';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './calendarpopup.css';

export const CalendarFilter = (props) => {
  const organization_id=
  sessionStorage.getItem('organization_id') === 'null'
    ? 2
    : sessionStorage.getItem('organization_id')
  const [aircraft, setaircraft] = useState([]);
  const [pilot, setpilot] = useState([]);
  const [pilotVal, setpilotVal] = useState(props.selectedPilots || []);
  const [showDisabled, setDisabled] = useState(false);
  const [aircraftVal, setaircraftVal] = useState(
    props.selectedAircrafts || [],
  );

  function handleClose() {
    props.toggleFilter(false);
  }

  const handlesubmit = async () => {
    console.log('inside handle submit')
    console.log('aircraft val', aircraftVal);
    console.log('pilotval', pilotVal);
    let aircraftstr = '';
    let pilotstr = '';
    for (let i = 0; i < aircraftVal.length; i++) {
      aircraftstr += 'aircraft_tail_number=' + aircraftVal[i].label + '&';
    }
    for (let i = 0; i < pilotVal.length; i++) {
      pilotstr += 'pilot_name=' + pilotVal[i].label + '&';
    }
    console.log('app url', `${api_urls.createAppointment}?organization_id=${organization_id}&${aircraftstr}${pilotstr}`);
    let APiurl = `${api_urls.createAppointment}?organization_id=${organization_id}&${aircraftstr}${pilotstr}`
    props.applyFilters(APiurl, aircraftVal, pilotVal);};

  const resetSubmit = () => {
    props.fetchAllAppointments();
    props.toggleFilter();
    props.handleRedDot();
  };

  useEffect(() => {
    // fetchData();
    fetchAircrafts();
    fetchPilots();
  }, []);

  const fetchAircrafts = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id')
      };
      let arr = [];
      const response = await ApiRequests(
        api_urls.aircraftProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      console.log('response', response.data);

      for (let i = 0; i < tempdata.length; i++) {
        arr.push({
          label: tempdata[i].tail_number || '',
          value: tempdata[i].id || '',
        });
      }
      setaircraft(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchPilots = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id')
      };
      let arr = [];
      const response = await ApiRequests(
        api_urls.pilotProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      console.log('response', response.data);

      for (let i = 0; i < tempdata.length; i++) {
        arr.push({
          label: tempdata[i].owner.name || '',
          value: tempdata[i].owner.id || '',
        });
      }
      setpilot(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleAircratsDropdown = (event) => {
    setaircraftVal(event);
  };

  const handlePilotsDropdown = (event) => {
    setpilotVal(event);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      className="report-popup p-0"
      centered>
      <Modal.Header>
        <Modal.Title className="modalTitle">Advance Filters</Modal.Title>
        <img
          src={reseticon}
          className="reset-icon"
          onClick={() => {
            resetSubmit();
          }}
        />
      </Modal.Header>
      <Modal.Body className="modalBody">
        <form onSubmit={handlesubmit}>
          <label className="report-label">Aircraft</label>
          <Select
            options={aircraft}
            value={aircraftVal}
            isMulti
            placeholder={
              <div className="select-placeholder-text">Select Aircraft</div>
            }
            onChange={handleAircratsDropdown}
            className="w-100"
          />

          <label className="report-label">Pilot</label>
          <Select
            options={pilot}
            value={pilotVal}
            isMulti
            placeholder={<div className="select-placeholder-text">Pilot</div>}
            onChange={handlePilotsDropdown}
            className="w-100"
          />

        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modalOkButton w-100" onClick={handlesubmit} disabled={showDisabled}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
