import React from "react"
import { Spinner } from "react-bootstrap"
import './spinner.css';

const Loader = ({ loadingMsg, style }) => {
  return (
    <div className="spinner-container">
      <Spinner animation="border" role="status" style={style}></Spinner>
      <pre>{loadingMsg}</pre>
    </div>
  )
}

export default Loader
