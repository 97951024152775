import { Modal } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { Drop } from "./Drop"

export const TrainingModal = (props) => {
  function handleClose() {
    props.setShowMoal(false)
    props.changeModalState(false)   
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Training</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{paddingLeft:0,paddingRight:0}}>
        <Drop  handleClose={handleClose} action="Create Training" showUpdatedData={props.showUpdatedData}/>
      </Modal.Body>
    </Modal>
  )
}
