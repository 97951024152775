import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../Sidebar';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { GeneralDetails } from './GeneralDetails';
import { DiscardChanges } from './DiscardChanges';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconedit from '../../images/icons/Iconedit.png';
import Save from '../../images/icons/save.png';
import arrowleft from '../../images/icons/arrow-left.png';
import { api_urls, organization_id } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';

export const ViewServicelog = () => {
  const navigate = useNavigate();
  const authRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [UserId, setUserId] = useState(null);

  let editable = location.state.edit;
  let create = location.state.create;
  let rowclick = location.state.rowclick;

  if (editable) {
    editable = false;
  } else {
    editable = true;
  }
  const [readOnly, setReadOnly] = useState(location.state.edit);
  const [editView, setEditView] = useState(JSON.parse(!editable));
  const [save, setSave] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [pilotName, setPilotName] = useState('');
  const [pilotId, setPilotId] = useState(null);
  const [newUserDocuments, setnewUserDocuments] = useState();
  const [newUserDetails, setnewUserDetails] = useState();
  const [selectedDocumentFile, setselectedDocumentFile] = useState(null);
  const [headerName, setHeaderName] = useState('');
  const [showdiscard, setdiscard] = useState(false);

  const handleDiscard = (flag) => {
    if (flag === true) {
      setdiscard(false);
      navigate('/dashboard/servicelog', { replace: true });
    } else {
      setdiscard(false);
    }
  };

  const fetchData = async () => {
    try {
      const url = new URL(window.location.href);
      const id = url.pathname.split('/')[3];
      let aircraft_id = url.pathname.split('/')[4];
      setUserId(id);

      let params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      let apiurl =
        api_urls.serviceProfile + id + '/?aircraft_id=' + aircraft_id;
      const response = await ApiRequests(apiurl, 'GET', null, params);

      setprofileDetails(response.data);
      setPilotName(response.data.name);
      setPilotId(response.data.id);
      setDataFetched(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (create) {
    } else {
      fetchData();
    }
  }, [dataFetched, editView]);

  useEffect(() => {
  }, [pilotId]);

  useEffect(() => {
    handleheader();
  }, []);

  const handleheader = (editClicked) => {
    if (editClicked === true) {
      setHeaderName('Edit Service');
      return;
    }
    if (!create) {
      setHeaderName('Service Details');
      return;
    } else {
      setHeaderName('Create Service');
      return;
    }
  };

  const storePilotDocument = (file) => {
    setselectedDocumentFile(file);
  };
  const storeNewUserDetails = async (object) => {
    storePilot(object);
  };

  const storePilot = async (object) => {
    try {
      const response = await ApiRequests(
        api_urls.serviceProfile,
        'POST',
        object,
        null,
      );
      if (response.status === 201) {
        toast.success('Service created successfully');
        navigate('/dashboard/servicelog', { replace: true });
      }
    } catch (error) {
      console.error('There was an error!', error);
      toast.error('Error in adding Service');
    }
  };

  const [selectedFile, setSelectedFile] = useState('');

  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const editPilot = () => {
    setReadOnly(false);
    setEditView(false);
    setSave(true);
    handleheader(true);
  };

  const saveChanges = () => {
    if (authRef && authRef.current) {
      authRef.current.handleSubmit();
    }
  };
  const cancelChanges = async () => {
    setdiscard(true);
};

  const savedSuccessfully = () => {
    setReadOnly(true);
    setEditView(false);
  };
  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name={headerName} />

          <Row>
            <Col md={1} xs={1}></Col>
            <Col
              style={{
                height: '80vh',
                marginTop: '20px',
                paddingRight: '20px',
                overflowY: 'auto',
              }}
              md={10}
              xs={10}>
              <Row>
                <>
                  {rowclick === true ? (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: 'block',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                    </Col>
                  ) : (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'block' : 'none',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'none' : 'block',
                        }}
                        className="backButton"
                        onClick={cancelChanges}>
                        Cancel
                      </Button>
                    </Col>
                  )}
                </>
                <Col xs={7} md={7}></Col>
                <Col xs={3} md={3} style={{ paddingRight: '0px' }}>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display: editView && !create && !rowclick ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={editPilot}>
                    Edit Service Log&nbsp;&nbsp;
                    <Image src={Iconedit}></Image>
                  </Button>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display: save || create ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={saveChanges}>
                    Save &nbsp;&nbsp;
                    <Image src={Save}></Image>
                  </Button>
                </Col>
              </Row>

              <Row
                style={{
                  marginTop: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  padding: '10px',
                }}>
                <Col>
                  <GeneralDetails
                    ref={authRef}
                    readOnly={readOnly}
                    savedSuccessfully={savedSuccessfully}
                    profileDetails={profileDetails}
                    editView={editView}
                    create={create}
                    storeNewUserDetails={storeNewUserDetails}
                    selectedFile={selectedFile}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={1} xs={1}></Col>
          </Row>
        </Sidebar>
      </Container>
      {showdiscard === true && (
        <DiscardChanges show={showdiscard} handleDiscard={handleDiscard} />
      )}
    </>
  );
};
