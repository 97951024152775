import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const MaintenanceRow = ({
  maintenanceDue,
  nxtmxDue,
  completedBy,
  is_day,
  readOnly,
  touched,
  errors,
  handleChange,
  handleBlur,
  fieldNames,
}) => {
  const [dateValue, setDateValue] = useState(formatDateForInput(completedBy));
console.log('maintenanceDue', errors)

  useEffect(() => {
    setDateValue(formatDateForInput(completedBy));
  }, [completedBy]);

  function formatDateForInput(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }

  function handleDateChange(event) {
    const newDate = event.target.value;
    setDateValue(newDate);
    const isoDate = new Date(newDate).toISOString();
    handleChange({ target: { name: fieldNames.completedBy, value: isoDate } });
  }
  return (
    <div>
      <Form.Group
        controlId={fieldNames.maintenanceDue}
        className={`form-floating viewPilot-Box mb-3 ${
          touched[fieldNames.maintenanceDue] &&
          errors[fieldNames.maintenanceDue]
            ? 'has-validation-error'
            : ''
        }`}>
        <Form.Control
          type="text"
          name={fieldNames.maintenanceDue}
          placeholder="Maintenance Due"
          value={maintenanceDue}
          className={readOnly ? 'disabledColor' : ''}
          readOnly={true}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={
            touched[fieldNames.maintenanceDue] &&
            errors[fieldNames.maintenanceDue]
          }
        />
        <label htmlFor={fieldNames.maintenanceDue} className="greyLabel">
          Maintenance Due
        </label>
        <Form.Control.Feedback type="invalid">
          {errors[fieldNames.maintenanceDue]}
        </Form.Control.Feedback>
      </Form.Group>

      {!is_day && (
        <Form.Group
          controlId={fieldNames.nxtmxDue}
          className={`form-floating viewPilot-Box mb-3 ${
            touched[fieldNames.nxtmxDue] && errors[fieldNames.nxtmxDue]
              ? 'has-validation-error'
              : ''
          }`}>
          <Form.Control
            type="text"
            name={fieldNames.nxtmxDue}
            placeholder="Next MX Due"
            value={nxtmxDue}
            className={readOnly ? 'disabledColor' : ''}
            readOnly={readOnly}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={
              touched[fieldNames.nxtmxDue] && errors[fieldNames.nxtmxDue]
            }
          />
          <label htmlFor={fieldNames.nxtmxDue} className="greyLabel">
            Next MX Due
          </label>
          <Form.Control.Feedback type="invalid">
            {errors[fieldNames.nxtmxDue]}
          </Form.Control.Feedback>
        </Form.Group>
      )}

      <Form.Group
        controlId={fieldNames.completedBy}
        className={`form-floating viewPilot-Box mb-3 ${
          touched[fieldNames.completedBy] && errors[fieldNames.completedBy]
            ? 'has-validation-error'
            : ''
        }`}>
        <Form.Control
          type="date"
          min="1600-12-31"
          max="2099-12-31"
          name={fieldNames.completedBy}
          placeholder="Completed By"
          value={dateValue}
          className={readOnly ? 'disabledColor' : ''}
          readOnly={readOnly}
          onChange={handleDateChange}
          onBlur={handleBlur}
          isInvalid={
            touched[fieldNames.completedBy] && errors[fieldNames.completedBy]
          }
        />

        <label htmlFor={fieldNames.completedBy} className="greyLabel">
          Completed By
        </label>
        <Form.Control.Feedback type="invalid">
          {errors[fieldNames.completedBy]}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
};

export default MaintenanceRow;
