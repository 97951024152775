import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Image, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import upload from '../../images/icons/uploadblack.png';
import Save from '../../images/icons/save.png';
import AlertCricle from '../../images/icons/alert-circle.png';
import CheckCricle from '../../images/icons/check-circle.png';
import PdfVector from '../../images/icons/PdfVector.png';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// toast.configure()

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // padding: '20px',
  paddingTop: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#EFF6FF',
  marginBottom: '20px',
  // color: '#bdbdbd',
  // outline: 'none',
  // transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const Drop = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState({});
  const [title, setTitle] = useState(''); // Added state variable for title
  const [showError, setError] = useState(false);
  const [date, setDate] = useState('');
  const [fileSelected, setFileSelected] = useState(false);

  useEffect(() => { }, [fileData]);
  const newData = new FormData();

  const handleTitleChange = useCallback((event) => {
    setTitle(event.target.value);
  }, []);
  const handleDateChange = useCallback((event) => {
    setDate(event.target.value);
  }, []);

  const onDrop = async (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      newData.append('file', file);
    });
    setFileSelected(true);
    const url = new URL(window.location.href);
    // const id = url.pathname.split('/').pop();
    const id = url.pathname.split('/')[3];
    let pilot_id = url.pathname.split('/')[4];

    newData.append('title', title);
    // if(props.action==='Create Training'){
    newData.append('date', date);
    newData.append('expiration_date', date);
    // }
    newData.append('pilot_user', pilot_id);
    setFileData({
      title: newData.get('title'),
      pilot_user: newData.get('pilot_user'),
      file: newData.get('file'),
      expiration_date: newData.get('expiration_date'),
      test: newData,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!fileSelected) {
      setError(true);
      toast.error('Please select a PDF file to upload.');
      return;
    }

    try {
      if (!props.create) {
        if (props.action === 'Upload Document') {
          const response = await ApiRequests(
            api_urls.pilotDocument,
            'POST',
            fileData.test,
          );
          if (response.status === 201) {
            props.action === 'Create Training'
              ? toast.success('Training uploaded successfully')
              : toast.success('Document uploaded successfully');
            props.showUpdatedData();
          }
        }
        if (props.action === 'Create Training') {
          const response = await ApiRequests(
            api_urls.pilotTraining,
            'POST',
            fileData.test,
          );
          if (response.status === 201) {
            props.action === 'Create Training'
              ? toast.success('Training created successfully')
              : toast.success('Document created successfully');
          }
        }
      }
      else {
        props.showfileList(true);
        props.storePilotDocument(fileData);
      }
      props.handleClose();
    } catch (error) {
      console.error('API error:', error);
      toast.error(error.response.data.error || 'Error in uploading PDF');
      setError(true);
    }
  };
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: '.pdf',
    multiple: false,
    onDrop: onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.path} className="file-li">
        <Image src={PdfVector}></Image> &nbsp;
        <span className="file-path"> {file.path}</span>
        <>
          {showError === true ? (
            <Image src={AlertCricle} style={{ float: 'right' }}></Image>
          ) : (
            <Image src={CheckCricle} style={{ float: 'right' }}></Image>
          )}
        </>
      </li>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <Image src={PdfVector}></Image> &nbsp;
      {file.path} <Image src={AlertCricle} style={{ float: 'right' }}></Image>
      <ul>
        {errors.map((e) => (
          <li style={{ color: 'red' }} key={e.code}>
            {e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  return (
    <Form onSubmit={handleSubmit}>
      <section className="container">
        <>
          {props.action === 'Create Training' ? (
            <Row className="training-popup-details d-flex">
              <div className="training-name  w-50">
                <label className="uploadDocumentLabel">Training Name</label>
                <br></br>
                <input
                  className="uploaddocumentInput"
                  type="text"
                  placeholder="Enter Training Name"
                  required
                  onChange={handleTitleChange}></input>
              </div>

              <div className="training-name w-50">
                <label className="uploadDocumentLabel">Training Date</label>
                <br></br>
                <input
                  className="uploaddocumentInput"
                  type="date"
                  min="1600-12-31"
                  max="2099-12-31"
                  placeholder="Training Date"
                  required
                  onChange={handleDateChange}></input>
              </div>
            </Row>
          ) : (
            <Row className="document-popup-details d-flex">
              <div className="training-name w-50">
                <label className="uploadDocumentLabel">Document title</label>
                <br></br>
                <input
                  className="uploaddocumentInput"
                  type="text"
                  placeholder="Enter Document Title"
                  required
                  onChange={handleTitleChange}></input>
              </div>

              <div className="training-name w-50">
                <label className="uploadDocumentLabel">
                  Document expiration date
                </label>
                <br></br>
                <input
                  className="uploaddocumentInput"
                  type="date"
                  min="1600-12-31"
                  max="2099-12-31"
                  placeholder="Document Expiration Date"
                  required
                  onChange={handleDateChange}></input>
              </div>
            </Row>
          )}
        </>

        <div className="file-upload-data mt-4">
          <ul className="file-input-list">{acceptedFileItems}</ul>
        </div>
        <div {...getRootProps({ style })} className="uploadDocumenUploadBox">
          <input {...getInputProps()} />
          <Image src={upload}></Image>
          <>
            {props.action === 'Create Training' ? (
              <>
                <label className="file-size-label mt-1">
                  Maximum File Size: 10 MB
                </label>
                <label className="file-size-label mt-1">
                  Select a pdf file to upload
                </label>
              </>
            ) : (
              <>
                <label className="file-size-label mt-1">
                  Maximum File Size: 10 MB
                </label>
                <label className="file-size-label mt-1">
                  Select a pdf file to upload
                </label>
              </>
            )}
          </>
        </div>
        <div style={{ float: 'right', marginTop: '20px' }}>
          <Button
            style={{
              margintop: '20px !important',
              textAlign: 'center',
              backgroundColor: '#1334B9',
            }}
            type="submit"
          // onClick={handleSubmit}
          >
            <>
              {props.action === 'Create Training' ? (
                <>Save Training &nbsp;&nbsp;</>
              ) : (
                <> Save Document &nbsp;&nbsp;</>
              )}
            </>

            <Image src={Save}></Image>
          </Button>
        </div>
      </section>
    </Form>
  );
};
