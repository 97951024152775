import React, { useEffect } from 'react';
// import 'typeface-inter';
// import 'react-select/dist/react-select.css'/;
// import '@emotion/react/css-in-js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import { ResetPasswordSuccessful } from './components/resetpassword/ResetPasswordSuccessful';
import { Calendar } from './components/Calendar';
import { Userprofile } from './components/myprofile/Userprofile.js';
import { Auth } from './components/login/Auth';
import RiskAssessment from './components/manageform/RiskAssessment';
import DailyReport from './components/manageform/DailyReport';
import { ResetPassword } from './components/resetpassword/ResetPassword';
import { ViewPilot } from './components/pilots/ViewPilot';
import { ViewReport } from './components/reports/ViewReport';
import { ViewCustomer } from './components/customers/ViewCustomer';
import { ViewSubAdmin } from './components/subAdmin/ViewSubAdmin';
import { ViewAircraft } from './components/aircrafts/ViewAircraft';
import { ViewServicelog } from './components/servicelogs/ViewServicelog';
import { ForgotPassword } from './components/forgotPassword/ForgotPassword';
import { PasswordChangeSuccessfull } from './components/forgotPassword/PasswordChangeSuccessfull';
import { UpdatePassword } from './components/updatepassword/UpdatePassword';
import { UpdatePasswordChangeSuccessful } from './components/updatepassword/UpdatePasswordChangeSuccessful';
import { ViewOrganizations } from './components/superAdmin/ViewOrganizations';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PrivacyPolicy } from './components/privacyPolicy/PrivacyPolicy.js';
import { ContactUs } from './components/contactUs/ContactUs.js';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Auth />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path='/contact-us' element={<ContactUs />}/>
        <Route
          path="/dashboard/reports"
          element={<ProtectedRoute compName="reports" />}></Route>
        <Route
          path="/dashboard/pilots"
          element={<ProtectedRoute compName="Pilots" />}></Route>
        <Route
          path="/dashboard/manageform"
          element={<ProtectedRoute compName="manageform" />}></Route>
        <Route
          path="/dashboard/customers"
          element={<ProtectedRoute compName="Customer" />}></Route>

        <Route
          path="/dashboard/subadmin"
          element={<ProtectedRoute compName="Subadmin" />}></Route>

        <Route
          path="/dashboard/memos"
          element={<ProtectedRoute compName="memos" />}></Route>
        <Route
          path="/dashboard/servicelog"
          element={<ProtectedRoute compName="servicelog" />}></Route>
        <Route
          path="/dashboard/aircrafts"
          element={<ProtectedRoute compName="Aircrafts" />}></Route>
        <Route
          path="/dashboard/organizations"
          element={<ProtectedRoute />}></Route>

        <Route path="/profile" element={<Userprofile />} />

        <Route path="/dashboard/reports/:id" element={<ViewReport />} />
        <Route path="/dashboard/pilots/:id/:pilotid" element={<ViewPilot />} />
        <Route path="/dashboard/customers/:id" element={<ViewCustomer />} />
        <Route path="/dashboard/subadmin/:id" element={<ViewSubAdmin />} />
        <Route
          path="/dashboard/servicelog/:id/:aircraftid"
          element={<ViewServicelog />}
        />
        <Route path="/dashboard/aircrafts/:id" element={<ViewAircraft />} />
        <Route
          path="/dashboard/organizations/:id"
          element={<ViewOrganizations />}
        />
        <Route path="/dashboard/riskassessment" element={<RiskAssessment />} />

        <Route path="/dashboard/dailyreport" element={<DailyReport />} />
        <Route
          path="/dashboard/calendar"
          element={<ProtectedRoute compName="Calendar" />}
        />
        <Route path="/change-password" element={<ForgotPassword />} />
        <Route path="/success" element={<PasswordChangeSuccessfull />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route
          path="/update-success"
          element={<UpdatePasswordChangeSuccessful />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-success" element={<ResetPasswordSuccessful />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}
export default App;
