import moment from 'moment-timezone';
import { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { api_urls } from '../../constants/constants';
import filter from '../../images/icons/filter.png';
import { ReactComponent as IconaircraftmenuIcons } from '../../images/inactiveicons/Icon-aircraftmenuIcons-calendar.svg';
import { ReactComponent as IconcalendarmenuIcons } from '../../images/inactiveicons/Icon-calendarmenuIcons.svg';
import { ReactComponent as IconpilotsmenuIcons } from '../../images/inactiveicons/Icon-pilotsmenuIcons-calendar.svg';
import { ApiRequests } from '../https/ApiRequests';
import AddEventForm from './AddEventForm';
import { CalendarFilter } from './CalendarFilter';
import { ConfirmationModal } from './ConfirmationModal';
import EventForm from './EventForm';
import ViewEventForm from './ViewEventForm.js';
import './calendar.css';

// import Datetime from 'react-datetime';

const localizer = momentLocalizer(moment);

const CalendarPoc2 = () => {
  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showEditform, setEditform] = useState(false);
  const [selEventId, setselEventId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showReportFilter, setshowReportFilter] = useState(false);
  const [filterdata, setFilterData] = useState([]);
  const [showRedDot, setShowRedDot] = useState(false);
  const [aircraftsData, setAircraftsData] = useState([]);
  const [showViewform, setViewForm] = useState(false);
  const [selectedAircrafts, setSelectedAircrafts] = useState([]);
  const [selectedPilots, setSelectedPilots] = useState([]);
  let organization_id =
    sessionStorage.getItem('organization_id') === 'null'
      ? 2
      : sessionStorage.getItem('organization_id');

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const getUserTimeZone = () => {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timeZone;
    } catch (error) {
      console.error('Error fetching user timezone:', error);
      return null;
    }
  };
  const userTimeZone = getUserTimeZone();
  console.log('User Time Zone:', userTimeZone);

  useEffect(() => {
    fetchAllAppointments();
  }, []);

  useEffect(() => {
    console.log('User Time Zone:', userTimeZone);
    console.log('Events Array:', events);
  }, [userTimeZone, events]);

  const handleBgColor = (event) => {
    if (event.type === 'day_off') {
      return '#EAB308';
    } else {
      if (event.aircraft) {
        if (event.aircraft.color) {
          return event.aircraft.color;
        }
      } else if (event.assigned_pilot_color) {
        return event.assigned_pilot_color;
      } else {
        return '#1334B9';
      }
    }
  };

  // const handleDayOf = (event) => {
  //   if (event.type === 'day_off') {
  //     return 0;
  //   }
  // };

  const toggleFilter = () => {
    setshowReportFilter(false);
  };

  const handleRedDot = () => {
    setShowRedDot(false);
    setSelectedAircrafts([]);
    setSelectedPilots([]);
  };

  const fetchAllAppointments = async () => {
    const params = {
      organization_id: organization_id,
    };
    let resp = await ApiRequests(
      api_urls.getAllAppointments,
      'GET',
      null,
      params,
    );
    let obj = {};
    let arr = [];
    let arrAircraft = [];
    for (let i = 0; i < resp.data.length; i++) {
      let endTime = formattedDatefunc(resp.data[i].end_time);
      let startTime = formattedDatefunc(resp.data[i].time);
      console.log('endTime', endTime);

      obj = {
        // end: moment.tz(formattedDatefunc(resp.data[i].end_time), 'UTC').toDate(),
        // start:moment.tz(formattedDatefunc(resp.data[i].time), 'UTC').toDate(),
        end: formattedDatefunc(resp.data[i].end_time),
        // end: convertedTime,
        // start: convertedTimeStart,
        start: formattedDatefunc(resp.data[i].time),
        id: resp.data[i].id,
        title: resp.data[i].title,
        type: resp.data[i].type,
        pilot: resp.data[i].assigned_pilot,
        aircraft: resp.data[i].aircraft,
        assigned_pilot_color: resp.data[i].assigned_pilot_color,
      };
      if (obj.end_time !== null) {
        arr.push(obj);
      }
      arrAircraft.push(resp.data[i].aircraft);
    }
    setEvents(arr);
    setAircraftsData(arrAircraft);
  };

  const formattedDatefunc = (inputDateTime) => {
    const dateObj = new Date(inputDateTime);

    // Convert the date to UTC time zone and get the ISO string in the given format
    const start_time = dateObj.toISOString();
    // return start_time;

    const dateObj1 = new Date(start_time);

    // Adjust the time to the desired timezone (e.g., "America/Anchorage")
    const adjustedTime = moment(dateObj1).tz(userTimeZone).format();

    // If you need the final date string in the specific format "start_time": "2023-08-22T05:00:00Z"
    // You can use the adjustedTime value directly, or format it to match the format above
    const formattedTime = moment(adjustedTime).utc().format();

    console.log(formattedTime);
    return formattedTime;
  };
  const handleCellClick = (slotInfo) => {
    setShowForm(true);
    // setEditform(true);
    // setSelectedDate(slotInfo.start);
    // setselEventId(slotInfo.id);
  };

  const handleEditCell = (slotInfo) => {};

  const handleFormClose = () => {
    setShowForm(false);
    setEditform(false);
    setViewForm(false);
    setSelectedDate(null);
    setSelectedAppointment(null);
  };

  const handleEditEvent = (appointment) => {
    setSelectedAppointment(appointment);
    setShowForm(false);
    setViewForm(false);
    setEditform(true);
    setselEventId(appointment.id);
  };

  const handleViewEvent = (appointment) => {
    setViewForm(true);
    setselEventId(appointment.id);
  };

  const handleDeleteEvent = (appointment) => {
    setSelectedAppointment(appointment);
    setShowConfirmation(true);
  };

  const handleAddEvent = (newEvent) => {
    handleFormClose();
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    setSelectedAppointment(null);
    fetchAllAppointments();
  };

  const formats = {
    eventTimeRangeFormat: () => {
      return '';
    },
  };

  const handleConfirmDelete = () => {
    // Perform the delete operation on your data source (e.g., API or local state) using the selectedAppointment object
    // Update the calendar view accordingly by fetching the updated appointments or removing the appointment from the events array
    handleConfirmationClose();
  };
  const applyFilters = async (APiurl, Selaircraft, Selpilot) => {
    setSelectedAircrafts(Selaircraft);
    setSelectedPilots(Selpilot);
    try {
      const response = await ApiRequests(
        APiurl.slice(0, APiurl.length - 1),
        'GET',
        null,
      );
      console.log('response', response);
      if (response.status === 200) {
        let obj = {};
        let arr = [];
        for (let i = 0; i < response.data.length; i++) {
          obj = {
            end: formattedDatefunc(response.data[i].end_time),
            start: formattedDatefunc(response.data[i].time),
            id: response.data[i].id,
            title: response.data[i].title,
            type: response.data[i].type,
            pilot: response.data[i].assigned_pilot,
            aircraft: response.data[i].aircraft,
            assigned_pilot_color: response.data[i].assigned_pilot_color,
          };
          if (obj.end_time !== null) {
            arr.push(obj);
          }
        }
        setEvents([]);
        setFilterData(arr);
        setShowRedDot(true);
        toggleFilter();
      }
    } catch (error) {
      console.log('Error in appointment', error);
      if (error.response.data) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error in fetching appointment');
      }
    }
  };
  // Master Calendar Code Block
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const renderCalender = () => {
    return (
      <DatePicker
        showIcon
        selected={currentDate}
        onChange={(date) => setCurrentDate(date)}
        inline
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <button
              style={{ border: 'none', fontSize: '20px' }}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}>
              {'<'}
            </button>

            <p
              style={{
                fontWeight: 'bold',
                fontSize: '16px',
                margin: 0,
              }}>
              {moment(date).format('MMMM, YYYY')}
            </p>

            <button
              style={{ border: 'none', fontSize: '20px' }}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}>
              {'>'}
            </button>
          </div>
        )}
      />
    );
  };

  const calendarRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  return (
    <div>
      <DashboardHeader name="Calendar" />
      <div
        style={{ zIndex: 100 }}
        className="filter-add-new d-flex justify-content-end">
        <div style={{ alignSelf: 'center' }}>
          <button
            className="right-div d-flex"
            style={{ borderWidth: 1, borderRadius: 4, borderColor: '#1334b9' }}
            onClick={() => setIsOpen(!isOpen)}>
            <IconcalendarmenuIcons />
          </button>
          {isOpen && (
            <div
              ref={calendarRef}
              style={{
                position: 'absolute',
                zIndex: 100,
                marginTop: '10px',
                right: '10%',
              }}>
              {renderCalender()}
            </div>
          )}
        </div>
        <div className="right-div d-flex" style={{ alignItems: 'center' }}>
          <img
            src={filter}
            className="report-filter curPointer"
            style={{ height: '40px' }}
            alt="img NP"
            onClick={() => setshowReportFilter(true)}
          />
          <>{showRedDot === true && <div class="red-dot-filter"></div>}</>
        </div>
        <div className="add-new-btn">
          <Button className="addNewButton" onClick={() => handleCellClick()}>
            Add New +
          </Button>
        </div>
      </div>
      {showEditform === true && (
        <EventForm
          selectedDate={selectedDate ? new Date(selectedDate) : null}
          onAddEvent={handleAddEvent}
          handleDeleteEvent={handleDeleteEvent}
          onClose={handleFormClose}
          show={showEditform}
          appointment={selectedAppointment}
          id={selEventId}
        />
      )}
      {showViewform && (
        <ViewEventForm
          id={selEventId}
          onClose={handleFormClose}
          show={showViewform}
          selectedDate={selectedDate ? new Date(selectedDate) : null}
        />
      )}
      {showForm && (
        <AddEventForm
          selectedDate={selectedDate ? new Date(selectedDate) : null}
          onAddEvent={handleAddEvent}
          onClose={handleFormClose}
          show={showForm}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          appointment={selectedAppointment}
          onConfirmDelete={handleConfirmDelete}
          onClose={handleConfirmationClose}
          show={showConfirmation}
        />
      )}
      <div className="calendar-container">
        {console.log('events', JSON.stringify(events))}
        <Calendar
          date={currentDate}
          onNavigate={(date) => setCurrentDate(date)}
          showAllEvents={true}
          localizer={localizer}
          formats={formats}
          //use props for make day column scrollable
          // dayLayoutAlgorithm={dayLayoutAlgorithm}
          // dayLayoutAlgorithm={'no-overlap'}
          events={events.length > 0 ? events : filterdata}
          // events={events}
          // startAccessor="start"
          startAccessor={(events) => {
            return new Date(events.start);
          }}
          endAccessor={(events) => {
            return new Date(events.end);
          }}
          selectable
          onSelectEvent={(event) => handleEditEvent(event)}
          style={{ height: 1000 }}
          className="p-4"
          views={['week', 'month', 'day']} // Remove 'agenda', 'day' view
          eventPropGetter={(event) => {
            console.log('event in eventPropGetter', event);
            const backgroundColor = handleBgColor(event);
            // const backgroundColor = event.type === 'day_off' ? '#EAB308' : '#1334B9';
            const color = 'white';
            // const top=`${handleDayOf(event)}%`;
            // console.log('top',top);
            const borderColor = handleBgColor(event);
            // const borderColor = event.type === 'day_off' ? '#EAB308' : '#1334B9';
            const fontWeight = '600';
            return {
              style: {
                backgroundColor,
                borderColor,
                color,
                // top,
                dzIndex: 9999, // Adjust the value as needed
              },
            };
          }}
          components={{
            event: ({ event }) => (
              <div
                className="editdelapp-wrapper"
                // onClick={() => handleViewEvent(event)}
                onClick={() => handleEditEvent(event)}>
                <div className="rbc-event-title event-title">
                  <span className="bold-title">
                    {' '}
                    {`${event.title}: `}&nbsp;
                  </span>
                  <span>
                    <IconpilotsmenuIcons />
                    &nbsp;
                    {/* {event.pilot.name == 'null'
                      ? `Anonymous Pilot`
                      : `$ */}
                    {event.pilot.name}
                    {/* `} */}
                    &nbsp;
                  </span>
                  <span>
                    {event.aircraft && (
                      <>
                        ; <IconaircraftmenuIcons />
                        &nbsp;
                        {/* {event.aircraft.tail_number !== null
                        ?  */}
                        {event.aircraft.tail_number}
                        {/* : 'Anonymous Aircraft'
                       } */}
                      </>
                    )}
                  </span>
                </div>
                {/* <div className="edit-del-app">
                  <img
                    src={Iconedit}
                    className="edit-appointment cursor-pointer"
                    onClick={() => handleEditEvent(event)}
                  />
                  <img
                    src={IconDel}
                    className="edit-appointment cursor-pointer"
                    onClick={() => handleDeleteEvent(event)}
                  />
                </div> */}
              </div>
            ),
          }}
        />
      </div>
      {showReportFilter && (
        <CalendarFilter
          show={showReportFilter}
          fetchAllAppointments={fetchAllAppointments}
          toggleFilter={toggleFilter}
          applyFilters={applyFilters}
          handleRedDot={handleRedDot}
          selectedAircrafts={selectedAircrafts}
          selectedPilots={selectedPilots}
          // data={products}
          filterdata={filterdata}
          // resetFilter={resetFilter}
          // customersData={customersData}
          // pilotsData={pilotsData}
        />
      )}
    </div>
  );
};

export default CalendarPoc2;
