import React, { useEffect, useMemo, useState } from 'react';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { useNavigate } from 'react-router-dom';
import selector from '../../images/icons/selector.png';
import Loader from '../Loader/index';
import editIcon from '../../images/icons/editIcon.png';
import ErrorMsg from '../ErrorMsg';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Image } from 'react-bootstrap';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './table.css';

export const Servicelog = () => {
  const navigate = useNavigate();
  // const { SearchBar } = Search

  const [loading, setLoading] = useState(true); // Add loading state
  const [noFound, setNoFound] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [products, setProducts] = useState([]);

  const [search, setSearch] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [activePage, setActivePage] = useState(0);

  const options = {
    custom: true,
    totalSize: totalCount,
    // onPageChange: onPageChange
  };

  const [UserId, setUserId] = useState(null);

  function handleRowClick(e, rowData) {
    e.preventDefault();

    // const data = products.find(item => item.id === row.id)
    // if (data) {
    // navigate(`/dashboard/pilots/${row.original.id}?edit=false`)
    // }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const params = {
          organization_id:
            sessionStorage.getItem('organization_id') === 'null'
              ? 2
              : sessionStorage.getItem('organization_id')
        };

        const response = await ApiRequests(
          api_urls.serviceProfile,
          'GET',
          null,
          params,
        );

        let tempdata = response.data;
        if (tempdata.length > 0) {
          setLoading(false);
          setNoFound(false);
        } else if (tempdata.length == 0) {
          setNoFound(true);
          setLoading(false);
        }
        setProducts(tempdata);
        setFilteredResults(response.data);

        setTotalCount(response.data.count);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const data = useMemo(() => products, [products]);
  const columns = useMemo(
    () => [
      // {
      //   Header: 'Aircraft Tail Number',
      //   accessor: 'aircraft.tail_number',
      //   disableFilters: true,
      //   disableSortBy: false,
      //   sticky: 'left',
      //   Cell: ({ value }) => (value ? <span style={{ cursor: 'pointer' }}>{value} </span> : null),
      // },
      {
        Header: 'Tail number',
        accessor: 'aircraft.tail_number',
        disableSortBy: false,
        sticky: 'left',
      },
      {
        Header: 'Last Service',
        accessor: 'last_service',
        disableSortBy: false,
        sticky: 'left',
      },
      {
        Header: 'Next Service',
        accessor: 'next_maintenance_scheduled',
        disableSortBy: false,
        sticky: 'left',
      },
      {
        Header: 'Action',
        disableSortBy: true,
        accessor: 'state',
        Cell: (props) => {
          return (
            <>
              <span>
                <Image
                  className='cursor-pointer'
                  src={editIcon}
                  style={{ marginRight: '20px' }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    navigate(
                      `/dashboard/servicelog/${props.row.original.id}/${props.row.original.aircraft.id}`,
                      {
                        state: { edit: true, create: false },
                      },
                    );
                  }}></Image>
              </span>
            </>
          );
        },
      },
    ],
    [],
  );

  const onRowClicked = (row, event) => {
    const data = products.find((item) => item.id === row.id);
    if (data) {
      // <ViewPilot/>
      navigate(`/dashboard/servicelog/${data.id}`);
    }
  };
  const createPilot = (e) => {
    e.stopPropagation();

    navigate(`/dashboard/customers/create`, {
      state: { create: true, edit: false },
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // initialState: { pageIndex: 0 }
      initialState: { pageIndex: activePage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, pageSize } = state;

  return (
    <div>
      <DashboardHeader name="Service Log" />
      <>
        {noFound === true ? (
          <ErrorMsg errormsg={'No Records Exist'} />
        ) : (
          <>
            <div
              style={{
                padding: '0px 30px',
                backgroundColor: '##F3F4F6',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '20px',
                borderColor: '#1334B9',
              }}>
              <div className="search-bar w-25">
                <span className="search-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                      stroke="#9CA3AF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 17.5L13.875 13.875"
                      stroke="#9CA3AF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  value={search}
                  className="SearchLabel searchpilot"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setGlobalFilter(e.target.value);
                  }}
                  placeholder="Search service logs"
                  style={{ '::placeholder': { color: '#D1D5DB' } }}
                />
              </div>

              {/* <Button className="addNewButton" onClick={createPilot}>
                Add New +
              </Button> */}
            </div>
            <>
              {loading === true ? (
                <Loader loadingMsg="Data is Loading... Please Wait" />
              ) : (
                <>
                  <div className="table-container">
                    <table {...getTableProps()} className="table-cls">
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps(),
                                )}>
                                {column.render('Header')}
                                <span>
                                  &nbsp;
                                  {column.disableSortBy === false ? (
                                    <Image src={selector}></Image>
                                  ) : null}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              onClick={() =>
                                navigate(
                                  `/dashboard/servicelog/${row.original.id}/${row.original.aircraft.id}`,
                                  {
                                    state: { rowclick: true, edit: true },
                                  },
                                )
                              }>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="paginationBox"
                  // style={{ backgroundColor: "#f2f2f2", padding: "10px", height: "50px" }}
                  >
                    <div
                      style={{ float: 'left', gap: '5px' }}
                      className="d-flex align-items-center">
                      <label className="paginationText">
                        Number of rows&nbsp;
                      </label>
                      <select
                        style={{ float: 'right' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}>
                        {[10, 25, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                          }`}>
                        Prev
                      </button>
                      {pageOptions.map((page, index) => (
                        <button
                          key={index}
                          onClick={() => gotoPage(index)}
                          className={`paginationButton ${pageIndex === index ? 'activeButton' : ''
                            }`}>
                          {index + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                          }`}>
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </>
    </div>
  );
};
