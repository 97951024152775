import React, { useState, useEffect } from 'react';
import notifications from '../images/activeicons/notifications.svg';
import notificationsactive from '../images/activeicons/notifications-active.svg';
import userprofile from '../images/activeicons/userprofile.svg';
import blueicon from '../images/activeicons/blueicon.svg';
import blueEmailIcon from '../images/activeicons/blueEmailIcon.svg';
import userprofileicon from '../images/activeicons/user-profile.svg';
import changePass from '../images/activeicons/change-password.svg';
import logout from '../images/activeicons/logout.svg';
import { api_urls } from '../constants/constants';
import { useNavigate } from 'react-router';
import { Menu, MenuItem } from '@mui/material';
import ErrorMsg from '../components/ErrorMsg';
import { Logout } from './Logout.js';
import { ApiRequests } from '../components/https/ApiRequests';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './dashboardheader.css';
import { convertUtcDateTimeToLocal } from '../utils/util.js';

export const DashboardHeader = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [noFound, setNoFound] = useState(false);
  const [anchorE2, setAnchorE2] = useState(null);
  const [userProfile, setUserProfile] = useState(false);
  const [logout, setLogout] = useState(false);

  const userprofileflag =
    sessionStorage.getItem('userimg') === ''
      ? userprofile
      : sessionStorage.getItem('userimg');

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleUserProfile = (event) => {
    setAnchorE2(event.currentTarget);
    setUserProfile(true);
  };

  const handleUserProfileClose = () => {
    setAnchorE2(null);
    setUserProfile(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleLogout = () => {
    setLogout(true);
    handleUserProfileClose();
  };

  const closeLogout = () => {
    setLogout(false);
  };

  const handleUserlogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_type');
    sessionStorage.removeItem('organization_id');
    sessionStorage.removeItem('aircraft_access');
    sessionStorage.removeItem('memos_access');
    sessionStorage.removeItem('service_log_access');
    sessionStorage.removeItem('calender_access');
    sessionStorage.removeItem('manage_form_access');
    sessionStorage.removeItem('customer_access');
    sessionStorage.removeItem('dashboard_access');
    sessionStorage.removeItem('service_log_access')
    sessionStorage.removeItem('userimg');
    sessionStorage.removeItem('pilot_access');
    navigate('/login');
  };

  const handleNotifications = async (id, flag) => {
    if (flag === false) {
      const body = {
        is_read: true,
      };
      try {
        const response = await ApiRequests(
          `${api_urls.getnotifications}${id}/`,
          'PATCH',
          body,
          null,
        );
        if (response.status === 200) {
          toast.success('Notification read successfully');
          fetchData();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error in notification');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiRequests(
        api_urls.getnotifications,
        'GET',
        null,
      );

      let tempdata = response.data;
      if (tempdata.length > 0) {
        setNoFound(false);
      } else if (tempdata.length == 0) {
        setNoFound(true);
      }

      setMenuItems(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          backgroundColor: '#ffffff',
          padding: '20px 30px',
          borderBottom: '1px solid #D1D5DB',
        }}
        className="dashboard-header d-flex justify-content-between">
        <h1 className="dashboard-heading">{props.name}</h1>

        <div
          className="right-side-header d-flex align-items-center"
          style={{ gap: '10px', height: '50px' }}>
          <>
            {sessionStorage.getItem('user_type') === 'organization' && (
              <>
                {menuItems.length > 0 ? (
                  <img
                    src={notificationsactive}
                    className="cursor-pointer"
                    onClick={handleMenuOpen}
                  />
                ) : (
                  <img
                    src={notifications}
                    className="cursor-pointer"
                    onClick={handleMenuOpen}
                  />
                )}
              </>
            )}
          </>
          <img
            src={userprofileflag}
            className={
              sessionStorage.getItem('userimg') !== ''
                ? 'right-img-icon cursor-pointer'
                : 'cursor-pointer'
            }
            onClick={handleUserProfile}
          />
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        className="notifications-wrapper">
        <MenuItem className="noti-heading">Notifications</MenuItem>
        <>
          {noFound === true ? (
            <div
              class="alert alert-danger mt-5 error-msg text-center"
              role="alert">
              No Records Exist
            </div>
          ) : (
            <>
              {menuItems.map((item, index) => (
                <>
                  <MenuItem
                    key={index}
                    className="menu-item-noti"
                    onClick={() => handleNotifications(item.id, item.is_read)}>
                    <div className="menu-item-div1">
                      <>
                        {item.is_read === false ? (
                          <img
                            src={blueicon}
                            alt="Profile Pic"
                            className="blue-icon"
                          />
                        ) : (
                          <div className="blue-icon" />
                        )}
                      </>
                      <div className="menu-title">{item.title}</div>
                    </div>
                    <div className="menu-item-div1 justify-content-end">
                      <div className="menu-date">{convertUtcDateTimeToLocal(item.created)}</div>
                      <>
                        {item.is_read === false && (
                          <img
                            src={blueEmailIcon}
                            alt="Profile Pic"
                            className="user-pic"
                          />
                        )}
                      </>
                    </div>
                  </MenuItem>
                  <hr className="m-0 noti-divider" />
                </>
              ))}
            </>
          )}
        </>
      </Menu>

      <Menu
        anchorEl={anchorE2}
        open={userProfile}
        onClose={handleUserProfileClose}
        className="user-wrapper">
        <>
          {(sessionStorage.getItem('user_type') === 'organization') ?
            (
              <>
                <MenuItem key={0} className="menu-item-noti">
                  <div
                    className="user-item-1 d-flex align-items-center"
                    onClick={async (e) => {
                      e.stopPropagation();
                      navigate(`/profile`, {
                        state: { edit: true, create: false, rowclick: false },
                      });
                      // }
                    }}>
                    <img src={userprofileicon} className="user-icon" />
                    <div className="user-profile-txt">My profile</div>
                  </div>
                </MenuItem>
                <MenuItem key={1} className="menu-item-noti">
                  <div className="user-item-1 d-flex align-items-center">
                    <img src={changePass} className="user-icon" />
                    <div
                      className="user-profile-txt"
                      onClick={() => navigate('/change-password')}>
                      Change Password
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  key={2}
                  className="menu-item-noti"
                  onClick={() => handleLogout()}>
                  <div className="user-item-1 d-flex align-items-center">
                    <img src={logout} className="user-icon" />
                    <div className="user-profile-txt">Logout</div>
                  </div>
                </MenuItem>
              </>
            ) : (
              <MenuItem
                key={2}
                className="menu-item-noti"
                onClick={() => handleLogout()}>
                <div className="user-item-1 d-flex align-items-center">
                  <img src={logout} className="user-icon" />
                  <div className="user-profile-txt">Logout</div>
                </div>
              </MenuItem>
            )}
        </>
      </Menu>
      {logout === true && (
        <Logout
          closeLogout={closeLogout}
          show={logout}
          handleUserlogout={handleUserlogout}
        />
      )}
    </>
  );
};
