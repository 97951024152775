export const api_urls = {
  login: `/api/v1/login/`,
  pilotProfile: `/api/v1/pilot-profile/`,
  customerProfile: `/api/v1/customer/`,
  deleteChoice: `/api/v1/risk-assessment-answer/`,
  pilotDocument: `/api/v1/pilot-document/`,
  getnotifications: `/api/v1/notification/`,
  memoProfile: `/api/v1/memos/`,
  pilotTraining: `/api/v1/pilot-training/`,
  getAllquestions: `/api/v1/form/`,
  getallMaintainece: `/api/v1/maintenence-type-options/`,
  getAllflightTypes: `/api/v1/flight-type-options/`,
  addEditQuestion: `/api/v1/risk-assessment-question/`,
  addEditOption: `/api/v1/risk-assessment-answer/`,
  changePassword: `/api/v1/change-password/`,
  resetPassword: `/api/v1/forgot-password/`,
  updatePassword: `/api/v1/update-password/`,
  aircraftProfile: `/api/v1/aircraft/`,
  serviceProfile: `/api/v1/aircraft-service-log/`,
  reportProfile: `/api/v1/daily-report/`,
  reportProfileCustomers: '/api/v1/daily-report-customer-hour/',
  Organization: `/api/v1/organization/`,
  CreatePilot: `/api/v1/create-pilot/`,
  CreateCustomer: `/api/v1/customer/`,
  CreateOrganization: `/api/v1/create-organization/`,
  getAllAppointments: `/api/v1/appointment/`,
  createAppointment: `/api/v1/appointment/`,
  subAdminProfile: `/api/v1/sub-admin-permissions/`,
  createSubAdmin: `/api/v1/create-sub-admin/`,
  subAdminProfileAPi: `/api/v1/profile/`,
  pilot_Qualification: `/api/v1/pilot-qualification/`,
  custom_question: `/api/v1/daily-report-custom-questions/`,
  question_mapping: `/api/v1/daily-report-custom-field-mapping/`,
  dayoff_report: '/api/v1/day-off-report/',
  privacyPolicy:'/api/v1/privacy-policy/',
  contactUs:'/modules/contact-us/contact_us/',
  qualification_list:`/api/v1/qualifications-list/`,
  qualification_report:`/api/v1/qualification-report/`,
  medical_report:`/api/v1/medical-report/`,
};

export const organization_id = 3;
