import React from "react"
import { Button, Modal } from "react-bootstrap"
import { api_urls } from "../../constants/constants"
import { ApiRequests } from "../https/ApiRequests"
export const ToggleOnModal = props => {
  function handleClose() {
    props.changeToggleOnModal(false)
  }

  return (
    <Modal
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className='modalTitle'>Deactivated Sub Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body  className='modalBody' >
      <h3 className='personalLabels'>Are you sure you want to deactivate this Sub Admin?</h3>
      </Modal.Body>
      <Modal.Footer>
      <Button className='backButton' onClick={handleClose}>No</Button>

        <Button
         
         className="modalOkButton"
          onClick={async () => {
            let url = api_urls.subAdminProfile + props.userid + "/"
            await ApiRequests(url, "PATCH", { is_disabled: true })
            props.clickdelete()
            handleClose()
          }}
        >
          Yes
        </Button>
        
      </Modal.Footer>
    </Modal>
  )
}
