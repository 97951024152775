import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Col, Container, Image, NavItem, Row } from 'react-bootstrap';
import arrowleft from '../../images/icons/arrow-left.png';
import { useNavigate } from 'react-router-dom';
import Editquestion from '../../images/activeicons/Editquestion.svg';
import Deletquestion from '../../images/activeicons/Deletequestion.svg';
import SaveIcon from '../../images/activeicons/defaultImage.svg';
import Save from '../../images/icons/save.png';
import { DashboardHeader } from '../../constants/DashboardHeader';
import Sidebar from '../Sidebar';

import addques from '../../images/activeicons/addques.svg';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { EditQuestion } from './EditQuestion';
import Loader from '../Loader/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './manageform.css';
import { ConfirmQuestionDelete } from './ConfirmQuestionDelete';


const CustomQuestions = () => {

    
    const [choicedisabled, setChoicedisabled] = useState(false);
    const [editQues, setEditQues] = useState(false);
  
    const [showQuestionInput, setShowQuestionInput] = useState(false);
    const [questionType, setQuestionType] = useState('T'); 
    const [choices, setChoices] = useState(['']);
    const [isRequired, setIsRequired] = useState(true);
    const [sequence, setsequence] = useState(0);
    const [customQuestions, setCustomQuestions] = useState([]);
    const [showEditCustomQuestion, setShowEditCustomQuestion] = useState(false);
    const [tempEditId, setTempEditId] = useState('');
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteQuestionId, setDeleteQuestionId] = useState('');
  
    const [maintenanceEditable, setMaintenanceEditable] = useState(false);
    const [flightTypeEditable, setFlightTypeEditable] = useState(false);
    const [showMaintenanceTxt, setshowMaintenanceTxt] = useState(false);
    const [showFlightTxt, setshowFlightTxt] = useState(false);
    const [answer1, setAnswer1] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noFound, setNoFound] = useState(false);
    const [quesArr, setQuesArr] = useState([]);
    const [showChoiceInput, setShowChoiceInput] = useState({});
    const [newquestion, setNewQuestion] = useState('');
    const [formId, setFormid] = useState(null);
    const [editableChoices, setEditableChoices] = useState([]);
    const [editableChoicesFlight, setEditableChoicesFlight] = useState([]);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [maintenanceData, setMaintenanceData] = useState([]);
    const [flightType, setflightType] = useState([]);
  
    // const handleAddChoice = (questionId, type) => {
    //   setCurrentQuestionId(questionId);
  
    //   if (type === 'maintenance') {
    //     setMaintenanceEditable((prevState) => !prevState);
    //   } else if (type === 'flightType') {
    //     setFlightTypeEditable((prevState) => !prevState);
    //   }
    // };
  
    const handleAddOption = async (type) => {
      let apiURL;
      if (type === 'maintenance') {
        apiURL = api_urls.getallMaintainece;
      } else {
        apiURL = api_urls.getAllflightTypes;
      }
      const body = {
        option: answer1,
        organization:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      if (answer1 === '' || answer1 === null) {
        toast.error('Error: Answer is a required field');
        return;
      }
      try {
        setChoicedisabled(true);
        const response = await ApiRequests(apiURL, 'POST', body, null);
        if (response) {
          toast.success('Answer added successfully');
          setEditableChoices([]);
          setEditableChoicesFlight([]);
          setshowFlightTxt({});
          setshowMaintenanceTxt();
          fetchFirstChoices();
          fetchSecChoices();
          setAnswer1(null);
          setChoicedisabled(false);
        }
      } catch (error) {
        toast.error('Error in adding answer');
        setLoading(false);
        setChoicedisabled(false);
      }
    };
  
    const handleAddChoice = (type) => {
      if (type === 'maintenance') {
        setshowMaintenanceTxt(true);
        setshowFlightTxt(false);
      } else {
        setshowMaintenanceTxt(false);
        setshowFlightTxt(true);
      }
    };
  
    const answerRef = useRef(null);
  
    const handleEditOption = (choiceId, type) => {
      if (type === 'flightType') {
        if (editableChoicesFlight.includes(choiceId)) {
          setEditableChoicesFlight(
            editableChoicesFlight.filter((id) => id !== choiceId),
          );
        } else {
          setEditableChoicesFlight([...editableChoicesFlight, choiceId]);
        }
      } else {
        if (editableChoicesFlight.includes(choiceId)) {
          setEditableChoices(editableChoices.filter((id) => id !== choiceId));
        } else {
          setEditableChoices([...editableChoices, choiceId]);
        }
      }
    };
  
    const handleDelChoice = async (choiceId, type) => {
      let apiUrl;
      if (type === 'flightType') {
        apiUrl = api_urls.getAllflightTypes;
      } else {
        apiUrl = api_urls.getallMaintainece;
      }
      try {
        let apiurl = `${apiUrl}${choiceId}/`;
        const response = await ApiRequests(apiurl, 'DELETE');
        if (response) {
          toast.success('Answer deleted successfully');
          fetchFirstChoices();
          fetchSecChoices();
        }
      } catch (error) {
        toast.error('Error in deleting answer');
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchFirstChoices();
      fetchSecChoices();
      fetchCustomQuestion();
    }, []);
  
    const fetchFirstChoices = async () => {
      setLoading(true);
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      try {
        const response = await ApiRequests(
          `${api_urls.getallMaintainece}?organization_id=${params.organization_id}`,
          'GET',
          null,
        );
        let tempdata = response.data;
        let obj = [];
        if (tempdata.length > 0) {
          setLoading(false);
          setNoFound(false);
        } else if (tempdata.length == 0) {
          setNoFound(true);
          setLoading(false);
        }
        setMaintenanceData(tempdata);
      } catch (error) {
        setLoading(false);
      }
    };
  
    const fetchSecChoices = async () => {
      setLoading(true);
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      try {
        const response = await ApiRequests(
          `${api_urls.getAllflightTypes}?organization_id=${params.organization_id}`,
          'GET',
          null,
        );
        let tempdata = response.data;
        let obj = [];
        if (tempdata.length > 0) {
          setLoading(false);
          setNoFound(false);
        } else if (tempdata.length == 0) {
          setNoFound(true);
          setLoading(false);
        }
        setflightType(tempdata);
      } catch (error) {
        setLoading(false);
      }
    };
  
    const handleSaveOption = async (choiceId, type) => {
      setChoicedisabled(true);
      if (answer1 === '') {
        toast.error('Error: Answer is a required field');
        return;
      }
      const body = {
        option: answer1,
      };
      let apiurl;
      let req;
      let apiURL =
        type === 'maintenance'
          ? api_urls.getallMaintainece
          : api_urls.getAllflightTypes;
      apiurl = `${apiURL}${choiceId}/`;
      req = 'PATCH';
      try {
        setChoicedisabled(true);
        const response = await ApiRequests(apiurl, req, body, null);
        if (response) {
          toast.success('Choice updated successfully');
          setEditableChoices([]);
          setEditableChoicesFlight([]);
          setShowChoiceInput({});
          fetchFirstChoices();
          fetchSecChoices();
          setChoicedisabled(false);
        }
      } catch (error) {
        toast.error('Error in updating choice');
        setLoading(false);
        setChoicedisabled(false);
      }
    };
  
    const fetchCustomQuestion = async () => {
      setLoading(true);
  
      try{
        let apiurl = `${api_urls.custom_question}`;
        const response = await ApiRequests(apiurl, 'GET', null, null);
        setCustomQuestions(response.data);
        setLoading(false);
      }
      catch(error){
        setLoading(false);
      }
    }
  
    //delete custom question
    const handleQuestionDelete = (flag) => {
      if (flag === true) {
        setDeleteModal(false);
        handleDeleteCustomQuestion(deleteQuestionId);
      } else {
        setDeleteModal(false);
      }
    }
  
    const deleteQuestionPop = async (id) => {
      setDeleteQuestionId(id);
      setDeleteModal(true);
    }
  
    const handleDeleteCustomQuestion = async (questionID) => {
      let apiurl = `${api_urls.custom_question}${questionID}/`;
      setLoading(true);
  
      try {
        const response = await ApiRequests(apiurl, 'DELETE', null, null);
        toast.success('Question deleted successfully');
        fetchCustomQuestion();
        setLoading(false)
      } catch {
        toast.error('Error in Question deletion');
        setLoading(false);
      }
  
    }
  
    //edit custom question
    const handleEditQuestion = async (question) => {
      setTempEditId(question.id)
      setShowEditCustomQuestion(true);
      setShowQuestionInput(false);
  
      await setEditFieldValues(question);
    }
    
    const setEditFieldValues = (question) => {
      setNewQuestion(question.question);
      setQuestionType(question.type); 
      setIsRequired(question.is_required); 
      if(question.type === "C"){
        const transformedChoices = question.question_choices.map(item => item.choice);
        setChoices(transformedChoices); 
      }
    }
  
    const updateUpdateQuestion = async (id) => {
      if (newquestion === '') {
        toast.error('Error: Question is a required field');
        return;
      }
      if (questionType === 'C')  {
        if(choices.some(choice => choice.trim() === "")){
          toast.error('Please fill in all choices');
          return;
        }
      }
  
      try {
        let apiurl = `${api_urls.custom_question}${id}/`;
  
        const body = new FormData();
  
        body.append('question', newquestion);
        body.append('type', questionType);
        body.append('is_required', isRequired);
        body.append('sequence',sequence);
  
        if (questionType === 'C') {
          choices.forEach((choice, index) => {
            body.append(`question_choices[${index}]choice`, choice);
          });
        }
  
        // for (let pair of body.entries()) {
        //   console.log(pair[0] + ': ' + pair[1]);
        // }
  
        const response = await ApiRequests(apiurl, 'PATCH', body, null);
        if (response) {
          toast.success('Question updated successfully');
        }
        handleAddQuestion();
        fetchCustomQuestion();
        setShowEditCustomQuestion(false);
        setShowQuestionInput(false);
      } catch (error) {
        toast.error('Error in updating addition');
        setLoading(false);
      }
  
    }
  
    // add custom question
    const handleAddQuestion = () => {
      setShowQuestionInput(true);
      setShowEditCustomQuestion(false);
      setNewQuestion('');
      setQuestionType('T'); 
      setChoices(['']); 
      setIsRequired(true); 
    };
  
    const handleTypeChange = (event) => {
      setQuestionType(event.target.value);
    };
  
    const handleChoicesChange = (index, value) => {
      const newChoices = [...choices];
      newChoices[index] = value;
      setChoices(newChoices);
    };
  
    const handleAddNewChoice = () => {
      setChoices([...choices, '']);
    };
    
    const handleRemoveChoice = (index) => {
      const newChoices = [...choices];
      newChoices.splice(index, 1);
      setChoices(newChoices);
    };
  
    const handleIsRequiredChange = (event) => {
      setIsRequired(event.target.checked);
    };
  
    const handleSequeceChange = (event) => {
      setsequence(event.target.value)
    }
     
    const handleAddQuesChange = (event) => {
      setNewQuestion(event.target.value);
    };
  
    const saveNewQuestion = async () => {
      if (newquestion === '') {
        toast.error('Error: Question is a required field');
        return;
      }
      if (choices.some(choice => choice.trim() === "")  && (questionType === 'C') ) {
        toast.error('Please fill in all choices');
        return;
      }
      
      try {
        let apiurl = `${api_urls.custom_question}`;
        // const body = {
        //   question: newquestion,
        //   type: questionType,
        //   is_required: true,
        //   squence: 1,
        // };
  
        const body = new FormData();
  
        body.append('question', newquestion);
        body.append('type', questionType);
        body.append('is_required', isRequired);
        body.append('sequence',sequence);
  
        if (questionType === 'C') {
          choices.forEach((choice, index) => {
            body.append(`question_choices[${index}]choice`, choice);
          });
        }
  
        // for (let pair of body.entries()) {
        //   console.log(pair[0] + ': ' + pair[1]);
        // }
  
  
        const response = await ApiRequests(apiurl, 'POST', body, null);
        if (response) {
          toast.success('Question added successfully');
        }
        handleAddQuestion();
        fetchCustomQuestion();
        setShowEditCustomQuestion(false);
        setShowQuestionInput(false);
      } catch (error) {
        toast.error('Error in Question addition');
        setLoading(false);
      }
    };

    return (
        loading === true ? (
            <Loader loadingMsg="Data is Loading... Please Wait" />
          ) : (
        <>
        <div className="question-option-wrapper">
                      <div className="question-border">
                        <div className="question-head-cont d-flex justify-content-between align-items-center">
                          <div className="question-head">Question</div>
                          <div className="edit-del-ques">
                            {editQues ? (
                              <Button
                                style={{
                                  float: 'right',
                                  backgroundColor: '#1334B9',
                                  height: '50px',
                                }}
                                className="editPilot"
                                // onClick={() => saveQuestion()}
                              >
                                Save &nbsp;&nbsp;
                                <Image src={Save}></Image>
                              </Button>
                            ) : (
                              <>
                                <img
                                  src={Editquestion}
                                  className="edit-ques"
                                  alt="edit icon NP"
                                  // onClick={() => setEditQues(true)}
                                />
                                <img
                                  src={Deletquestion}
                                  className="edit-ques"
                                  alt="edit icon NP"
                                  // onClick={() => handleDelQuestion()}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="question-wrapper mt-2">
                          <input
                            type="text"
                            className="form-control question-input"
                            value="Upcoming maintainence"
                            disabled={true}
                          />
                          {/* {maintenanceData.length > 0 && (
                            <div className="question-head mt-4">Choices</div>
                          )} */}
                          {maintenanceData &&
                            maintenanceData.map((choiceitem, index) => (
                              <>
                                <div
                                  className="choice-wrraper"
                                  key={choiceitem.id}>
                                  <div className="choice-option-wrapper mt-3">
                                    <input
                                      type="text"
                                      className="form-control question-input w-25"
                                      defaultValue={choiceitem.option}
                                      disabled={
                                        !editableChoices.includes(choiceitem.id)
                                      }
                                      onChange={(e) =>
                                        setAnswer1(e.target.value)
                                      }
                                    />
                                    {editableChoices.includes(choiceitem.id) ? (
                                      <Button
                                        style={{
                                          float: 'right',
                                          backgroundColor: '#1334B9',
                                          height: '50px',
                                        }}
                                        className="editPilot"
                                        disabled={choicedisabled}
                                        onClick={() =>
                                          handleSaveOption(
                                            choiceitem.id,
                                            'maintenance',
                                          )
                                        }>
                                        Save &nbsp;&nbsp;
                                        <Image src={Save}></Image>
                                      </Button>
                                    ) : (
                                      <>
                                        <img
                                          src={Editquestion}
                                          className="edit-ques"
                                          alt="edit icon NP"
                                          onClick={() =>
                                            handleEditOption(
                                              choiceitem.id,
                                              'maintenance',
                                            )
                                          }
                                        />
                                        <img
                                          src={Deletquestion}
                                          className="edit-ques"
                                          alt="edit icon NP"
                                          onClick={() =>
                                            handleDelChoice(
                                              choiceitem.id,
                                              'maintenance',
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                          <>
                            {showMaintenanceTxt === true ? (
                              <>
                                <div className="choice-option-wrapper mt-3">
                                  <input
                                    type="text"
                                    className="form-control question-input w-25"
                                    onChange={(e) => setAnswer1(e.target.value)}
                                  />

                                  <Button
                                    disabled={choicedisabled}
                                    style={{
                                      float: 'right',
                                      backgroundColor: '#1334B9',
                                      height: '50px',
                                    }}
                                    className="editPilot"
                                    onClick={() =>
                                      handleAddOption('maintenance')
                                    }>
                                    Save &nbsp;&nbsp;
                                    <Image src={Save}></Image>
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <div
                                className="add-choice mt-4"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleAddChoice('maintenance')}>
                                <img src={addques} className="add-choice-img" />
                                <span className="add-choice-txt">
                                  Add Choice
                                </span>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>

                    <div className="question-option-wrapper">
                      <div className="question-border">
                        <div className="question-head-cont d-flex justify-content-between align-items-center">
                          <div className="question-head">Question</div>
                        </div>
                        <div className="question-wrapper mt-2">
                          <input
                            type="text"
                            className="form-control question-input"
                            value="Flight type"
                            disabled={true}
                          />
                          {flightType.length > 0 && (
                            <div className="question-head mt-4">Choices</div>
                          )}
                          {flightType &&
                            flightType.map((choiceitem, index) => (
                              <>
                                <div
                                  className="choice-wrraper"
                                  key={choiceitem.id}>
                                  <div className="choice-option-wrapper mt-3">
                                    <input
                                      type="text"
                                      className="form-control question-input w-25"
                                      defaultValue={choiceitem.option}
                                      disabled={
                                        !editableChoicesFlight.includes(
                                          choiceitem.id,
                                        )
                                      }
                                      onChange={(e) =>
                                        setAnswer1(e.target.value)
                                      }
                                    />
                                    {editableChoicesFlight.includes(
                                      choiceitem.id,
                                    ) ? (
                                      <Button
                                        style={{
                                          float: 'right',
                                          backgroundColor: '#1334B9',
                                          height: '50px',
                                        }}
                                        className="editPilot"
                                        disabled={choicedisabled}
                                        onClick={() =>
                                          handleSaveOption(
                                            choiceitem.id,
                                            'flightType',
                                          )
                                        }>
                                        Save &nbsp;&nbsp;
                                        <Image src={Save}></Image>
                                      </Button>
                                    ) : (
                                      <>
                                        <img
                                          src={Editquestion}
                                          className="edit-ques"
                                          alt="edit icon NP"
                                          onClick={() =>
                                            handleEditOption(
                                              choiceitem.id,
                                              'flightType',
                                            )
                                          }
                                        />
                                        <img
                                          src={Deletquestion}
                                          className="edit-ques"
                                          alt="edit icon NP"
                                          onClick={() =>
                                            handleDelChoice(
                                              choiceitem.id,
                                              'flightType',
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ))}
                          <>
                            <>
                              {showFlightTxt === true ? (
                                <>
                                  <div className="choice-option-wrapper mt-3">
                                    <input
                                      type="text"
                                      className="form-control question-input w-25"
                                      onChange={(e) =>
                                        setAnswer1(e.target.value)
                                      }
                                    />

                                    <Button
                                      style={{
                                        float: 'right',
                                        backgroundColor: '#1334B9',
                                        height: '50px',
                                      }}
                                      className="editPilot"
                                      disabled={choicedisabled}
                                      onClick={() =>
                                        handleAddOption('flightType')
                                      }>
                                      Save &nbsp;&nbsp;
                                      <Image src={Save}></Image>
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="add-choice mt-4"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleAddChoice('flightType')}>
                                  <img
                                    src={addques}
                                    className="add-choice-img"
                                  />
                                  <span className="add-choice-txt">
                                    Add Choice
                                  </span>
                                </div>
                              )}
                            </>
                          </>
                        </div>
                      </div>
                    </div>

{/* edit custom question */}
                    <div className="question-option-wrapper">
                        {customQuestions.map((question) => (

                          <div className="question-border" key={question.id}>
                            <div className="question-head-cont d-flex justify-content-between align-items-center">
                              <div className="question-head">Question</div>
                              <div className="edit-del-ques">
                                {showEditCustomQuestion 
                                && tempEditId==question.id
                                ? (
                                  <Button
                                    style={{
                                      float: 'right',
                                      backgroundColor: '#1334B9',
                                      height: '50px',
                                    }}
                                    className="editPilot"
                                    onClick={() => updateUpdateQuestion(question.id)}
                                  >
                                    Save &nbsp;&nbsp;
                                    <Image src={Save}></Image>
                                  </Button>
                                ) : (
                                  <>
                                    <img
                                      src={Editquestion}
                                      className="edit-ques"
                                      alt="edit icon NP"
                                      onClick={() => handleEditQuestion(question)}
                                    />
                                    <img
                                      src={Deletquestion}
                                      className="edit-ques"
                                      alt="edit icon NP"
                                      onClick={() => deleteQuestionPop(question.id)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            
                                <div className="question-wrapper mt-2">
                                  <input
                                    type="text"
                                    className="form-control question-input"
                                    value={(showEditCustomQuestion && tempEditId==question.id) ? newquestion: question.question}
                                    disabled={!(showEditCustomQuestion && tempEditId==question.id)}
                                    onChange={(e) => handleAddQuesChange(e)}
                                  />
                                </div>

                                <div >
                                  <Row className='my-2'>
                                  <div style={{ color: 'black' , fontWeight: 'normal', width:'82px'}}>Required</div> &nbsp;
                                    <div className='col w-25 mx-0 px-0'>
                                      <input
                                        className='form-check'
                                        type="checkbox"
                                        id="isRequired"
                                        checked={(showEditCustomQuestion && tempEditId==question.id) ? isRequired: question.is_required}
                                        onChange={handleIsRequiredChange}
                                        disabled={!(showEditCustomQuestion && tempEditId==question.id)}
                                      />
                                    </div>
                                  </Row>

                                  <Row className='my-2'>
                                  <div style={{ color: 'black' , fontWeight: 'normal', width:'82px'}}>Type</div>&nbsp;
                                    <div className='col-md-3 w-25 mx-0 px-0'>
                                      <select
                                        className='form-select'
                                        id="questionType"
                                        onChange={handleTypeChange}
                                        value={(showEditCustomQuestion && tempEditId==question.id) ? questionType: question.type}
                                        disabled={!(showEditCustomQuestion && tempEditId==question.id)}
                                      >
                                        <option value="T">Text field</option>
                                        <option value="I">Image field</option>
                                        <option value="C">Choice field</option>
                                      </select>
                                    </div>
                                  </Row>

                                  <Row className='my-2'>
                                  <div style={{ color: 'black' , fontWeight: 'normal', width:'82px'}}>Sequence</div>&nbsp;
                                    <div className='w-25 mx-0 px-0'>
                                      <input
                                        className='form-control'
                                        type="number"
                                        id="isRequired"
                                        value={(showEditCustomQuestion && tempEditId==question.id) ? sequence: question.sequence}
                                        min="0"
                                        onChange={handleSequeceChange}
                                        disabled={!(showEditCustomQuestion && tempEditId==question.id)}
                                      />
                                    </div>
                                  </Row>

                                </div>

                            {(showEditCustomQuestion && tempEditId == question.id) ?
                              questionType === 'C' && (
                                <div>
                                  <div className="question-head mt-4">Choices</div>
                                  {choices.map((choice, index) => (
                                    <div key={index} className='row'>
                                      <div className='col-md-4'>
                                        <input
                                          type="text"
                                          className='form-control question-input mt-3'
                                          value={choice}
                                          onChange={(e) => handleChoicesChange(index, e.target.value)}
                                        />
                                      </div>
                                      {choices.length > 2 &&
                                        <div className='col-sm-2 mt-3'>
                                          <img
                                            src={Deletquestion}
                                            className="edit-ques"
                                            alt="edit icon NP"
                                            onClick={() =>
                                              handleRemoveChoice(index)
                                            }
                                          />
                                        </div>
                                      }


                                    </div>
                                  ))}
                                  <div
                                    className="add-choice mt-4"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleAddNewChoice()}>
                                    <img
                                      src={addques}
                                      className="add-choice-img"
                                    />
                                    <span className="add-choice-txt">
                                      Add Choice
                                    </span>
                                  </div>
                                </div>
                              )
                              :
                              
                                question.type === 'C' && (
                                  <div>
                                    <div className="question-head mt-4">Choices</div>

                                    {question.question_choices.map((choices, index) => (
                                      <div key={index} className='row'>
                                        <div className='col-md-4'>
                                          <input
                                            type="text"
                                            className='form-control question-input mt-3'
                                            value={choices.choice}
                                            onChange={(e) => handleChoicesChange(index, e.target.value)}
                                            disabled={true}
                                          />
                                        </div>

                                      </div>
                                    ))
                                    }

                                  </div>
                                )
                              
                            }

                          </div>
                        ))}
                      </div>

{/* Add Custom question */}
                    <div className="add-new-ques-wrapper">
                        {showQuestionInput ? (
                          <div className="question-border w-100 mx-3">
                            <div className="question-head-cont d-flex justify-content-between align-items-center">
                              <div className="question-head">Add question</div>
                              <div className="edit-del-ques">
                                <Button
                                  style={{
                                    float: 'right',
                                    backgroundColor: '#1334B9',
                                    height: '50px',
                                  }}
                                  className="editPilot"
                                  onClick={() => saveNewQuestion()}>
                                  Save &nbsp;&nbsp;
                                  <Image src={Save}></Image>
                                </Button>
                              </div>
                            </div>
                            <div className="question-wrapper mt-2">
                              <input
                                type="text"
                                className="form-control question-input"
                                value={newquestion}
                                onChange={(e) => handleAddQuesChange(e)}
                              // disabled={!editQues}
                              />
                            </div>

                            <div >
                              
                              <Row className='my-2'>
                              <div style={{ color: 'black' , fontWeight: 'normal', width:'82px'}}>Required</div> &nbsp;
                                  <div className='col w-25 mx-0 px-0'>
                                  <input
                                    className='form-check'
                                    type="checkbox"
                                    id="isRequired"
                                    checked={isRequired}
                                    onChange={handleIsRequiredChange}
                                  />
                                </div>
                              </Row>

                              <Row className='my-2'>
                              <div className='col-md-1' style={{ color: 'black' , fontWeight: 'normal', width:'82px'}}>Type</div>&nbsp;
                                  <div className='col-md-3 w-25 mx-0 px-0'>
                                  <select
                                    className='form-select'
                                    id="questionType"
                                    value={questionType}
                                    onChange={handleTypeChange}
                                  >
                                    <option value="T">Text field</option>
                                    <option value="I">Image field</option>
                                    <option value="C">Choice field</option>
                                  </select>
                                </div>
                              </Row>

                              <Row className='my-2'>
                              <div style={{ color: 'black' , fontWeight: 'normal', width:'82px'}}>Sequence</div>&nbsp;
                                  <div className='w-25 mx-0 px-0'>
                                  <input
                                    className='form-control'
                                    type="number"
                                    id="isRequired"
                                    value={sequence}
                                    min="0"
                                    onChange={handleSequeceChange}
                                  />
                                </div>
                              </Row>
                            </div>
                            {questionType === 'C' && (
                              <div>
                                <div className="question-head mt-4">Choices</div>
                                {choices.map((choice, index) => (
                                  <div key={index} className='row'>
                                    <div className='col-md-4'>
                                      <input
                                        type="text"
                                        className='form-control question-input mt-3'
                                        value={choice}
                                        onChange={(e) => handleChoicesChange(index, e.target.value)}
                                      />
                                    </div>
                                    {choices.length>2 && 
                                      <div className='col-sm-2 mt-3'>
                                      <img
                                        src={Deletquestion}
                                        className="edit-ques"
                                        alt="edit icon NP"
                                        onClick={() =>
                                          handleRemoveChoice(index)
                                        }
                                      />
                                    </div>
                                    }
                                    

                                  </div>
                                ))}
                               <div
                                  className="add-choice mt-4"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleAddNewChoice()}>
                                  <img
                                    src={addques}
                                    className="add-choice-img"
                                  />
                                  <span className="add-choice-txt">
                                    Add Choice
                                  </span>
                                </div>
                             </div>
                            )}

                          </div>
                        ) : (
                          <Button
                            type="primary"
                            className="add-ques-btn d-flex align-items-center"
                            onClick={handleAddQuestion}
                            style={{ gap: '5px' }}>
                            Add question
                            <img
                              src={addques}
                              alt="Add question"
                              className="add-ques-icon"
                            />
                          </Button>
                        )}
                      </div>

                    {showDeleteModal === true && (
                        <ConfirmQuestionDelete
                            show={showDeleteModal}
                            handleDiscard={handleQuestionDelete}
                        />
                    )}
        </>
          )
    )

};

export default CustomQuestions;