import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
export const GeneralDetails = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const schema = Yup.object().shape({
    Email: Yup.string().matches(emailRegExp, 'Invalid email format').required('Email is required'),
    fullName: Yup.string().required('Full Name is required'),
  });

  const [updateData, setUpdateData] = useState({});
  const [isDashboardChecked, setIsDashboardChecked] = useState(props.profileDetails.dashboard_access === true ? true : false);
  const [isPilotChecked, setIsPilotChecked] = useState(props.profileDetails.pilot_access === true ? true : false);
  const [isAircraftChecked, setIsAircraftChecked] = useState(props.profileDetails.aircraft_access === true ? true : false);
  const [isServiceChecked, setIsServiceChecked] = useState(props.profileDetails.service_log_access === true ? true : false);
  const [isManageFormChecked, setIsManageFormChecked] = useState(props.profileDetails.manage_form_access === true ? true : false);
  const [isCalendarChecked, setIsCalendarChecked] = useState(props.profileDetails.calender_access === true ? true : false);
  const [isMemosChecked, setIsMemosChecked] = useState(props.profileDetails.memos_access === true ? true : false);
  const [isCustomerChecked, setIsCustomerChecked] = useState(props.profileDetails.customer_access === true ? true : false);


  useEffect(() => {
    setIsAircraftChecked(props.profileDetails.aircraft_access || false);
    setIsCalendarChecked(props.profileDetails.calender_access || false);
    setIsCustomerChecked(props.profileDetails.customer_access || false);
    setIsDashboardChecked(props.profileDetails.dashboard_access || false);
    setIsManageFormChecked(props.profileDetails.manage_form_access || false);
    setIsMemosChecked(props.profileDetails.memos_access || false);
    setIsPilotChecked(props.profileDetails.pilot_access || false);
    setIsServiceChecked(props.profileDetails.service_log_access || false);
  }, [props.profileDetails]);
  const handleDashboardCheckboxChange = (e) => {
    setIsDashboardChecked(e.target.checked);
  };

  const handlePilotCheckboxChange = (e) => {
    setIsPilotChecked(e.target.checked);
  };

  const handleAircraftCheckboxChange = (e) => {
    setIsAircraftChecked(e.target.checked);
  };

  const handleServiceCheckboxChange = (e) => {
    setIsServiceChecked(e.target.checked);
  };
  const handleManageFormCheckboxChange = (e) => {
    setIsManageFormChecked(e.target.checked);
  };
  const handleCalendarCheckboxChange = (e) => {
    setIsCalendarChecked(e.target.checked);
  };

  const handleMemosCheckboxChange = (e) => {
    setIsMemosChecked(e.target.checked);
  };
  const handleCustomerCheckboxChange = (e) => {
    setIsCustomerChecked(e.target.checked);
  };

  const handleReset = () => {
  };

  return (
    <>
      {/* <Button onClick={handleReset}>reset</Button> */}
      <Formik
        innerRef={ref}
        validationSchema={schema}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
              fullName: props.profileDetails.user.name || '',
              Email: props.profileDetails.user.email || '',
            }
            : {
              fullName: '',
              Email: '',
            }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          const url = new URL(window.location.href);
          const id = url.pathname.split('/').pop();
          let apiurl = api_urls.subAdminProfile + id + '/';
          if (isDashboardChecked === false && isPilotChecked === false && isAircraftChecked === false && isServiceChecked === false && isManageFormChecked === false && isCalendarChecked === false && isMemosChecked === false && isCustomerChecked === false) {
            toast.error('Please select atleast one access');
            return;
          }
          if (!props.create) {
            try {
              let body = {
                name: values.fullName,
                email: values.Email,
                organization_id:
                  sessionStorage.getItem('organization_id') === 'null'
                    ? 2
                    : sessionStorage.getItem('organization_id'),
                dashboard_access: isDashboardChecked,
                pilot_access: isPilotChecked,
                aircraft_access: isAircraftChecked,
                service_log_access: isServiceChecked,
                manage_form_access: isManageFormChecked,
                calender_access: isCalendarChecked,
                memos_access: isMemosChecked,
                customer_access: isCustomerChecked,
              };
              const response = await ApiRequests(apiurl, 'PATCH', body, null);
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Sub Admin updated successfully');
                navigate('/dashboard/subadmin', { replace: true });
              }
            } catch (error) {
              console.error('There was an error!', error);
              toast.error('Error in updating Sub Admin');
            }
          } else {
            let body = {
              name: values.fullName,
              email: values.Email,
              organization_id:
                sessionStorage.getItem('organization_id') === 'null'
                  ? 2
                  : sessionStorage.getItem('organization_id'),

              dashboard_access: isDashboardChecked,
              pilot_access: isPilotChecked,
              aircraft_access: isAircraftChecked,
              service_log_access: isServiceChecked,
              manage_form_access: isManageFormChecked,
              calender_access: isCalendarChecked,
              memos_access: isMemosChecked,
              customer_access: isCustomerChecked,
            };
            props.storeNewUserDetails(body);
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form
            className=""
            noValidate
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Personal information</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="fullName"
                  className={`form-floating  viewPilot-Box  mb-3 ${touched.fullName && errors.fullName
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={values.fullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={touched.fullName && errors.fullName}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Full Name
                  </label>
                  {touched.fullName && errors.fullName ? null : (
                    <div className="input-group-append " style={{ marginBottom: "10px" }}></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.fullName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Email"
                  className={`form-floating viewPilot-Box   mb-3 ${touched.Email && errors.Email ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="email"
                    name="Email"
                    placeholder="Email"
                    value={values.Email}
                    // className={props.readOnly ? 'disabledColor' : ''}
                    // readOnly={props.readOnly}
                    className={props.create ? '' : 'disabledColor'}
                    readOnly={props.create ? false : true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Email && errors.Email}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Email
                  </label>
                  {touched.Email && errors.Email ? null : (
                    <div className="input-group-append " style={{ marginBottom: "10px" }}></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Access Details</h4>
              <hr />
              <div>

                <div className='d-flex justify-content-between'>
                  <Form.Group
                    className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Dashboard access"
                      checked={isDashboardChecked}
                      onChange={handleDashboardCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>

                  <Form.Group
                    className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Pilot access"
                      checked={isPilotChecked}
                      onChange={handlePilotCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>

                  <Form.Group
                    className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Aircraft access"
                      checked={isAircraftChecked}
                      onChange={handleAircraftCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>

                  <Form.Group className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Customer access"
                      checked={isCustomerChecked}
                      onChange={handleCustomerCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>
                </div>

                <div className='d-flex justify-content-between'>

                  <Form.Group className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Service log access"
                      checked={isServiceChecked}
                      onChange={handleServiceCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>

                  <Form.Group className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Manage form access"
                      checked={isManageFormChecked}
                      onChange={handleManageFormCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>

                  <Form.Group className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Calender access"
                      checked={isCalendarChecked}
                      onChange={handleCalendarCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>

                  <Form.Group className='w-25'>
                    <Form.Check
                      type="checkbox"
                      label="Memos access"
                      checked={isMemosChecked}
                      onChange={handleMemosCheckboxChange}
                      disabled={props.readOnly}
                    />
                  </Form.Group>
                </div>
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
});
