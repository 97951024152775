import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';
import closeIcon from '../../images/icons/close-event.png';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './calendar.css';
import { convertCustomLocalDateTimeToUTCForCalender, convertLocalDateTimeToUTC, convertLocalDateTimeToUTCForCalender } from '../../utils/util';

const createTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push(<option key={time} value={time}>{time}</option>);
    }
  }
  return options;
};

const isStartTimeBeforeEndTime = (startTime, endTime) => {
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  return startHour < endHour || (startHour === endHour && startMinute < endMinute);
};

const AddEventForm = (props) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [start, setStart] = useState(
    props.selectedDate
      ? moment(props.selectedDate).format('YYYY-MM-DDTHH:mm')
      : '',
  );
  const [end, setEnd] = useState('');
  const [status, setStatus] = useState(1);
  const [getPilots, setPilots] = useState([]);
  const [pilotVal, setpilotVal] = useState([]);
  const [getCustomers, setCustomers] = useState([]);
  const [getAircrafts, setAircrafts] = useState([]);
  const [customerVal, setCustomerVal] = useState([]);
  const [aircraftVal, setaircraftVal] = useState([]);
  const [showPilotError, setshowPilotError] = useState(false);

  const schema = Yup.object().shape({
    title:
      status === 1
        ? Yup.string().required('Appointment title is required')
        : Yup.string().required('Additional comments is required'),
    appStartDate: Yup.string().required('Start date is required'),
    appStartTime: Yup.string().required('Start time is required'),
    appEndDate: status === 1 && Yup.string().required('End date is required'),
    appEndTime: status === 1 && Yup.string().required('End time is required'),
  });

  const [minDateTime, setMinDateTime] = useState('');

  const roundTo15Minutes = (dateString) => {
    const date = new Date(dateString);
    const roundedMinutes = 15 * Math.ceil(date.getMinutes() / 15);
    date.setMinutes(roundedMinutes);
    return date.toISOString().slice(0, 16);
  };

  useEffect(() => {
    const currentDate = moment().tz('America/Anchorage'); // Get the current date and time in Alaska

    // Round to the nearest 15 minutes
    const roundedDate = moment(Math.ceil(currentDate.minutes() / 15) * 15);

    // Set the minutes to the rounded value
    currentDate.minutes(roundedDate.minutes());

    const minDateTime = currentDate.toISOString().slice(0, 16);
    setMinDateTime(minDateTime);
  }, []);

  // useEffect(() => {
  //   const currentDate = moment().tz('America/Anchorage'); // Get the current date and time in Alaska

  //   // Format the date for the input
  //   const roundedDate = moment(Math.floor(currentDate / 900000) * 900000);
  //   const now = new Date();
  //   const roundedMinutes = Math.ceil(now.getMinutes() / 15) * 15; // Round to the nearest 15 minutes
  //   now.setMinutes(roundedMinutes);

  //   const minDateTime = now.toISOString().slice(0, 16);
  //   // const formattedDate = currentDate.format('YYYY-MM-DDTHH:mm');
  //   // console.log('formattedDate', formattedDate);
  //   setMinDateTime(minDateTime);
  // }, []);

  useEffect(() => {
    fetchPilots();
    fetchCustomers();
    fetchAircrafts();
  }, []);

  const fetchPilots = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.pilotProfile,
        'GET',
        null,
        params,
      );
      let arr = [];
      let tempdata = response.data;
      for (let i = 0; i < tempdata.length; i++) {
        arr.push({
          label: tempdata[i].owner.name,
          value: tempdata[i].owner.id,
        });
      }
      setPilots(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.customerProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      let arr = [];

      for (let i = 0; i < tempdata.length; i++) {
        arr.push({ label: tempdata[i].company, value: tempdata[i].id });
      }

      setCustomers(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchAircrafts = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      let arr = [];
      const response = await ApiRequests(
        api_urls.aircraftProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      console.log('response from get aircrafts', response.data);
      for (let i = 0; i < tempdata.length; i++) {
        arr.push({ label: tempdata[i].tail_number, value: tempdata[i].id });
      }
      setAircrafts(arr);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlecustomerDropdown = (event) => {
    setCustomerVal(event);
  };

  const handleAircratsDropdown = (event) => {
    setaircraftVal(event);
  };

  const handlePilotsDropdown = (event) => {
    setshowPilotError(false);
    setpilotVal(event);
  };

  const formatDate = (inputDateTime) => {
    // Convert the input string to a Date object
    var dateTime = new Date(inputDateTime);

    // Extract the desired components
    var year = dateTime.getFullYear();
    var month = ('0' + (dateTime.getMonth() + 1)).slice(-2); // Add leading zero if necessary
    var day = ('0' + dateTime.getDate()).slice(-2); // Add leading zero if necessary
    var hours = ('0' + dateTime.getHours()).slice(-2); // Add leading zero if necessary
    var minutes = ('0' + dateTime.getMinutes()).slice(-2); // Add leading zero if necessary
    var seconds = ('0' + dateTime.getSeconds()).slice(-2); // Add leading zero if necessary

    // Assemble the formatted string
    var formattedDateTime =
      year +
      '-' +
      month +
      '-' +
      day +
      ' '
    // +
    // hours +
    // ':' +
    // minutes +
    // ':' +
    // seconds;

    // Output the result
    console.log(formattedDateTime);

    return formattedDateTime;
  };

  const {
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      title: '',
      appStartDate: '',
      appStartTime: '00:00',
      appEndDate: '',
      appEndTime: '00:00',
      startPlace: '',
      endPlace: '',
      aircraft: '',
      customer: [],
      assigned_pilot: '',
      is_repeating: 'false',
      repeating_days: '',
      note:'',
    },
    validationSchema: schema,
    onSubmit: async () => {
      console.log('status##', status);
    // return console.log('values---->', values);
      let temparr = [];
      for (let i = 0; i < customerVal.length; i++) {
        temparr.push(customerVal[i].value);
      }

      let dateOk = true;
      if (formatDate(values.appStartDate) == formatDate(values.appEndDate)) {
        if (!isStartTimeBeforeEndTime(values.appStartTime, values.appEndTime)) {
          dateOk = false;
        }
        else {
          dateOk = true;
        }
      } else { dateOk = true };

      const body = {
        title: values.title,
        type: status === 1 ? 'upcoming' : 'day_off',
        time:
          status === 1
            // ? formatDate(values.appStartDate)
            // ? (values.appStartDate)+ " "  + values.appStartTime + ":00"
            ?convertLocalDateTimeToUTCForCalender(values.appStartDate,values.appStartTime)
            //  : `${values.appStartDate} 00:01:00`,
            :convertCustomLocalDateTimeToUTCForCalender(values.appStartDate,'24:00'),
            // :`${values.appStartDate-1} 18:30:00`,
            // :convertLocalDateTimeToUTCForCalender(values.appStartDate,values.appStartTime),
        assigned_pilot: pilotVal.value ? pilotVal.value : '',
        organization:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
        customer: temparr,
        aircraft: aircraftVal.value ? aircraftVal.value : '',
        start_location: values.startPlace,
        drop_location: values.endPlace,
        end_time:
          status == 1
            // ? formatDate(values.appEndDate)
            // ? (values.appEndDate)+ " "  + values.appEndTime + ":00"
            ?convertLocalDateTimeToUTCForCalender(values.appEndDate,values.appEndTime)
            // : `${values.appStartDate} 23:59:00`,
            // :`${values.appStartDate} 18:30:00`,
            :convertCustomLocalDateTimeToUTCForCalender(values.appStartDate,'24:00'),
            // :convertLocalDateTimeToUTCForCalender(values.appEndDate,values.appEndTime),
        is_repeating: (values.is_repeating == 'true'),
        repeating_days: (values.is_repeating == 'true') ? values.repeating_days.toString() : "",
        note: values.note
      };

      if (dateOk) {
        console.log('body', body);
        try {
          const response = await ApiRequests(
            api_urls.createAppointment,
            'POST',
            body,
            null,
          );
          if (response.data) {
            toast.success('Appointment created successfully');
            navigate('/dashboard/calendar', { replace: true });
             window.location.reload();
            props.onClose();
          }
        } catch (error) {
          console.log('Error in appointment', error);
          if (error.response.data) {
            toast.error(error.response.data.error);
          } else {
            toast.error('Error in adding appointment');
          }
        }

      } else {
        toast.error("End time should not be less than or equal to Start Time");
      }


    },
  });

  const radioHandler = (radiostate) => {
    setStatus(radiostate);
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        className="delete-acc-modal"
        centered>
        <div className="event-header">
          <Modal.Header className="heading border-bottom-0 p-0">
            Add New
          </Modal.Header>
          {/* <FontAwesomeIcon
          icon={faTimes}
          onClick={() => props.onClose()}
          className="close-events"
        /> */}
          <img
            src={closeIcon}
            style={{ cursor: 'pointer' }}
            className="event-close cursor-pointer"
            onClick={() => props.onClose()}
          />
        </div>
        <>
          <div className="radio-container">
            <div className="appointment-btn">
              <input
                type="radio"
                name="release"
                checked={status === 1}
                onClick={(e) => radioHandler(1)}
              />
              <label
                for="Appointment"
                style={{ color: status === 1 ? '#000000' : '#9CA3AF' }}>
                Appointment
              </label>
            </div>

            <div className="appointment-btn">
              <input
                type="radio"
                name="release"
                checked={status === 2}
                onClick={(e) => radioHandler(2)}
              />
              <label
                for="Appointment"
                style={{ color: status === 2 ? '#000000' : '#9CA3AF' }}>
                Pilot Leave
              </label>
            </div>
          </div>
          {status === 1 ? (
            <Modal.Body className="modal-body-body">
              <Form onSubmit={handleSubmit} className="add-event-form">
                <Form.Group
                  controlId="title"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.title && errors.title ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="title"
                    value={values.title}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && errors.title}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Appointment title*
                  </label>

                  {touched.title && errors.title ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="appStartDate"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.appStartDate && errors.appStartDate
                    ? 'has-validation-error startdate-validation-error'
                    : ''
                    }`}>
                  {/* <Form.Control
                    type="datetime-local"
                    name="appStartDate"
                    placeholder="appStartDate"
                    value={values.appStartDate}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.appStartDate && errors.appStartDate}
                    step={'900'}
                    minLength={15}
                    onTimeUpdateCapture={20}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Start Date and time*
                  </label> */}

                  <div className='row'>
                    <div className='col-md-8'>
                      <Form.Control
                        type="date"
                        name="appStartDate"
                        placeholder="appStartDate"
                        value={values.appStartDate}
                        className={props.readOnly ? 'disabledColor' : ''}
                        readOnly={props.readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.appStartDate && errors.appStartDate}
                        // step={'900'}
                        minLength={15}
                        onTimeUpdateCapture={20}
                        style={{ height: '3.5rem', paddingTop: '1.5rem' }}
                      />
                      <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0' }}>
                        Start Date*
                      </label>
                    </div>
                    <div className='col-md-4'>
                      <Form.Control
                        as="select"
                        name="appStartTime"
                        value={values.appStartTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.appStartTime && errors.appStartTime}
                        readOnly={props.readOnly}
                        style={{ height: '3.5rem', paddingTop: '1.5rem' }} // Adjust width as needed
                      >
                        {createTimeOptions()}
                      </Form.Control>
                      <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0', marginLeft: '70%' }}>
                        Start Time*
                      </label>
                    </div>
                  </div>

                  {touched.appStartDate && errors.appStartDate ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.appStartDate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="appEndDate"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.appEndDate && errors.appEndDate
                    ? 'has-validation-error startdate-validation-error'
                    : ''
                    }`}>
                  {/* <Form.Control
                    type="datetime-local"
                    name="appEndDate"
                    placeholder="appEndDate"
                    value={values.appEndDate}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.appEndDate && errors.appEndDate}
                    // min={new Date().toISOString().slice(0, 16)}
                    // min={values.appStartDate}
                    min={
                      values.appStartDate ? values.appStartDate : minDateTime
                    } // Set the min attribute to appStartDate value or current time if appStartDate is not set yet.
                  />
                  <label for="floatingInput" className="greyLabel">
                    End Date and time*
                  </label> */}

                  <div className='row'>
                    <div className='col-md-8'>
                      <Form.Control
                        type="date"
                        name="appEndDate"
                        placeholder="appEndDate"
                        value={values.appEndDate}
                        className={props.readOnly ? 'disabledColor' : ''}
                        readOnly={props.readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ height: '3.5rem', paddingTop: '1.5rem' }}
                        isInvalid={touched.appEndDate && errors.appEndDate}
                        // min={new Date().toISOString().slice(0, 16)}
                        // min={values.appStartDate}
                        min={
                          values.appStartDate ? values.appStartDate : minDateTime
                        } // Set the min attribute to appStartDate value or current time if appStartDate is not set yet.
                      />
                      <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0' }}>
                        End Date*
                      </label>
                    </div>
                    <div className='col-md-4'>
                      <Form.Control
                        as="select"
                        name="appEndTime"
                        value={values.appEndTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.appEndTime && errors.appEndTime}
                        readOnly={props.readOnly}
                        style={{ height: '3.5rem', paddingTop: '1.5rem' }}
                      >
                        {createTimeOptions()}

                      </Form.Control>
                      <label for="floatingInput" className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0', marginLeft: '70%' }}>
                        End Time*
                      </label>
                    </div>
                  </div>

                  {touched.appEndDate && errors.appEndDate ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.appEndDate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="isRepeating"
                  className="form-floating viewPilot-Box-select w-100">
                  {/* <div className='mx-1'>
                    <input type="checkbox" name="is_repeating" value='true' />&nbsp;Is repeating
                  </div> */}
                  <div>&nbsp;Is Repeating</div>
                  <div className='row'>
                    <div className="form-check ms-3 col-md-2">
                      <input className="form-check-input"
                        type="radio"
                        name="is_repeating"
                        id="did_fly"
                        value='true'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.is_repeating == 'true'}
                      />
                      <label style={{ fontWeight: 'normal' }} >
                        Yes
                      </label>
                    </div>
                    <div className="form-check col-md-2">
                      <input className="form-check-input"
                        type="radio"
                        name="is_repeating"
                        id="did_fly"
                        value='false'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.is_repeating == 'false'}
                      />
                      <label style={{ fontWeight: 'normal' }}>
                        No
                      </label>
                    </div>
                  </div>

                </Form.Group>

                {values.is_repeating == 'true' &&
                  <Form.Group
                    controlId="isRepeating"
                    className="form-floating viewPilot-Box-select w-100">

                    <Form.Control
                      type="number"
                      name="repeating_days"
                      id="repeating_days"
                      value={values.repeating_days}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={1}
                      max={30}
                    />
                    <label className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0' }}>
                      No. of Days repeating
                    </label>

                  </Form.Group>
                }

                <Form.Group
                  controlId="pilot"
                  className={`form-floating viewPilot-Box-select m-0 w-100 ${touched.pilot && errors.pilot ? 'has-validation-error' : ''
                    }`}>
                  <Select
                    options={getPilots}
                    value={pilotVal}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select pilot*
                      </div>
                    }
                    onChange={handlePilotsDropdown}
                    isInvalid={touched.pilot && errors.pilot}
                    className="w-100"
                  />
                  {touched.pilot && errors.pilot ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <>
                    {showPilotError === true && (
                      <div className="text-danger">
                        Pilot is a required field
                      </div>
                    )}
                  </>
                </Form.Group>

                <Form.Group
                  controlId="aircraft"
                  className={`form-floating viewaircraft-Box w-100 ${touched.aircraft && errors.pilot
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Select
                    options={getAircrafts}
                    value={aircraftVal}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select aircraft
                      </div>
                    }
                    onChange={handleAircratsDropdown}
                    className="w-100"
                  />
                  {touched.aircraft && errors.aircraft ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.aircraft}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="customer"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.customer && errors.customer
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Select
                    options={getCustomers}
                    value={customerVal}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select customers
                      </div>
                    }
                    onChange={handlecustomerDropdown}
                    isMulti
                    className="w-100"
                  />
                  {touched.customer && errors.customer ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.customer}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="startPlace"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.startPlace && errors.startPlace
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="startPlace"
                    placeholder="startPlace"
                    value={values.startPlace}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.startPlace && errors.startPlace}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Start destination
                  </label>

                  {touched.startPlace && errors.startPlace ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.startPlace}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="startPlace"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.startPlace && errors.startPlace
                    ? 'has-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="endPlace"
                    placeholder="endPlace"
                    value={values.endPlace}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.endPlace && errors.endPlace}
                  />
                  <label for="floatingInput" className="greyLabel">
                    End destination
                  </label>

                  {touched.endPlace && errors.endPlace ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.endPlace}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="note"
                  className={`viewPilot-Box m-0 w-100 ${touched.note && errors.note ? 'has-validation-error' : ''
                    }`}>
                        <Form.Label className="greyLabel">Appointment note</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="note"
                    style={{
                      height:'auto',
                    }}

                    value={values.note}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.note && errors.note}
                  />
                  {/* <label for="floatingInput" className="greyLabel">
                    Appointment note
                  </label> */}

                  {touched.note && errors.note ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.note}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="events-btns w-100 d-flex justify-content-center">
                  <Button
                    // variant="outline-primary"
                    className="cancel-btn w-50"
                    onClick={() => props.onClose()}>
                    Cancel
                  </Button>
                  <Button
                    className="add-app-btn w-50"
                    // variant="primary rounded-pill"
                    onClick={() =>
                      pilotVal.length == 0
                        ? setshowPilotError(true)
                        : setshowPilotError(false)
                    }
                    type="submit">
                    Add Appointment
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          ) : (
            <Modal.Body className="modal-body-body">
              <Form onSubmit={handleSubmit} className="add-event-form">
              

                <Form.Group
                  controlId="appStartDate"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.appStartDate && errors.appStartDate
                    ? 'has-validation-error startdate-validation-error'
                    : ''
                    }`}>
                  <Form.Control
                    type="date"
                    name="appStartDate"
                    placeholder="appStartDate"
                    value={values.appStartDate}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={minDateTime}
                    isInvalid={touched.appStartDate && errors.appStartDate}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Day of Leave*
                  </label>

                  {touched.appStartDate && errors.appStartDate ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.appStartDate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="isRepeating"
                  className="form-floating viewPilot-Box-select w-100">
                  {/* <div className='mx-1'>
                    <input type="checkbox" name="is_repeating" value='true' />&nbsp;Is repeating
                  </div> */}
                  <div>&nbsp;Is Repeating</div>
                  <div className='row'>
                    <div className="form-check ms-3 col-md-2">
                      <input className="form-check-input"
                        type="radio"
                        name="is_repeating"
                        id="did_fly"
                        value='true'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.is_repeating == 'true'}
                      />
                      <label style={{ fontWeight: 'normal' }} >
                        Yes
                      </label>
                    </div>
                    <div className="form-check col-md-2">
                      <input className="form-check-input"
                        type="radio"
                        name="is_repeating"
                        id="did_fly"
                        value='false'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.is_repeating == 'false'}
                      />
                      <label style={{ fontWeight: 'normal' }}>
                        No
                      </label>
                    </div>
                  </div>

                </Form.Group>

                {values.is_repeating == 'true' &&
                  <Form.Group
                    controlId="isRepeating"
                    className="form-floating viewPilot-Box-select w-100">

                    <Form.Control
                      type="number"
                      name="repeating_days"
                      id="repeating_days"
                      value={values.repeating_days}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      min={1}
                      max={30}
                    />
                    <label className="greyLabel px-2 py-1" style={{ position: 'absolute', top: '0', left: '0' }}>
                      No. of Days repeating
                    </label>

                  </Form.Group>
                }

                <Form.Group
                  controlId="pilot"
                  className={`form-floating viewPilot-Box-select m-0 w-100 ${touched.pilot && errors.pilot ? 'has-validation-error' : ''
                    }`}>
                  <Select
                    options={getPilots}
                    value={pilotVal}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select pilot*
                      </div>
                    }
                    onChange={handlePilotsDropdown}
                    className="w-100"
                  />
                  {touched.pilot && errors.pilot ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <>
                    {showPilotError === true && (
                      <div className="text-danger">
                        Pilot is a required field
                      </div>
                    )}
                  </>
                </Form.Group>

                <Form.Group
                  controlId="title"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.title && errors.title ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="title"
                    value={values.title}
                    // disabled
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && errors.title}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Additional comments*
                  </label>

                  {touched.title && errors.title ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="events-btns w-100 d-flex justify-content-center">
                  <Button
                    // variant="outline-primary"
                    className="cancel-btn w-50"
                    onClick={() => props.onClose()}>
                    Cancel
                  </Button>
                  <Button
                    className="add-app-btn w-50"
                    // variant="primary rounded-pill"
                    onClick={() =>
                      pilotVal.length == 0
                        ? setshowPilotError(true)
                        : setshowPilotError(false)
                    }
                    type="submit">
                    Add Leave
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          )}
        </>
      </Modal>
    </div>
  );
};

export default AddEventForm;
