import React, { forwardRef } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';

export const GeneralDetails = forwardRef((props, ref) => {
  console.log('props.profileDetails', props.profileDetails);
  const navigate = useNavigate();

  const organization_id =
    sessionStorage.getItem('organization_id') === 'null'
      ? 2
      : sessionStorage.getItem('organization_id');

  const handleReset = () => {};

  return (
    <>
      <Formik
        innerRef={ref}
        initialValues={
          Object.keys(props.profileDetails).length > 0
            ? {
                orgName: props.profileDetails.owner.name || '',
                DateOfBirth: props.profileDetails.incorporation_date || '',
                Email: props.profileDetails.owner.email || '',
                Phone: props.profileDetails.phone_number || '',
                Bio: props.profileDetails.additional_information || '',
                Address: props.profileDetails.address || '',
                City: props.profileDetails.city || '',
                zipCode: props.profileDetails.zip_code || '',
                State: props.profileDetails.state || '',
                Country: props.profileDetails.country || '',
              }
            : {
                orgName: '',
                DateOfBirth: '',
                Email: '',
                Phone: '',
                Bio: '',
                Address: '',
                City: '',
                zipCode: '',
                State: '',
                Country: '',
              }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          let apiurl = api_urls.Organization + organization_id + '/';
          try {
            let body = {
              owner: {
                email: values.Email,
                name: values.orgName,
              },
              phone_number: values.Phone,
              incorporation_date: values.DateOfBirth
                ? values.DateOfBirth
                : null,
              additional_information: values.Bio,
              address: values.Address,
              city: values.City,
              zip_code: values.zipCode,
              state: values.State,
              country: values.Country,
            };

            const formData = new FormData();
            for (const key in body) {
              if (body[key]) {
                if (
                  typeof body[key] === 'object' &&
                  !Array.isArray(body[key])
                ) {
                  for (const nestedKey in body[key]) {
                    formData.append(`owner.${nestedKey}`, body[key][nestedKey]);
                  }
                } else {
                  formData.append(key, body[key]);
                }
              }
            }
            if (props.selectedFile) {
              formData.append('image', props.selectedFile);
            }
            const response = await ApiRequests(apiurl, 'PATCH', formData, null);
            if (response.status === 200) {
              toast.success('Profile updated successfully');
              // navigate('/dashboard/reports', { replace: true });
              navigate(-1, { replace: true });
            }
          } catch (error) {
            console.error('There was an error!', error);
            toast.error('Error in editing profile');
          }
          actions.setSubmitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form
            className=""
            noValidate
            style={{
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}>
            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h3 className="personalLabels">Organization information</h3>
              <hr />
              <div>
                <Form.Group
                  controlId="orgName"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.orgName && errors.orgName
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="orgName"
                    placeholder="Full Name"
                    value={values.orgName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    isInvalid={touched.orgName && errors.orgName}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Organization Name
                  </label>
                </Form.Group>
                <Form.Group
                  controlId="DateOfBirth"
                  className={`form-floating  viewPilot-Box  mb-3 ${
                    touched.DateOfBirth && errors.DateOfBirth
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="date"
                    name="DateOfBirth"
                    placeholder=""
                    value={values.DateOfBirth}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="1600-12-31"
                    max="2099-12-31"
                    pattern="\d{4}-\d{2}-\d{2}"
                    isInvalid={touched.DateOfBirth && errors.DateOfBirth}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Date Of Incorporation
                  </label>

                  {touched.DateOfBirth && errors.DateOfBirth ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.DateOfBirth}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  controlId="Email"
                  className={`form-floating viewPilot-Box   mb-3 ${
                    touched.Email && errors.Email ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Email"
                    placeholder="Email"
                    value={values.Email}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Email && errors.Email}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Email
                  </label>
                </Form.Group>

                <Form.Group
                  controlId="Phone"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Phone && errors.Phone ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Phone"
                    placeholder="Phone number"
                    value={values.Phone}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Phone && errors.Phone}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Phone number
                  </label>

                  {touched.Phone && errors.Phone ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Phone}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  style={{ width: '99%' }}
                  controlId="Bio"
                  className={`form-floating viewPilot-Box  mb-3 ${
                    touched.Bio && errors.Bio ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    as="textarea"
                    name="Bio"
                    placeholder="Bio"
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    value={values.Bio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Bio && errors.Bio}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Additional information
                  </label>

                  {touched.Bio && errors.Bio ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Bio}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>

            <Row
              style={{
                borderRadius: '5px',
                border: '1px solid #E5E7EB',
                paddingBottom: '10px',
                marginBottom: '10px',
              }}>
              <h4 className="personalLabels">Organization address</h4>
              <hr />

              <div>
                <Form.Group
                  controlId="Address"
                  className={`form-floating  w49 viewPilot-Box  mb-3 ${
                    touched.Address && errors.Address
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Address"
                    placeholder="Address"
                    value={values.Address}
                    onChange={handleChange}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onBlur={handleBlur}
                    isInvalid={touched.Address && errors.Address}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Address
                  </label>

                  {touched.Address && errors.Address ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Address}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="City"
                  className={`form-floating viewPilot-Box w49 mb-3 ${
                    touched.City && errors.City ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="City"
                    placeholder="City"
                    value={values.City}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.City && errors.City}
                  />
                  <label for="floatingInput" className="greyLabel">
                    City
                  </label>

                  {touched.City && errors.City ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.City}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="zipCode"
                  className={`form-floating viewPilot-Box w49 mb-3 ${
                    touched.zipCode && errors.zipCode
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="zipCode"
                    placeholder="Zip Code"
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    value={values.zipCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.zipCode && errors.zipCode}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Zip Code
                  </label>

                  {touched.zipCode && errors.zipCode ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="State"
                  className={`form-floating viewPilot-Box w49 mb-3 ${
                    touched.State && errors.State ? 'has-validation-error' : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="State"
                    placeholder="State"
                    value={values.State}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.State && errors.State}
                  />
                  <label for="floatingInput" className="greyLabel">
                    State
                  </label>

                  {touched.State && errors.State ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.State}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="Country"
                  className={`form-floating viewPilot-Box w49 mb-3 ${
                    touched.Country && errors.Country
                      ? 'has-validation-error'
                      : ''
                  }`}>
                  <Form.Control
                    type="text"
                    name="Country"
                    placeholder="Country"
                    value={values.Country}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.Country && errors.Country}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Country
                  </label>

                  {touched.Country && errors.Country ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.Country}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
});
