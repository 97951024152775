import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
import { DiscardChanges } from './/DiscardChanges';
import { MultiCustomerDeleteCheck } from './MultiCustomerDeleteCheck.js';
import { DeleteCustomerPop } from './DeleteCustomerPop.js';
import ViewImage from './ViewImage.jsx';
import defaultImage from '../../images/activeicons/defaultImage.svg';
import Save from '../../images/icons/save.png';
import Deletquestion from '../../images/activeicons/Deletequestion.svg';
import Editquestion from '../../images/activeicons/Editquestion.svg';
import addques from '../../images/activeicons/addques.svg';
import Loader from '../Loader';
import momentTimezone from 'moment-timezone';
import {convertLocalDateTimeToUTC, convertUtcDateTimeToLocal} from '../../utils/util';
export const GeneralDetails = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    // Email: Yup.string().email('Invalid email').required('Email is required'),
    // Email:Yup.string().matches(emailRegExp, 'Invalid email format').required('Email is required'),
    // fullName: Yup.string().required('Name is required'),
    // Company: Yup.string().required('Company is required'),
  });
console.log("props.reportDetails",props.reportDetails.did_fly,props.editView)
const [did_flyFlg, setDid_flyFlg] = useState(props.reportDetails.did_fly);

useEffect(() => {
    setDid_flyFlg(props.reportDetails.did_fly);
}, [props.reportDetails.did_fly]);

console.log("did_flyFlg",did_flyFlg)

const [selHourmeter, setSelHourmeter] = useState();
  const [selLogbook, setSelLogbook] = useState();
  const [selIssueImg1, setSelIssueImg1] = useState();
  const [selIssueImg2, setSelIssueImg2] = useState();
  const [selIssueImg3, setSelIssueImg3] = useState();
  const [selIssueImg4, setSelIssueImg4] = useState();

  const [customersFetched, setCustomersFetched] = useState([]);
  const [aircarftsFetched, setAircraftsFetched] = useState([]);
  const [flightTypesFetched, setFlightTypes] = useState([]);
  const [maintenanceOptions, setMaintenanceOptions] = useState([]);

  const [editableIndex, setEditableIndex] = useState(-1);
  const [customerIdVal, setCustomerIdVal] = useState('');
  const [customerHourIdVal, setCustomerHourIdVal] = useState('');
  const [hourVal, setHourVal] = useState('');
  const [showAddCustomer, setAddCustomer] = useState(true);

  const [questions, setQuestions] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [questionMapping, setQuestionMapping] = useState({});
  const [userInputs, setUserInputs] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleEditClick = (index, id, hour) => {
    setCustomerIdVal(id);
    setEditableIndex(index);
    setHourVal(hour);
  };

  const fetchCustomersList = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.customerProfile,
        'GET',
        null,
        params,
      );
      setCustomersFetched(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Customer List:', error);
      setLoading(false);
    }
  };

  const getCustomersVal = () => {
    let tempdata = props.reportDetails.report_customer_hours;
    let arr = [];
    for (let i = 0; i < tempdata.length; i++) {
      arr.push({
        id: tempdata[i].id,
        customerId: tempdata[i].customer_info.id,
        name: tempdata[i].customer_info.company,
        hour: tempdata[i].hour,
      });
    }
    return arr;
  };

  const fetchAircrafts = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      const response = await ApiRequests(
        api_urls.aircraftProfile,
        'GET',
        null,
        params,
      );
      setAircraftsFetched(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Aircrafts:', error);
      setLoading(false);
    }
  };

  const fetchFlightTpyes = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      const response = await ApiRequests(
        api_urls.getAllflightTypes,
        'GET',
        null,
        params,
      );
      setFlightTypes(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Aircrafts:', error);
      setLoading(false);
    }
  };

  const fetchMaintaineceOptions = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.getallMaintainece,
        'GET',
        null,
        params,
      );
      setMaintenanceOptions(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Maintenance Options:', error);
      setLoading(false);
    }
  };

  const fetchFieldMapping = async () => {
    setLoading(true);
    try {
      let apiurl = `${api_urls.question_mapping}`;
      const response = await ApiRequests(apiurl, 'GET', null, null);
      setQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching question mapping:', error);
      setLoading(false);
    }
  };

  const fetchCustomQuestion = async () => {
    setLoading(true);
    try {
      let apiurl = `${api_urls.custom_question}`;
      const response = await ApiRequests(apiurl, 'GET', null, null);
      setCustomQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching custom question mapping:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomersList();
    fetchMaintaineceOptions();
    fetchAircrafts();
    fetchFlightTpyes();
    fetchFieldMapping();
    fetchCustomQuestion();
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      const generatedMapping = {};
      questions.forEach((question) => {
        const { key } = question.mapping;
        generatedMapping[key] = true;
      });
      setQuestionMapping(generatedMapping);
    }
  }, [questions]);

  useEffect(() => {
    // Check if report_custom_answers is an array
    const reportAnswers = Array.isArray(
      props.reportDetails.report_custom_answers,
    )
      ? props.reportDetails.report_custom_answers
      : [];

    // Initialize userInputs with initial values from props
    const initialUserInputs = customQuestions.map((question) => {
      const answer = reportAnswers.find((ans) => ans.question === question.id);
      const defaultValues = {
        image: null,
        choice: '',
        text: '',
      };

      return {
        questionId: question.id,
        type: question.type,
        image:
          answer && answer.image_answer !== undefined
            ? answer.image_answer
            : defaultValues.image,
        choice:
          answer && answer.choice_answer !== undefined
            ? answer.choice_answer
            : defaultValues.choice,
        text:
          answer && answer.text_answer !== undefined
            ? answer.text_answer
            : defaultValues.text,
      };
    });

    setUserInputs(initialUserInputs);
    console.log(userInputs);
  }, [props.reportDetails.report_custom_answers]);

  const handleImageChange = (questionId, file) => {
    setUserInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.questionId === questionId ? { ...input, image: file } : input,
      ),
    );
  };

  const handleChoiceChange = (questionId, choice) => {
    setUserInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.questionId === questionId ? { ...input, choice } : input,
      ),
    );
  };

  const handleTextChange = (questionId, text) => {
    setUserInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.questionId === questionId ? { ...input, text } : input,
      ),
    );
  };

  const getImageSource = (questionId, userInputs) => {
    const input =
      userInputs.find((input) => input.questionId === questionId) || {};

    if (input.type === 'I' && input.image instanceof File) {
      // If the image is a File instance, use Object URL
      return URL.createObjectURL(input.image);
    }

    // Otherwise, use the stored URL or an empty string if not available
    return input.image || defaultImage;
  };

  const addCustomerHours = async (hours, customerId) => {
    // const url = new URL(window.location.href);
    let apiurl = api_urls.reportProfileCustomers;
    console.log(apiurl);
    if (hours == '' || customerId == '') {
      toast.warn('Customer and hours cannot be empty');
    } else {
      try {
        let body = {
          report: props.reportDetails.id,
          hour: hours,
          customer: customerId ? parseInt(customerId) : '',
        };
        const formData = new FormData();
        for (let key in body) {
          formData.append(key, body[key]);
        }
        console.log(body);

        if (props.reportDetails.report_customer_hours.length == 1) {
          const url = new URL(window.location.href);
          const id = url.pathname.split('/').pop();
          let dailyReportapiurl = api_urls.reportProfile + id + '/';
          const multiple_customers = new FormData();
          multiple_customers.append('multiple_customers', true);
          const res = await ApiRequests(
            dailyReportapiurl,
            'PATCH',
            multiple_customers,
            null,
          );
        }

        const response = await ApiRequests(apiurl, 'POST', formData, null);
        if (response.status === 201) {
          toast.success('Customer hours updated successfully');
          window.location.reload(true);
        }
      } catch (error) {
        console.error('There was an error!', error);
        toast.error('Error in saving Customer hours');
      }
    }
  };

  const deleteCustomerHours = async (id) => {
    if (props.reportDetails.report_customer_hours.length == 2) {
      const url = new URL(window.location.href);
      const id = url.pathname.split('/').pop();
      let dailyReportapiurl = api_urls.reportProfile + id + '/';
      const multiple_customers = new FormData();
      multiple_customers.append('multiple_customers', false);
      const res = await ApiRequests(
        dailyReportapiurl,
        'PATCH',
        multiple_customers,
        null,
      );
    }
    try {
      let apiurl = `${api_urls.reportProfileCustomers}${id}/`;
      const response = await ApiRequests(apiurl, 'DELETE');
      if (response) {
        toast.success('Customer Hours deleted successfully');
        window.location.reload(false);
      }
    } catch (error) {
      toast.error('Error in deleting Customer Hours');
    }
  };

  const deleteCustomerHoursOnCheck = async (ids) => {
    const excludeLast = ids.slice(0, -1);
    const combinedIds = excludeLast.join(',');
    const queryParams = { id: combinedIds };
    try {
      let apiurl = `${api_urls.reportProfileCustomers}bulk-delete/`;
      const response = await ApiRequests(apiurl, 'DELETE', null, queryParams);
    } catch (error) {
      toast.error('Error in deleting Customer Hours');
    }
  };

  const updateCustomerHours = async (index, id) => {
    console.log(id);
    console.log(customerIdVal);
    console.log(hourVal);
    // setEditableIndex(-1);

    let body = {
      hour: hourVal,
      customer: customerIdVal,
    };
    const formData = new FormData();
    for (let key in body) {
      formData.append(key, body[key]);
    }
    try {
      let apiurl = `${api_urls.reportProfileCustomers}${id}/`;
      const response = await ApiRequests(apiurl, 'PATCH', formData);
      if (response) {
        setEditableIndex(-1);
        toast.success('Customer Hours updated successfully');
        window.location.reload(false);
      }
    } catch (error) {
      toast.error('Error in updating Customer Hours');
    }
  };

  const [showMultiCustomerCheck, setMultiCustomerCheck] = useState(false);
  const multiCustomerCheck = async (flag) => {
    // if (props.reportDetails.report_customer_hours != 0)
    //     toast.warn("Are you sure? Customers will be deleted");
    if (flag === true) {
      setMultiCustomerCheck(true);
    } else {
      setMultiCustomerCheck(false);
    }
  };
  const showMultiCustomerCheckPop = async () => {
    setMultiCustomerCheck(true);
  };

  const [showCustomerDelete, setCustomerDelete] = useState(false);
  const [customerDeleteId, setCustomerDeleteId] = useState('');
  const handleCustomerDelete = (flag) => {
    if (flag === true) {
      setCustomerDelete(false);
      deleteCustomerHours(customerDeleteId);
    } else {
      setCustomerDelete(false);
    }
  };
  const deleteCustomerHoursPop = async (id) => {
    setCustomerDeleteId(id);
    setCustomerDelete(true);
  };

  const handleReset = () => {};
  var date;
  var time;

  const handleDateChange = (e) => {
    date = e.target.value;
    console.log('date', date);
    console.log(momentTimezone.tz.guess());
    // console.dir(e.target)
  };

//   const convertLocalDateTimeToUTC = (day_start_date, day_start_time) => {
//     var date_time = day_start_date + ' ' + day_start_time;
//     const localDate = new Date(date_time);
//     const utcTimeString = localDate.toISOString();
//     console.log('UTC..date', utcTimeString);
//     return utcTimeString;
//   };

//   const convertUtcDateTimeToLocal = (utcDate) => {
//     console.log('UTC..date', utcDate);
//     //convert utcTimeString into localtime
//     const localTime = moment.utc(utcDate).local();
//     //console.log("localtime",localTime._d)
//     const formattedLocalDateTime = moment(localTime._d).format(
//       'YYYY-MM-DDTHH:mm:ssZ',
//     );
//     console.log('formattedTime', formattedLocalDateTime);
//     return formattedLocalDateTime;
//   };
// constHandleDate=()=>{
// console.log("value.day_start_date",values.day_start_date)
// }

const handleFlyClick = (e) => {
  console.log('e.target.name', e.target.value);
  if (e.target.value === 'true')
  setDid_flyFlg(true);
// else
//   setDid_flyFlg(false);
}

  return (
    <>
      <Formik
        innerRef={ref}
        // validationSchema={schema}
        initialValues={
          Object.keys(props.reportDetails).length > 0
            ? {
                did_fly: props.reportDetails.did_fly.toString() || '',
                aircraft: props.reportDetails.aircraft
                  ? props.reportDetails.aircraft.id
                  : '',
                flightType: props.reportDetails.flight_type
                  ? props.reportDetails.flight_type.id
                  : '',
                multiple_customers:
                  props.reportDetails.multiple_customers.toString() || '',
                // customers: getCustomers(),
                customers: getCustomersVal(),
                customerHours: '',
                customer: '',

                other_customer: props.reportDetails.other_customer || '',
                passengers: props.reportDetails.passengers || '',
                base: props.reportDetails.base || '',
                collective_start_time:
                  props.reportDetails.collective_start_time || '',
                collective_end_time:
                  props.reportDetails.collective_end_time || '',
                hour_meter_photo: props.reportDetails.hour_meter_photo || '',
                returning_gear:
                  props.reportDetails.returning_gear.toString() || '',
                items: props.reportDetails.items || '',
                maintenance_logbook_photo:
                  props.reportDetails.maintenance_logbook_photo || '',
                upcoming_maintenance: props.reportDetails.upcoming_maintenance
                  ? props.reportDetails.upcoming_maintenance.id
                  : '',
                issue_addressed:
                  props.reportDetails.issue_addressed.toString() || '',
                issues: props.reportDetails.issues || '',
                issue_img_1: props.reportDetails.issue_img_1 || '',
                issue_img_2: props.reportDetails.issue_img_2 || '',
                issue_img_3: props.reportDetails.issue_img_3 || '',
                issue_img_4: props.reportDetails.issue_img_4 || '',
                day_start: props.reportDetails.day_start || '',
                day_start_date: props.reportDetails.day_start
                  ? props.reportDetails.day_start.split(' ')[0]
                  : '',
                day_start_time: props.reportDetails.day_start
                  ? props.reportDetails.day_start.split(' ')[1]
                  : '',
                day_end: props.reportDetails.day_end || '',
                day_end_date: props.reportDetails.day_end
                  ? props.reportDetails.day_end.split(' ')[0]
                  : '',
                day_end_time: props.reportDetails.day_end
                  ? props.reportDetails.day_end.split(' ')[1]
                  : '',
                total_hours: props.reportDetails.total_hours || '',
                AHT: props.reportDetails.AHT.toString() || '',
                other_Base: props.reportDetails.other_Base || '',
                fuel: props.reportDetails.fuel || '',
                rev_time: props.reportDetails.rev_time || '',
                ACTT_total: props.reportDetails.ACTT_total || '',
                landings_at_ANC: props.reportDetails.landings_at_ANC || '',
                landings_at_PAMR: props.reportDetails.landings_at_PAMR || '',
                day_landings: props.reportDetails.day_landings || '',
                night_landings: props.reportDetails.night_landings || '',
                fly_hazmat: props.reportDetails.fly_hazmat.toString() || '',
                perform_power_check:
                  props.reportDetails.perform_power_check.toString() || '',
              }
            : {
                did_fly: '',
                aircraft: '',
                flightType: '',
                multiple_customers: '',
                // customers: '',
                other_customer: '',
                passengers: '',
                base: '',
                collective_start_time: '',
                collective_end_time: '',
                hour_meter_photo: '',
                returning_gear: '',
                items: '',
                maintenance_logbook_photo: '',
                upcoming_maintenance: '',
                issue_addressed: '',
                issues: '',
                issue_img_1: '',
                issue_img_2: '',
                issue_img_3: '',
                issue_img_4: '',
                day_start: '',
                day_end: '',
                total_hours: '',
                AHT: '',
                other_Base: '',
                fuel: '',
                rev_time: '',
                ACTT_total: '',
                landings_at_ANC: '',
                landings_at_PAMR: '',
                day_landings: '',
                night_landings: '',
                fly_hazmat: '',
                perform_power_check: '',
              }
        }
        enableReinitialize
        onReset={handleReset}
        onSubmit={async (values, actions) => {
          const url = new URL(window.location.href);
          const id = url.pathname.split('/').pop();
          let apiurl = api_urls.reportProfile + id + '/';
          console.log(apiurl);
          try {
            // if (props.reportDetails.upcoming_maintenance) {
            //     updated_upcoming_maintenance = { ...props.reportDetails.upcoming_maintenance };
            //     updated_upcoming_maintenance.option = values.upcoming_maintenance;
            // }
            let customerValues = [];
            for (let i = 0; i < values.customers.length; i++) {
              customerValues.push(parseInt(values.customers[i]));
            }

            let body = {
              did_fly: values.did_fly == 'true',
              aircraft: values.did_fly == 'true' ? values.aircraft : '',
              flight_type: values.did_fly == 'true' ? values.flightType : '',
              multiple_customers: values.multiple_customers == 'true',
              // customers: (values.multiple_customers == 'true') ? "[" + customerValues.toString() + "]" : "[]",
              other_customer: values.other_customer,
              passengers: values.passengers,
              base: values.base,
              collective_start_time: values.collective_start_time
                ? parseFloat(values.collective_start_time).toFixed(2)
                : '',
              collective_end_time: values.collective_end_time
                ? parseFloat(values.collective_end_time).toFixed(2)
                : '',

              returning_gear: values.returning_gear == 'true',
              items: values.returning_gear == 'true' ? values.items : '',
              upcoming_maintenance: values.upcoming_maintenance,
              issue_addressed: values.issue_addressed == 'true',
              issues: values.issue_addressed == 'true' ? values.issues : '',

              day_start:
                values.day_start_date && values.day_start_time
                  ? convertLocalDateTimeToUTC(values.day_start_date, values.day_start_time)
                  : '',
              day_end:
                values.day_end_date && values.day_end_time
                  ? convertLocalDateTimeToUTC(values.day_end_date, values.day_end_time)
                  : '',
              total_hours: values.total_hours
                ? parseFloat(values.total_hours)
                : '',
              AHT: values.AHT == 'true',
              other_Base: values.other_Base,
              fuel: values.fuel ? parseFloat(values.fuel).toFixed(1) : '',
              rev_time:
                '' + values.rev_time
                  ? parseFloat(values.rev_time).toFixed(5)
                  : '',
              ACTT_total:
                '' + values.ACTT_total
                  ? parseFloat(values.ACTT_total).toFixed(1)
                  : '',
              landings_at_ANC: values.landings_at_ANC
                ? parseInt(values.landings_at_ANC)
                : '',
              landings_at_PAMR: values.landings_at_PAMR
                ? parseInt(values.landings_at_PAMR)
                : '',
              day_landings: values.day_landings
                ? parseInt(values.day_landings)
                : '',
              night_landings: values.night_landings
                ? parseInt(values.night_landings)
                : '',
              fly_hazmat: values.fly_hazmat == 'true',
              perform_power_check: values.perform_power_check == 'true',
            };

            const formData = new FormData();

            formData.append(
              'flight_hours',
              body.collective_end_time - body.collective_start_time,
            );

            if (values.issue_addressed == 'true') {
              formData.append('issues', values.issues);

              if (selIssueImg1 instanceof File) {
                formData.append('issue_img_1', selIssueImg1);
              }
              if (selIssueImg2 instanceof File) {
                formData.append('issue_img_2', selIssueImg2);
              }
              if (selIssueImg3 instanceof File) {
                formData.append('issue_img_3', selIssueImg3);
              }
              if (selIssueImg4 instanceof File) {
                formData.append('issue_img_4', selIssueImg4);
              }
            } else {
              formData.append('issue_img_1', '');
              formData.append('issue_img_2', '');
              formData.append('issue_img_3', '');
              formData.append('issue_img_4', '');
            }

            if (selHourmeter instanceof File) {
              formData.append('hour_meter_photo', selHourmeter);
            }
            if (selLogbook instanceof File) {
              formData.append('maintenance_logbook_photo', selLogbook);
            }

            for (let key in body) {
              formData.append(key, body[key]);
            }

            if (body.multiple_customers === false) {
              const customerIds = values.customers.map((cust) => cust.id);
              if (customerIds.length > 1) {
                deleteCustomerHoursOnCheck(customerIds);
              }
            }
            let ok = true;
            if (values.did_fly == 'true') {
              if (values.flightType != '' && values.aircraft != '') {
                ok = true;
              } else {
                ok = false;
                if (values.flightType == '')
                  toast.error('Please select flight type');
                if (values.aircraft == '')
                  toast.error('Please select aircraft');
              }
            } else {
              ok = true;
            }

            let multiCustomerOk = true;
            if (values.multiple_customers == 'true') {
              if (values.customers.length > 1) {
                multiCustomerOk = true;
              } else {
                multiCustomerOk = false;
                toast.error(
                  'Minimum two customers are required for multiple customer "Y" option',
                );
              }
            } else {
              multiCustomerOk = true;
            }

            userInputs.forEach((input, index) => {
              switch (input.type) {
                case 'I':
                  if (input.image instanceof File) {
                    formData.append(
                      `report_custom_answers[${index}]question`,
                      input.questionId,
                    );
                    formData.append(
                      `report_custom_answers[${index}]image_answer`,
                      input.image,
                    );
                  }
                  break;

                case 'C':
                  formData.append(
                    `report_custom_answers[${index}]question`,
                    input.questionId,
                  );
                  formData.append(
                    `report_custom_answers[${index}]choice_answer`,
                    input.choice,
                  );
                  break;

                case 'T':
                  formData.append(
                    `report_custom_answers[${index}]question`,
                    input.questionId,
                  );
                  formData.append(
                    `report_custom_answers[${index}]text_answer`,
                    input.text,
                  );
                  break;

                default:
                  // Handle other types if needed
                  break;
              }
            });

            if (!questionMapping.hasOwnProperty('did_fly')) {
              ok = true;
            }

            if (!questionMapping.hasOwnProperty('multiple_customer')) {
              multiCustomerOk = true;
            }

            if (multiCustomerOk == true && ok == true) {
              const response = await ApiRequests(
                apiurl,
                'PATCH',
                formData,
                null,
              );
              if (response.status === 200) {
                props.savedSuccessfully();
                toast.success('Report updated successfully');
                console.log(body);
                navigate('/dashboard/reports', { replace: true });
              }
            }
            console.log(formData);
          } catch (error) {
            console.error('There was an error!', error);
            toast.error('Error in updating Report');
          }
          actions.setSumitting(false);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) =>
          loading === true ? (
            <Loader loadingMsg="Data is Loading... Please Wait" />
          ) : (
            <form noValidate>
              {questionMapping.did_fly && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '30px 20px 20px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'did_fly',
                        ).label
                      }
                    </div>
                    <Form.Group
                      controlId="did_fly"
                      className={`w-25 ${
                        touched.did_fly && errors.did_fly
                          ? 'has-validation-error'
                          : ''
                      }`}>
                      {props.readOnly ? (
                        <>
                          <input
                            type="text"
                            disabled="disabled"
                            value={values.did_fly === 'true' ? 'Yes' : 'No'}
                            className="grey-input"
                          />
                        </>
                      ) : (
                        <>
                          <div className="form-check">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="did_fly"
                              id="did_fly"
                              value="true"
                              checked={values.did_fly == 'true'}
                              onChange={handleChange}
                              onClick={handleFlyClick}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault1">
                              Yes
                            </label>
                          </div>
                          <div className="form-check">
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="did_fly"
                              id="did_fly"
                              value="false"
                              checked={values.did_fly == 'false'}
                              onChange={handleChange}
                              // onClick={handleFlyClick}
                              onBlur={handleBlur}
                            />
                            <label
                              className="form-check-label"
                              for="flexRadioDefault2">
                              No
                            </label>
                          </div>
                        </>
                      )}
                    </Form.Group>
                    <>
                      {values.did_fly === 'true' && (
                        <>
                          <div className="row-options">
                            <span className="row-txt">Flight type&nbsp;</span>

                            {props.readOnly ? (
                              <span className="row-option-txt">
                                {props.reportDetails.flight_type &&
                                  props.reportDetails.flight_type.option}
                              </span>
                            ) : (
                              <Field
                                as="select"
                                name="flightType"
                                className="form-select w-25 mx-4">
                                <option value="">
                                  <span>Select Flight Type</span>
                                </option>
                                {flightTypesFetched &&
                                  flightTypesFetched.map((opt) => (
                                    <option value={opt.id}>{opt.option}</option>
                                  ))}
                              </Field>
                            )}
                          </div>

                          <div className="row-options">
                            <span className="row-txt">Aircraft</span>
                            {props.readOnly ? (
                              <span className="row-option-txt mx-4">
                                {props.reportDetails.aircraft &&
                                  props.reportDetails.aircraft.tail_number}
                              </span>
                            ) : (
                              <Field
                                as="select"
                                name="aircraft"
                                className="form-select w-25 mx-5">
                                <option value="">
                                  <span>Select Aircraft</span>
                                </option>
                                {aircarftsFetched &&
                                  aircarftsFetched.map((opt) => (
                                    <option value={opt.id}>
                                      {opt.tail_number}
                                    </option>
                                  ))}
                              </Field>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </Row>
              )}
{(did_flyFlg || !props.editView) && <div>
              <Row
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: '15px 20px',
                }}
                className="questions-row">
                <div className="child-row-1">
                  <div className="sec-row">Multiple customers</div>
                  <Form.Group
                    controlId="multiple_customers"
                    className={`w-25 ${
                      touched.multiple_customers && errors.multiple_customers
                        ? 'has-validation-error'
                        : ''
                    }`}>
                    {props.readOnly ? (
                      <>
                        <input
                          type="text"
                          disabled="disabled"
                          value={
                            values.multiple_customers === 'true' ? 'Yes' : 'No'
                          }
                          className="grey-input"
                        />
                      </>
                    ) : (
                      <>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="multiple_customers"
                            id="multiple_customers"
                            value="true"
                            checked={values.multiple_customers == 'true'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="multiple_customers"
                            id="multiple_customers"
                            value="false"
                            checked={values.multiple_customers == 'false'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={showMultiCustomerCheckPop}
                          />
                          <label
                            className="form-check-label"
                            for="flexRadioDefault2">
                            No
                          </label>
                        </div>
                      </>
                    )}
                  </Form.Group>
                </div>
              </Row>

              {/* {values.multiple_customers == 'true' && (
                            <Row
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    padding: '15px 20px',
                                }}
                                className="questions-row">
                                <div className="child-row-1">

                                    <div className="sec-row">Customers</div>
                                    {props.readOnly && props.reportDetails.customers.map((data, index) => (
                                        <>
                                            <div className="">
                                                <input
                                                    type="text"
                                                    disabled="disabled"
                                                    value={data.name}
                                                    className="grey-input"
                                                />
                                            </div>
                                        </>
                                    ))}
                                    {!props.readOnly && <div className="sec-row">Select Customer:</div>}

                                    <div className='row'>
                                        {!props.readOnly && customersFetched.map((customer) => (
                                            <div className='col-md-4' style={{ color: 'black' }}>
                                                <Field type="checkbox" name="customers" value={customer.id.toString()} />
                                                &nbsp;&nbsp;&nbsp;{customer.name}
                                            </div>
                                        ))}
                                    </div>

                                    <div className="sec-row">Other Customer</div>
                                    <div className="">
                                        
                                        <Form.Control
                                            type="text"
                                            name="other_customer"
                                            value={values.other_customer}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={props.readOnly ? 'grey-input w-25' : 'w-25'}
                                            readOnly={props.readOnly}
                                            isInvalid={touched.other_customer && errors.other_customer}
                                        />
                                    </div>


                                </div>
                            </Row>
                        )} */}

              {/* {values.multiple_customers == 'true' && ( */}
              <Row
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: '15px 20px',
                }}
                className="questions-row">
                <div className="child-row-1">
                  {values.customers && values.customers.length > 0 && (
                    <div className="row">
                      <div className="col-md-3" style={{ color: 'black' }}>
                        Customers
                      </div>
                      <div className="col-md-2 mx-3" style={{ color: 'black' }}>
                        Hours
                      </div>
                    </div>
                  )}
                  {props.readOnly ? (
                    <>
                      {values.customers &&
                        values.customers.map((data, index) => (
                          <>
                            <div className="row">
                              <input
                                type="text"
                                value={data.name}
                                readOnly={props.readOnly}
                                className="grey-input col-md-3 ms-2"
                              />
                              <input
                                type="number"
                                value={data.hour}
                                readOnly={props.readOnly}
                                className="grey-input col-md-1 mx-3"
                              />
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {values.customers &&
                        values.customers.map((data, index) => (
                          <>
                            <div className="row">
                              <select
                                onChange={(e) => {
                                  setCustomerIdVal(e.target.value);
                                }}
                                disabled={editableIndex !== index}
                                className={`form-select w-25 col-md-3 ms-2 ${
                                  editableIndex !== index && 'disabled'
                                }`}>
                                <option value={data.customerId} selected>
                                  {data.name}
                                </option>
                                {customersFetched
                                  .filter(
                                    (customer) =>
                                      !values.customers.some(
                                        (data) =>
                                          data.customerId === customer.id,
                                      ),
                                  )
                                  .map((customer) => (
                                    <option value={customer.id}>
                                      {customer.company}
                                    </option>
                                  ))}
                              </select>

                              {editableIndex !== index ? (
                                <input
                                  type="number"
                                  placeholder={data.hour}
                                  value={data.hour}
                                  // onChange={(e) => { setHourVal(e.target.value) }}
                                  readOnly={true}
                                  className={`col-md-1 mx-3 grey-input`}
                                />
                              ) : (
                                <input
                                  type="number"
                                  placeholder={data.hour}
                                  value={hourVal}
                                  onChange={(e) => {
                                    setHourVal(e.target.value);
                                  }}
                                  readOnly={editableIndex !== index}
                                  className={`col-md-1 mx-3 form-control`}
                                  style={{ width: '80px' }}
                                />
                              )}

                              {editableIndex !== index ? (
                                <>
                                  <div
                                    className="col-sm-1 ms-2"
                                    style={{ width: '55px' }}>
                                    <img
                                      src={Editquestion}
                                      className="edit-ques"
                                      alt="edit icon NP"
                                      onClick={() =>
                                        handleEditClick(
                                          index,
                                          values.customers[index].customerId,
                                          values.customers[index].hour,
                                        )
                                      }
                                      style={{
                                        marginRight: '2px',
                                        width: '40px',
                                        height: '40px',
                                      }}
                                    />
                                  </div>
                                  <div className="col-sm-1 px-0">
                                    <img
                                      src={Deletquestion}
                                      className="edit-ques"
                                      alt="edit icon NP"
                                      onClick={() =>
                                        deleteCustomerHoursPop(data.id)
                                      }
                                      style={{
                                        // marginRight: '2px',
                                        width: '40px',
                                        height: '40px',
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <Button
                                  className="col-md-2 ms-3"
                                  style={{
                                    float: 'right',
                                    backgroundColor: '#1334B9',
                                    height: '55px',
                                    width: '100px',
                                  }}
                                  onClick={() => {
                                    updateCustomerHours(
                                      index,
                                      values.customers[index].id,
                                    );
                                  }}>
                                  Save &nbsp;&nbsp;
                                  <Image src={Save}></Image>
                                </Button>
                              )}
                            </div>
                          </>
                        ))}
                    </>
                  )}
                  {!props.readOnly &&
                    showAddCustomer &&
                    values.multiple_customers === 'true' && (
                      <div
                        className="add-choice my-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setAddCustomer(false)}>
                        <img src={addques} className="add-choice-img" />
                        <span className="add-choice-txt">
                          Add Customer and hours
                        </span>
                      </div>
                    )}

                  {!props.readOnly && !showAddCustomer && (
                    <>
                      <div className="sec-row">Add Customer:</div>

                      <div className="row">
                        <Field
                          as="select"
                          name="customer"
                          className="form-select w-25 col-md-3 ms-2">
                          <option value="">Select Customer</option>
                          {customersFetched
                            .filter(
                              (customer) =>
                                !values.customers.some(
                                  (data) => data.customerId === customer.id,
                                ),
                            )
                            .map((customer) => (
                              <option value={customer.id}>
                                {customer.company}
                              </option>
                            ))}
                        </Field>

                        <Form.Control
                          type="number"
                          min="0"
                          name="customerHours"
                          value={values.customerHours}
                          onBlur={handleBlur}
                          // placeholder='customer hours'
                          // className={props.readOnly ? 'grey-input ' : ''}
                          // readOnly={props.readOnly}
                          className="col-md-1"
                          style={{ width: '80px', marginLeft: '15px' }}
                          isInvalid={
                            touched.customerHours && errors.customerHours
                          }
                          onChange={handleChange}
                        />

                        <Button
                          className="col-md-2"
                          // disabled={choicedisabled}
                          style={{
                            float: 'right',
                            backgroundColor: '#1334B9',
                            height: '55px',
                            width: '100px',
                            marginLeft: '30px',
                          }}
                          onClick={() => {
                            addCustomerHours(
                              values.customerHours,
                              values.customer,
                            );
                          }}>
                          Save &nbsp;&nbsp;
                          <Image src={Save}></Image>
                        </Button>
                      </div>
                    </>
                  )}
                  {/* {values.multiple_customers == 'true' && ( */}
                  <>
                    <div className="sec-row">Other Customer</div>
                    <div className="">
                      <Form.Control
                        type="text"
                        name="other_customer"
                        value={values.other_customer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={props.readOnly ? 'grey-input w-25' : 'w-25'}
                        readOnly={props.readOnly}
                        isInvalid={
                          touched.other_customer && errors.other_customer
                        }
                      />
                    </div>
                  </>
                  {/* )} */}
                </div>
              </Row>
              {/* )} */}

              {questionMapping.passengers && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'passengers',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="passengers"
                        className={`w-25 ${
                          touched.passengers && errors.passengers
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <textarea
                          rows="2"
                          name="passengers"
                          value={values.passengers}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            props.readOnly
                              ? 'form-control overflow-auto grey-input'
                              : 'form-control overflow-auto'
                          }
                          readOnly={props.readOnly}
                          isInvalid={touched.passengers && errors.passengers}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.base && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'base',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="base"
                        className={`w-25 ${
                          touched.base && errors.base
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="text"
                          name="base"
                          value={values.base}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input' : ''}
                          readOnly={props.readOnly}
                          isInvalid={touched.base && errors.base}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              <Row
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: '15px 20px',
                }}
                className="questions-row">
                <div className="child-row-1">
                  <div className="sec-row">Flight Time</div>
                  <div className="row">
                    <div className="row-1 w-50">
                      <div className="sec-row">Start Time</div>
                      {props.readOnly ? (
                        <>
                          <input
                            type="text"
                            disabled="disabled"
                            value={values.collective_start_time}
                            className="grey-input mt-2"
                          />
                        </>
                      ) : (
                        <>
                          <Form.Control
                            className=""
                            type="number"
                            step="0.01"
                            name="collective_start_time"
                            id="collective_start_time"
                            value={values.collective_start_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </>
                      )}
                    </div>
                    <div className="row-1 w-50">
                      <div className="sec-row">End Time</div>
                      {props.readOnly ? (
                        <>
                          <input
                            type="text"
                            disabled="disabled"
                            value={values.collective_end_time}
                            className="grey-input mt-2"
                          />
                        </>
                      ) : (
                        <>
                          <Form.Control
                            className=""
                            type="number"
                            step="0.01"
                            name="collective_end_time"
                            id="collective_end_time"
                            value={values.collective_end_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min={
                              values.collective_start_time
                                ? values.collective_start_time + 0.01
                                : null
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Row>

              {questionMapping.hour_meter && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div
                      className={
                        props.reportDetails.hour_meter_photo
                          ? 'cursor-pointer sec-row'
                          : 'sec-row'
                      }>
                      {
                        questions.find(
                          (question) => question.mapping.key === 'hour_meter',
                        ).label
                      }
                      {props.readOnly ? (
                        <img
                          src={
                            props.reportDetails.hour_meter_photo
                              ? props.reportDetails.hour_meter_photo
                              : defaultImage
                          }
                          // src={sampledefault}
                          onClick={() => {
                            if (props.reportDetails.hour_meter_photo) {
                              props.setimageData(
                                props.reportDetails.hour_meter_photo,
                              );
                              props.setshowViewImage(true);
                            }
                          }}
                          className="hour-photo"
                          alt="image NP"
                          style={{ marginLeft: '10px' }}
                        />
                      ) : (
                        <>
                          {!selHourmeter && (
                            <img
                              src={values.hour_meter_photo || defaultImage}
                              className="hour-photo"
                              alt="image NP"
                              style={{ marginLeft: '10px' }}
                            />
                          )}
                          {selHourmeter && (
                            <>
                              {/* <span>&nbsp;&rarr;</span> */}
                              <img
                                src={URL.createObjectURL(selHourmeter)}
                                className="hour-photo"
                                style={{ marginLeft: '10px' }}
                              />
                            </>
                          )}
                          <Form.Control
                            type="file"
                            name="hour_meter_photo"
                            className="w-50 mx-4"
                            accept="image/*"
                            onChange={(e) => {
                              setSelHourmeter(e.target.files[0]);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.utility_gear && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'utility_gear',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="returning_gear"
                        className={`w-25 ${
                          touched.returning_gear && errors.returning_gear
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        {props.readOnly ? (
                          <>
                            <input
                              type="text"
                              disabled="disabled"
                              value={
                                values.returning_gear == 'true' ? 'Yes' : 'No'
                              }
                              className="grey-input"
                            />
                          </>
                        ) : (
                          <>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="returning_gear"
                                id="returning_gear"
                                value="true"
                                checked={values.returning_gear == 'true'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="returning_gear"
                                id="returning_gear"
                                value="false"
                                checked={values.returning_gear == 'false'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2">
                                No
                              </label>
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </div>
                    {values.returning_gear == 'true' && (
                      <>
                        <div className="sec-row">Items</div>
                        <div className="">
                          <Form.Group
                            controlId="items"
                            className={`w-25 ${
                              touched.items && errors.items
                                ? 'has-validation-error'
                                : ''
                            }`}>
                            <textarea
                              rows="2"
                              name="items"
                              value={values.items}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                props.readOnly
                                  ? 'form-control overflow-auto grey-input'
                                  : 'form-control overflow-auto'
                              }
                              readOnly={props.readOnly}
                              isInvalid={touched.items && errors.items}
                            />
                          </Form.Group>
                        </div>
                      </>
                    )}

                    {/* {dataFetched.returning_gear === true && ( */}

                    {/* )} */}
                  </div>
                </Row>
              )}

              {questionMapping.maintenance_logbook && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div
                      className={
                        props.reportDetails.maintenance_logbook_photo
                          ? 'cursor-pointer sec-row'
                          : 'sec-row'
                      }>
                      {
                        questions.find(
                          (question) =>
                            question.mapping.key === 'maintenance_logbook',
                        ).label
                      }
                      {props.readOnly ? (
                        <>
                          <img
                            src={
                              props.reportDetails.maintenance_logbook_photo ||
                              defaultImage
                            }
                            onClick={() => {
                              if (
                                props.reportDetails.maintenance_logbook_photo
                              ) {
                                props.setimageData(
                                  props.reportDetails.maintenance_logbook_photo,
                                );
                                props.setshowViewImage(true);
                              }
                            }}
                            className="hour-photo"
                            alt="image NP"
                            style={{ marginLeft: '10px' }}
                          />
                        </>
                      ) : (
                        <>
                          {!selLogbook && (
                            <img
                              src={
                                values.maintenance_logbook_photo || defaultImage
                              }
                              className="hour-photo"
                              alt="image NP"
                              style={{ marginLeft: '10px' }}
                            />
                          )}
                          {selLogbook && (
                            <>
                              {/* <span>&nbsp;&rarr;</span> */}
                              <img
                                src={URL.createObjectURL(selLogbook)}
                                className="hour-photo"
                                style={{ marginLeft: '10px' }}
                              />
                            </>
                          )}
                          <Form.Control
                            type="file"
                            name="maintenance_logbook_photo"
                            className="w-50 mx-4"
                            accept="image/*"
                            onChange={(e) => {
                              setSelLogbook(e.target.files[0]);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.maintenance && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'maintenance',
                        ).label
                      }
                    </div>
                    <div className="">
                      <>
                        <Form.Group
                          controlId="upcoming_maintenance"
                          className={`w-25 ${
                            touched.did_fly && errors.did_fly
                              ? 'has-validation-error'
                              : ''
                          }`}>
                          {props.readOnly ? (
                            <>
                              {props.reportDetails.upcoming_maintenance && (
                                <input
                                  type="text"
                                  disabled="disabled"
                                  value={
                                    props.reportDetails.upcoming_maintenance
                                      .option
                                  }
                                  className="grey-input"
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {!props.readOnly &&
                                maintenanceOptions.map((opt) => (
                                  <div className="mx-2">
                                    <input
                                      type="radio"
                                      name="upcoming_maintenance"
                                      id="upcoming_maintenance"
                                      value={opt.id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      checked={
                                        values.upcoming_maintenance == opt.id
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <label
                                      className="form-check-label"
                                      key={opt.id}>
                                      {opt.option}
                                    </label>
                                  </div>
                                ))}
                            </>
                          )}
                        </Form.Group>
                      </>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.issue_address && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) =>
                            question.mapping.key === 'issue_address',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="issue_addressed"
                        className={`w-25 ${
                          touched.issue_addressed && errors.issue_addressed
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        {props.readOnly ? (
                          <>
                            <input
                              type="text"
                              disabled="disabled"
                              value={
                                values.issue_addressed === 'true' ? 'Yes' : 'No'
                              }
                              className="grey-input"
                            />
                          </>
                        ) : (
                          <>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="issue_addressed"
                                id="issue_addressed"
                                value="true"
                                checked={values.issue_addressed == 'true'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="issue_addressed"
                                id="issue_addressed"
                                value="false"
                                checked={values.issue_addressed == 'false'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2">
                                No
                              </label>
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </div>

                    <>
                      {values.issue_addressed == 'true' && (
                        <>
                          <div className="sec-row">
                            Issue &nbsp;&nbsp;&nbsp;&nbsp;
                            <Form.Control
                              type="text"
                              name="issues"
                              value={values.issues}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={props.readOnly ? 'grey-input' : ''}
                              readOnly={props.readOnly}
                              isInvalid={touched.issues && errors.issues}
                            />
                          </div>

                          <div
                            className={
                              props.reportDetails.issue_img_1
                                ? 'cursor-pointer sec-row'
                                : 'sec-row'
                            }>
                            <Row>
                              <Col className="col-sm-2">Image</Col>
                              <Col>
                                {!props.readOnly ? (
                                  <div className="row">
                                    <div className="col-md-6">
                                      {!selIssueImg1 && (
                                        <img
                                          src={
                                            values.issue_img_1 || defaultImage
                                          }
                                          className="hour-photo"
                                          alt="image NP"
                                          style={{ marginLeft: '10px' }}
                                        />
                                      )}
                                      {selIssueImg1 && (
                                        <>
                                          {/* <span>&nbsp;&rarr;</span> */}
                                          <img
                                            src={URL.createObjectURL(
                                              selIssueImg1,
                                            )}
                                            className="hour-photo"
                                            style={{ marginLeft: '10px' }}
                                          />
                                        </>
                                      )}
                                      <Form.Control
                                        type="file"
                                        name="issue_img_1"
                                        className="my-2"
                                        accept="image/*"
                                        onChange={(e) => {
                                          setSelIssueImg1(e.target.files[0]);
                                        }}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      {!selIssueImg2 && (
                                        <img
                                          src={
                                            values.issue_img_2 || defaultImage
                                          }
                                          className="hour-photo"
                                          alt="image NP"
                                          style={{ marginLeft: '10px' }}
                                        />
                                      )}
                                      {selIssueImg2 && (
                                        <>
                                          {/* <span>&nbsp;&rarr;</span> */}
                                          <img
                                            src={URL.createObjectURL(
                                              selIssueImg2,
                                            )}
                                            className="hour-photo"
                                            style={{ marginLeft: '10px' }}
                                          />
                                        </>
                                      )}
                                      <Form.Control
                                        type="file"
                                        name="issue_img_2"
                                        className="my-2"
                                        accept="image/*"
                                        onChange={(e) => {
                                          setSelIssueImg2(e.target.files[0]);
                                        }}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      {!selIssueImg3 && (
                                        <img
                                          src={
                                            values.issue_img_3 || defaultImage
                                          }
                                          className="hour-photo"
                                          alt="image NP"
                                          style={{ marginLeft: '10px' }}
                                        />
                                      )}
                                      {selIssueImg3 && (
                                        <>
                                          {/* <span>&nbsp;&rarr;</span> */}
                                          <img
                                            src={URL.createObjectURL(
                                              selIssueImg3,
                                            )}
                                            className="hour-photo"
                                            style={{ marginLeft: '10px' }}
                                          />
                                        </>
                                      )}
                                      <Form.Control
                                        type="file"
                                        name="issue_img_3"
                                        className="my-2"
                                        accept="image/*"
                                        onChange={(e) => {
                                          setSelIssueImg3(e.target.files[0]);
                                        }}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      {!selIssueImg4 && (
                                        <img
                                          src={
                                            values.issue_img_4 || defaultImage
                                          }
                                          className="hour-photo"
                                          alt="image NP"
                                          style={{ marginLeft: '10px' }}
                                        />
                                      )}
                                      {selIssueImg4 && (
                                        <>
                                          {/* <span>&nbsp;&rarr;</span> */}
                                          <img
                                            src={URL.createObjectURL(
                                              selIssueImg4,
                                            )}
                                            className="hour-photo"
                                            style={{ marginLeft: '10px' }}
                                          />
                                        </>
                                      )}
                                      <Form.Control
                                        type="file"
                                        name="issue_img_4"
                                        className="my-2"
                                        accept="image/*"
                                        onChange={(e) => {
                                          setSelIssueImg4(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <Row>
                                      <img
                                        src={
                                          props.reportDetails.issue_img_1 ||
                                          defaultImage
                                        }
                                        onClick={() => {
                                          if (props.reportDetails.issue_img_1) {
                                            props.setimageData(
                                              values.selIssueImg1 instanceof
                                                File
                                                ? values.selIssueImg1
                                                : props.reportDetails
                                                    .issue_img_1,
                                            );
                                            props.setshowViewImage(true);
                                          }
                                        }}
                                        // src={samplephoto}
                                        className={
                                          props.reportDetails.hour_meter_photo
                                            ? 'cursor-pointer hour-photo'
                                            : 'hour-photo'
                                        }
                                        alt="image NP"
                                        style={{ marginLeft: '10px' }}
                                      />
                                      <img
                                        src={
                                          props.reportDetails.issue_img_2 ||
                                          defaultImage
                                        }
                                        onClick={() => {
                                          if (props.reportDetails.issue_img_2) {
                                            props.setimageData(
                                              props.reportDetails.issue_img_2,
                                            );
                                            props.setshowViewImage(true);
                                          }
                                        }}
                                        className={
                                          props.reportDetails.issue_img_3
                                            ? 'cursor-pointer hour-photo'
                                            : 'hour-photo'
                                        }
                                        alt="image NP"
                                        style={{ marginLeft: '10px' }}
                                      />
                                      <img
                                        src={
                                          props.reportDetails.issue_img_3 ||
                                          defaultImage
                                        }
                                        onClick={() => {
                                          if (props.reportDetails.issue_img_3) {
                                            props.setimageData(
                                              props.reportDetails.issue_img_3,
                                            );
                                            props.setshowViewImage(true);
                                          }
                                        }}
                                        className={
                                          props.reportDetails.issue_img_4
                                            ? 'cursor-pointer hour-photo'
                                            : 'hour-photo'
                                        }
                                        alt="image NP"
                                        style={{ marginLeft: '10px' }}
                                      />
                                      <img
                                        src={
                                          props.reportDetails.issue_img_4 ||
                                          defaultImage
                                        }
                                        onClick={() => {
                                          if (props.reportDetails.issue_img_4) {
                                            props.setimageData(
                                              props.reportDetails.issue_img_4,
                                            );
                                            props.setshowViewImage(true);
                                          }
                                        }}
                                        className="hour-photo"
                                        alt="image NP"
                                        style={{ marginLeft: '10px' }}
                                      />
                                    </Row>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </Row>
              )}
              <Row
                style={{
                  backgroundColor: '#FFFFFF',
                  padding: '15px 20px',
                }}
                className="questions-row">
                <div className="child-row-1">
                  <div className="sec-row">Duty Time</div>
                  <div className="row">
                    <div className="row-1 w-50">
                      <div className="sec-row">Duty Day Start</div>
                      {props.readOnly ? (
                        <>
                          <input
                            type="text"
                            disabled="disabled"
                            value={convertUtcDateTimeToLocal(
                              values.day_start_date,
                            )}
                            className="grey-input mt-2"
                          />
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col>
                              <Form.Control
                                className=""
                                type="date"
                                name="day_start_date"
                                id="day_start_date"
                                value={
                                  values.day_start_date
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className=""
                                type="time"
                                name="day_start_time"
                                id="day_start_time"
                                value={values.day_start_time}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                    <div className="row-1 w-50">
                      <div className="sec-row">Duty day End</div>
                      {props.readOnly ? (
                        <>
                          <input
                            type="text"
                            disabled="disabled"
                            value={convertUtcDateTimeToLocal(values.day_end)}
                            className="grey-input mt-2"
                          />
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col>
                              <Form.Control
                                className=""
                                type="date"
                                name="day_end_date"
                                id="day_end_date"
                                value={values.day_end_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className=""
                                type="time"
                                name="day_end_time"
                                id="day_end_time"
                                value={values.day_end_time}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Row>

              {questionMapping.total_hours && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'total_hours',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="total_hours"
                        className={`w-25 ${
                          touched.total_hours && errors.total_hours
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          min="0"
                          name="total_hours"
                          value={values.total_hours}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={touched.total_hours && errors.total_hours}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.aht && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'aht',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="AHT"
                        className={`w-25 ${
                          touched.AHT && errors.AHT
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        {props.readOnly ? (
                          <>
                            <input
                              type="text"
                              disabled="disabled"
                              value={values.AHT === 'true' ? 'Yes' : 'No'}
                              className="grey-input"
                            />
                          </>
                        ) : (
                          <>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="AHT"
                                id="AHT"
                                value="true"
                                checked={values.AHT == 'true'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="AHT"
                                id="AHT"
                                value="false"
                                checked={values.AHT == 'false'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2">
                                No
                              </label>
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.other_base && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'other_base',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="other_Base"
                        className={`w-25 ${
                          touched.other_Base && errors.other_Base
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="text"
                          name="other_Base"
                          value={values.other_Base}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input' : ''}
                          readOnly={props.readOnly}
                          isInvalid={touched.other_Base && errors.other_Base}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.fuel && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'fuel',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="fuel"
                        className={`w-25 ${
                          touched.fuel && errors.fuel
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          step="0.1"
                          name="fuel"
                          value={values.fuel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={touched.fuel && errors.fuel}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.rev_time && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'rev_time',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="rev_time"
                        className={`w-25 ${
                          touched.rev_time && errors.rev_time
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          step="0.0001"
                          name="rev_time"
                          value={values.rev_time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={touched.rev_time && errors.rev_time}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.acctt && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'acctt',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="ACTT_total"
                        className={`w-25 ${
                          touched.ACTT_total && errors.ACTT_total
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          step="0.1"
                          name="ACTT_total"
                          value={values.ACTT_total}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={touched.ACTT_total && errors.ACTT_total}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.anc_landing && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'anc_landing',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="landings_at_ANC"
                        className={`w-25 ${
                          touched.landings_at_ANC && errors.landings_at_ANC
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          name="landings_at_ANC"
                          value={values.landings_at_ANC}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={
                            touched.landings_at_ANC && errors.landings_at_ANC
                          }
                          onChange={(e) => {
                            if (e.target.value.includes('.')) {
                              e.target.value = e.target.value.slice(0, -1);
                            }
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.pamr_landing && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'pamr_landing',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="landings_at_PAMR"
                        className={`w-25 ${
                          touched.landings_at_PAMR && errors.landings_at_PAMR
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          name="landings_at_PAMR"
                          value={values.landings_at_PAMR}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={
                            touched.landings_at_PAMR && errors.landings_at_PAMR
                          }
                          onChange={(e) => {
                            if (e.target.value.includes('.')) {
                              e.target.value = e.target.value.slice(0, -1);
                            }
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.day_landing && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'day_landing',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="day_landings"
                        className={`w-25 ${
                          touched.day_landings && errors.day_landings
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          name="day_landings"
                          value={values.day_landings}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={
                            touched.day_landings && errors.day_landings
                          }
                          onChange={(e) => {
                            if (e.target.value.includes('.')) {
                              e.target.value = e.target.value.slice(0, -1);
                            }
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.night_landing && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) =>
                            question.mapping.key === 'night_landing',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="night_landings"
                        className={`w-25 ${
                          touched.night_landings && errors.night_landings
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="number"
                          name="night_landings"
                          value={values.night_landings}
                          onBlur={handleBlur}
                          className={props.readOnly ? 'grey-input ' : ''}
                          readOnly={props.readOnly}
                          isInvalid={
                            touched.night_landings && errors.night_landings
                          }
                          onChange={(e) => {
                            if (e.target.value.includes('.')) {
                              e.target.value = e.target.value.slice(0, -1);
                            }
                            handleChange(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.fly_hazmat && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'fly_hazmat',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="fly_hazmat"
                        className={`w-25 ${
                          touched.fly_hazmat && errors.fly_hazmat
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        {props.readOnly ? (
                          <>
                            <input
                              type="text"
                              disabled="disabled"
                              value={
                                values.fly_hazmat === 'true' ? 'Yes' : 'No'
                              }
                              className="grey-input"
                            />
                          </>
                        ) : (
                          <>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="fly_hazmat"
                                id="fly_hazmat"
                                value="true"
                                checked={values.fly_hazmat == 'true'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="fly_hazmat"
                                id="fly_hazmat"
                                value="false"
                                checked={values.fly_hazmat == 'false'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2">
                                No
                              </label>
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {questionMapping.power_check && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">
                      {
                        questions.find(
                          (question) => question.mapping.key === 'power_check',
                        ).label
                      }
                    </div>
                    <div className="">
                      <Form.Group
                        controlId="perform_power_check"
                        className={`w-25 ${
                          touched.perform_power_check &&
                          errors.perform_power_check
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        {props.readOnly ? (
                          <>
                            <input
                              type="text"
                              disabled="disabled"
                              value={
                                values.perform_power_check === 'true'
                                  ? 'Yes'
                                  : 'No'
                              }
                              className="grey-input"
                            />
                          </>
                        ) : (
                          <>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="perform_power_check"
                                id="perform_power_check"
                                value="true"
                                checked={values.perform_power_check == 'true'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault1">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="perform_power_check"
                                id="perform_power_check"
                                value="false"
                                checked={values.perform_power_check == 'false'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                className="form-check-label"
                                for="flexRadioDefault2">
                                No
                              </label>
                            </div>
                          </>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </Row>
              )}

              {customQuestions.map((question, index) => (
                <Row
                  key={question.id}
                  style={{
                    backgroundColor: '#FFFFFF',
                    padding: '15px 20px',
                  }}
                  className="questions-row">
                  <div className="child-row-1">
                    <div className="sec-row">{question.question}</div>

                    {question.type === 'I' && (
                      <>
                        <img
                          src={getImageSource(question.id, userInputs)}
                          alt="Selected Image"
                          className="hour-photo"
                          style={{
                            marginRight: '2px',
                          }}
                        />
                        {!props.readOnly && (
                          <input
                            type="file"
                            onChange={(e) =>
                              handleImageChange(question.id, e.target.files[0])
                            }
                            className="form-control"
                          />
                        )}
                      </>
                    )}
                    {question.type === 'C' && (
                      <select
                        onChange={(e) =>
                          handleChoiceChange(question.id, e.target.value)
                        }
                        value={
                          (
                            userInputs.find(
                              (input) => input.questionId === question.id,
                            ) || {}
                          ).choice || ''
                        }
                        className={`form-select w-25 col-md-3 ms-2 ${
                          editableIndex !== index && 'disabled'
                        }`}
                        disabled={props.readOnly}>
                        <option key="" value="">
                          Select an option
                        </option>
                        {question.question_choices.map((choice) => (
                          <option key={choice.id} value={choice.choice}>
                            {choice.choice}
                          </option>
                        ))}
                      </select>
                    )}
                    {question.type === 'T' && (
                      <input
                        type="text"
                        onChange={(e) =>
                          handleTextChange(question.id, e.target.value)
                        }
                        value={
                          (
                            userInputs.find(
                              (input) => input.questionId === question.id,
                            ) || {}
                          ).text || defaultImage
                        }
                        className={
                          props.readOnly
                            ? 'grey-input w-25'
                            : 'form-control w-25'
                        }
                        readOnly={props.readOnly}
                      />
                    )}
                  </div>
                </Row>
              ))}

              {props.showViewImage === true && (
                <ViewImage
                  show={props.showViewImage}
                  closeImagePopup={props.closeImagePopup}
                  imageData={props.imageData}
                />
              )}
              {showMultiCustomerCheck === true && (
                <MultiCustomerDeleteCheck
                  show={showMultiCustomerCheck}
                  handleDiscard={multiCustomerCheck}
                />
              )}

              {showCustomerDelete === true && (
                <DeleteCustomerPop
                  show={showCustomerDelete}
                  handleDiscard={handleCustomerDelete}
                />
              )}
              </div>}
            </form>
          )
        }
      </Formik>
      {props.showdiscard === true && (
                <DiscardChanges
                  show={props.showdiscard}
                  handleDiscard={props.handleDiscard}
                />
              )}
    </>
  );
});
