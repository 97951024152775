import React from 'react'
import { Button, Modal } from "react-bootstrap"
import { api_urls } from '../../constants/constants'
import { ApiRequests } from '../https/ApiRequests'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const DeleteModal = (props) => {
  function handleClose() {
    props.changeDeleteModal(false)
  }

  return (


    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className='modalTitle'>Delete Sub Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modalBody' >

        <h3 className='personalLabels'>Are you sure you want to delete this sub admin?</h3>


      </Modal.Body>
      <Modal.Footer>
        <Button className='backButton' onClick={handleClose}>Cancel</Button>

        <Button className='modalOkButton' onClick={async () => {
          let url = (api_urls.subAdminProfile + props.userid + "/")
          await ApiRequests(url, "DELETE");
          toast.success('Sub Admin Deleted Successfully');
          handleClose();
          props.clickdelete();
        }}>Delete Sub Admin</Button>
      </Modal.Footer>
    </Modal>
  )
}
