import React, { forwardRef, useEffect, useState, useMemo } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';
import { useNavigate, useParams } from 'react-router-dom';
import defaultProfile from '../../images/icons/default-profile.png';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';
import {formatDate} from '../../utils/util.js';
import {timezoneHandler} from '../../utils/timezoneHandler.js';
import Loader from '../Loader/index';
import { Button, Image } from 'react-bootstrap';
import selector from '../../images/icons/selector.png';
import ErrorMsg from '../ErrorMsg';
import './table.css';

export const Servicelog = forwardRef((props, ref) => {
  const [servicedata, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [noFound, setNoFound] = useState(false);
  const {id} = useParams();

  const navigate = useNavigate();

  const data = useMemo(() => servicedata, [servicedata]);
  const columns = useMemo(
    () => [
      {
        Header: 'Requested by',
        accessor: 'requested_by.name',
        sticky: 'left',
        disableSortBy: false,
      },
      {
        Header: 'Reported issues',
        accessor: 'reported_issues',
        sticky: 'left',
        disableSortBy: false,
      },
      {
        Header: 'Last Service',
        accessor: 'last_service',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ value }) => {
          return <span>{formatDate(value)}</span>
        },
      },
      {
        Header: 'Upcoming maintenance',
        accessor: 'upcoming_maintenance.option',
        sticky: 'left',
        disableSortBy: false,
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  useEffect(() => {
    fetchServiceLogs();
  }, []);

  const fetchServiceLogs = async () => {
    setLoading(true);
    try {
      const params = {
        aircraft_id: id || 1
      };

      const response = await ApiRequests(
        api_urls.serviceProfile,
        'GET',
        null,
        params,
      );
      
      let tempdata = response.data;
      if (tempdata.length > 0) {
        setLoading(false);
        setNoFound(false);
      } else if (tempdata.length == 0) {
        setNoFound(true);
        setLoading(false);
      }
      setProducts(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  return (
    <>
      <>
        {noFound === true ? (
          <ErrorMsg errormsg={'No Records Exist'} />
        ) : (
          <>
            {loading === true ? (
              <Loader loadingMsg="Data is Loading... Please Wait" />
            ) : (
              <>
                <div className="table-container">
                  <table {...getTableProps()} className="table-cls">
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}>
                              {column.render('Header')}
                              <span>
                                  &nbsp;
                                  {column.disableSortBy === false ? (
                                    <Image src={selector}></Image>
                                  ) : null}
                                </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {/* Add image and name in the first column */}
                            <td
                              className="img-name-cont"
                              style={{ cursor: 'pointer' }}>
                              {row.original.requested_by
                               ? (
                                <img
                                  src={row.original.requested_by.pilot_image}
                                  alt="Image"
                                  className="profile-img"
                                />
                              ) : (
                                <img
                                  src={defaultProfile}
                                  alt="Default Image"
                                  className="profile-img"
                                />
                              )}
                              {row.original.requested_by &&
                              <>
                              {
                                row.original.requested_by.name
                              }
                              </>}
                            </td>

                            {row.cells.map((cell, cellIndex) => {
                              // Skip rendering the name column again
                              if (cellIndex === 0) {
                                return null;
                              }
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {/* <div
                    className="paginationBox"
                  >
                    <div
                      style={{ float: 'left', gap: '5px' }}
                      className="d-flex align-items-center">
                      <label className="paginationText">
                        Number of rows&nbsp;
                      </label>
                      <select
                        style={{ float: 'right' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}>
                        {[10, 25, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className={`paginationButton ${
                          !canPreviousPage ? 'disabled' : ''
                        }`}>
                        Prev
                      </button>
                      {pageOptions.map((page, index) => (
                        <button
                          key={index}
                          onClick={() => gotoPage(index)}
                          className={`paginationButton ${
                            pageIndex === index ? 'activeButton' : ''
                          }`}>
                          {index + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className={`paginationButton ${
                          !canPreviousPage ? 'disabled' : ''
                        }`}>
                        Next
                      </button>
                    </div>
                  </div> */}
              </>
            )}
          </>
        )}
      </>
    </>
  );
});
