import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logo.png';
import logoText from '../../images/logoText.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import './style.css';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { GetUrlParameters } from '../../constants/GetUrlParameters';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const userCode = GetUrlParameters('code');

  const schema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
  });

  return (
    <Container fluid>
      <Row style={{ height: '100vh' , overflow: 'auto'}}>
        <Col style={{ height: '100%' }} xs={12} md={6} className='zeropadding'>
          <Image
            style={{ height: '100%', width: '100%'}}
            src={logo}
            fluid
            className="Logo-img"
          />
        </Col>
        <Col xs={12} md={6} className='success-cont'>
          <div>
            <Image src={logoText}></Image>
          </div>
          <div className="Auth-form-container">
            <Formik
              validationSchema={schema}
              initialValues={{ email: '' }}
              onSubmit={async (values) => {
                  let body = {
                    email: values.email
                  };
                  try {
                    const response = await ApiRequests(
                      api_urls.resetPassword,
                      'POST',
                      body,
                    );
                    if (response.status === 200) {
                      navigate('/reset-success');
                    }
                  } catch (error) {
                    toast.error(error.response.data.error);
                    console.error(
                      'There was an error!',
                      error.response.data.error,
                    );
                  }
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
                /* and other goodies */
              }) => (
                <form
                  className="Auth-form"
                  onSubmit={handleSubmit}
                  noValidate>
                  <div
                    className="Auth-form-content w-100"
                    style={{ marginTop: '-20px' }}>
                    <h3 className="resetpass-title">Reset your password</h3>
                    <p className="resetpass-subheading">
                      Enter your Email and we’ll send you a link to reset your
                      password
                    </p>
                    <Form.Group controlId="email">
                      <div
                        className={`form-floating   mb-3 ${
                          touched.email && errors.email
                            ? 'has-validation-error'
                            : ''
                        }`}>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email*"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && errors.email}
                        />
                        <label for="floatingInput">Email*</label>

                        {touched.email && errors.email ? null : (
                          <div className="input-group-append ">
                            <button className="btn" type="button">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21M16.5 19.7942C15.0801 20.614 13.5296 21.0029 12 21.0015"
                                  stroke="#2563EB"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="d-grid gap-2 mt-3">
                      <button
                        type="submit"
                        isValid={false}
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary "
                        style={{ width: '100%', backgroundColor: ' #1334B9' }}>
                        Send Link to Email
                      </button>
                      <div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
