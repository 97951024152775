import React, { useState } from 'react'
import { ApiRequests } from '../https/ApiRequests'
import { api_urls } from '../../constants/constants'
import { useEffect } from 'react'

export const PrivacyPolicy = () => {

  const [privacyPolicy, setPolicy] = useState();

  useEffect(() => {
    privacyPolicyAPI();
  }, [])

  const privacyPolicyAPI = async () => {
    try {
      const response = await fetch(api_urls.privacyPolicy, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const rawData = await response.json();
      setPolicy(rawData);
      console.log("Policy", rawData)
    } catch (error) {
      console.error('Error fetching privacy policy:', error);
    }
  }


  return (
    (privacyPolicy && privacyPolicy.length > 0 && privacyPolicy[0].body) &&

    <div 
      style={{ overflow: 'auto', maxHeight: '100vh', padding: '20px' }} 
      dangerouslySetInnerHTML={{ __html: privacyPolicy[0].body }}
    ></div>

  )
}
