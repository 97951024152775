import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const Logout = (props) => {
  function handleClose() {
    props.closeLogout(false);
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered>
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">Logout</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <h3 className="personalLabels">Are you sure you want to logout?</h3>
      </Modal.Body>
      <Modal.Footer>
        <Button className="backButton" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          className="modalOkButton"
          onClick={async () => {
            props.handleUserlogout();
          }}>
          Logout
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
