import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import './style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logo.png';
import logoText from '../../images/logoText.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  faEnvelope,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';

export const Auth = () => {
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });
  const userToken = sessionStorage.getItem('token');
  const checkUserToken = () => {
    const userToken = sessionStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      return navigate('/login');
    } else {
      const userType = sessionStorage.getItem('user_type');
      if (userType === 'organization') {
        return navigate('/dashboard/reports');
      }
      else if (userType === 'sub_admin') {
        return navigate('/dashboard/subadmin')
      }
      else {
        return navigate('/dashboard/organizations');
      }
    }
  };

  // useEffect(() => {
  //   checkUserToken();
  // }, []);
  return (
    <Container fluid>
      <Row style={{ height: '100vh', overflow: 'auto' }}>
        <Col style={{ height: '100%' }} xs={12} md={6} className="zeropadding">
          <Image
            style={{ height: '100%', width: '100%' }}
            src={logo}
            fluid
            className="Logo-img"
          />
        </Col>
        <Col xs={12} md={6}>
          <div className="Auth-form-container d-flex justify-content-center align-items-center w-100 h-100">
            <Formik
              validationSchema={schema}
              initialValues={{ email: '', password: '' }}
              onSubmit={async (values) => {
                let body = {
                  email: values.email,
                  password: values.password,
                };

                try {
                  const response = await ApiRequests(
                    api_urls.login,
                    'POST',
                    body,
                  );
                  if (response.status === 200) {
                    sessionStorage.setItem('token', response.data.token);
                    sessionStorage.setItem(
                      'user_type',
                      response.data.user.user_type,
                    );
                    sessionStorage.setItem(
                      'userimg',
                      response.data.user.organization_image || '',
                    );

                    sessionStorage.setItem(
                      'organization_id',
                      response.data.user.organization_profile_id,
                    );
                    if (
                      (response.data.user.first_login === true &&
                        response.data.user.user_type === 'super_admin') ||
                      (response.data.user.first_login === true &&
                        response.data.user.user_type === 'organization')
                    ) {
                      navigate('/update-password');
                    } else {
                      if (
                        response.data.user.user_type === 'super_admin'
                      ) {
                        sessionStorage.setItem(
                          'organization_id',
                          response.data.user.organization_profile_id,
                        );
                        navigate('/dashboard/organizations');
                      }
                      else if (
                        response.data.user.user_type === 'organization'
                      ) {
                        sessionStorage.setItem(
                          'organization_id',
                          response.data.user.organization_profile_id,
                        );
                        navigate('/dashboard/reports');
                      }
                      else if (response.data.user.user_type === 'sub_admin') {
                        sessionStorage.setItem(
                          'organization_id',
                          response.data.user.sub_admin_permissions.organization,
                        );
                        sessionStorage.setItem(
                          'aircraft_access',
                          response.data.user.sub_admin_permissions.aircraft_access,
                        );
                        sessionStorage.setItem(
                          'calender_access',
                          response.data.user.sub_admin_permissions.calender_access,
                        );
                        sessionStorage.setItem(
                          'customer_access',
                          response.data.user.sub_admin_permissions.customer_access,
                        );
                        sessionStorage.setItem(
                          'manage_form_access',
                          response.data.user.sub_admin_permissions.manage_form_access,
                        );
                        sessionStorage.setItem(
                          'memos_access',
                          response.data.user.sub_admin_permissions.memos_access,
                        );
                        sessionStorage.setItem(
                          'pilot_access',
                          response.data.user.sub_admin_permissions.pilot_access,
                        );
                        sessionStorage.setItem(
                          'service_log_access',
                          response.data.user.sub_admin_permissions.service_log_access,
                        );
                        sessionStorage.setItem(
                          'dashboard_access',
                          response.data.user.sub_admin_permissions.dashboard_access,
                        );
                        let pageName = '';
                        for (const prop in response.data.user.sub_admin_permissions) {
                          if (response.data.user.sub_admin_permissions.hasOwnProperty(prop) && prop.endsWith('_access') && response.data.user.sub_admin_permissions[prop] === true) {
                            pageName = prop.replace('_access', '');
                            break;
                          }
                        }
                        if (pageName === 'dashboard') {
                          navigate('/dashboard/reports');
                        }
                        else if (pageName === 'pilot') {
                          navigate('/dashboard/pilots')
                        }
                        else if (pageName === 'customer') {
                          navigate('/dashboard/customers')
                        }
                        else if (pageName === 'aircraft') {
                          navigate('/dashboard/aircrafts')
                        }
                        else if (pageName === 'service_log') {
                          navigate('/dashboard/servicelog')
                        }
                        else if (pageName === 'manage_form') {
                          navigate('/dashboard/manageform')
                        }
                        else if (pageName === 'calender') {
                          navigate('/dashboard/calendar')
                        }
                        else if (pageName === 'memos') {
                          navigate('/dashboard/memos')
                        }
                        else {
                          toast.error('Either sub admin is deleted or do not have an access to view the application');
                        }
                      }
                      else {
                        toast.error('User must be Super admin, sub admin or Organization');
                        return;
                      }
                    }
                  } else {
                    toast.error('User must be Super admin, sub admin or Organization');
                  }
                } catch (error) {
                  console.log('setErrorState', error);
                  if (
                    error.response &&
                    error.response.status === 400 &&
                    error.response.data.message
                  ) {
                    // show error message for incorrect credentials
                    // setErrorState(true);
                    toast.error(error.response.data.message);
                  } else if (
                    error.response &&
                    error.response.data.non_field_error.message
                  ) {
                    toast.error(error.response.data.non_field_error.message);
                  } else {
                    console.error('There was an error!', error);
                    toast.error('Error in user login');
                  }
                }
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
                /* and other goodies */
              }) => (
                <form
                  className="Auth-form w-100"
                  onSubmit={handleSubmit}
                  noValidate>
                  <div className="Auth-form-content w-100">
                    <h3 className="Auth-form-title">
                      <Image src={logoText}></Image>
                    </h3>

                    <Form.Group controlId="email">
                      <div
                        className={`form-floating   mb-3 ${touched.email && errors.email
                          ? 'has-validation-error'
                          : ''
                          }`}>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email*"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="w-100"
                          isInvalid={touched.email && errors.email}
                        />
                        <label for="floatingInput" className="greyLabel">
                          Email*
                        </label>

                        {touched.email && errors.email ? null : (
                          <div className="input-group-append ">
                            <button className="btn" type="button">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12ZM16 12V13.5C16 14.8807 17.1193 16 18.5 16C19.8807 16 21 14.8807 21 13.5V12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21M16.5 19.7942C15.0801 20.614 13.5296 21.0029 12 21.0015"
                                  stroke="#2563EB"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="password">
                      <div
                        className={`form-floating   mb-3 ${touched.password && errors.password
                          ? 'has-validation-error'
                          : ''
                          }`}>
                        <Form.Control
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.password && errors.password}
                        />
                        <label for="floatingInput" className="greyLabel">
                          Password*
                        </label>
                        {touched.password && errors.password ? null : (
                          <div className="input-group-append ">
                            <button
                              className="btn"
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}>
                              <FontAwesomeIcon
                                icon={showPassword ? faEye : faEyeSlash}
                                style={{ color: '#2563EB' }}
                              />
                            </button>
                          </div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="d-grid gap-2 mt-3">
                      <button
                        type="submit"
                        isValid={false}
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary "
                        style={{ width: '100%' }}
                      // onClick={checkUserToken}
                      >
                        Login
                      </button>
                      <div>
                        {errorState ? (
                          <p
                            className="form-floating   mb-3 .invalid-feedback"
                            style={{ color: 'red' }}>
                            Incorrect Username Or Password
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="login-footer">
                      <span className="footer-first">
                        Forgot your Login Details ?
                      </span>
                      <span
                        className="footer-sec cursor-pointer"
                        onClick={() => navigate('/reset-password')}>
                        {' '}
                        Get Help Signing In
                      </span>
                    </div>
                    <br></br>
                    <div className="login-footer">
                      <span className="footer-first">
                      If you require any assistance, please do not hesitate to
                      </span>
                      <br></br>
                      <span
                        className="footer-sec cursor-pointer"
                        onClick={() => navigate('/contact-us')}>
                        {' '}
                        Contact Us
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container >
  );
};
