import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dashboard } from '../components/Dashboard';
import Sidebar from '../components/Sidebar';
import { Pilots } from '../components/pilots/Pilots';
import Calendar from '../components/Calendar/CalendarPoc2';
import { Aircrafts } from '../components/aircrafts/Aircrafts';
import { Customer } from '../components/customers/Customer';
import { SubAdmin } from '../components/subAdmin/SubAdmin';
import { Servicelog } from '../components/servicelogs/Servicelog';
import { SuperAdminDashboard } from '../components/superAdmin/SuperAdminDashboard';
import { Reports } from '../components/reports/Reports.js';
import { Memos } from '../components/Memos/Memos.js';
import Manageform from '../components/manageform/Manageform';

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [superAdmin, setsuperAdmin] = useState(false);

  const checkUserToken = () => {
    const userToken = sessionStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      return navigate('/login');
    }
    setIsLoggedIn(true);
    if (sessionStorage.getItem('user_type') === 'super_admin') {
      setsuperAdmin(true);
      return;
    }
    if (
      sessionStorage.getItem('user_type') != '' ||
      sessionStorage.getItem('user_type') == 'organization' ||
      sessionStorage.getItem('user_type') == 'sub_admin'
    ) {
      setsuperAdmin(false);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  const renderComponent = () => {
    if (props.compName === 'Pilots') {
      return <Pilots />;
    } else if (props.compName === 'Calendar') {
      return <Calendar />;
    } else if (props.compName === 'Customer') {
      return <Customer />;
    } else if (props.compName === 'Subadmin') {
      return <SubAdmin />;
    } else if (props.compName === 'Aircrafts') {
      return <Aircrafts />;
    } else if (props.compName === 'reports') {
      return <Reports />;
    } else if (props.compName === 'servicelog') {
      return <Servicelog />;
    } else if (props.compName === 'memos') {
      return <Memos />;
    } else if (props.compName === 'manageform') {
      return <Manageform />;
    }
  };
  return (
    <>
      {isLoggedIn ? (
        superAdmin ? (
          <Sidebar>
            <SuperAdminDashboard />
          </Sidebar>
        ) : (
          <Sidebar>{renderComponent()}</Sidebar>
        )
      ) : (
        ''
      )}
    </>
  );
};
export default ProtectedRoute;
