import axios from "axios";

export async function ApiRequests(url, method, body,params) {
  try {
    let token=sessionStorage.getItem("token");
    let auth;
    if(token!=null)
    {
    auth="token "+token;
    }
    const options = {
      method: method,
      url: url,
      data: body,
      params: params,
      headers: {
        ...(auth !== "" && { Authorization: auth }
        ),

      }
    };

    const response = await axios(options);

    return response;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
}


