import React, { useEffect, useMemo, useState } from 'react';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';
import nodocument from '../../images/icons/nodocument.png';
import upload from '../../images/icons/upload.png';
import viewIcon from '../../images/icons/viewIcon.png';
import PdfVector from '../../images/icons/PdfVector.png';
import AlertCricle from '../../images/icons/alert-circle.png';
import CheckCricle from '../../images/icons/check-circle.png';
import { Button, Col, Image, Row } from 'react-bootstrap';
import selector from '../../images/icons/selector.png';
import { formatDate } from '../../utils/util.js';
import { timezoneHandler } from '../../utils/timezoneHandler.js';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import { UploadDocumentModal } from './UploadDocumentModal';
// import { ViewPdf } from "../../constants/ViewPdf"
import { useNavigate } from 'react-router';
import { ViewPdf } from '../../constants/ViewPdf';
import { GetUrlParameters } from '../../constants/GetUrlParameters';

export const PilotDocuments = (props) => {
  console.log('props inside PilotDocuments', props);
  const [dataFetched, setDataFetched] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  const [show, setShowModal] = useState(false);
  const [fileList, setFileList] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  // const [pageSize, setPageSize] = useState(1000);

  const uploadDocument = () => {
    setShowModal(true);
  };
  const changeModalState = (state) => {
    setShowModal(state);
  };
  const showUpdatedData = () => {
    setUpdateData(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = new URL(window.location.href);
        // const id = url.pathname.split('/').pop();
        const id = url.pathname.split('/')[3];
        let pilot_id = url.pathname.split('/')[4];
        let params = { user_id: pilot_id };
        const response = await ApiRequests(
          api_urls.pilotDocument,
          'GET',
          null,
          params,
        );
        setProducts(response.data);
        setTotalCount(response.data.count);
        setDataFetched(true);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (!props.create) {
      fetchData();
    }
  }, [dataFetched, show, updateData]);

  const data = useMemo(() => products, [products]);

  const columns = useMemo(
    () => [
      {
        Header: 'Document title',
        accessor: 'title',
        disableFilters: true,
        sticky: 'left',
        disableSortBy: false,
      },
      {
        Header: 'Uploaded date',
        accessor: 'uploaded_at',
        sticky: 'left',
        disableSortBy: false,
        Cell: ({ value }) => {
          return <span>{formatDate(value)}</span>;
        },
      },

      {
        Header: 'Expiry date',
        disableSortBy: false,
        accessor: 'expiration_date',
        Cell: ({ value }) => {
          return <span>{formatDate(value)}</span>;
        },
      },
      {
        Header: 'Action',
        disableSortBy: true,
        accessor: 'file',
        Cell: (props) => {
          return (
            <>
              <span>
                <Image
                  src={viewIcon}
                  onClick={() => {
                    window.open(props.row.values.file);
                  }}></Image>
              </span>
            </>
          );
        },
      },
    ],
    [],
  );

  const showfileList = (temp) => {
    setFileList(temp);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize = 1000,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, pageSize } = state;
  return (
    <>
      {products.length > 0 ? (
        <>
          <Row
            style={{
              display: 'flex',
              padding: '15px 0px',
              justifyContent: 'space-between',
            }}>
            <Col className="search-bar" style={{ float: 'left' }}>
              <span className="search-icon" style={{ left: '18px' }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                    stroke="#9CA3AF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.5 17.5L13.875 13.875"
                    stroke="#9CA3AF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setGlobalFilter(e.target.value);
                }}
                className="SearchLabel"
                style={{ width: '60%', paddingLeft: '35px' }}
                placeholder="Search Documents"
              />
            </Col>
            <Col>
              <>{
                props.rowclick == false &&
                <Button
                  style={{ float: 'right', backgroundColor: '#1334B9' }}
                  disabled={props.rowclick == true ? true : false}
                  onClick={() => {
                    if (!props.rowclick == true) {
                      uploadDocument();
                    }
                  }}>
                  Upload Document <Image src={upload}></Image>
                </Button>
              }</>
            </Col>
          </Row>
          <Row className="" style={{ padding: '15px' }}>
            <table {...getTableProps()} className="document-Table m-0">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="documentsTableHead"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}>
                        {column.render('Header')}
                        <span>
                          &nbsp;
                          {column.disableSortBy === false ? (
                            <Image src={selector}></Image>
                          ) : null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="doc-tr">
                      <td
                        className="pdf-name-cont"
                        style={{ cursor: 'pointer' }}
                        role="cell">
                        <Image src={PdfVector} className="pdf-img"></Image>
                        {row.original.title}
                      </td>
                      {row.cells.map((cell, cellIndex) => {
                        if (cellIndex === 0) {
                          return null;
                        }
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Row>
          <Row>
            <div
              className="paginationBox"
              style={{ borderTop: 'none', padding: '20px 7px 20px 20px' }}>
              <div
                style={{
                  float: 'left',
                  display: 'flex',
                  gap: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="d-flex align-items-center">
                <label className="paginationText">Number of rows&nbsp;</label>
                <select
                  style={{ float: 'right', marginTop: '5px' }}
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}>
                  {[10, 25, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ float: 'right' }}>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                    }`}>
                  Prev
                </button>
                {pageOptions.map((page, index) => (
                  <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    className={`paginationButton ${pageIndex === index ? 'activeButton' : ''
                      }`}>
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                    }`}>
                  Next
                </button>
              </div>
            </div>
          </Row>
        </>
      ) : props.create && fileList ? (
        <div style={{ textAlign: 'center', margin: '20px', padding: '10px' }}>
          <ul>
            <li key={props.selectedDocumentFile.file.path}>
              <Image src={PdfVector}></Image> &nbsp;
              {props.selectedDocumentFile.file.path}{' '}
              <Image src={CheckCricle} style={{ float: 'right' }}></Image>
            </li>
          </ul>
          <h5>
            <span onClick={uploadDocument}>Click Here</span> to change document
          </h5>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <Row
            style={{
              display: 'flex',
              padding: '15px 0px',
              justifyContent: 'flex-end',
            }}>
            <Col md={4} xs={4}>
              <>{props.rowclick == false || props.rowclick == undefined &&
                <Button
                  style={{ float: 'right', backgroundColor: '#1334B9' }}
                  disabled={props.rowclick == true ? true : false}
                  onClick={() => {
                    if (!props.rowclick == true) {
                      uploadDocument();
                    }
                  }}>
                  Upload Document <Image src={upload}></Image>
                </Button>
              }</>
            </Col>
          </Row>

          <>{props.rowclick == false || props.rowclick == undefined &&
            <div className="doc-upload-bottom">
              <Image src={nodocument}></Image>
              <h5
                className="no-doc-added w-25 mx-auto"
              // onClick={() => uploadDocument()}
              >
                Select a PDF File to upload Or drag and drop it here.
              </h5>
            </div>
          }</>
          {/* <>{props.rowclick == false && */}
            <Col>
              <>{props.rowclick == false &&
              <>
                <Button
                  style={{ float: 'right', backgroundColor: '#1334B9' }}
                  disabled={props.rowclick == true ? true : false}
                  onClick={() => {
                    if (!props.rowclick == true) {
                      uploadDocument();
                    }
                  }}>
                  Upload Document <Image src={upload}></Image>
                </Button>
                
                <div className="doc-upload-bottom">
              <Image src={nodocument}></Image>
              <h5
                className="no-doc-added w-25 mx-auto"
              // onClick={() => uploadDocument()}
              >
                Select a PDF File to upload Or drag and drop it here.
              </h5>
            </div>
            </>
              }</>
            </Col>
        </div>
      )}

      {show && (
        <UploadDocumentModal
          setShowMoal={setShowModal}
          show={show}
          changeModalState={changeModalState}
          create={props.create}
          storePilotDocument={props.storePilotDocument}
          showfileList={showfileList}
          showUpdatedData={showUpdatedData}
        />
      )}

      {/* {selectedFileUrl && <ViewPdf url={selectedFileUrl} />} */}
    </>
  );
};
