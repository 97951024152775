import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import logo from '../../images/logo.png';
import logoText from '../../images/logoText.png';
import { Formik } from 'formik';
import CheckCricle from '../../images/icons/check-circle-blue.png';
import { useNavigate } from 'react-router-dom';

export const ResetPasswordSuccessful = () => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <Row style={{ height: '100vh' , overflow: 'auto'}}>
        <Col style={{ height: '100%' }} xs={12} md={6} className="zeropadding">
          <Image
            style={{ height: '100%', width: '100%'}}
            src={logo}
            fluid
            className="Logo-img"
          />
        </Col>
        <Col xs={12} md={6} className='success-cont'>
          <div>
            <Image src={logoText}></Image>
          </div>
          <div className="Auth-form-container">
            <Formik>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
                /* and other goodies */
              }) => (
                <form
                  className="Auth-form"
                  onSubmit={handleSubmit}
                  noValidate>
                  <div
                    className="Auth-form-content"
                    style={{ marginTop: '-20px' }}>
                    <Image
                      className="Auth-form-title"
                      src={CheckCricle}></Image>
                    <h3 className="email-sent-heading">Email sent</h3>
                    <p className="resetpass-subheading">
                      Check your email and open the link we sent to continue
                    </p>
                    <div className="d-grid gap-2 mt-3">
                      <button
                        onClick={() => navigate('/login')}
                        isValid={false}
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary "
                        style={{ width: '100%', backgroundColor: ' #1334B9' }}>
                        Continue to Sign in
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
