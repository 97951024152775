import React, { useEffect } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import arrowleft from '../../images/icons/arrow-left.png';
import logo from '../../images/logo.png';
import logoText from '../../images/logoText.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import './contactUs.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls } from '../../constants/constants';

export const ContactUs = () => {

    const navigate = useNavigate()

    const schema = Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        description: Yup.string().required('Description is required'),
    });

    return (

        <Container fluid>

            <Row style={{ height: '100vh', overflow: 'auto' }}>
                <Col style={{ height: '100%' }} xs={12} md={6} className="zeropadding">
                    <Image
                        style={{ height: '100%', width: '100%' }}
                        src={logo}
                        fluid
                        className="Logo-img"
                    />
                </Col>


                <Col xs={12} md={6}>

                    <div className='form'>
                        <div className='d-flex justify-content-start align-items-start w-100 ms-2'>
                            <Button
                                style={{
                                    float: 'left',
                                    display: 'block',
                                    width: '110px',
                                    marginBottom: '10px'
                                }}
                                className="backButton"
                                onClick={() => {
                                    navigate("/");
                                }}>
                                <Image src={arrowleft}></Image> Back
                            </Button>
                        </div>
                        <div className="Auth-form-container d-flex justify-content-center align-items-center w-100">
                            <Formik
                                validationSchema={schema}
                                initialValues={{ fullName: '', email: '', description: '' }}
                                onSubmit={async (values, { resetForm }) => {
                                    let body = {
                                        name: values.fullName,
                                        email: values.email,
                                        message: values.description,
                                    };

                                    try {
                                        const response = await fetch(api_urls.contactUs, {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json',
                                            },
                                            body: JSON.stringify(body),
                                        });

                                        if (response.ok) {
                                            toast.success('Your query has been sent to admin');
                                            resetForm();
                                        } else {
                                            const errorData = await response.json();
                                            if (errorData.tail_number && errorData.tail_number.length > 0) {
                                                toast.error(errorData.tail_number[0]);
                                            } else {
                                                toast.error('Error sending query');
                                            }
                                        }
                                    } catch (error) {
                                        console.error('There was an error!', error);
                                        toast.error('Error sending query');
                                    }
                                }}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    isValid,
                                    dirty,
                                    /* and other goodies */
                                }) => (
                                    <form
                                        className="Auth-form w-100"
                                        onSubmit={handleSubmit}
                                        noValidate>
                                        <div className="Auth-form-content w-100">
                                            <h3 className="Auth-form-title">
                                                <Image src={logoText}></Image>
                                            </h3>
                                            <div className='form-title'>
                                                Contact Us
                                            </div>


                                            <Form.Group controlId="fullName">
                                                <div
                                                    className={`form-floating   mb-3 ${touched.fullName && errors.fullName
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        name="fullName"
                                                        placeholder="Full name*"
                                                        value={values.fullName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.fullName && errors.fullName}
                                                    />
                                                    <label for="floatingInput" className="greyLabel">
                                                        Full name*
                                                    </label>

                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="email">
                                                <div
                                                    className={`form-floating   mb-3 ${touched.email && errors.email
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email*"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.email && errors.email}
                                                    />
                                                    <label for="floatingInput" className="greyLabel">
                                                        Email*
                                                    </label>

                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="description">
                                                <div className={`form-floating mb-3 ${touched.description && errors.description ? 'has-validation-error' : ''}`}>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="description"
                                                        placeholder="Please let us know how we can assist you.*"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className="w-100"
                                                        isInvalid={touched.description && errors.description}
                                                        rows={3}
                                                        style={{ height: 'auto', minHeight: '100px' }}
                                                    />
                                                    <label htmlFor="floatingInput" className="greyLabelTop">
                                                        Please let us know how we can assist you.*
                                                    </label>
                                                </div>
                                            </Form.Group>


                                            <div className="d-grid gap-2 mt-3">
                                                <button
                                                    type="submit"
                                                    isValid={false}
                                                    className="btn btn-primary "
                                                    style={{ width: '100%' }}
                                                >
                                                    Submit Query
                                                </button>

                                            </div>

                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>


                </Col>
            </Row>
        </Container >

    )
}
