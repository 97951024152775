import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Col, Container, Image, NavItem, Row } from 'react-bootstrap';
import arrowleft from '../../images/icons/arrow-left.png';
import { useNavigate } from 'react-router-dom';
import Editquestion from '../../images/activeicons/Editquestion.svg';
import Deletquestion from '../../images/activeicons/Deletequestion.svg';
import SaveIcon from '../../images/activeicons/defaultImage.svg';
import Save from '../../images/icons/save.png';
import { DashboardHeader } from '../../constants/DashboardHeader';
import Sidebar from '../Sidebar';
import addques from '../../images/activeicons/addques.svg';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { EditQuestion } from './EditQuestion';
import Loader from '../Loader/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './manageform.css';

const RiskAssessment = () => {
  const navigate = useNavigate();
  const [choicedisabled, setChoicedisabled]= useState(false);
  const mediumstartRef = useRef(null);
  const mediumEndRef = useRef(null);
  const [getData, setData] = useState([]);
  const [totalScore, setTotalScore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noFound, setNoFound] = useState(false);
  const [quesArr, setQuesArr] = useState([]);
  const [showQuestionInput, setShowQuestionInput] = useState(false);
  const [showChoiceInput, setShowChoiceInput] = useState({});
  const [newquestion, setNewQuestion] = useState('');
  const [formId, setFormid] = useState(null);
  const [editableChoices, setEditableChoices] = useState([]);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [mediumStart, setMediumStart] = useState(null);
  const [mediumEnd, setMediumEnd] = useState(null);
  const [disablerisk, setdisablerisk] = useState(true);

  const handleRangeSave = async () => {
    const startValue = mediumstartRef.current.value;
    const endValue = mediumEndRef.current.value;

    // Validation check: mediumEnd should be greater than mediumStart
    if (parseInt(endValue) <= parseInt(startValue)) {
      // Display an error message or perform the desired action
      toast.error('Error: mediumEnd should be greater than mediumStart');
      return;
    } else if (parseInt(endValue) > parseInt(totalScore)) {
      // Display an error message or perform the desired action
      toast.error('Error: mediumEnd should be smaller than total score');
      return;
    }
    const body = {
      form_id: formId,
      medium_start: mediumstartRef.current.value,
      medium_end: mediumEndRef.current.value,
    };
    try {
      const response = await ApiRequests(
        api_urls.getAllquestions,
        'PATCH',
        body,
        null,
      );
      if (response) {
        toast.success('Medium risk updated successfully');
        setEditableChoices([]);
        setShowChoiceInput({});
        setdisablerisk(true);
        fetchData();
      }
    } catch (error) {
      toast.error('Error in Medium risk updation');
      setLoading(false);
    }
  };

  const handleAddQuestion = () => {
    setShowQuestionInput(!showQuestionInput);
    setNewQuestion('');
  };

  const handleAddChoice = (questionId) => {
    // setCurrentQuestion(question);
    setCurrentQuestionId(questionId);
    setShowChoiceInput((prevMap) => ({
      ...prevMap,
      [questionId]: !prevMap[questionId],
    }));
  };

  const handleAddQuesChange = (event) => {
    setNewQuestion(event.target.value);
  };

  const saveNewQuestion = async () => {
    try {
      let apiurl = `${api_urls.addEditQuestion}`;
      const body = {
        question: newquestion,
        form: formId,
      };
      const response = await ApiRequests(apiurl, 'POST', body, null);
      if (response) {
        toast.success('Question added successfully');

        // window.location.reload()
      }
      setShowQuestionInput(false);
      fetchData();
    } catch (error) {
      toast.error('Error in Question addition');
      setLoading(false);
    }
  };

  const QuestionItem = ({ item }) => {
    const [editQues, setEditQues] = useState(false);
    const [question, setQuestion] = useState(item.question);

    const handleQuesChange = (event) => {
      setQuestion(event.target.value);
    };

    const handleDelQuestion = async () => {
      try {
        let apiurl = `${api_urls.addEditQuestion}${item.id}/`;
        const body = {
          question: question,
        };
        const response = await ApiRequests(apiurl, 'DELETE', body, null);
        if (response) {
          toast.success('Question deleted successfully');
          fetchData();
        }
      } catch (error) {
        toast.error('Error in Question deletion');
        setLoading(false);
      }
    };

    const saveQuestion = async () => {
      setEditQues(false);
      setEditableChoices([]);
      try {
        let apiurl = `${api_urls.addEditQuestion}${item.id}/`;
        const body = {
          question: question,
        };
        const response = await ApiRequests(apiurl, 'PATCH', body, null);
        if (response) {
          toast.success('Question updated successfully');
          fetchData();
        }
      } catch (error) {
        toast.error('Error in Question updation');
        setLoading(false);
      }
    };

    return (
      <div className="question-border">
        <div className="question-head-cont d-flex justify-content-between align-items-center">
          <div className="question-head">Question</div>
          <div className="edit-del-ques">
            {editQues ? (
              <Button
                style={{
                  float: 'right',
                  backgroundColor: '#1334B9',
                  height: '50px',
                }}
                className="editPilot"
                onClick={() => saveQuestion()}>
                Save &nbsp;&nbsp;
                <Image src={Save}></Image>
              </Button>
            ) : (
              <>
                <img
                  src={Editquestion}
                  className="edit-ques"
                  alt="edit icon NP"
                  onClick={() => setEditQues(true)}
                />
                <img
                  src={Deletquestion}
                  className="edit-ques"
                  alt="edit icon NP"
                  onClick={() => handleDelQuestion()}
                />
              </>
            )}
          </div>
        </div>
        <div className="question-wrapper mt-2">
          <input
            type="text"
            className="form-control question-input"
            value={question}
            onChange={(e) => handleQuesChange(e)}
            disabled={!editQues}
          />
          {/* {item.choice.length > 0 && (
            <div className="question-head mt-4">Choices</div>
          )} */}
          {item.choice &&
            item.choice.map((choiceitem, index) => (
              <ChoiceItem
                questionId={item.id}
                key={choiceitem.id}
                choice={choiceitem}
                editable={editableChoices.includes(choiceitem.id)}
                onSave={handleSaveOption}
              />
            ))}
          <div className="">
            {showChoiceInput[item.id] ? (
              <ChoiceItem
                questionId={item.id}
                editable={true}
                // key={choiceitem.id}
                onSave={handleSaveOption}
              />
            ) : (
              <div
                className="add-choice mt-4"
                style={{ cursor: 'pointer' }}
                onClick={() => handleAddChoice(item.id)}>
                <img src={addques} className="add-choice-img" />
                <span className="add-choice-txt">Add Choice</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ChoiceItem = ({ choice, editable, onSave }) => {
    const answerRef = useRef(null);
    const riskCountRef = useRef(null);
    const [showdisabled, setshowdisabled]= useState(false);

    const handleEditOption = (choiceId) => {
      setEditableChoices([...editableChoices, choiceId]);
    };
    let flag = choice ? 'edit' : 'addnew';

    const handleSaveOption = () => {
      setshowdisabled(true);
      const updatedChoice = {
        id: choice ? choice.id : currentQuestionId,
        answer: answerRef.current.value,
        risk_count: riskCountRef.current.value,
        flag: flag,
      };
      if (updatedChoice.answer === '' || updatedChoice.risk_count === '') {
        toast.error('Error: Answer and risk count are required fields');
        return;
      }
      onSave(updatedChoice);
      setshowdisabled(false);
    };

    const handleDelChoice = async () => {
      try {
        let apiurl = `${api_urls.deleteChoice}${choice.id}/`;
        const response = await ApiRequests(apiurl, 'DELETE');
        if (response) {
          toast.success('Answer deleted successfully');
          fetchData();
        }
      } catch (error) {
        toast.error('Error in Answer deletion');
        setLoading(false);
      }
    };

    return (
      <div className="choice-wrraper" key={choice ? choice.id : null}>
        <div className="choice-option-wrapper mt-3">
          <div className="form-group w-25">
            <label className="choice-label">
              Answer
            </label>
            <input
              type="text"
              className="form-control question-input w-100"
              defaultValue={choice ? choice.answer : ''}
              disabled={!editable}
              ref={answerRef}
            />
          </div>

          <div className="form-group w-25">
            <label className="choice-label">
              Risk Count
            </label>
            <input
              type="number"
              className="form-control question-input w-100"
              defaultValue={choice ? choice.risk_count : null}
              disabled={!editable}
              ref={riskCountRef}
            />
          </div>
          {editable ? (
            <Button
              style={{
                float: 'right',
                backgroundColor: '#1334B9',
                height: '50px',
              }}
              disabled={choicedisabled}
              className="editPilot mt-4"
              onClick={handleSaveOption}>
              Save &nbsp;&nbsp;
              <Image src={Save}></Image>
            </Button>
          ) : (
            <>
              <img
                src={Editquestion}
                className="edit-ques mt-4"
                alt="edit icon NP"
                onClick={() => handleEditOption(choice ? choice.id : null)}
              />
              <img
                src={Deletquestion}
                className="edit-ques mt-4"
                alt="edit icon NP"
                onClick={handleDelChoice}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    const organization_id =
      sessionStorage.getItem('organization_id') === 'null'
        ? 2
        : sessionStorage.getItem('organization_id');
    try {
      const response = await ApiRequests(
        `${api_urls.getAllquestions}?organization_id=${organization_id}`,
        'GET',
        null,
      );
      let tempdata = response.data.form;
      let obj = [];
      if (tempdata.length > 0) {
        setLoading(false);
        setNoFound(false);
      } else if (tempdata.length == 0) {
        setNoFound(true);
        setLoading(false);
      }
      setData(tempdata);
      setTotalScore(response.data.total_score);
      setMediumStart(response.data.medium_start);
      setMediumEnd(response.data.medium_end);
      for (let i = 0; i < tempdata.length; i++) {
        obj.push({
          question: tempdata[i].question,
          choice: tempdata[i].answers,
          id: tempdata[i].id,
        });
      }

      setFormid(response.data.id);
      setQuesArr(obj);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveOption = async (choicedata) => {
    console.log('choicedata', choicedata);
    setChoicedisabled(true);
    let apiurl;
    let body;
    let req;
    if (choicedata.flag === 'edit') {
      apiurl = `${api_urls.addEditOption}${choicedata.id}/`;
      body = {
        answer: choicedata.answer,
        risk_count: choicedata.risk_count,
      };
      req = 'PATCH';
    } else {
      apiurl = `${api_urls.addEditOption}`;
      body = {
        answer: choicedata.answer,
        risk_count: choicedata.risk_count,
        question: choicedata.id,
      };
      req = 'POST';
    }
    try {
      const response = await ApiRequests(apiurl, req, body, null);
      if (response) {
        choicedata.flag === 'edit'
          ? toast.success('Answer updated successfully')
          : toast.success('Answer added successfully');
        setEditableChoices([]);
        setShowChoiceInput({});
        fetchData();
        setChoicedisabled(false);
      }
    } catch (error) {
      choicedata.flag === 'edit'
        ? toast.error('Error in Answer updation')
        : toast.error('Error in Answer addition');
      setLoading(false);
      setChoicedisabled(false);
    }
  };

  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name="Edit Risk assessment form" />
          <>
            {loading === true ? (
              <Loader loadingMsg="Data is Loading... Please Wait" />
            ) : (
              <div className="RiskAssessment-wrapper">
                <Row className="pilot-cont">
                  <Col md={1} xs={1}></Col>
                  <Col
                    style={{
                      marginTop: '20px',
                      paddingRight: '20px',
                      overflowY: 'auto',
                      paddingLeft: '0px'
                    }}
                    md={10}
                    xs={10}>
                    <Button
                      style={{
                        float: 'left',
                        display: 'block',
                      }}
                      className="backButton"
                      onClick={() => {
                        navigate(-1);
                      }}>
                      <Image src={arrowleft}></Image> Back
                    </Button>
                  </Col>
                </Row>

                <Row style={{ height: '80vh' }}>
                  <Col md={1} xs={1}></Col>
                  <Col
                    style={{
                      marginTop: '20px',
                      paddingRight: '20px',
                      overflowY: 'auto',
                      height: '80vh',
                      backgroundColor: 'white',
                      paddingBottom: '50px',
                      paddingLeft: '0px'
                    }}
                    md={10}
                    xs={10}>
                    <div className="risk-ass-body mt-3">
                      <div className="risk-add-head">Risk assessment form</div>
                    </div>

                    <div className="question-option-wrapper">
                      <>
                        {quesArr &&
                          quesArr.map((item, index) => (
                            <QuestionItem key={index} item={item} />
                          ))}
                      </>
                      <div className="add-new-ques-wrapper">
                        {showQuestionInput ? (
                          <div className="question-border w-100">
                            <div className="question-head-cont d-flex justify-content-between align-items-center">
                              <div className="question-head">Question</div>
                              <div className="edit-del-ques">
                                <Button
                                  style={{
                                    float: 'right',
                                    backgroundColor: '#1334B9',
                                    height: '50px',
                                  }}
                                  className="editPilot"
                                  onClick={() => saveNewQuestion()}>
                                  Save &nbsp;&nbsp;
                                  <Image src={Save}></Image>
                                </Button>
                              </div>
                            </div>
                            <div className="question-wrapper mt-2">
                              <input
                                type="text"
                                className="form-control question-input"
                                value={newquestion}
                                onChange={(e) => handleAddQuesChange(e)}
                              // disabled={!editQues}
                              />
                            </div>
                          </div>
                        ) : (
                          <Button
                            type="primary"
                            className="add-ques-btn d-flex align-items-center"
                            onClick={handleAddQuestion}
                            style={{ gap: '5px' }}>
                            Add question
                            <img
                              src={addques}
                              alt="Add question"
                              className="add-ques-icon"
                            />
                          </Button>
                        )}
                      </div>
                    </div>

                    <div className="question-border">
                      <div className="question-head-cont d-flex justify-content-between align-items-center">
                        <div class="question-head">Risk Counter</div>
                        <div className="risk-total">Total: {totalScore}</div>
                      </div>

                      <div className="risk-range mt-3">Medium risk range</div>
                      <div className="choice-option-wrapper mt-3">
                        <input
                          type="number"
                          className="form-control question-input w-25"
                          defaultValue={mediumStart}
                          disabled={disablerisk}
                          ref={mediumstartRef}
                        />
                        To
                        <input
                          type="number"
                          className="form-control question-input w-25"
                          defaultValue={mediumEnd}
                          disabled={disablerisk}
                          ref={mediumEndRef}
                        />
                        {!disablerisk ? (
                          <Button
                            style={{
                              float: 'right',
                              backgroundColor: '#1334B9',
                              height: '50px',
                            }}
                            className="editPilot"
                            onClick={handleRangeSave}>
                            Save &nbsp;&nbsp;
                            <Image src={Save}></Image>
                          </Button>
                        ) : (
                          <img
                            src={Editquestion}
                            className="edit-ques"
                            alt="edit icon NP"
                            onClick={() => {
                              setdisablerisk(!disablerisk);
                            }}
                          />
                        )}
                      </div>

                      <div className="show-risk-range mt-4">
                        <div className="low-risk-wrapper">
                          <span className="low-risk-range">
                            Low risk range :
                          </span>
                          <span className="low-risk-range-part2">
                            {` 0-${mediumStart - 1}`}
                          </span>
                        </div>

                        <div className="low-risk-wrapper mt-3">
                          <span className="low-risk-range">
                            High risk range :
                          </span>
                          <span className="low-risk-range-part2">
                            {` ${mediumEnd + 1}-${totalScore}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </>
        </Sidebar>
      </Container>
    </>
  );
};
export default RiskAssessment;
