import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Col, Container, Image, NavItem, Row } from 'react-bootstrap';
import arrowleft from '../../images/icons/arrow-left.png';
import { useNavigate } from 'react-router-dom';
import Editquestion from '../../images/activeicons/Editquestion.svg';
import Deletquestion from '../../images/activeicons/Deletequestion.svg';
import SaveIcon from '../../images/activeicons/defaultImage.svg';
import Save from '../../images/icons/save.png';
import { DashboardHeader } from '../../constants/DashboardHeader';
import Sidebar from '../Sidebar';

import addques from '../../images/activeicons/addques.svg';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { EditQuestion } from './EditQuestion';
import Loader from '../Loader/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './manageform.css';
import { ConfirmQuestionDelete } from './ConfirmQuestionDelete';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import CustomQuestions from './CustomQuestions';
import FixedQuestions from './FixedQuestions';

const DailyReport = () => {
  const navigate = useNavigate();
  const mediumstartRef = useRef(null);
  const mediumEndRef = useRef(null);

  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name="Edit Daily report form" />

          <Row className="pilot-cont">
            <Col md={1} xs={1}></Col>
            <Col
              style={{
                height: '80vh',
                marginTop: '20px',
                paddingRight: '20px',
                overflowY: 'auto',
              }}
              md={10}
              xs={10}>
              <Row>

                <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                  <Button
                    style={{
                      float: 'left',
                      display: 'block',
                    }}
                    className="backButton"
                    onClick={() => {
                      navigate(-1);
                    }}>
                    <Image src={arrowleft}></Image> Back
                  </Button>
                </Col>

                {/* <Col xs={8} md={8}></Col> */}

              </Row>


              <Row style={{ backgroundColor: 'white', marginTop: '10px' }}>
                <Tabs
                  defaultActiveKey="fixedQuestions"
                  id="uncontrolled-tab-example"
                  className="mt-3"
                >

                  <Tab
                    eventKey="fixedQuestions"
                    title="Fixed questions "
                    className="px-2"
                    style={{ backgroundColor: 'white' }}
                  >
                    <FixedQuestions />
                  </Tab>

                  <Tab
                    eventKey="customQuestions"
                    title="Custom questions "
                    className="px-2"
                    style={{ backgroundColor: 'white' }}
                  >
                    <CustomQuestions />
                  </Tab>

                </Tabs>
              </Row>

            </Col>
            <Col md={1} xs={1}></Col>
          </Row>

        </Sidebar>
      </Container>
    </>
  );
};
export default DailyReport;
