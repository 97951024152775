import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../Sidebar';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import sampledefault from '../../images/icons/sampledefault.jpg';
import samplephoto from '../../images/activeicons/samplephoto.svg';
import defaultImage from '../../images/activeicons/defaultImage.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { convertUtcDateTimeToLocal, extractDateFromLocalDateTime, extractTimeFromLocalDateTime, formatDate } from '../../utils/util.js';
import { timezoneHandler } from '../../utils/timezoneHandler.js';
import { DiscardChanges } from './/DiscardChanges';
import ViewImage from './ViewImage.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { api_urls, organization_id } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import arrowleft from '../../images/icons/arrow-left.png';
import Iconedit from '../../images/icons/Iconedit.png';
import Save from '../../images/icons/save.png';
import { GeneralDetails } from './GeneralDetails.js';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RiskAssessment } from './RiskAssessment.js';
import ErrorMsg from '../ErrorMsg';

export const ViewReport = () => {
  const navigate = useNavigate();
  const authRef = useRef(null);
  const [dataFetched, setDataFetched] = useState([]);
  const [showViewImage, setshowViewImage] = useState(false);
  const [imageData, setimageData] = useState(null);

  const location = useLocation();
  const [showdiscard, setdiscard] = useState(false);
  const [save, setSave] = useState(false);

  let editable = location.state.edit;
  let rowclick = location.state.rowclick;
  if (editable) {
    editable = false;
  } else {
    editable = true;
  }
  const [readOnly, setReadOnly] = useState(location.state.edit);
  const [editView, setEditView] = useState(JSON.parse(!editable));
  const [headerName, setHeaderName] = useState('Report Details');

  const closeImagePopup = () => {
    setshowViewImage(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url = new URL(window.location.href);
      const id = url.pathname.split('/').pop();

      let params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };
      let apiurl = api_urls.reportProfile + id + '/';
      const response = await ApiRequests(apiurl, 'GET', null, params);
      setDataFetched(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const handleDiscard = (flag) => {
    if (flag === true) {
      setdiscard(false);
      navigate('/dashboard/reports', { replace: true });
    } else {
      setdiscard(false);
    }
  };

  const cancelChanges = async () => {
    setdiscard(true);
  };

  const handleheader = (editClicked) => {
    if (editClicked === true) {
      setHeaderName('Edit Report');
      return;
    }
  };

  const editReport = () => {
    setReadOnly(false);
    setEditView(false);
    // setUploadImage(true);
    setSave(true);
    handleheader(true);
  };

  const savedSuccessfully = () => {
    setReadOnly(true);
    setEditView(false);
  };
  const saveChanges = () => {
    if (authRef && authRef.current) {
      authRef.current.handleSubmit();
    }
  };

  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name={headerName} />

          <Row className="pilot-cont">
            <Col md={1} xs={1}></Col>
            <Col
              style={{
                height: '80vh',
                marginTop: '20px',
                paddingRight: '20px',
                overflowY: 'auto',
              }}
              md={10}
              xs={10}>
              <Row>
                <>
                  {rowclick === true ? (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: 'block',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                    </Col>
                  ) : (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'block' : 'none',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'none' : 'block',
                        }}
                        className="backButton"
                        onClick={cancelChanges}>
                        Cancel
                      </Button>
                    </Col>
                  )}
                </>
                <Col xs={8} md={8}></Col>
                <Col xs={2} md={2} style={{ paddingRight: '0px' }}>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display:
                        editView && !rowclick ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={editReport}>
                    Edit Report &nbsp;&nbsp;
                    <Image src={Iconedit}></Image>
                  </Button>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display: save ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={saveChanges}
                  >
                    Save &nbsp;&nbsp;
                    <Image src={Save}></Image>
                  </Button>
                </Col>
              </Row>

              <Row
                style={{
                  marginTop: '20px',
                  borderRadius: '8px 8px 0px 0px',
                  backgroundColor: '#DBEAFE',
                  padding: '15px 20px',
                }}>
                <Col xs={3} md={3}>
                  <div className="top-row d-flex flex-column">
                    <div className="top-row-label">Appointment Title</div>
                    <>
                      {dataFetched.appointment && (
                        <div className="top-row-heading">
                          {dataFetched.appointment.title}
                        </div>
                      )}
                    </>
                  </div>
                </Col>

                <Col xs={3} md={3}>
                  <div className="top-row d-flex flex-column">
                    <div className="top-row-label">Appointment Date</div>
                    <>
                      {dataFetched.appointment && (
                        <div className="top-row-heading">
                          {/* {formatDate(dataFetched.appointment.time)} */}
                          {extractDateFromLocalDateTime(dataFetched.appointment.time)}
                        </div>
                      )}
                    </>
                  </div>
                </Col>

                <Col xs={3} md={3}>
                  <div className="top-row d-flex flex-column">
                    <div className="top-row-label">Pilot</div>
                    <>
                      {dataFetched.pilot && (
                        <div className="top-row-heading">
                          {dataFetched.pilot.name}
                        </div>
                      )}
                    </>
                  </div>
                </Col>

                <Col xs={3} md={3}>
                  <div className="top-row d-flex flex-column">
                    <div className="top-row-label">Aircraft</div>
                    <>
                      {dataFetched.aircraft && (
                        <div className="top-row-heading">
                          {dataFetched.aircraft.tail_number}
                        </div>
                      )}
                    </>
                  </div>
                </Col>
              </Row>
              <Row style={{ backgroundColor: 'white' }}>
                <Tabs
                  defaultActiveKey="generalDetails"
                  id="uncontrolled-tab-example"
                  className="mt-3">
                  <Tab
                    eventKey="generalDetails"
                    title="General Details "
                    className="px-2"
                    style={{ backgroundColor: 'white' }}
                  >
                    <GeneralDetails
                      ref={authRef}
                      readOnly={readOnly}
                      reportDetails={dataFetched}
                      editView={editView}
                      showdiscard={showdiscard}
                      handleDiscard={handleDiscard}
                      closeImagePopup={closeImagePopup}
                      showViewImage={showViewImage}
                      imageData={imageData}
                      setimageData={setimageData}
                      setshowViewImage={setshowViewImage}
                      savedSuccessfully={savedSuccessfully}
                    />
                  </Tab>

                  <Tab
                    eventKey="riskassessment"
                    title="Risk Assessment"
                    className="px-2"
                    style={{
                      backgroundColor: 'white', marginLeft: '0',
                      marginRight: '0'
                    }}>
                    {dataFetched.risk_assessment_result &&
                      <RiskAssessment
                        ref={authRef}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        riskAssesmentResult={dataFetched.risk_assessment_result.risk_assessment_result}
                        riskAssesmentsubmissions={dataFetched.risk_assessment_result.risk_assessment_submissions}
                        editView={editView}
                      />
                    }
                    {!dataFetched.risk_assessment_result &&
                      <ErrorMsg errormsg={'No Record Exist'} />
                    }
                  </Tab>



                </Tabs>
              </Row>

            </Col>
            <Col md={1} xs={1}></Col>
          </Row>
        </Sidebar>
      </Container>
      {/* {showViewImage === true && (
        <ViewImage
          show={showViewImage}
          closeImagePopup={closeImagePopup}
          imageData={imageData}
        />
      )} */}

      {/* {showdiscard === true && (
        <DiscardChanges show={showdiscard} handleDiscard={handleDiscard} />
      )} */}
    </>
  );
};
