import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';

export const DeleteQualificationPop = (props) => {
  function handleClose() {
    props.handleDiscard(false);
  }

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered>
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">Delete Qualification</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <h3 className="personalLabels">
          Are you sure you want to delete this Qualification?
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button className="backButton" onClick={handleClose}>
          Cancel
        </Button>

        <Button
          className="modalOkButton"
          handleDiscard
          onClick={() => {
            props.handleDiscard(true);
          }}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
