import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
import { DiscardChanges } from './/DiscardChanges';
import ViewImage from './ViewImage.jsx';
import defaultImage from '../../images/activeicons/defaultImage.svg';
import { actions } from 'react-table';


export const RiskAssessment = (props) => {
    const navigate = useNavigate();

    const handleReset = () => {
    };

    return (

        <form noValidate>
            <Row
                style={{
                    backgroundColor: '#FFFFFF',
                    padding: '30px 20px 20px 20px',
                }}
                className="questions-row">
                <div className="child-row-1">
                    <div className="sec-row">Assessment Result </div>
                    <div className="d-flex ">
                        <div className="m-2">
                            <div style={{
                                color: 'black',
                                fontWeight: 'normal'
                            }}>
                                {props.riskAssesmentResult.result_type}
                            </div>
                        </div>

                    </div>
                </div>
            </Row>
            {props.riskAssesmentsubmissions && props.riskAssesmentsubmissions.map((submission) => (
                <Row
                    style={{
                        backgroundColor: '#FFFFFF',
                        padding: '30px 20px 20px 20px',
                    }}
                    className="questions-row">
                    <div className="child-row-1">
                        <div className="sec-row">{submission.question.question} </div>
                        <div className="d-flex ">
                            <div className="">

                                {submission.question.answers.map((answer) => (
                                    <div className='row m-2'>
                                        <label key={answer.id} className='pb-1' style={{fontWeight: 'normal'}}>
                                            <input
                                                type="radio"
                                                name={`question_${submission.question.id}`}
                                                value={answer.answer}
                                                checked={answer.answer === submission.answer.answer}
                                                readOnly
                                                disabled
                                                className='me-2'
                                            />
                                            {answer.answer}
                                        </label>
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>
                </Row>
            ))}

        </form>
    )
};