import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../Sidebar';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import imageIcon from '../../images/icons/imageicon.png';
import { GeneralDetails } from './GeneralDetails';
import { DiscardChanges } from './DiscardChanges';
import { useLocation, useNavigate } from 'react-router-dom';
import Iconedit from '../../images/icons/Iconedit.png';
import Save from '../../images/icons/save.png';
import arrowleft from '../../images/icons/arrow-left.png';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import './userprofile.css';

export const Userprofile = (props) => {
  const navigate = useNavigate();
  const authRef = useRef(null);
  const location = useLocation();
  const [headerName, setHeaderName] = useState('');
  const [showdiscard, setdiscard] = useState(false);

  let editable = location.state.edit;
  let create = location.state.create;
  let rowclick = location.state.rowclick;

  if (editable) {
    editable = false;
  } else {
    editable = true;
  }
  const [readOnly, setReadOnly] = useState(location.state.edit);
  const [editView, setEditView] = useState(JSON.parse(!editable));
  const [uploadImage, setUploadImage] = useState(false);
  const [save, setSave] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [profileDetails, setprofileDetails] = useState({});
  const [pilotName, setPilotName] = useState('');
  const [pilotId, setPilotId] = useState(null);

  const handleDiscard = (flag) => {
    if (flag === true) {
      setdiscard(false);
    //   navigate('/dashboard/reports', { replace: true });
      navigate(-1, { replace: true });
    } else {
      setdiscard(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let organization_id =
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id');

        let apiurl = api_urls.Organization + organization_id + '/';
        const response = await ApiRequests(apiurl, 'GET', null);

        setprofileDetails(response.data);
        setPilotName(response.data.owner.name);
        setPilotId(response.data.owner.id);
        setDataFetched(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (create) {
    } else {
      fetchData();
    }
  }, [dataFetched, editView]);

  useEffect(() => {}, [pilotId]);

  useEffect(() => {
    handleheader();
  }, []);
  const handleheader = (editClicked) => {
    if (editClicked === true) {
      setHeaderName('My profile');
      return;
    }
    if (!create) {
      setHeaderName('My profile');
      return;
    } else {
      setHeaderName('My profile');
      return;
    }
  };
  const [selectedFile, setSelectedFile] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const editPilot = () => {
    setReadOnly(false);
    setEditView(false);
    setUploadImage(true);
    setSave(true);
    handleheader(true);
  };

  const saveChanges = () => {
    if (authRef && authRef.current) {
      authRef.current.handleSubmit();
    }
  };
  const cancelChanges = async () => {
    setdiscard(true);
  };

  const savedSuccessfully = () => {
    setReadOnly(true);
    setEditView(false);
  };

  return (
    <>
      <Container fluid style={{ overflowY: 'scroll !important' }}>
        <Sidebar>
          <DashboardHeader name={headerName} />
          <Row className="pilot-cont">
            <Col md={1} xs={1}></Col>
            <Col
              style={{
                height: '80vh',
                marginTop: '20px',
                paddingRight: '20px',
                overflowY: 'auto',
              }}
              md={10}
              xs={10}>
              <Row>
                <>
                  {rowclick === true ? (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: 'block',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                    </Col>
                  ) : (
                    <Col xs={2} md={2} style={{ paddingLeft: '0px' }}>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'block' : 'none',
                        }}
                        className="backButton"
                        onClick={() => {
                          navigate(-1);
                        }}>
                        <Image src={arrowleft}></Image> Back
                      </Button>
                      <Button
                        style={{
                          float: 'left',
                          display: editView ? 'none' : 'block',
                        }}
                        className="backButton"
                        onClick={cancelChanges}>
                        Cancel
                      </Button>
                    </Col>
                  )}
                </>
                <Col xs={8} md={8}></Col>
                <Col xs={2} md={2} style={{ paddingRight: '0px' }}>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display:
                        editView && !create && !rowclick ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={editPilot}>
                    Edit profile &nbsp;&nbsp;
                    <Image src={Iconedit}></Image>
                  </Button>
                  <Button
                    style={{
                      float: 'right',
                      backgroundColor: '#1334B9',
                      display: save || create ? 'block' : 'none',
                    }}
                    className="editPilot"
                    onClick={saveChanges}>
                    Save &nbsp;&nbsp;
                    <Image src={Save}></Image>
                  </Button>
                </Col>
              </Row>
              <Row
                style={{
                  marginTop: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  padding: '10px',
                }}>
                <Col md={12} xs={12}>
                      <div className="file-input-container d-flex align-items-center">
                        <label
                          htmlFor="file-input"
                          className="custom-file-input">
                          {previewUrl ? (
                            <img
                              src={previewUrl}
                              alt="Preview"
                              style={{
                                width: '100px', // Adjust the width as per your icon size
                                height: '100px', // Adjust the height as per your icon size
                              }}
                            />
                          ) : (
                            <>
                              <Image
                                style={{ float: 'left', marginRight: '20px' }}
                                src={
                                  profileDetails.image
                                    ? profileDetails.image
                                    : imageIcon
                                }
                                height={80}></Image>
                            </>
                          )}
                        </label>
                        {uploadImage ? (
                          <input
                            type="file"
                            id="file-input"
                            className="hidden-input"
                            onChange={handleFileChange}
                          />
                        ) : (
                          ''
                        )}
                        <div style={{ marginTop: '10px', float: 'right' }}>
                          <h5>
                            <b>{pilotName}</b>
                          </h5>
                          <p className="org-txt">Org</p>
                        </div>
                      </div>
                </Col>

                <Col md={4} xs={4}></Col>
                <>
                  {!create && (
                    <Col
                      md={3}
                      xs={3}
                      style={{
                        display: rowclick === true ? 'none' : 'block',
                      }}>
                      <p style={{ float: 'right', display: 'none' }}>
                        Pilot Status &nbsp;
                      </p>
                    </Col>
                  )}
                </>
              </Row>
              <Row
                style={{
                  marginTop: '10px',
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  padding: '10px',
                }}>
                <Col>
                  <GeneralDetails
                    ref={authRef}
                    readOnly={readOnly}
                    savedSuccessfully={savedSuccessfully}
                    profileDetails={profileDetails}
                    editView={editView}
                    selectedFile={selectedFile}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={1} xs={1}></Col>
          </Row>
        </Sidebar>
      </Container>
      {showdiscard === true && (
        <DiscardChanges show={showdiscard} handleDiscard={handleDiscard} />
      )}
    </>
  );
};
