import React, { useState, useMemo, useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';
import {
  FaTh,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { api_urls } from '../constants/constants.js';
import { ApiRequests } from '../components/https/ApiRequests.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logoText from '../images/logoText.png';
import { ReactComponent as IconreportsmenuIcons } from '../images/inactiveicons/IconreportsmenuIcons.svg';
import { ReactComponent as IconpilotsmenuIcons } from '../images/inactiveicons/Icon-pilotsmenuIcons.svg';
import { ReactComponent as IconcustomersmenuIcons } from '../images/inactiveicons/Icon-customersmenuIcons.svg';
import { ReactComponent as IconsubAdminmenuIcons } from '../images/inactiveicons/Icon-subAdminmenuIcons.svg';
import { ReactComponent as IconaircraftmenuIcons } from '../images/inactiveicons/Icon-aircraftmenuIcons.svg';
import { ReactComponent as IconcalendarmenuIcons } from '../images/inactiveicons/Icon-calendarmenuIcons.svg';
import { ReactComponent as IconmanagecontentmenuIcons } from '../images/inactiveicons/Icon-manage_contentmenuIcons.svg';
import { ReactComponent as IconmanageformmenuIcons } from '../images/inactiveicons/Icon-manage_formmenuIcons.svg';
import { ReactComponent as IconservicelogmenuIcons } from '../images/inactiveicons/Icon-service_logmenuIcons.svg';
import { ReactComponent as SuperAdminOrganization } from '../images/activeicons/Super-admin-organization-active.svg';
import { ReactComponent as SuperAdminOrganizationinactive } from '../images/inactiveicons/Super-admin-organization.svg';
import { ReactComponent as IconunsendformsmenuIcons } from '../images/inactiveicons/Icon-unsend_formsmenuIcons.svg';
import { ReactComponent as Iconpilotsactive } from '../images/activeicons/Icon-pilotsactive.svg';
import { ReactComponent as Iconreportsactive } from '../images/activeicons/Iconreportsactive.svg';
import { ReactComponent as Iconaircraftactive } from '../images/activeicons/Icon-aircraftactive.svg';
import { ReactComponent as Iconcalendaractive } from '../images/activeicons/Icon-calendaractive.svg';
import { ReactComponent as Iconcustomersactive } from '../images/activeicons/Icon-customersactive.svg';
import { ReactComponent as IconsubAdminactive } from '../images/activeicons/Icon-subAdminactive.svg';
import { ReactComponent as Iconmanagecontentactive } from '../images/activeicons/Icon-manage_contentactive.svg';
import { ReactComponent as Iconmanageformactive } from '../images/activeicons/Icon-manage_formactive.svg';
import { ReactComponent as Iconservicelogactive } from '../images/activeicons/Icon-service_logactive.svg';
import { ReactComponent as Iconunsendformsactive } from '../images/activeicons/Icon-unsend_formsactive.svg';
import rightarrow from '../images/icons/arrowright.png';
import leftarrow from '../images/icons/leftarrow.png';
import './sidebar.css';

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const lastSegment = url.pathname.split('/').pop();
  console.log('url pathname', url.pathname);
  const isId = !isNaN(lastSegment);
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const toggle = () => setIsOpen(!isOpen);
  const [superAdmin, setsuperAdmin] = useState(
    sessionStorage.getItem('user_type'),
  );
  let subAdminAccess = [];
  let newSubAdminArr = [];
  subAdminAccess.push({
    aircraft_access: sessionStorage.getItem('aircraft_access'),
    memos_access: sessionStorage.getItem('memos_access'),
    service_log_access: sessionStorage.getItem('service_log_access'),
    calender_access: sessionStorage.getItem('calender_access'),
    manage_form_access: sessionStorage.getItem('manage_form_access'),
    customer_access: sessionStorage.getItem('customer_access'),
    pilot_access: sessionStorage.getItem('pilot_access'),
    dashboard_access: sessionStorage.getItem('dashboard_access'),
  });
  let toastShown = false;
  useEffect(() => {
    fetchSubAdminData();
  }, []);
  const fetchSubAdminData = async () => {
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id')
      };

      const response = await ApiRequests(
        api_urls.subAdminProfileAPi,
        'GET',
        null,
        params,
      );
      if (response) {
        if (response.data.user.user_type === 'sub_admin') {
          newSubAdminArr = [{
            aircraft_access: String(response.data.user.sub_admin_permissions.aircraft_access),
            memos_access: String(response.data.user.sub_admin_permissions.memos_access),
            service_log_access: String(response.data.user.sub_admin_permissions.service_log_access),
            calender_access: String(response.data.user.sub_admin_permissions.calender_access),
            manage_form_access: String(response.data.user.sub_admin_permissions.manage_form_access),
            customer_access: String(response.data.user.sub_admin_permissions.customer_access),
            pilot_access: String(response.data.user.sub_admin_permissions.pilot_access),
            dashboard_access: String(response.data.user.sub_admin_permissions.dashboard_access),
          }];
          const isChange =
            JSON.stringify(subAdminAccess) !== JSON.stringify(newSubAdminArr);


          if (isChange && !toastShown) {
            // Perform actions when there is a change
            toast.error('Your access is changed');
            toastShown = true;
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user_type');
            sessionStorage.removeItem('organization_id');
            sessionStorage.removeItem('aircraft_access');
            sessionStorage.removeItem('memos_access');
            sessionStorage.removeItem('service_log_access');
            sessionStorage.removeItem('calender_access');
            sessionStorage.removeItem('manage_form_access');
            sessionStorage.removeItem('customer_access');
            sessionStorage.removeItem('dashboard_access');
            sessionStorage.removeItem('service_log_access')
            sessionStorage.removeItem('pilot_access');
            sessionStorage.removeItem('userimg');
            setTimeout(() => {
              window.location.href = '/login';
            }, 1000);
          }

          let pageName = '';
          for (const prop in response.data.user.sub_admin_permissions) {
            if (response.data.user.sub_admin_permissions.hasOwnProperty(prop) && prop.endsWith('_access') && response.data.user.sub_admin_permissions[prop] === true) {
              pageName = prop.replace('_access', '');
              break;
            }
          }
          if (pageName === 'dashboard' && !isId && url.pathname.includes('reports')) {
            navigate('/dashboard/reports');
          }
          else if (pageName === 'pilot' && !isId && url.pathname.includes('pilots')) {
            navigate('/dashboard/pilots');
          }
          else if (pageName === 'customer' && !isId && url.pathname.includes('customers')) {
            navigate('/dashboard/customers');
          }
          else if (pageName === 'aircraft' && !isId && url.pathname.includes('aircrafts')) {
            navigate('/dashboard/aircrafts');
          }
          else if (pageName === 'service_log' && !isId && url.pathname.includes('servicelog')) {
            navigate('/dashboard/servicelog');
          }
          else if (pageName === 'manage_form' && !isId && url.pathname.includes('manageform')) {
            navigate('/dashboard/manageform');
          }
          else if (pageName === 'calender' && !isId && url.pathname.includes('calendar')) {
            navigate('/dashboard/calendar');
          }
          else if (pageName === 'memos' && !isId && url.pathname.includes('memos')) {
            navigate('/dashboard/memos');
          }
          // else {
          //   toast.error('Either sub admin is deleted or do not have an access to view the application');
          // }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  let initalArr = [
    {
      path: '/dashboard/reports',
      name: 'Reports',
      icon: <IconreportsmenuIcons />,
      activeIcon: <Iconreportsactive />,
    },
    {
      path: '/dashboard/pilots',
      name: 'Pilots',
      icon: <IconpilotsmenuIcons />,
      activeIcon: <Iconpilotsactive />,
    },
    {
      path: '/dashboard/subadmin',
      name: 'Sub Admin',
      icon: <IconsubAdminmenuIcons />,
      activeIcon: <IconsubAdminactive />,
    },
    {
      path: '/dashboard/customers',
      name: 'Customers',
      icon: <IconcustomersmenuIcons />,
      activeIcon: <Iconcustomersactive />,
    },
    {
      path: '/dashboard/aircrafts',
      name: 'Aircrafts',
      icon: <IconaircraftmenuIcons />,
      activeIcon: <Iconaircraftactive />,
    },
    {
      path: '/dashboard/servicelog',
      name: 'Service Log',
      icon: <IconservicelogmenuIcons />,
      activeIcon: <Iconservicelogactive />,
    },
    {
      path: '/dashboard/manageform',
      name: 'Manage Form',
      icon: <IconmanageformmenuIcons />,
      activeIcon: <Iconmanageformactive />,
    },
    {
      path: '/dashboard/calendar',
      name: 'Calendar',
      icon: <IconcalendarmenuIcons />,
      activeIcon: <Iconcalendaractive />,
    },
    {
      path: '/dashboard/memos',
      name: 'Memos',
      icon: <IconmanagecontentmenuIcons />,
      activeIcon: <Iconmanagecontentactive />,
    }]

  const filteredArr = initalArr.filter(item => {
    const access = subAdminAccess[0];
    return (
      access.aircraft_access === "true" && item.path === '/dashboard/aircrafts' ||
      access.memos_access === "true" && item.path === '/dashboard/memos' ||
      access.service_log_access === "true" && item.path === '/dashboard/servicelog' ||
      access.calender_access === "true" && item.path === '/dashboard/calendar' ||
      access.manage_form_access === "true" && item.path === '/dashboard/manageform' ||
      access.customer_access === "true" && item.path === '/dashboard/customers' ||
      access.pilot_access === "true" && item.path === '/dashboard/pilots' ||
      access.dashboard_access === "true" && item.path === '/dashboard/reports'
    );
  });

  const menuItem = useMemo(
    () => {
      if (superAdmin === 'organization') {
        return [
          {
            path: '/dashboard/reports',
            name: 'Reports',
            icon: <IconreportsmenuIcons />,
            activeIcon: <Iconreportsactive />,
          },
          {
            path: '/dashboard/pilots',
            name: 'Pilots',
            icon: <IconpilotsmenuIcons />,
            activeIcon: <Iconpilotsactive />,
          },
          {
            path: '/dashboard/subadmin',
            name: 'Sub Admin',
            icon: <IconsubAdminmenuIcons />,
            activeIcon: <IconsubAdminactive />,
          },
          {
            path: '/dashboard/customers',
            name: 'Customers',
            icon: <IconcustomersmenuIcons />,
            activeIcon: <Iconcustomersactive />,
          },
          {
            path: '/dashboard/aircrafts',
            name: 'Aircrafts',
            icon: <IconaircraftmenuIcons />,
            activeIcon: <Iconaircraftactive />,
          },
          {
            path: '/dashboard/servicelog',
            name: 'Service Log',
            icon: <IconservicelogmenuIcons />,
            activeIcon: <Iconservicelogactive />,
          },
          {
            path: '/dashboard/manageform',
            name: 'Manage Form',
            icon: <IconmanageformmenuIcons />,
            activeIcon: <Iconmanageformactive />,
          },
          {
            path: '/dashboard/calendar',
            name: 'Calendar',
            icon: <IconcalendarmenuIcons />,
            activeIcon: <Iconcalendaractive />,
          },
          {
            path: '/dashboard/memos',
            name: 'Memos',
            icon: <IconmanagecontentmenuIcons />,
            activeIcon: <Iconmanagecontentactive />,
          }
        ];
      }
      else if (superAdmin === 'sub_admin') {
        return filteredArr
      }
      else {
        return [
          {
            path: '/dashboard/organizations',
            name: 'Organization',
            icon: <SuperAdminOrganizationinactive />,
            activeIcon: <SuperAdminOrganization />,
          },
        ];
      }
    },
    [superAdmin]
  );


  const getCurrentPath = () => {
    return window.location.pathname;
  };

  const handleClick = (path) => {
    setActiveLink(path);
  };

  const isActive = (path) => {
    return getCurrentPath() === path;
  };

  return (
    <React.Fragment>
      <div>
        <div
          style={{ width: '100%', backgroundColor: '#e3e5e8' }}
          className="side-bar-container">
          <div style={{ width: isOpen ? '25%' : '5%' }} className="sidebar">
            <div className="top_section">
              <Image
                style={{ display: isOpen ? 'block' : 'none' }}
                src={logoText}
              />
              {/* <div style={{ marginLeft: isOpen ? "5%" : "0%" }} className="bars">
                <img src={isOpen?leftarrow:rightarrow} style={{textAlign:"center"}} alt="Toggle" onClick={toggle} />
              </div> */}
            </div>
            <hr style={{ border: '1px solid #D1D5DB' }} />
            {menuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className={isOpen ? 'link' : 'collapsedLink'}
                activeClassName="active"
                onClick={() => handleClick(item.path)}>
                {/* <div className="icon"> */}
                {isActive(item.path) || activeLink === item.path
                  ? item.activeIcon
                  : item.icon}
                {/* </div> */}
                <div
                  style={{ display: isOpen ? 'block' : 'none' }}
                  className="link_text">
                  {item.name}
                </div>
              </NavLink>
            ))}

            <div
              style={{
                marginLeft: isOpen
                  ? '4% bars expand_bar'
                  : '0% bars collapse_bar',
              }}
              className={isOpen ? 'bars expand_bar' : 'bars collapse_bar'}>
              <img
                src={isOpen ? leftarrow : rightarrow}
                style={{ textAlign: 'center' }}
                alt="Toggle"
                onClick={toggle}
                className="bar-img"
              />
            </div>
          </div>
          <main>{children}</main>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
