import { Modal } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { Drop } from "./Drop"

export const UploadMemo = props => {
  function handleClose() {
    props.setShowMoal(false)
    props.changeModalState(false)
  }  

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={props.show}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle">Upload Memo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <Drop
          handleClose={handleClose}
          create={props.create}
          storePilotDocument={props.storePilotDocument}
          showfileList={props.showfileList}
          action="Upload Memo"
          showUpdatedData={props.showUpdatedData}
        />
      </Modal.Body>
    </Modal>
  )
}
