import React, { useEffect, useMemo, useState } from 'react';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import { useNavigate } from 'react-router-dom';
import editIcon from '../../images/icons/editIcon.png';
import { convertUtcDateTimeToLocal, formatDate } from '../../utils/util.js';
import defaultProfile from '../../images/icons/default-profile.png';
import exportExcel from '../../images/icons/exportExcel.png';
import filter from '../../images/icons/filter.png';
import selector from '../../images/icons/selector.png';
import { DashboardHeader } from '../../constants/DashboardHeader';
import { timezoneHandler } from '../../utils/timezoneHandler.js';
import Loader from '../Loader/index';
import { Button, Image } from 'react-bootstrap';
import ErrorMsg from '../ErrorMsg';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Reportfilter } from './Reportfilter';
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from 'react-table';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './table.css';
import { toast } from 'react-toastify';
import { Menu, MenuItem } from '@mui/material';

export const Reports = () => {
  const navigate = useNavigate();
  // const { SearchBar } = Search

  const [loading, setLoading] = useState(true); // Add loading state
  const [noFound, setNoFound] = useState(false);
  const [showReportFilter, setshowReportFilter] = useState(false);
  const [products, setProducts] = useState([]);
  // const [exportData,setExportData]=useState([])
  const [search, setSearch] = useState('');
  const [filterdata, setFilterData] = useState({});
  const [showRedDot, setShowRedDot] = useState(false);
  const [aircraftdata, setAircrafts] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const [pilotsData, setPilots] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOption = (option) => {
    if (option === 'allfields') {
        exportToCSV(products, 'Daily Report')
    } else if (option === 'customfields') {
        exportToCSVcustom(products, 'Daily Report')
    } 
    setAnchorEl(null);
  };
  const [showDayOffReportModal, setDayOffReportModal] = useState(false);
  const handleDiscard = (flag) => {
    setDayOffReportModal(false);
  }

  useEffect(() => {
    let organization_id =
      sessionStorage.getItem('organization_id') === 'null'
        ? 2
        : sessionStorage.getItem('organization_id');
    let body = { organization_id: organization_id };
    fetchData(body);
    fetchAircraftData(body);
    fetchPilotsData();
    fetchCustomersData();
  }, []);

  const fetchAircraftData = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.aircraftProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      console.log('response from aircrafts in report');
      if (tempdata.length > 0) {
        setLoading(false);
        // setNoFound(false);
      } else if (tempdata.length == 0) {
        // setNoFound(true);
        setLoading(false);
      }

      setAircrafts(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchCustomersData = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.customerProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      if (tempdata.length > 0) {
        setLoading(false);
        // setNoFound(false);
      } else if (tempdata.length == 0) {
        // setNoFound(true);
        setLoading(false);
      }
      setCustomers(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchPilotsData = async () => {
    setLoading(true);
    try {
      const params = {
        organization_id:
          sessionStorage.getItem('organization_id') === 'null'
            ? 2
            : sessionStorage.getItem('organization_id'),
      };

      const response = await ApiRequests(
        api_urls.pilotProfile,
        'GET',
        null,
        params,
      );

      let tempdata = response.data;
      if (tempdata.length > 0) {
        setLoading(false);
        // setNoFound(false);
      } else if (tempdata.length == 0) {
        // setNoFound(true);
        setLoading(false);
      }

      setPilots(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const resetFilter = (body, filters) => {
    setShowRedDot(false);
    setFilterData(filters);
    fetchData(body);
  };

  const fetchData = async (body) => {
    setLoading(true);
    try {
      const response = await ApiRequests(
        api_urls.reportProfile,
        'GET',
        null,
        body,
      );

      let tempdata = response.data;
      console.log('tdata', tempdata);
      if (tempdata.length > 0) {
        setLoading(false);
        setNoFound(false);
      } else if (tempdata.length == 0) {
        setNoFound(true);
        setLoading(false);
      }

      setProducts(tempdata);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handlefilters = (data, filters) => {
    setShowRedDot(true);
    setFilterData(filters);
    fetchData(data);
  };

  const toggleFilter = () => {
    setshowReportFilter(false);
  };

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [exportData, setExportData] = useState([]);
  const exportToCSV = (apiData, fileName) => {
    console.log('apiData#', apiData);

    const processedData = apiData.reduce((accumulator, listData) => {
      console.log("accumulator",accumulator,listData)
      if (listData.report_customer_hours && listData.report_customer_hours.length > 0) {
        const customerHours = listData.report_customer_hours.map(item => {
          const {
            report_customer_hours,
            ...restListDataWithoutReportCustomerHours
          } = listData;
console.log("restListDataWithoutReportCustomerHours",item)
          return {
            ...restListDataWithoutReportCustomerHours,
            risk_assessment_result: listData.risk_assessment_result !== null ? listData.risk_assessment_result.risk_assessment_result.result_type : '',
            customers: item.customer_info.company,
            hours: item.hour,
            day_start:convertUtcDateTimeToLocal(listData.day_start),
            day_end:convertUtcDateTimeToLocal(listData.day_end),
            aircraft: listData.aircraft !== null ? listData.aircraft.tail_number : '',
            appointment: listData.appointment.title,
            flight_type: listData.flight_type !== null ? listData.flight_type.option : '',
            upcoming_maintenance: listData.upcoming_maintenance !== null ? listData.upcoming_maintenance.option : '',
            created_at:convertUtcDateTimeToLocal(listData.created_at),
            updated_at:convertUtcDateTimeToLocal(listData.updated_at),
            pilot: listData.pilot.name
          };
        });

        return [...accumulator, ...customerHours];
      } else {
        const {
          report_customer_hours,
          ...restListDataWithoutReportCustomerHours
        } = listData;

        return [
          ...accumulator,
          {
            ...restListDataWithoutReportCustomerHours,
            risk_assessment_result: listData.risk_assessment_result !== null ? listData.risk_assessment_result.risk_assessment_result.result_type : '',
            customers: '',
            hours: '',
            aircraft: listData.aircraft !== null ? listData.aircraft.tail_number : '',
            appointment: listData.appointment.title,
            flight_type: listData.flight_type !== null ? listData.flight_type.option : '',
            upcoming_maintenance: listData.upcoming_maintenance !== null ? listData.upcoming_maintenance.option : '',
            pilot: listData.pilot.name
          }
        ];
      }
    }, []);



    console.log('apiData', apiData);
    // const ws = XLSX.utils.json_to_sheet(apiData);
    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    console.log('ws', ws, 'wb', wb);
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    toast.success("Excel file downloaded successfully")
  };

  const exportToCSVcustom = (apiData, fileName) => {
    const processedData = apiData.reduce((accumulator, listData) => {
      const customerHours = listData.report_customer_hours || [];

      if (customerHours.length > 0) {
        customerHours.forEach(item => {
          const customerData = {
            Date: listData.date,
            Aircraft: listData.aircraft !== null ? listData.aircraft.tail_number : '',
            Pilot: listData.pilot.name,
            // Start: listData.day_start ? listData.day_start.split(" ")[1] : '',
            Start: convertUtcDateTimeToLocal(listData.day_start),
            End: convertUtcDateTimeToLocal(listData.day_end),
            // End: listData.day_end ? listData.day_end.split(" ")[1] : '',
            "DUTY HOURS": listData.total_hours.toFixed(2),
            Customer: item.customer_info.company,
            "Flight Type": listData.flight_type !== null ? listData.flight_type.option : '',
            "FLIGHT TIME": listData.flight_hours,
            "Day Land": listData.day_landings,
            "Night Land": listData.night_landings
          };
          accumulator.push(customerData);
        });
      } else {
        const rowData = {
          Date: listData.date,
            Aircraft: listData.aircraft !== null ? listData.aircraft.tail_number : '',
            Pilot: listData.pilot.name,
            Start: listData.day_start ? listData.day_start.split(" ")[1] : '',
            End: listData.day_end ? listData.day_end.split(" ")[1] : '',
            "DUTY HOURS": listData.total_hours.toFixed(2),
            Customer: '',
            "Flight Type": listData.flight_type !== null ? listData.flight_type.option : '',
            "FLIGHT TIME": listData.flight_hours,
            "Day Land": listData.day_landings,
            "Night Land": listData.night_landings
        };
        accumulator.push(rowData);
      }

      return accumulator;
    }, []);

    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    toast.success("Excel file downloaded successfully");

  };

  const data = useMemo(() => products, [products]);
  const columns = useMemo(
    () => [
      {
        Header: 'Pilot Name',
        accessor: 'pilot.name',
        sticky: 'left',
        disableSortBy: false,
      },
      {
        Header: 'Aircraft Tail Number',
        accessor: 'aircraft.tail_number',
        sticky: 'left',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Flight Date',
        accessor: 'date',
        sticky: 'left',
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }) => {
          return <span>{formatDate(value)}</span>;
        },
      },
      {
        Header: 'Flight Time',
        accessor: 'flight_hours',
        sticky: 'left',
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }) => (value ? <span>{value.toFixed(2)} H</span> : null),
      },
      {
        Header: 'Customer',
        accessor: `report_customer_hours`,
        sticky: 'left',
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }) =>
          value !== null || value !== undefined ? (
            <span>
              {value.length > 1
                ? 'Multiple'
                : value[0] === undefined
                  ? 'Not Available'
                  : value[0].customer_info.name}
            </span>
          ) : null,
      },
      {
        Header: 'Risk Assessment',
        accessor: 'risk_assessment_completed',
        sticky: 'left',
        disableFilters: true,
        disableSortBy: false,
        Cell: ({ value }) => {
          return value === true ? (
            <span style={{ color: '#15803D' }}>Completed</span>
          ) : (
            <span style={{ color: 'red' }}>Pending</span>
          );
        },
      },
      {
        Header: 'Action',
        disableSortBy: true,
        accessor: 'state',
        Cell: (props) => {
          return (
            <>
              <span>
                <Image
                  className="cursor-pointer"
                  src={editIcon}
                  style={{ marginRight: '20px' }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    navigate(
                      `/dashboard/reports/${props.row.original.id}`,
                      {
                        state: { edit: true, rowclick: false },
                      },
                    );
                    // }
                  }}></Image>
              </span>
            </>
          );
        },
      },
    ],

    [],
  );

  const onRowClicked = (row, event) => {
    const data = products.find((item) => item.id === row.id);
    if (data) {
      // <ViewPilot/>
      navigate(`/dashboard/pilots/${data.id}`);
    }
  };
  const createPilot = (e) => {
    e.stopPropagation();

    navigate(`/dashboard/pilots/create`, {
      state: { create: true, edit: true },
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,

      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, pageSize } = state;

  return (
    <div>
      <DashboardHeader name="Reports" />
      <>
        {noFound === true ? (
          <>
            <div
              style={{
                padding: '0px 30px',
                backgroundColor: '##F3F4F6',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '20px',
                borderColor: '#1334B9',
              }}>
              <div className="search-bar w-25">
                <span className="search-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                      stroke="#9CA3AF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 17.5L13.875 13.875"
                      stroke="#9CA3AF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  value={search}
                  className="SearchLabel searchpilot"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setGlobalFilter(e.target.value);
                  }}
                  placeholder="Search"
                  style={{ '::placeholder': { color: '#D1D5DB' } }}
                />
              </div>
              <div className="right-div-reports d-flex">
                <img
                  src={filter}
                  className="report-filter curPointer"
                  alt="img NP"
                  onClick={() => setshowReportFilter(true)}
                />
                <>{showRedDot === true && <div class="red-dot"></div>}</>
                <img
                  src={exportExcel}
                  // onClick={(e) => exportToCSV(products, 'Daily Report')}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className="report-filter curPointer"
                  alt="img NP"
                />
              </div>

              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                getContentAnchorEl={null}
              >
                <MenuItem onClick={() => handleMenuOption('allfields')}>All Fields</MenuItem>
                <MenuItem onClick={() => handleMenuOption('customfields')}>Custom Fields</MenuItem>
              </Menu> */}
            </div>
            <ErrorMsg errormsg={'No Records Exist'} />
          </>
        ) : (
          <>
            <div
              style={{
                padding: '0px 30px',
                backgroundColor: '##F3F4F6',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '20px',
                borderColor: '#1334B9',
              }}>
              <div className="search-bar w-25">
                <span className="search-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                      stroke="#9CA3AF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 17.5L13.875 13.875"
                      stroke="#9CA3AF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  value={search}
                  className="SearchLabel searchpilot"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setGlobalFilter(e.target.value);
                  }}
                  placeholder="Search"
                  style={{ '::placeholder': { color: '#D1D5DB' } }}
                />
              </div>
              <div className="right-div-reports d-flex">
                <img
                  src={filter}
                  className="report-filter curPointer"
                  alt="img NP"
                  onClick={() => setshowReportFilter(true)}
                />
                <>{showRedDot === true && <div class="red-dot"></div>}</>
                <img
                  src={exportExcel}
                  // onClick={(e) => exportToCSV(products, 'Daily Report')}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className="report-filter curPointer"
                  alt="img NP"
                />
              </div>
              
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                getContentAnchorEl={null}
              >
                <MenuItem onClick={() => handleMenuOption('allfields')}>All Fields</MenuItem>
                <MenuItem onClick={() => handleMenuOption('customfields')}>Custom Fields</MenuItem>
              </Menu>
            </div>
            <>
              {loading === true ? (
                <Loader loadingMsg="Data is Loading... Please Wait" />
              ) : (
                <>
                  <div className="table-container">
                    <table {...getTableProps()} className="table-cls">
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps(),
                                )}>
                                {column.render('Header')}
                                <span>
                                  &nbsp;
                                  {column.disableSortBy === false ? (
                                    <Image src={selector}></Image>
                                  ) : null}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              onClick={() =>
                                navigate(
                                  `/dashboard/reports/${row.original.id}`,
                                  {
                                    state: { rowclick: true, edit: true },
                                  },
                                )
                              }>
                              {/* Add image and name in the first column */}
                              <td
                                className="img-name-cont"
                                style={{ cursor: 'pointer' }}>
                                {row.original.pilot ? (
                                  <img
                                    src={
                                      row.original.pilot.pilot_image ||
                                      defaultProfile
                                    }
                                    // src={defaultProfile}
                                    alt="Image"
                                    className="profile-img"
                                  />
                                ) : (
                                  <img
                                    src={defaultProfile}
                                    alt="Default Image"
                                    className="profile-img"
                                  />
                                )}
                                {row.original.pilot && (
                                  <>{row.original.pilot.name}</>
                                )}
                              </td>

                              {row.cells.map((cell, cellIndex) => {
                                // Skip rendering the name column again
                                if (cellIndex === 0) {
                                  return null;
                                }
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="paginationBox">
                    <div
                      style={{ float: 'left', gap: '5px' }}
                      className="d-flex align-items-center">
                      <label className="paginationText">
                        Number of rows&nbsp;
                      </label>
                      <select
                        style={{ float: 'right' }}
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}>
                        {[10, 25, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ float: 'right' }}>
                      <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                          }`}>
                        Prev
                      </button>
                      {pageOptions.map((page, index) => (
                        <button
                          key={index}
                          onClick={() => gotoPage(index)}
                          className={`paginationButton ${pageIndex === index ? 'activeButton' : ''
                            }`}>
                          {index + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className={`paginationButton ${!canPreviousPage ? 'disabled' : ''
                          }`}>
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </>
      {showReportFilter && (
        <Reportfilter
          submitfilter={handlefilters}
          show={showReportFilter}
          toggleFilter={toggleFilter}
          data={products}
          filterdata={filterdata}
          resetFilter={resetFilter}
          aircraftdata={aircraftdata}
          customersData={customersData}
          pilotsData={pilotsData}
        />
      )}
    </div>
  );
};
