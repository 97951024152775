import {timezoneHandler} from './timezoneHandler.js';
import momentTimezone from 'moment-timezone';
import moment from 'moment-timezone';
import subtract from 'moment-timezone';

export const formatDate = (inputDate) => {
  // console.log('timezoneHandler time zone', timezoneHandler(inputDate));
  let date;
  if (inputDate === null) {
    const now = new Date();
    date = new Date(now.toLocaleString('en-US', { timeZone: 'America/Anchorage' }));
  }
  else {
    date = (inputDate);
  }
  // const date = new Date(inputDate);

  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'America/Anchorage', // Alaska time zone
  };

  const formattedDate = date.toLocaleString('en-US', options);
  return formattedDate;
};


export const convertLocalDateTimeToUTC = (day_start_date, day_start_time) => {
  var date_time = day_start_date + ' ' + day_start_time;
  const localDate = new Date(date_time);
  const utcTimeString = localDate.toISOString();
  console.log('UTC..date', utcTimeString);
  return utcTimeString;
};

export const convertLocalDateTimeToUTCForCalender = (_date, _time) => {
  console.log('date', _date,'time',_time);
  var date_time = _date + ' ' + _time;
  const localDate = new Date(date_time);
  const utcTimeString = localDate.toISOString();
  console.log('UTC..date', utcTimeString);
  const newUtcTimeString = utcTimeString.toString().replace('T',' ').substring(0, utcTimeString.indexOf('.'));
  // const time = moment(utcTimeString).format('HH:mm:ss');
  console.log('New_UTC..date', newUtcTimeString);
  return newUtcTimeString;
};

export const convertCustomLocalDateTimeToUTCForCalender = (_date, _time) => {
  // Parse the input date using moment.js
  var startDate = moment(_date);

  // Subtract one day from the parsed date
  startDate.subtract(1, "days");

  // Format the resulting date as YYYY-MM-DD
  var formattedDate = startDate.format("YYYY-MM-DD");

  // Combine the formatted date and input time
  var dateTimeString = formattedDate + " " + _time;

  // Parse the combined datetime string using moment.js
  var localDateTime = moment(dateTimeString);

  // Convert the local datetime to UTC
  var utcDateTimeString = localDateTime.utc().format("YYYY-MM-DD HH:mm:ss");

  // Return the UTC datetime string
  return utcDateTimeString;
};


export const convertUtcDateTimeToLocal = (utcDate) => {
  console.log('UTC..date', utcDate);
  //convert utcTimeString into localtime
  const localTime = moment.utc(utcDate).local();
  //console.log("localtime",localTime._d)
  const formattedLocalDateTime = moment(localTime._d).format(
    'YYYY-MM-DDTHH:mm:ssZ',
  );
  console.log('formattedTime', formattedLocalDateTime);
  return formattedLocalDateTime;
};

export const extractDateFromLocalDateTime = (utcDate) => {
  console.log('UTC..date', utcDate);
  //convert utcTimeString into localtime
  const localTime = moment.utc(utcDate).local();
  //console.log("localtime",localTime._d)
  const formattedLocalDateTime = moment(localTime._d).format(
    'YYYY-MM-DDTHH:mm:ssZ',
  );
  console.log('formattedTime', formattedLocalDateTime);
  const date = moment(formattedLocalDateTime).format('YYYY-MM-DD');
  // const date=formattedLocalDateTime.substring(0,formattedLocalDateTime.indexOf('T'))
console.log("date",date)
  return date;
};

export const extractTimeFromLocalDateTime = (utcDate) => {
  console.log('UTC..date', utcDate);
  //convert utcTimeString into localtime
  const localTime = moment.utc(utcDate).local();
  //console.log("localtime",localTime._d)
  const formattedLocalDateTime = moment(localTime._d).format(
    'YYYY-MM-DDTHH:mm:ssZ',
  );
  console.log('formattedTime', formattedLocalDateTime);
  const time = moment(formattedLocalDateTime).format('HH:mm A');
  // const time=formattedLocalDateTime.substring(formattedLocalDateTime.indexOf('T')+1,formattedLocalDateTime.indexOf('+'))
  console.log("date",time)
  return time;
};