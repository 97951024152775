import React from "react";
import { Modal } from 'react-bootstrap';

const ViewImage = (props) => {
    const handleClose = () => {
        props.closeImagePopup();
    }
    return (
        <div className="ViewImage-Wrapper">
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={props.show}
                onHide={handleClose}
                centered>
                <Modal.Header closeButton>
                    {/* <Modal.Title className="modalTitle">Delete Organizations</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="modalBody">
                    <img src={props.imageData} className="prev-image w-100 h-50" alt="image Np" />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ViewImage