import React, { useEffect, useState } from 'react';
import { api_urls } from '../../constants/constants';
import { ApiRequests } from '../https/ApiRequests';
import Loader from '../Loader';
import { Field, Form } from 'formik';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import Editquestion from '../../images/activeicons/Editquestion.svg';
import Deletquestion from '../../images/activeicons/Deletequestion.svg';
import Save from '../../images/icons/save.png';
import { toast } from 'react-toastify';
import { ConfirmQuestionDelete } from './ConfirmQuestionDelete';


const FixedQuestions = () => {

    const [loading, setLoading] = useState(false);
    const [fieldMapping, setCustomQuestions] = useState([]);
    const [showEditLabel, setShowEditLabel] = useState(false);
    const [tempEditId, setTempEditId] = useState('');
    const [label, setLabel] = useState("");
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteQuestionId, setDeleteQuestionId] = useState('');

    const fetchFieldMapping = async () => {
        setLoading(true);

        try {
            let apiurl = `${api_urls.question_mapping}`;
            const response = await ApiRequests(apiurl, 'GET', null, null);
            setCustomQuestions(response.data);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchFieldMapping();
    }, [])

    const handleQuestionDelete = (flag) => {
        if (flag === true) {
            setDeleteModal(false);
            handleDeleteCustomQuestion(deleteQuestionId);
        } else {
            setDeleteModal(false);
        }
    }
    const deleteQuestionPop = async (id) => {
        setDeleteQuestionId(id);
        setDeleteModal(true);
    }
    const handleDeleteCustomQuestion = async (questionID) => {
        let apiurl = `${api_urls.question_mapping}${questionID}/`;
        setLoading(true);

        try {
            const response = await ApiRequests(apiurl, 'DELETE', null, null);
            toast.success('Question deleted successfully');
            fetchFieldMapping();
            setLoading(false)
        } catch {
            toast.error('Error in Question deletion');
            setLoading(false);
        }

    }

    const handleEditQuestion = async (question) => {
        setTempEditId(question.id)
        setShowEditLabel(true);
        setLabel(question.label);
    }

    const handleAddQuesLabel = (event) => {
        setLabel(event.target.value);
    };

    const updateUpdateQuestion = async (id) => {
        if (label === '') {
            toast.error('Error: Question is a required field');
            return;
        }

        try {
            let apiurl = `${api_urls.question_mapping}${id}/`;

            const body = {
                label: label
            }

            const response = await ApiRequests(apiurl, 'PATCH', body, null);
            if (response) {
                toast.success('Question updated successfully');
            }
            setShowEditLabel(false);
            fetchFieldMapping();
        } catch (error) {
            toast.error('Error in updating addition');
            setLoading(false);
        }

    }

    return (
        loading === true ? (
            <Loader loadingMsg="Data is Loading... Please Wait" />
        ) : (
            <>
                {fieldMapping.map((question) => (
                    <>
                        <Row
                            style={{
                                backgroundColor: '#FFFFFF',
                                padding: '30px 20px 20px 20px',
                            }}
                            className="questions-row">
                            <div className="child-row-1">
                                <div className="question-head-cont d-flex justify-content-between align-items-center">

                                    <div className="question-head">Question</div>
                                    <div className="edit-del-ques">
                                        {showEditLabel
                                            && tempEditId == question.id
                                            ? (
                                                <Button
                                                    style={{
                                                        float: 'right',
                                                        backgroundColor: '#1334B9',
                                                        height: '50px',
                                                    }}
                                                    className="editPilot"
                                                    onClick={() => updateUpdateQuestion(question.id)}
                                                >
                                                    Save &nbsp;&nbsp;
                                                    <Image src={Save}></Image>
                                                </Button>
                                            ) : (
                                                <>
                                                    <img
                                                        src={Editquestion}
                                                        className="edit-ques"
                                                        alt="edit icon NP"
                                                        onClick={() => handleEditQuestion(question)}
                                                    />
                                                    <img
                                                        src={Deletquestion}
                                                        className="edit-ques"
                                                        alt="edit icon NP"
                                                        onClick={() => deleteQuestionPop(question.id)
                                                        }
                                                    />
                                                </>
                                            )}
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className="form-control question-input"
                                    value={(showEditLabel && tempEditId == question.id) ? label : question.label}
                                    disabled={!(showEditLabel && tempEditId == question.id)}
                                    onChange={(e) => handleAddQuesLabel(e)}
                                />

                                {(question.mapping.key == "did_fly"
                                    || question.mapping.key == "multiple_customer"
                                    || question.mapping.key == "utility_gear"
                                    || question.mapping.key == "maintenance"
                                    || question.mapping.key == "issue_address"
                                    || question.mapping.key == "aht"
                                    || question.mapping.key == "power_check"
                                )
                                    &&
                                    <Row style={{
                                        backgroundColor: '#FFFFFF',
                                    }}
                                        className="questions-row">
                                        <div>

                                            <div className="form-check">
                                                <input className="form-check-input"
                                                    type="radio"
                                                    disabled
                                                />
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input"
                                                    type="radio"
                                                    disabled
                                                />
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    No
                                                </label>
                                            </div>

                                        </div>

                                    </Row>
                                }

                                {(question.mapping.key == "passengers"
                                    || question.mapping.key == "base"
                                    || question.mapping.key == "other_base"
                                )
                                    &&
                                    <Row style={{
                                        backgroundColor: '#FFFFFF',
                                    }}
                                        >
                                        <div>

                                            <div className="form-check ps-0">
                                                <input
                                                    type="text"
                                                    disabled="disabled"
                                                    className="grey-input"
                                                    placeholder='Answer'
                                                />
                                            </div>

                                        </div>

                                    </Row>
                                }

                                {(question.mapping.key == "fuel"
                                    || question.mapping.key == "rev_time"
                                    || question.mapping.key == "anc_landing"
                                    || question.mapping.key == "acctt"
                                    || question.mapping.key == "pamr_landing"
                                    || question.mapping.key == "day_landing"
                                    || question.mapping.key == "night_landing"
                                    || question.mapping.key == "total_hours"
                                )
                                    &&
                                    <Row style={{
                                        backgroundColor: '#FFFFFF',
                                    }}
                                        className="questions-row">
                                        <div>

                                            <div className="form-check ps-0">
                                                <input
                                                    type="number"
                                                    disabled="disabled"
                                                    className="grey-input"
                                                    placeholder='0'
                                                />
                                            </div>

                                        </div>

                                    </Row>
                                }

                                {(question.mapping.key == "maintenance_logbook"
                                    || question.mapping.key == "hour_meter"
                                )
                                    &&
                                    <Row style={{
                                        backgroundColor: '#FFFFFF',
                                    }}
                                        className="questions-row">
                                        <div>

                                            <div className="form-check ps-0">
                                                <input
                                                    type="file"
                                                    disabled="disabled"
                                                    className="grey-input"
                                                    placeholder='upload photo'
                                                />
                                            </div>

                                        </div>

                                    </Row>
                                }

                                {(question.mapping.key == "flight_time"
                                )
                                    &&
                                    <Row style={{
                                        backgroundColor: '#FFFFFF',
                                    }}
                                        className="questions-row">
                                        <div className='col-md-4'>
                                            <div className="form-check ps-0">Start time</div>
                                            <div className="form-check ps-0">
                                                <input
                                                    type="time"
                                                    disabled="disabled"
                                                    className="grey-input"
                                                    placeholder='start time'
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-check">End time</div>
                                            <div className="form-check">
                                                <input
                                                    type="time"
                                                    disabled="disabled"
                                                    className="grey-input"
                                                    placeholder='end time'
                                                />
                                            </div>
                                        </div>

                                    </Row>
                                }
                            </div>
                        </Row>
                    </>
                ))}
                {showDeleteModal === true && (
                    <ConfirmQuestionDelete
                        show={showDeleteModal}
                        handleDiscard={handleQuestionDelete}
                    />
                )}
            </>
        )
    )
};
export default FixedQuestions;