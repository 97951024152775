import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { ApiRequests } from '../https/ApiRequests';
import { api_urls, organization_id } from '../../constants/constants';
import closeIcon from '../../images/icons/close-event.png';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './calendar.css';

const ViewEventForm = (props) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(1);
  const [getPilots, setPilots] = useState([]);
  const [eventData, setEventData] = useState({});
  const [getCustomer, setCustomer] = useState('');
  const [getpilot, setpilot] = useState(null);

  const schema = Yup.object().shape({
    // title: Yup.string().required('It is required'),
    appStartDate: Yup.string().required('Start date is required'),
    appEndDate: Yup.string().required('End date is required'),
    // startPlace: Yup.string().required('Start destination is required'),
    // endPlace: Yup.string().required('End destination is required'),
    // aircrafts: Yup.string().required('Aircraft is required'),
    // customer: Yup.array().min(1, 'Customer is required'),
    // pilot: Yup.string().required('Pilot is required'),
  });

  useEffect(() => {
    fetchAppointment(props.id);
  }, []);

  const fetchAppointment = async (id) => {
    let apiUrl = `${api_urls.getAllAppointments}${id}/`;
    let resp = await ApiRequests(apiUrl, 'GET', null);
    getSpecificPilot(resp.data.assigned_pilot_profile);
    setEventData(resp.data);
    let statustemp = resp.data.type === 'day_off' ? '2' : '1';
    setStatus(statustemp);
    let temp = resp.data.customer;
    let str = '';
    for (let i = 0; i < temp.length; i++) {
      str = str + '' + temp[i].name + ', ';
    }
    setCustomer(str.slice(0, str.length - 2));
  };

  const getSpecificPilot = async (pilot_id) => {
    let apiUrl = `${api_urls.pilotProfile}${pilot_id}/`;
    let resp = await ApiRequests(apiUrl, 'GET', null);
    setpilot(resp.data.owner.name);
  };

  const {
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      title: '',
      appStartDate: '',
      appEndDate: '',
      startPlace: '',
      endPlace: '',
      aircrafts: '',
      customer: [],
      pilot: '',
    },
    validationSchema: schema,
    onSubmit: async () => {
    },
  });

  return (
    <div>
      <Modal
        {...props}
        size="md"
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        className="delete-acc-modal"
        centered>
        <div className="event-header">
          <Modal.Header className="heading border-bottom-0 p-0">
            Add New
          </Modal.Header>
          <img
            src={closeIcon}
            style={{ cursor: 'pointer' }}
            className="event-close cursor-pointer"
            onClick={() => props.onClose()}
          />
        </div>
        <>
          <div className="radio-container">
            <div className="appointment-btn">
              <input type="radio" name="release" checked={status === '1'} />
              <label
                for="Appointment"
                style={{ color: status === '2' ? '#9CA3AF' : '#000000' }}>
                Appointment
              </label>
            </div>

            <div className="appointment-btn">
              <input type="radio" name="release" checked={status === '2'} />
              <label
                for="Appointment"
                style={{ color: status === '2' ? '#000000' : '#9CA3AF' }}>
                Pilot Leave
              </label>
            </div>
          </div>
          {status === '1' && (
            <Modal.Body className="modal-body-body">
              <Form onSubmit={handleSubmit} className="add-event-form">
                <Form.Group
                  controlId="title"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.title && errors.title ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="title"
                    disabled
                    value={eventData.title}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && errors.title}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Appointment title
                  </label>

                  {touched.title && errors.title ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="appStartDate"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.appStartDate && errors.appStartDate
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="datetime-local"
                    name="appStartDate"
                    disabled
                    placeholder="appStartDate"
                    value={eventData.time}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.appStartDate && errors.appStartDate}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Start Date and time
                  </label>

                  {touched.appStartDate && errors.appStartDate ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.appStartDate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="appEndDate"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.appEndDate && errors.appEndDate
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    disabled
                    type="datetime-local"
                    name="appEndDate"
                    placeholder="appEndDate"
                    value={eventData.end_time}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.appEndDate && errors.appEndDate}
                  />
                  <label for="floatingInput" className="greyLabel">
                    End Date and time
                  </label>

                  {touched.appEndDate && errors.appEndDate ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.appEndDate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="pilot"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.pilot && errors.pilot ? 'has-validation-error' : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="appEndDate"
                    disabled
                    placeholder={
                      <div className="select-placeholder-text">
                        Select pilot
                      </div>
                    }
                    value={getpilot}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.pilot && errors.pilot}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Pilot
                  </label>
                  {touched.pilot && errors.pilot ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.pilot}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="aircraft"
                  className={`form-floating viewaircraft-Box w-100 ${touched.aircraft && errors.pilot
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="aircraft"
                    placeholder={
                      <div className="select-placeholder-text">
                        Select aircraft
                      </div>
                    }
                    value={eventData.aircraft ? eventData.aircraft.tail_number : ''}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.aircraft && errors.aircraft}
                    disabled
                  />
                  <label for="floatingInput" className="greyLabel">
                    Aircraft
                  </label>
                  {touched.aircraft && errors.aircraft ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.aircraft}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="customer"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.customer && errors.customer
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="customer"
                    disabled
                    placeholder={
                      <div className="select-placeholder-text">Customer</div>
                    }
                    value={getCustomer}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.customer && errors.customer}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Customer(s)
                  </label>
                  {touched.customer && errors.customer ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.customer}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="startPlace"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.startPlace && errors.startPlace
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="startPlace"
                    disabled
                    placeholder="startPlace"
                    value={eventData.start_location}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.startPlace && errors.startPlace}
                  />
                  <label for="floatingInput" className="greyLabel">
                    Start destination
                  </label>

                  {touched.startPlace && errors.startPlace ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.startPlace}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  controlId="startPlace"
                  className={`form-floating viewPilot-Box m-0 w-100 ${touched.startPlace && errors.startPlace
                      ? 'has-validation-error'
                      : ''
                    }`}>
                  <Form.Control
                    type="text"
                    name="endPlace"
                    disabled
                    placeholder="endPlace"
                    value={eventData.drop_location}
                    className={props.readOnly ? 'disabledColor' : ''}
                    readOnly={props.readOnly}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.endPlace && errors.endPlace}
                  />
                  <label for="floatingInput" className="greyLabel">
                    End destination
                  </label>

                  {touched.endPlace && errors.endPlace ? null : (
                    <div className="input-group-append "></div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    {errors.endPlace}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form>
            </Modal.Body>
          )
          }
          {
            status === '2' &&
            (
              <Modal.Body className="modal-body-body">
                <Form onSubmit={handleSubmit} className="add-event-form">
                  <Form.Group
                    controlId="appStartDate"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.appStartDate && errors.appStartDate
                        ? 'has-validation-error'
                        : ''
                      }`}>
                    <Form.Control
                      disabled
                      type="datetime-local"
                      name="appStartDate"
                      placeholder="appStartDate"
                      value={eventData.time}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.appStartDate && errors.appStartDate}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Start Date and time
                    </label>

                    {touched.appStartDate && errors.appStartDate ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.appStartDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="appEndDate"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.appEndDate && errors.appEndDate
                        ? 'has-validation-error'
                        : ''
                      }`}>
                    <Form.Control
                      type="datetime-local"
                      name="appEndDate"
                      disabled
                      placeholder="appEndDate"
                      value={eventData.end_time}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.appEndDate && errors.appEndDate}
                    />
                    <label for="floatingInput" className="greyLabel">
                      End Date and time
                    </label>

                    {touched.appEndDate && errors.appEndDate ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.appEndDate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="pilot"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.pilot && errors.pilot ? 'has-validation-error' : ''
                      }`}>
                    <Form.Control
                      type="text"
                      name="pilot"
                      disabled
                      placeholder={
                        <div className="select-placeholder-text">
                          Select pilot
                        </div>
                      }
                      value={getpilot}
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.pilot && errors.pilot}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Pilot
                    </label>
                    {touched.pilot && errors.pilot ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.pilot}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="title"
                    className={`form-floating viewPilot-Box m-0 w-100 ${touched.title && errors.title ? 'has-validation-error' : ''
                      }`}>
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="title"
                      value={eventData.title}
                      disabled
                      className={props.readOnly ? 'disabledColor' : ''}
                      readOnly={props.readOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.title && errors.title}
                    />
                    <label for="floatingInput" className="greyLabel">
                      Additional comments
                    </label>

                    {touched.title && errors.title ? null : (
                      <div className="input-group-append "></div>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </Modal.Body>
            )}
        </>
      </Modal>
    </div>
  );
};

export default ViewEventForm;
